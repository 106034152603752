import * as React from 'react';
import PageHeader from '../layout/PageHeader';
import Loading from '../api/Loading.jsx';
import { Usuario } from '../modelos/usuario';
import { getUsuario, atualizarUsuario } from '../api/api';
import copiaProfunda from '../utils/copiaProfunda';
import getFetchErroComponent from '../utils/erroCompotent';
import Swal from 'sweetalert2';

interface State {
  usuario: Usuario;
  carregando: boolean;
  loadingErro: boolean;
  bloquearBnts: boolean;
  erroAtualizarNome: boolean;
  errorMessagePass: string;
}

export default class EditarConta extends React.Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
	  usuario: null,
	  carregando: true,
	  loadingErro: false,
	  bloquearBnts: false,
	  erroAtualizarNome: false,
	  errorMessagePass: ''
    };
  }

  componentDidMount() {
	  this.getUsuario();
  }

  getUsuario = async () => {
	this.setState({loadingErro: false, carregando: true});
	try {
		let usuario = await getUsuario();
		this.setState({
						usuario,
						loadingErro: false,
						carregando: false
					});
	} catch(err) {
		Swal('Falha', err.response.data.message, 'error');
		this.setState({loadingErro: true, carregando: false});
	}
  }

  confirmaSenha = ''
  atualizarUsuario = async (qual, ev) => {
	  ev.preventDefault();
	if(
		qual === 'senha' &&
		this.state.usuario.senha !== this.confirmaSenha
	) {
		this.setState({errorMessagePass: 'As senhas não conferem'});
		return;
	}
	this.setState({bloquearBnts: true});

	let usuarioAtualizado = this.state.usuario;
	try {
		if(qual == 'senha')
			usuarioAtualizado = await atualizarUsuario({senha: this.state.usuario.senha});
		if(qual == 'nome')
			usuarioAtualizado = await atualizarUsuario({nome: this.state.usuario.nome});
	} catch(err) {
		if(qual == 'senha')
			this.setState({errorMessagePass: 'Falha ao atualizar a senha', bloquearBnts: false});
		if(qual == 'nome')
			this.setState({erroAtualizarNome: true, bloquearBnts: false});
		return;
	}

	this.setState({
		usuario: usuarioAtualizado,
		bloquearBnts: false,
		erroAtualizarNome: false,
		errorMessagePass: ''
	}, () =>
		Swal('Sucesso', 'Usuário atualizado com sucesso', 'success')
		.then(() => document.location.reload())
	);
  }

  render() {
	let conteudo = null;
	if(this.state.carregando) 
		conteudo = <Loading />
	else if(this.state.loadingErro)
		conteudo = getFetchErroComponent(this.getUsuario);
	else
		conteudo = this.renderForm();


    return (
      <div className="content-inner">
		<div className="container-fluid mt-4">
			<div className="row">
				<div className="col">
					<div className="card">
						<div className="card-header d-flex align-items-center">
							<h3 className="h4 lead">Editar Conta</h3>
						</div>
						<div className="card-body">
							{conteudo}
						</div>
					</div>
				</div>
			</div>
		</div>
      </div>
    );
  }

  handleChange = (chave, ev) => {
	  const copiaUsuario = copiaProfunda(this.state.usuario) as Usuario;
	  copiaUsuario[chave] = ev.target.value;
	  this.setState({usuario: copiaUsuario});
  }

  renderForm = () => {
	  return (<>
	  <form
		style={{
			padding: '10px'
		}}
	  >
		<div className="form-group">
			<label>Nome completo:</label>
			<input
					type="text"
					className="form-control"
					placeholder="Nome"
					value={this.state.usuario.nome}
					onChange={ev => this.handleChange('nome', ev)}
			/>
		</div>
		<div className="form-group">
			<label>Email:</label>
			<input 
					className="form-control"
					type="text"
					value={this.state.usuario.email}
					readOnly
			/>
		</div>
		<div className="row">
			<div className="col-6 col-md-9">
				{
					this.state.erroAtualizarNome
					? <div className="alert alert-danger">
							Falha ao atualizar
						</div>
					: null
				}
			</div>
			<div className="col-6 col-md-3">
				<button
						className="btn btn-primary"
						onClick={ev => this.atualizarUsuario('nome', ev)}
						disabled={this.state.bloquearBnts}
				>Atualizar</button>
			</div>
		</div>

		</form>
		<hr />
		<form
			style={{
				backgroundColor: "#f6f6f6",
				padding: '10px',
				borderRadius: '5px',
				marginTop: '25px'
			}}
		>

		<h4
			style={{
				borderBottom: "1px solid #d3d3d3",
				textAlign: "end"
			}}
		>Alterar senha</h4>
		<div className="form-group">
			<label>Nova senha</label>
			<input
					type="password"
					className="form-control"
					placeholder="Nova senha"
					onChange={ev => this.handleChange('senha', ev)}
			/>
		</div>
		<div className="form-group">
			<label>Repita a nova senha</label>
			<input
					type="password"
					className="form-control"
					placeholder="Confirmar nova senha"
					onChange={ev => this.confirmaSenha = ev.target.value}
			/>
		</div>
		<div className="row">
			<div className="col-6 col-md-9">
			{
				this.state.errorMessagePass != ""
				? (<div className="alert alert-danger">
						{this.state.errorMessagePass}
					</div>
				)
				: null
			}
			</div>
			<div className="col-6 col-md-3">
				<button
						className="btn btn-primary"
						disabled={this.state.bloquearBnts}
						onClick={ev => this.atualizarUsuario('senha', ev)}
				>Alterar</button>
			</div>
		</div>
	</form></>);
  }

}

import moment from 'moment';
/*
  Os campos 'chave' de cada uma destas perguntas precisam
  ter um mapeamento "um para um" com 'DadosColetaEventosAdversos'
  em /src/modelos/index.ts.
*/
export default function getEventosAdversosComDados(coleta) {
  return [
    { type: 'header', text: 'Identificação do prontuário' },
    { type: 'header', text: 'Eventos adversos na mãe' },
    {
      identificador: 'P9',
      chave: 'ocorreuMorteMaterna',
      type: 'radio',
      label: 'Ocorreu morte materna?',
      options: ['Sim', 'Não'],
      obrigatorio: true,
      value: coleta.ocorreuMorteMaterna ? 'Sim' : 'Não'
    },
    {
      identificador: 'P10',
      chave: 'houveLaceracao34grau',
      type: 'radio',
      label: 'Ocorreu laceração de 3º ou 4º grau?',
      options: ['Sim', 'Não'],
      obrigatorio: true,
      value: coleta.houveLaceracao34grau ? 'Sim' : 'Não'
    },
    {
      identificador: 'P11',
      chave: 'maeRecebeuTransfusaoSangue',
      type: 'radio',
      label: 'A mãe recebeu transfusão sanguínea?',
      options: ['Sim', 'Não'],
      obrigatorio: true,
      value: coleta.maeRecebeuTransfusaoSangue ? 'Sim' : 'Não'
    },
    {
      identificador: 'P12',
      chave: 'houveRupturaUterina',
      type: 'radio',
      label: 'Ocorreu ruptura uterina?',
      options: ['Sim', 'Não'],
      obrigatorio: true,
      value: coleta.houveRupturaUterina ? 'Sim' : 'Não'
    },
    {
      identificador: 'P13',
      chave: 'reinternacaoMae',
      type: 'radio',
      label: 'Ocorreu retorno à sala de parto ou de cirurgia?',
      options: ['Sim', 'Não'],
      obrigatorio: true,
      value: coleta.reinternacaoMae ? 'Sim' : 'Não'
    },
    {
      identificador: 'P14',
      chave: 'maeInternadaUTI',
      type: 'radio',
      label: 'A mãe foi internada em Unidade de Terapia Intensiva?',
      options: ['Sim', 'Não'],
      obrigatorio: true,
      value: coleta.maeInternadaUTI ? 'Sim' : 'Não'
    },
    { type: 'header', text: 'Complicações e eventos adversos no recém-nascido' },
    {
      identificador: 'P15',
      chave: 'rnMorteNeonatal',
      type: 'radio',
      label: 'Ocorreu morte neonatal?',
      options: ['Sim', 'Não'],
      obrigatorio: true,
      value: coleta.rnMorteNeonatal ? 'Sim' : 'Não'
    },
    {
      identificador: 'P16',
      chave: 'rnTraumaLesao',
      type: 'radio',
      label: 'O Recém-nascido sofreu algum trauma ou lesão em decorrência do parto?',
      options: ['Sim', 'Não'],
      obrigatorio: true,
      value: coleta.rnTraumaLesao ? 'Sim' : 'Não'
    },
    {
      identificador: 'P17',
      chave: 'rnApgarMenor7',
      type: 'radio',
      label: 'Ocorreu Apgar menor que 7 no 5º minuto?',
      options: ['Sim', 'Não'],
      obrigatorio: true,
      value: coleta.rnApgarMenor7 ? 'Sim' : 'Não'
    },
    {
      identificador: 'P18',
      chave: 'rnInternadoUTIMaisde24h',
      type: 'radio',
      label: 'O Recém-nascido foi internado com peso normal(>2500g) em UTI por mais de 24 horas?',
      options: ['Sim', 'Não'],
      obrigatorio: true,
      value: coleta.rnInternadoUTIMaisde24h ? 'Sim' : 'Não'
    },
    { type: 'header', text: 'Observações a respeito da coleta' },
    {
      identificador: 'P19',
      chave: 'observacoes',
      type: 'textarea',
      label: 'Observações(opcional)',
      value: coleta.observacoes
    }
  ];
}

export function getPerguntasRN(id: number) {
  return [{ type: 'header', text: 'Complicações e eventos adversos no recém-nascido 0' + id },
  {
    identificador: `RN0${id}-P01`,
    chave: 'rnMorteNeonatal',
    type: 'checkbox',
    label: 'Ocorreu morte neonatal?',
    value: false
  },
  {
    identificador: `RN0${id}-P02`,
    chave: 'rnTraumaLesao',
    type: 'checkbox',
    label: 'O Recém-nascido sofreu algum trauma ou lesão em decorrência do parto?',
    value: false
  },
  {
    identificador: `RN0${id}-P03`,
    chave: 'rnApgarMenor7',
    type: 'checkbox',
    label: 'Ocorreu Apgar menor que 7 no 5º minuto?',
    value: false
  },
  {
    identificador: `RN0${id}-P04`,
    chave: 'rnInternadoUTIMaisde24h',
    type: 'checkbox',
    label: 'O Recém-nascido foi internado com peso normal(>2500g) em UTI por mais de 24 horas?',
    value: false
  },
  {
    identificador: `RN0${id}-P05`,
    chave: 'rnPeso',
    type: 'number',
    label: 'Qual o peso do Recém-nascido ao nascer? (em gramas)',
    value: '',
    obrigatorio: true,
    valorMinimo: 0
  }
  ];
};

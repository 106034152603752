import { Container } from 'unstated';
import { Usuario } from './modelos/usuario';

export interface AppState {
  usuario: Usuario;
}

export class AppStore extends Container<AppState> {
  constructor(usuario: Usuario) {
    super();
    this.state = {
      usuario
    };
  }
}

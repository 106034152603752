import * as React from 'react';
import '../Estilos.css';
import copiaProfunda from '../../utils/copiaProfunda';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import data from './PerguntasBoasPraticas';
import {
	getHospitailById,
	salvarDadosBoasPraticas,
	obterColetaContinuar,
	atualizarDadosBoasPraticas
} from '../../api/api';
import Swal from 'sweetalert';
import { Redirect } from 'react-router-dom';
import { getUrlParameter, getNumColetas } from '../../utils';
import moment from 'moment';
import { DadosColetaEventosAdversos } from '../../modelos';
import getPerguntasComDados from './PerguntasBoasPraticasComDados';

interface State {
	dadosEA: DadosColetaEventosAdversos[] | null;
}
class QuestionarioBoasPraticas extends React.Component<any, any> {
	hospitalId: number;
	hospitalIdContinuar = Number(this.props.location.pathname.split('/').slice(-1)[0]);

	constructor(props) {
		super(props);
		this.hospitalId = +props.match.params.id;
		this.state = {
			isContinuar: this.props.location.pathname.indexOf('continuar') >= 0 ? true : false,
			isCadastro: false,
			carregando: false,
			perguntas: data,
			mudarTela: null,
			CNES: '',
			numeroProntuario: null,
			numCL: null,
			numBP: null,
			numEA: null,
			numPG: null,
			jaTemEA: null,
			jaTemCL: null,
			jaTemBP: null,
			dataParto: null,
			coletaBoasPraticas: []
		};
	}

	componentDidMount() {
		if (Number.isNaN(this.hospitalId)) { this.hospitalId = this.hospitalIdContinuar }
		this.getCNESHospital(this.hospitalId);
		this.setDados();
	}

	getCNESHospital = async id => {
		const H = await getHospitailById(id);
		this.setState({ CNES: H.cnes });
	}

	setInfoGerais(numeroProntuario, dataParto) {
		this.state.perguntas.forEach((pergunta) => {
			if (pergunta.chave == "numeroProntuario" && numeroProntuario)
				pergunta.value = numeroProntuario;
			if (pergunta.chave == "dataParto" && dataParto)
				pergunta.value = moment(dataParto);
		});
	}

	setDados = async () => {
		const numeroProntuario = getUrlParameter('numeroProntuario');
		const dataParto = getUrlParameter('dataParto');
		this.setState({
			dataParto: dataParto,
			numeroProntuario: numeroProntuario
		});
		if (this.state.isContinuar) {
			const coletaBP = await obterColetaContinuar(parseInt(numeroProntuario), this.hospitalId, dataParto.concat("T00:00:00.000Z"), '/boaspraticas/continuar/prontuario/');
			if (coletaBP[0] != undefined) {
				const coleta = await getPerguntasComDados(coletaBP[0]);
				this.setState({
					coletaBoasPraticas: coletaBP[0],
					CNES: coletaBP[0].hospital.cnes,
					perguntas: coleta,
					isCadastro: true
				});
			}
		}
		if(!this.state.isCadastro){
			const coletaSalvaStringificado = localStorage.getItem('boasPraticas');
			if (coletaSalvaStringificado !== null) {
				const coletaSalva = JSON.parse(coletaSalvaStringificado);
				if (coletaSalva[1].value == numeroProntuario && coletaSalva[2].value.split("T")[0] == dataParto) {
					let ind = 0;
					this.state.perguntas.forEach((pergunta) => {
						if (pergunta.chave == "numeroProntuario" && numeroProntuario)
							pergunta.value = numeroProntuario;
						else if (pergunta.chave == "dataParto" && dataParto)
							pergunta.value = moment(dataParto);
						else if (pergunta.type != "header")
							pergunta.value = coletaSalva[ind].value;
						ind++;
					});
				} else {
					this.state.perguntas.forEach((pergunta) => {
						if (pergunta.type == "input" || pergunta.type == "number" || pergunta.type == "textarea")
							pergunta.value = '';
						else if (pergunta.type != "header")
							pergunta.value = null;
					});
					localStorage.removeItem('boasPraticas');
					this.setInfoGerais(numeroProntuario, dataParto)
				}
			} else { this.setInfoGerais(numeroProntuario, dataParto) }
		}
		if (dataParto || numeroProntuario) {
			const hospId = this.hospitalId;
			const dadosNumColeta = await getNumColetas(numeroProntuario, dataParto, hospId);
			this.setState({
				numPartoGemelar: dadosNumColeta.quantEAPartoGemelar,
				numEA: dadosNumColeta.quantEA,
				numCL: dadosNumColeta.quantCL,
				numBP: dadosNumColeta.quantBP,
				jaTemEA: dadosNumColeta.jaTemEA,
				jaTemCL: dadosNumColeta.jaTemCL,
				jaTemBP: dadosNumColeta.jaTemBP
			});
		}
	}

	atualizar = async () => {
		if (!this.validarQuestionarios()) {
			Swal(
				'Oops',
				'Verifique os campos obrigatórios',
				'error'
			);
			return;
		}
		const dadosColeta = { id: this.state.coletaBoasPraticas.id }
		this.state.perguntas.forEach((pergunta) => {
			let valor = pergunta.value;
			if (pergunta.type === 'radio' && pergunta.options.length === 2) {
				if (pergunta.value !== null) {
					if (pergunta.value === 'Sim')
						valor = true;
					else if (pergunta.value === 'Não')
						valor = false;
					else
						valor = pergunta.value
				}
				else
					valor = null
			} else if (pergunta.type === 'date' && pergunta.value) {
				const d: Date = pergunta.value.toDate()
				valor = `${d.getFullYear()}-${(d.getMonth() + 1) > 9
					? (d.getMonth() + 1)
					: '0' + (d.getMonth() + 1)
					}-${d.getDate() > 9
						? d.getDate()
						: '0' + d.getDate()
					}`;
			} else if (pergunta.type === 'number' && pergunta.value) {
				valor = Number(pergunta.value)
			}

			dadosColeta[pergunta.chave] = valor === 'Não se aplica' ? 'NA' : valor;
		});
		this.setState({ carregando: true });

		atualizarDadosBoasPraticas(dadosColeta)
			.then(() => {
				Swal(
					'Concluído',
					'Sucesso ao atualizar a coleta',
					'success'
				)
					.then(() => window.location.reload());
			})
			.catch((err) => {
				Swal('Falha', err.response.data.message, 'error');
				this.setState({ carregando: false });
			});
	};

	enviar = async () => {
		if (!this.validarQuestionarios()) {
			Swal(
				'Oops',
				'Verifique os campos obrigatórios',
				'error'
			);
			return;
		}

		const dadosColeta = {
			hospital: { id: this.hospitalId }
		};
		this.state.perguntas.forEach((pergunta) => {
			let valor = pergunta.value;

			if (pergunta.type === 'radio' && pergunta.options.length === 2) {
				if (pergunta.value !== null) {
					if (pergunta.value === 'Sim')
						valor = true;
					else if (pergunta.value === 'Não')
						valor = false;
					else
						valor = pergunta.value
				}
				else
					valor = null
			} else if (pergunta.type === 'date' && pergunta.value) {
				const d: Date = pergunta.value.toDate()
				valor = `${d.getFullYear()}-${(d.getMonth() + 1) > 9
					? (d.getMonth() + 1)
					: '0' + (d.getMonth() + 1)
					}-${d.getDate() > 9
						? d.getDate()
						: '0' + d.getDate()
					}`;
			} else if (pergunta.type === 'select' && pergunta.value) {
				if (pergunta.value.startsWith('Grupo ')) {
					const temp = pergunta.value.split(':')[0].split(' ')[1];
					valor = Number(temp);
				}
			}
			dadosColeta[pergunta.chave] = valor === 'Não se aplica' ? 'NA' : valor;
		});
		salvarDadosBoasPraticas(dadosColeta)
			.then((resp) => {
				this.setState({ numeroProntuario: resp.data.numeroProntuario });
				this.setState({ dataParto: resp.data.dataParto });
				localStorage.setItem('boasPraticas', JSON.stringify(this.state.perguntas));
				Swal(
					'Concluído',
					'Sucesso ao salvar a Coleta',
					'success'
				)
					.then(() => this.selecionarOpcoesTela());
			})
			.catch((err) => {
				Swal('Falha', err.response.data.message, 'error');
				this.setState({ carregando: false });
			});
	};

	handleChange(valor, identificador) {
		const i = this.identificador2Indice(this.state.perguntas, identificador);
		this.setState((state) => {
			const copia = copiaProfunda(state.perguntas);
			copia[i].value = valor;
			return { perguntas: copia };
		});
	}

	indicesInvalidos = [];
	campoInvalidado(pergunta, identificador): boolean {
		const indice = this.identificador2Indice(this.state.perguntas, identificador);
		if (
			pergunta.obrigatorio
			&&
			this.indicesInvalidos.includes(indice)
		)
			return true;
		else
			return false;
	}

	identificador2Indice(perguntas, identificador) {
		for (let i = 0; i < perguntas.length; i++)
			if (perguntas[i].identificador === identificador)
				return i;
		throw new Error(`Identificador(${identificador}) não encontrado`);
	}

	chave2Indice(perguntas, chave) {
		for (let i = 0; i < perguntas.length; i++)
			if (perguntas[i].chave === chave)
				return i;
		throw new Error(`Chave(${chave}) não encontrada`);
	}

	/*
	  Retorna se o questionário possui campos obrigatórios não preenchidos
	  e registra os indices das perguntas invalidas no 'indicesInvalidos'
	*/
	validarQuestionarios(): boolean {
		this.indicesInvalidos = [];
		this.state.perguntas.forEach((pergunta: any, i: number) => {

			const v = pergunta.value;
			let invalidado = false;

			if (pergunta.obrigatorio) {
				// Se esta pergunta só deve ser exibida de acordo com o valor de outra
				// Mas a outra não atendeu ao valor
				if (
					pergunta.condicao &&
					this.state.perguntas[this.chave2Indice(this.state.perguntas, pergunta.condicao)].value !== pergunta.valorCondicao
				) {
					invalidado = false;
				} else { // Se esta pergunta está na tela
					// Campo obrigatório não preenchido
					if (v === '' || v === null || v === undefined) {
						this.indicesInvalidos.push(i);
						invalidado = true;
					}
				}
			}

			if (!invalidado) { // O Campo não foi invalidado na anterior
				// Se o campo era obrigatório e foi preenchido com algum valor não zero
				if (pergunta.obrigatorio || Number(pergunta.value) !== 0) {
					if (
						pergunta.type === 'number'
						&& typeof pergunta.valorMinimo === 'number'
						&& Number(pergunta.value) < pergunta.valorMinimo
					) { // Valor inválido informado
						this.indicesInvalidos.push(i);
						invalidado = true;
					}
				}
			}

		});
		this.forceUpdate(); // Mostrando no front os campos invalidados
		return this.indicesInvalidos.length === 0;
	}

	render() {
		return (
			<div className="container-fluid">
				{this.mudarTelaColetaSeNescessario()}
				<div className="row justify-content-center mt-4">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header">
								<ul className="nav nav-tabs card-header-tabs">
									<li className="nav-item">
										<a
											className="nav-link"
											href=""
											onClick={e => {
												e.preventDefault();
												this.mudarTela('ig');
											}}
										>
											Informações Gerais
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											href=""
											onClick={e => {
												e.preventDefault();
												this.mudarTela('ea');
											}}
										>
											Eventos adversos
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											href=""
											onClick={e => {
												e.preventDefault();
												this.mudarTela('checklist');
											}}
										>
											Adesão ao checklist para parto seguro
										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link active">
											Boas práticas
										</a>
									</li>
								</ul>
							</div>

							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<h4 className="text-muted">CNES do Hospital: {this.state.CNES}</h4>
									</div>
									{this.state.numPartoGemelar > 0 ? <div className="col-md-12">
										<h4>COLETA DE PARTO GEMELAR <i className="fas fa-user-friends"></i> IDENTIFICADA:</h4>
										<h4 className="ml-5">{this.state.numBP > 0 ? <span>{this.state.numBP} COLETA{this.state.numBP > 1 ? "S" : ""} DE BOAS PRÁTICAS.</span> : ''}</h4>
										<h4 className="ml-5">{this.state.numCL > 0 ? <span>{this.state.numCL} COLETA{this.state.numCL > 1 ? "S" : ""} DE CHECKLIST.</span> : ''}</h4>
										<h4 className="ml-5">{this.state.numEA > 0 ? <span>{this.state.numEA} COLETA{this.state.numEA > 1 ? "S" : ""} DE EVENTOS ADVERSOS.</span> : ''}</h4>
									</div> : ''}
								</div>
								{
									this.state.perguntas
										.map(pergunta => this.perguntaToFront(pergunta))
								}
								<div className="row justify-content-center">
									<div className="col-6">
										<button
											disabled={this.state.carregando}
											type="button"
											className="btn btn-primary btn-block"
											onClick={this.state.isCadastro ? this.atualizar : this.enviar}
										> {this.state.isCadastro ? (this.state.carregando ? 'Atualizando...' : 'Atualizar') : (this.state.carregando ? 'Enviando...' : 'Enviar')}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	/*
		  Esta função mapeia cada pergunta passada para um conjunto de perguntas no front
	*/
	perguntaToFront = pergunta => {
		if (
			pergunta.condicao &&
			this.state.perguntas[this.chave2Indice(this.state.perguntas, pergunta.condicao)].value !== pergunta.valorCondicao
		) {
			return null;
		}
		if (pergunta.type === 'text' || pergunta.type === 'number') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<label>
						<b>({pergunta.identificador})</b> - {pergunta.label}:
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
						{
							pergunta.descricao
								? <a
									className="fas fa-info ml-2"
									data-toggle="collapse"
									title="Saber mais"
									href={"#collapse" + pergunta.identificador}
									role="button"
									aria-expanded="false"
									aria-controls={"collapse" + pergunta.identificador}
								/>
								: null
						}
					</label>
					{
						pergunta.descricao
							? (<div
								className="collapse"
								id={"collapse" + pergunta.identificador}
								style={{
									paddingLeft: '30px',
									marginBottom: '30px'
								}}
							>
								<span
									className="text-muted"
									style={{
										fontSize: '11pt'
									}}
								>
									{pergunta.descricao}
								</span>
							</div>)
							: null
					}
					{
						pergunta.chave == 'numeroProntuario'
							?
							<input
								type={pergunta.type}
								value={pergunta.value}
								disabled
								onChange={(e) => this.handleChange(e.target.value, pergunta.identificador)}
								className={
									"form-control"
									+ (this.campoInvalidado(pergunta, pergunta.identificador)
										? " is-invalid"
										: ""
									)
								}
							/>
							:
							<input
								type={pergunta.type}
								value={pergunta.value}
								onChange={(e) => this.handleChange(e.target.value, pergunta.identificador)}
								className={
									"form-control"
									+ (this.campoInvalidado(pergunta, pergunta.identificador)
										? " is-invalid"
										: ""
									)
								}
							/>
					}
					<div className="invalid-feedback">
						Campo obrigatório
					</div>
				</div>
			);
		}
		if (pergunta.type === 'select') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<label>
						<b>({pergunta.identificador})</b> - {pergunta.label}:
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
						{
							pergunta.descricao
								? <a
									className="fas fa-info ml-2"
									data-toggle="collapse"
									title="Saber mais"
									href={"#collapse" + pergunta.identificador}
									role="button"
									aria-expanded="false"
									aria-controls={"collapse" + pergunta.identificador}
								/>
								: null
						}
					</label>
					<select
						value={pergunta.value}
						onChange={(e) => this.handleChange(e.target.value, pergunta.identificador)}
						className="form-control">
						{pergunta.options.map((option) => (
							<option value={option} key={option}>
								{option}
							</option>
						))}
					</select>
					{
						pergunta.descricao
							? (<div
								className="collapse"
								id={"collapse" + pergunta.identificador}
								style={{
									paddingLeft: '30px',
									marginBottom: '30px'
								}}
							>
								<span
									className="text-muted"
									style={{
										fontSize: '11pt'
									}}
								>
									{pergunta.descricao}
								</span>
							</div>)
							: null
					}
				</div>
			);
		}
		if (pergunta.type === 'header') {
			return (
				<div key={pergunta.text}>
					<h4 className="text-center mt-4">{pergunta.text}</h4>
					<hr className="mb-4" />
				</div>
			);
		}
		if (pergunta.type === 'date') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<label>
						<b>({pergunta.identificador})</b> - {pergunta.label}
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
						{
							pergunta.descricao
								? <a
									className="fas fa-info ml-2"
									data-toggle="collapse"
									title="Saber mais"
									href={"#collapse" + pergunta.identificador}
									role="button"
									aria-expanded="false"
									aria-controls={"collapse" + pergunta.identificador}
								/>
								: null
						}
					</label>
					{
						pergunta.chave == "dataParto"
							? <DatePicker
								selected={pergunta.value}
								disabled
								onChange={(date) => this.handleChange(date, pergunta.identificador)}
								className={
									"form-control datepicker"
									+ (this.campoInvalidado(pergunta, pergunta.identificador)
										? " is-invalid"
										: ""
									)
								}
							/>
							: <DatePicker
								selected={pergunta.value}
								onChange={(date) => this.handleChange(date, pergunta.identificador)}
								className={
									"form-control datepicker"
									+ (this.campoInvalidado(pergunta, pergunta.identificador)
										? " is-invalid"
										: ""
									)
								}
							/>
					}
					{
						this.campoInvalidado(pergunta, pergunta.identificador)
							? (
								<div className="invalid-feedback d-block">
									Campo obrigatório
								</div>
							)
							: null
					}
					{
						pergunta.descricao
							? (<div
								className="collapse"
								id={"collapse" + pergunta.identificador}
								style={{
									paddingLeft: '30px',
									marginBottom: '30px'
								}}
							>
								<span
									className="text-muted"
									style={{
										fontSize: '11pt'
									}}
								>
									{pergunta.descricao}
								</span>
							</div>)
							: null
					}
				</div>
			);
		}
		if (pergunta.type === 'radio') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<p
						style={{
							marginBottom: this.campoInvalidado(pergunta, pergunta.identificador) || pergunta.descricao
								? '0px'
								: '1rem'
						}}
					>
						<b>({pergunta.identificador})</b> - {pergunta.label}
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
						{
							pergunta.descricao
								? <a
									className="fas fa-info ml-2"
									data-toggle="collapse"
									title="Saber mais"
									href={"#collapse" + pergunta.identificador}
									role="button"
									aria-expanded="false"
									aria-controls={"collapse" + pergunta.identificador}
								/>
								: null
						}
					</p>
					{
						this.campoInvalidado(pergunta, pergunta.identificador)
							? (
								<div className="invalid-feedback d-block">
									Campo obrigatório
								</div>
							)
							: null
					}
					{
						pergunta.descricao
							? (<div
								className="collapse"
								id={"collapse" + pergunta.identificador}
								style={{
									paddingLeft: '20px',
								}}
							>
								<span
									className="text-muted"
									style={{
										fontSize: '11pt'
									}}
								>
									{pergunta.descricao}
								</span>
							</div>)
							: null
					}
					{pergunta.options.map((p, i) => (
						<button
							key={i}
							type="button"
							className={`btn ${p === pergunta.value ? 'btn-info' : 'btn-outline-info'} mr-2`}
							onClick={(e) => this.handleChange(p, pergunta.identificador)}>
							{p}
						</button>
					))}
				</div>
			);
		}
		if (pergunta.type === 'label') {
			return (
				<h5 className="text-center mt-4" key={pergunta.identificador}>
					{pergunta.label}
				</h5>
			);
		}
		if (pergunta.type === 'textarea') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<label>
						<b>({pergunta.identificador})</b> - {pergunta.label}:
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
					</label>
					<textarea
						rows={5}
						placeholder={pergunta.descricao}
						value={pergunta.value}
						onChange={(e) => this.handleChange(e.target.value, pergunta.identificador)}
						className={
							"form-control"
							+ (this.campoInvalidado(pergunta, pergunta.identificador)
								? " is-invalid"
								: ""
							)
						}
					/>
					<div className="invalid-feedback">
						Campo obrigatório
					</div>
				</div>
			);
		}
		return null;
	};

	mudarTela = qual => {
		localStorage.setItem('boasPraticas', JSON.stringify(this.state.perguntas));
		this.setState({ mudarTela: qual });
	};
	selecionarOpcoesTela = () => {
		let configProxPag = null;
		let sair = false;
		let cont = 0;

		configProxPag = {
			buttons: {
				cancel: true
			}
		}
		if (!this.state.jaTemEA || this.state.numPartoGemelar > 0) {
			Object.assign(configProxPag["buttons"], {
				ea: {
					text: "Eventos Adversos",
					value: "ea",
				}
			});
			cont++;
		}
		if (!this.state.jaTemCL || this.state.numPartoGemelar > 0) {
			Object.assign(configProxPag["buttons"], {
				cl: {
					text: "Adesão ao Checklist",
					value: "checklist",
				}
			});
			cont++;
		}
		if (this.state.numPartoGemelar > 0) {
			Object.assign(configProxPag["buttons"], {
				bp: {
					text: "Boas Práticas",
					value: "bp",
				}
			});
			cont++;
		}
		if (cont == 0) { sair = true; }

		if (!sair) {
			Swal("Qual o próximo formulário que você deseja preencher?", configProxPag)
				.then((value) => {
					this.setState({ mudarTela: value });
					this.mudarTelaColetaSeNescessario();
				});
		} else {
			Swal(
				'Excelente!',
				'Identificamos que todas as coletas foram realizadas',
				'success'
			).then((value) => {
				this.setState({ mudarTela: "hospitais" });
				this.mudarTelaColetaSeNescessario();
			});
		}
	};
	mudarTelaColetaSeNescessario = () => {
		switch (this.state.mudarTela) {
			case 'ig':
				return <Redirect to={`/questionarios`
					+ (this.state.isContinuar ? `/continuar` : ``)
					+ `/informacoes-gerais/`
					+ this.hospitalId
					+ '?numeroProntuario=' + this.state.numeroProntuario
					+ '&dataParto=' + this.state.dataParto} />
			case 'ea':
				return <Redirect to={`/questionarios`
					+ (this.state.isContinuar ? `/continuar` : ``)
					+ `/eventos-adversos/`
					+ this.hospitalId
					+ '?numeroProntuario=' + this.state.numeroProntuario
					+ '&dataParto=' + this.state.dataParto} />
			case 'checklist':
				return <Redirect to={`/questionarios`
					+ (this.state.isContinuar ? `/continuar` : ``)
					+ `/adesao-checklist/`
					+ this.hospitalId
					+ '?numeroProntuario=' + this.state.numeroProntuario
					+ '&dataParto=' + this.state.dataParto} />
			case 'hospitais':
				return <Redirect to={`/relatorios/hospitais`} />
			default:
				return null;
		}
	};

}

export default QuestionarioBoasPraticas;

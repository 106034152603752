import React, { Component } from 'react';
import PageHeader from '../layout/PageHeader';
import ResumoHospital from './ResumoHospital';
import { Hospital } from '../modelos';
import { Usuario } from '../modelos/usuario';
import { getHospitaisColetados, getUsuario, getHospitais } from '../api/api';
import Loading from '../api/Loading';
import { Link } from 'react-router-dom';
import getFetchErroComponent from '../utils/erroCompotent';
import Swal from 'sweetalert2';

interface state {
	carregando: boolean;
	fetchErro: boolean;
	listaHospitais: Hospital[];
	conteudoE: Element;
	usuario: Usuario;
};
let conteudo = null;
interface props { };

class Inicio extends Component<props, state> {

	constructor(props) {
		super(props);

		this.state = {
			carregando: true,
			fetchErro: false,
			listaHospitais: [],
			conteudoE: null,
			usuario: null
		};
	}

	componentDidMount() {
		this.getHospitais();
	}

	getHospitais = async () => {
		this.setState({ carregando: true });
		try {
			let r = [];
			const usuario = await getUsuario();
			r = await getHospitaisColetados();
			conteudo = (<><PageHeader titulo="Resumo de resultados dos meus hospitais" />
				<ResumoHospital hospital={r[0]} listaHospitais={r} recarregar={false} /></>);
			this.setState({
				fetchErro: false,
				listaHospitais: r,
				conteudoE: conteudo,
				usuario: usuario,
			});
		} catch (err) {
			Swal('Falha', err.response.data.message, 'error');
			this.setState({ fetchErro: true });
		} finally {
			this.setState({ carregando: false });
		}
	}

	render() {
		if (this.state.carregando)
			conteudo = <Loading />
		else if (this.state.fetchErro)
			conteudo = getFetchErroComponent(this.getHospitais);
		else if (this.state.listaHospitais.length > 0)
			conteudo = <ResumoHospital hospital={this.state.listaHospitais[0]}
				listaHospitais={this.state.listaHospitais}
				recarregar={false} />;
		else
			conteudo = (
				<div className="d-flex flex-column justify-content-center align-items-center mt-3">
					<h3>Bem vindo(a) a Plataforma QualiParto</h3>
					<h4>Cadastre seu primeiro hospital no menu ao lado</h4>
				</div>);
		return (
			<div className="content-inner">
				{this.state.conteudoE}
			</div>
		);
	}

}


export default Inicio;

import * as React from 'react';
import { DateTime } from 'luxon';
import { DadosColetaEventosAdversos } from '../modelos';
import { contarEventosAdversosMae } from '../utils/eventos-adversos';
import RunChart from './RunChart';

interface Props {
  dados: DadosColetaEventosAdversos[];
}

export default function RunChartEAMae(props: Props) {
	const eventosAdversosPorQuinzena = {};
	const agrupadoPorMes = [];

    // Separar o evento adverso por quinzenas
    props.dados.forEach((coleta) => {
      // Já foi registrada essa data?
      if (eventosAdversosPorQuinzena[coleta.dataAltaObito]) {
        eventosAdversosPorQuinzena[coleta.dataAltaObito].total += 1;
      } else {
        // Ainda não, registrar pela primeira vez
        eventosAdversosPorQuinzena[coleta.dataAltaObito] = {
          total: 1,
          ea: 0
        };
	  }
	  const totalEventos = contarEventosAdversosMae(coleta);
	  if(totalEventos > 0)
	  	eventosAdversosPorQuinzena[coleta.dataAltaObito].ea += 1;
    });

    const sorted = Object.keys(eventosAdversosPorQuinzena)
    .sort((data1, data2) => {
      return DateTime.fromISO(data1).toMillis() - DateTime.fromISO(data2).toMillis();
    });

    const dados = sorted.map((dataAltaObito) => ({
        x: DateTime.fromISO(dataAltaObito).toMillis(),
        y: (eventosAdversosPorQuinzena[dataAltaObito].ea/eventosAdversosPorQuinzena[dataAltaObito].total)*100,
        total: eventosAdversosPorQuinzena[dataAltaObito].total
    }));
	
	let mesAtual = '';
	let totalMes = 1;
	let i = -1;
	dados.forEach((ponto, index) => {
	  let d = DateTime.fromMillis(ponto.x).toObject();
	  let mes = d.year+'-'+((d.month>9) ? d.month : '0'+d.month);
	  if(mesAtual !== mes) {
		  if(i >= 0)
			  agrupadoPorMes[i].y /= totalMes;
		  i++;
		  agrupadoPorMes[i] = {
			  x: DateTime.fromISO(mes+'-01').toMillis(),
			  y: ponto.y,
			  total: ponto.total
		  };
		  mesAtual = mes;
		  totalMes = 1;
	  } else {
		  agrupadoPorMes[i].y += ponto.y;
		  totalMes++;
	  }
	  if(index == dados.length-1) // Ultima iteração
		  agrupadoPorMes[i].y /= totalMes;
	});

  return <RunChart
		dados={agrupadoPorMes}
		exportavel={true}
		mostrarLegendas={true}
		titulo='Eventos Adversos maternos'
		nome='eventosadversosmaternos'
		yLabel='Percentual de EA'
		corLinha='#07a7e3'
		percentual={true}
  />;
}
import React from 'react';
import Loading from './Loading';
export default function LoadingReset() {
  return (
    
    <div className='content'>
      <Loading />
      <p style={{
        color: 'grey',
        fontSize: '14pt',
        marginLeft: '170px',
        marginRight: '10px',
        textShadow: '2px 8px 8px black'
        
         
      }}>
        Validando token ...
        
      
      </p>
    </div>
    
    
    
  );
}
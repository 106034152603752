import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface Props {
  titulo: string;
  categorias: string[];
  series: any[];
  height?: number;
  ocultarN?: boolean;
}

export default function ColumnChart(props: Props) {
  return (
    <div style={{ width: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: { type: 'column', height: props.height || 400 },
          credits: { enabled: false },
		  title: { text: props.titulo },
		  legend: {
            enabled: false
          },
          xAxis: {
            categories: props.categorias
          },
          yAxis: { min: 0, title: { text: 'Percentual de EA' }, max: 100 },
		  tooltip: { enabled: false },
		  plotOptions: {
			series: {
				borderWidth: 0,
				dataLabels: {
					enabled: true,
					formatter: function() {
            if(!props.ocultarN)
              return `<span>${this.y.toFixed(1)}% (${this.point.n})</span>`;
            return `<span>${this.y.toFixed(1)}%</span>`;
					}
				}
			}
		  },
		  series: props.series,
		  exporting: {
			filename: props.titulo
		  }
        }}
      />
    </div>
  );
}

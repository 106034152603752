import * as React from 'react';
import { ChecklistRegrasAplicadasRunChart } from "../utils/processarRunChart";

export default function RunChartLegendaRegras(props: ChecklistRegrasAplicadasRunChart) {
  return (
    <div style={{ width: '100%', wordWrap: 'break-word' }} className="mb-1">
      {props.pontosConsecutivosAbaixoDaMediana ? legendaAbaixoLinhaMediana() : null}
      {props.pontosConsecutivosAcimaDaMediana ? legendaAcimaLinhaMediana() : null}
      {props.quedasConsecutivas ? legendaQuedasConsecutivas() : null}
      {props.subidasConsecutivas ? legendaSubidasConsecutivas() : null}
      {props.outlierPositivo ? legendaOutlierPositivo() : null}
      {props.outlierNegativo ? legendaOutlierNegativo() : null}
    </div>
  );
}

function legendaAbaixoLinhaMediana() {
    return (<>
        <img src={"/theme/img/square_red.png"} className="mr-1" />
        <span> - <b>Regra 1: Shift.</b> 6 pontos ou mais abaixo da linha da mediana</span>
        <br/>
    </>);
}

function legendaAcimaLinhaMediana() {
    return (<>
        <img src={"/theme/img/square_red.png"} className="mr-1" />
        <span> - <b>Regra 1: Shift.</b> 6 pontos ou mais acima da linha da mediana</span>
        <br/>
    </>);
}

function legendaQuedasConsecutivas() {
    return (<>
        <img src={"/theme/img/triangle_red.png"} className="mr-1" />
        <span> - <b>Regra 2: Tendência.</b> 5 pontos ou mais decrescentes</span>
        <br/>
    </>);
}

function legendaSubidasConsecutivas() {
    return (<>
        <img src={"/theme/img/triangle_red.png"} className="mr-1" />
        <span> - <b>Regra 2: Tendência.</b> 5 pontos ou mais crescentes</span>
        <br/>
    </>);
}

function legendaOutlierPositivo() {
    return (<>
        <img src={"/theme/img/circle_red.png"} className="mr-1" />
        <span> - <b>Regra 4: Ponto astronômico.</b> Ponto com valor muito acima dos demais</span>
        <br/>
    </>);
}

function legendaOutlierNegativo() {
    return (<>
        <img src={"/theme/img/circle_red.png"} className="mr-1" />
        <span> - <b>Regra 4: Outlier.</b> Ponto com valor muito abaixo dos demais</span>
        <br/>
    </>);
}

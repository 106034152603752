import React, { Component } from 'react';
import PageHeader from '../layout/PageHeader';

class PageNotFound extends Component {
  render() {
    return (
      <div className="content-inner">
        <PageHeader titulo="Plataforma QualiParto" />

		<div className="container-fluid mt-4">
			<div className="row">
				<div className="col">
					<div className="card">
						<div className="card-header d-flex align-items-center">
							<h3 className="h4 lead">Não encontrado</h3>
						</div>
						<div className="card-body">
							<h1 className="text-center">Oops, a Página buscada não foi encontrada</h1>
						</div>
					</div>
				</div>
			</div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;

import * as React from 'react';
import '../Estilos.css';
import copiaProfunda from '../../utils/copiaProfunda';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import data from './PerguntasInformacoesGerais';
import getPerguntasComDados from './PerguntasInformacoesGeraisComDados';
import {
	salvarDadosInformacoesGerais,
	getHospitailById,
	obterColetaContinuar,
	atualizarDadosInformacoesGerais
} from '../../api/api';
import Swal from 'sweetalert';
import Swal2 from 'sweetalert2';
import { Redirect } from 'react-router';
import moment from 'moment';
import { getUrlParameter } from '../../utils';
import { isNull } from 'util';

class QuestionarioInformacoesGerais extends React.Component<any, any> {
	hospitalId: number;
	hospitalIdContinuar = Number(this.props.location.pathname.split('/').slice(-1)[0]);

	constructor(props) {
		super(props);
		this.hospitalId = +props.match.params.id;
		this.state = {
			isContinuar: this.props.location.pathname.indexOf('continuar') >= 0 ? true : false,
			isCadastro: false,
			carregando: false,
			perguntas: data,
			mudarTela: null,
			CNES: '',
			numeroProntuario: null,
			dataParto: null,
			mensagemCampo: 'Campo obrigatório',
			coletaInformacoesGerais: []
		};
	}

	componentDidMount() {
		if (Number.isNaN(this.hospitalId)) { this.hospitalId = this.hospitalIdContinuar }
		this.getCNESHospital(this.hospitalId);
		this.setDados();
	}

	getCNESHospital = async id => {
		const H = await getHospitailById(id);
		this.setState({ CNES: H.cnes });
	}

	setDados = async () => {
		let numeroProntuario = getUrlParameter('numeroProntuario');
		let dataParto = getUrlParameter('dataParto');

		if (this.state.isContinuar) {
			const coletaIG = await obterColetaContinuar(parseInt(numeroProntuario), this.hospitalId, dataParto.concat("T00:00:00.000Z"), '/informacoes-gerais/continuar/prontuario/');
			if (coletaIG[0] != undefined) {
				const coleta = await getPerguntasComDados(coletaIG[0]);
				this.setState({
					coletaInformacoesGerais: coletaIG[0],
					CNES: coletaIG[0].hospital.cnes,
					perguntas: coleta,
					isCadastro: true
				});
			}
		}
		if (!this.state.isCadastro) {
			const coletaSalvaStringificado = localStorage.getItem('informacoesGerais');
			if (coletaSalvaStringificado !== null) {
				const coletaSalva = JSON.parse(coletaSalvaStringificado);
				if (coletaSalva['numeroProntuario'] == numeroProntuario && coletaSalva['dataParto'].split("T")[0] == dataParto) {
					this.state.perguntas.forEach((pergunta) => {
						if (pergunta.chave == "numeroProntuario" && numeroProntuario) { pergunta.value = numeroProntuario; }
						else if (pergunta.chave == "dataParto" && dataParto) { pergunta.value = moment(dataParto); }
						else if (pergunta.chave == "dataAltaObito" && coletaSalva['dataAltaObito']) { pergunta.value = moment(coletaSalva['dataAltaObito']); }
						else if (pergunta.chave == "dataInternacao" && coletaSalva['dataInternacao']) { pergunta.value = moment(coletaSalva['dataInternacao']); }
						else if (pergunta.chave == "dataNascimento" && coletaSalva['dataNascimento']) { pergunta.value = moment(coletaSalva['dataNascimento']); }
						else if (pergunta.chave == "maeIdadeGestacional" && coletaSalva['maeIdadeGestacional']) { pergunta.value = coletaSalva['maeIdadeGestacional']; }
						else if (pergunta.chave == "partoGemelar") { pergunta.value = coletaSalva['partoGemelar'] ? "Sim" : "Não"; }
						else if (pergunta.chave == "rnPeso" && coletaSalva['rnPeso']) { pergunta.value = coletaSalva['rnPeso']; }
						else if (pergunta.chave == "tipoParto" && coletaSalva['tipoParto'] != null) { pergunta.value = coletaSalva['tipoParto']; }
					});
				} else {
					this.state.perguntas.forEach((pergunta) => {
						if (pergunta.type == "input" || pergunta.type == "number")
							pergunta.value = '';
						else if (pergunta.type != "header")
							pergunta.value = null;
					});
					localStorage.removeItem('informacoesGerais');
					this.setInfoGerais(numeroProntuario, dataParto)
				}
			} else { this.setInfoGerais(numeroProntuario, dataParto) }
		}
	}

	setInfoGerais(numeroProntuario, dataParto) {
		this.state.perguntas.forEach((pergunta) => {
			if (pergunta.chave == "numeroProntuario" && numeroProntuario)
				pergunta.value = numeroProntuario;
			if (pergunta.chave == "dataParto" && dataParto)
				pergunta.value = moment(dataParto);
		});
	}

	atualizar = async () => {
		if (!this.validarQuestionarios()) {
			Swal(
				'Oops',
				'Verifique os campos obrigatórios',
				'error'
			);
			return;
		}
		const dadosColeta = { id: this.state.coletaInformacoesGerais.id }
		this.state.perguntas.forEach((pergunta) => {
			let valor = pergunta.value;
			if (pergunta.type === 'radio' && pergunta.options.length === 2) {
				if (pergunta.value !== null) {
					if (pergunta.value === 'Sim')
						valor = true;
					else if (pergunta.value === 'Não')
						valor = false;
					else
						valor = pergunta.value
				}
				else
					valor = null
			} else if (pergunta.type === 'date' && pergunta.value) {
				const d: Date = pergunta.value.toDate()
				valor = `${d.getFullYear()}-${(d.getMonth() + 1) > 9
					? (d.getMonth() + 1)
					: '0' + (d.getMonth() + 1)
					}-${d.getDate() > 9
						? d.getDate()
						: '0' + d.getDate()
					}`;
			} else if (pergunta.type === 'number' && pergunta.value) {
				valor = Number(pergunta.value)
			}

			dadosColeta[pergunta.chave] = valor === 'Não se aplica' ? 'NA' : valor;
		});
		this.setState({ carregando: true });

		atualizarDadosInformacoesGerais(dadosColeta)
			.then(() => {
				Swal(
					'Concluído',
					'Sucesso ao atualizar a coleta',
					'success'
				)
					.then(() => window.location.reload());
			})
			.catch((err) => {
				Swal('Falha', err.response.data.message, 'error');
				this.setState({ carregando: false });
			});
	};

	enviar = () => {
		if (!this.validarQuestionarios()) {
			Swal(
				'Oops',
				'Verifique os campos obrigatórios',
				'error'
			);
			return;
		}
		this.setState({ carregando: true });
		const dadosColeta = this.formatarDadosPerguntas();
		salvarDadosInformacoesGerais(dadosColeta)
			.then((resp) => {
				this.setState({ numeroProntuario: resp.data.numeroProntuario });
				this.setState({ dataParto: resp.data.dataParto });
				localStorage.setItem('informacoesGerais', JSON.stringify(dadosColeta));
				Swal(
					'Concluído',
					'Sucesso ao salvar a Coleta',
					'success'
				).then(() => this.selecionarOpcoesTela());
			})
			.catch((err) => {
				Swal('Falha', err.response.data.message, 'error');
				this.setState({ carregando: false });
			});
	};

	formatarDadosPerguntas() {
		const dadosColeta = {
			hospital: { id: this.hospitalId }
		};
		this.state.perguntas.forEach((pergunta) => {
			let valor = pergunta.value;

			if (pergunta.type === 'radio' && pergunta.options.length === 2) {
				valor = pergunta.value === 'Sim';
			} else if (pergunta.type === 'date' && pergunta.value) {
				valor = this.formataData(pergunta.value);
			}
			dadosColeta[pergunta.chave] = valor;
		});
		return dadosColeta;
	}

	formataData(dataMoment) {
		const d: Date = dataMoment.toDate();
		const dataFormatada = `${d.getFullYear()}-${(d.getMonth() + 1) > 9
			? (d.getMonth() + 1)
			: '0' + (d.getMonth() + 1)
			}-${d.getDate() > 9
				? d.getDate()
				: '0' + d.getDate()
			}`;
		return dataFormatada;
	}

	handleChange(valor, identificador) {
		const i = this.identificador2Indice(this.state.perguntas, identificador);
		this.setState((state) => {
			const copia = copiaProfunda(state.perguntas);
			copia[i].value = valor;
			return { perguntas: copia };
		});
	}

	indicesInvalidos = [];
	campoInvalidado(pergunta, identificador): boolean {
		const indice = this.identificador2Indice(this.state.perguntas, identificador);
		if (
			pergunta.obrigatorio
			&&
			this.indicesInvalidos.includes(indice)
		)
			return true;
		else
			return false;
	}

	identificador2Indice(perguntas, identificador) {
		for (let i = 0; i < perguntas.length; i++)
			if (perguntas[i].identificador === identificador)
				return i;
		throw new Error(`Identificador(${identificador}) não encontrado`);
	}

	/*
	  Retorna se o questionário possui campos obrigatórios não preenchidos
	  e registra os indices das perguntas invalidas no 'indicesInvalidos'
	*/
	validarQuestionarios(): boolean {
		this.indicesInvalidos = [];
		this.state.perguntas.forEach((pergunta: any, i: number) => {
			const v = pergunta.value;
			let invalidado = false;
			if (pergunta.obrigatorio) {
				if (v === '' || v === null || v === undefined) { // Campo obrigatório não preenchido
					this.indicesInvalidos.push(i);
					invalidado = true;
				}
			}
			if (!invalidado) { // O Campo não foi invalidado na anterior
				// Se o campo era obrigatório e foi preenchido com algum valor não zero
				if (pergunta.obrigatorio || Number(pergunta.value) !== 0) {
					if (
						pergunta.type === 'number'
						&& typeof pergunta.valorMinimo === 'number'
						&& Number(pergunta.value) < pergunta.valorMinimo
					) { // Valor inválido informado
						this.indicesInvalidos.push(i);
						invalidado = true;
					}
				}
			}
		});
		this.forceUpdate(); // Mostrando no front os campos invalidados
		return this.indicesInvalidos.length === 0;
	}

	validarMudancaTela(): boolean {
		this.indicesInvalidos = [];
		this.state.perguntas.forEach((pergunta: any, i: number) => {
			const v = pergunta.value;
			let invalidado = false;
			if (pergunta.obrigatorio && (pergunta.chave == 'dataParto' || pergunta.chave == 'numeroProntuario')) {
				if (v === '' || v === null || v === undefined) { // Campo obrigatório não preenchido
					this.indicesInvalidos.push(i);
					invalidado = true;
				}
			}
		});
		this.forceUpdate(); // Mostrando no front os campos invalidados
		return this.indicesInvalidos.length === 0;
	}

	render() {
		return (
			<div className="container-fluid">
				{this.mudarTelaColetaSeNescessario()}
				<div className="row justify-content-center mt-4">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header">
								<ul className="nav nav-tabs card-header-tabs">
									<li className="nav-item">
										<a className="nav-link active">
											Informações Gerais
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											href=""
											onClick={e => {
												e.preventDefault();
												this.mudarTela('ea');
											}}
										>
											Eventos adversos
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											href=""
											onClick={e => {
												e.preventDefault();
												this.mudarTela('checklist');
											}}
										>
											Adesão ao checklist para parto seguro
										</a>
									</li>
									<li className="nav-item">
										<a
											className="nav-link"
											href=""
											onClick={e => {
												e.preventDefault();
												this.mudarTela('bp');
											}}
										>
											Boas práticas
										</a>
									</li>
								</ul>
							</div>

							<div className="card-body">
								<div className="row">
									<div className="col-md-12">
										<h4 className="text-muted">CNES do Hospital: {this.state.CNES}</h4>
									</div>
								</div>
								{
									this.state.perguntas
										.map(pergunta => this.perguntaToFront(pergunta))
								}
								<div className="row justify-content-center">
									<div className="col-6">
										<button
											disabled={this.state.carregando}
											type="button"
											className="btn btn-primary btn-block"
											onClick={this.state.isCadastro ? this.atualizar : this.enviar}
										> {this.state.isCadastro ? (this.state.carregando ? 'Atualizando...' : 'Atualizar') : (this.state.carregando ? 'Enviando...' : 'Enviar')}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	/*
		  Esta função mapeia cada pergunta passada para um conjunto de perguntas no front
	*/
	perguntaToFront = pergunta => {
		if (pergunta.type === 'text' || pergunta.type === 'number') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<label>
						<b>({pergunta.identificador})</b> - {pergunta.label}:
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
					</label>
					<input
						type={pergunta.type}
						value={pergunta.value}
						onChange={(e) => this.handleChange(e.target.value, pergunta.identificador)}
						className={
							"form-control"
							+ (this.campoInvalidado(pergunta, pergunta.identificador)
								? " is-invalid"
								: ""
							)
						}
					/>
					<div className="invalid-feedback">
						Campo obrigatório
					</div>
					{
						pergunta.descricao
							? (<div
								style={{
									paddingLeft: '30px',
									marginBottom: '30px'
								}}
							>
								<span className="text-muted">
									{pergunta.descricao}
								</span>
							</div>)
							: null
					}
				</div>
			);
		}
		if (pergunta.type === 'select') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<label>
						<b>({pergunta.identificador})</b> - {pergunta.label}:
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
					</label>
					<select
						value={pergunta.value}
						onChange={(e) => this.handleChange(e.target.value, pergunta.identificador)}
						className="form-control">
						{pergunta.options.map((option) => (
							<option value={option} key={option}>
								{option}
							</option>
						))}
					</select>
					{
						pergunta.descricao
							? (<div
								style={{
									paddingLeft: '30px',
									marginBottom: '30px'
								}}
							>
								<span className="text-muted">
									{pergunta.descricao}
								</span>
							</div>)
							: null
					}
				</div>
			);
		}
		if (pergunta.type === 'header') {
			return (
				<div key={pergunta.text}>
					<h4 className="text-center mt-4">{pergunta.text}</h4>
					<hr className="mb-4" />
				</div>
			);
		}
		if (pergunta.type === 'date') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<label>
						<b>({pergunta.identificador})</b> - {pergunta.label}
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
					</label>
					<DatePicker
						selected={pergunta.value}
						onChange={(date) => this.handleChange(date, pergunta.identificador)}
						className={
							"form-control datepicker"
							+ (this.campoInvalidado(pergunta, pergunta.identificador)
								? " is-invalid"
								: ""
							)
						}
					/>
					{
						this.campoInvalidado(pergunta, pergunta.identificador)
							? (
								<div className="invalid-feedback d-block">
									Campo obrigatório
								</div>
							)
							: null
					}
					{
						pergunta.descricao
							? (<div
								style={{
									paddingLeft: '30px',
									marginBottom: '30px'
								}}
							>
								<span className="text-muted">
									{pergunta.descricao}
								</span>
							</div>)
							: null
					}
				</div>
			);
		}
		if (pergunta.type === 'radio') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<p
						style={{
							marginBottom: this.campoInvalidado(pergunta, pergunta.identificador) || pergunta.descricao
								? '0px'
								: '1rem'
						}}
					>
						<b>({pergunta.identificador})</b> - {pergunta.label}
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
					</p>
					{
						this.campoInvalidado(pergunta, pergunta.identificador)
							? (
								<div className="invalid-feedback d-block">
									Campo obrigatório
								</div>
							)
							: null
					}
					{
						pergunta.descricao
							? (<div
								style={{
									paddingLeft: '20px',
								}}
							>
								<span
									className="text-muted"
									style={{
										fontSize: '11pt'
									}}
								>
									{pergunta.descricao}
								</span>
							</div>)
							: null
					}
					{pergunta.options.map((p, i) => (
						<button
							key={i}
							type="button"
							className={`btn ${p === pergunta.value ? 'btn-info' : 'btn-outline-info'} mr-2`}
							onClick={(e) => this.handleChange(p, pergunta.identificador)}>
							{p}
						</button>
					))}
					{
						pergunta.observacao
							? <p style={{ color: 'red' }}>{pergunta.observacao}</p>
							: null
					}
				</div>
			);
		}
		if (pergunta.type === 'label') {
			return (
				<h5 className="text-center mt-4" key={pergunta.identificador}>
					{pergunta.label}
				</h5>
			);
		}
		if (pergunta.type === 'textarea') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<label>
						<b>({pergunta.identificador})</b> - {pergunta.label}:
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
					</label>
					<textarea
						rows={5}
						placeholder={pergunta.descricao}
						value={pergunta.value}
						onChange={(e) => this.handleChange(e.target.value, pergunta.identificador)}
						className={
							"form-control"
							+ (this.campoInvalidado(pergunta, pergunta.identificador)
								? " is-invalid"
								: ""
							)
						}
					/>
					<div className="invalid-feedback">
						Campo obrigatório
					</div>
				</div>
			);
		}
		return null;
	};

	mudarTela = qual => {
		if (!this.validarMudancaTela()) {
			Swal(
				'Oops',
				'Verifique os campos obrigatórios',
				'error'
			);
			return;
		}
		let numeroProntuario = getUrlParameter('numeroProntuario');
		let dataParto = getUrlParameter('dataParto');
		if (numeroProntuario && dataParto &&
			this.state.perguntas[1].value != numeroProntuario &&
			this.state.perguntas[4].value != dataParto) {
			Swal2({
				title: 'Deseja mesmo prosseguir?',
				text: "Você perderá as respostas já registradas porque alterou o prontuário ou a data do parto!",
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#d33',
				cancelButtonColor: '#3085d6',
				confirmButtonText: 'Prosseguir mesmo assim!'
			}).then((data) => {
				if (data.value) {
					const dadosColeta = this.formatarDadosPerguntas();
					localStorage.setItem('informacoesGerais', JSON.stringify(dadosColeta));
					this.setState({
						numeroProntuario: dadosColeta['numeroProntuario'],
						dataParto: dadosColeta['dataParto'],
						mudarTela: qual
					});
				} else { return; }
			});
		} else {
			const dadosColeta = this.formatarDadosPerguntas();
			localStorage.setItem('informacoesGerais', JSON.stringify(dadosColeta));
			this.setState({
				numeroProntuario: dadosColeta['numeroProntuario'],
				dataParto: dadosColeta['dataParto'],
				mudarTela: qual
			});
		}

	};

	selecionarOpcoesTela = () => {
		Swal("Qual o próximo formulário que você deseja preencher?", {
			buttons: {
				ea: {
					text: "Eventos Adversos",
					value: "ea",
				},
				cl: {
					text: "Adesão ao Checklist",
					value: "checklist",
				},
				bp: {
					text: "Boas Práticas",
					value: "bp",
				},
			},
		})
			.then((value) => {
				this.setState({ mudarTela: value });
				this.mudarTelaColetaSeNescessario();
			});
	};
	mudarTelaColetaSeNescessario = () => {
		switch (this.state.mudarTela) {
			case 'ea':
				return <Redirect to={`/questionarios`
					+ (this.state.isContinuar ? `/continuar` : ``)
					+ `/eventos-adversos/`
					+ this.hospitalId
					+ '?numeroProntuario=' + this.state.numeroProntuario
					+ '&dataParto=' + this.state.dataParto} />
			case 'checklist':
				return <Redirect to={`/questionarios`
					+ (this.state.isContinuar ? `/continuar` : ``)
					+ `/adesao-checklist/`
					+ this.hospitalId
					+ '?numeroProntuario=' + this.state.numeroProntuario
					+ '&dataParto=' + this.state.dataParto} />
			case 'bp':
				return <Redirect to={`/questionarios`
					+ (this.state.isContinuar ? `/continuar` : ``)
					+ `/boas-praticas/`
					+ this.hospitalId
					+ '?numeroProntuario=' + this.state.numeroProntuario
					+ '&dataParto=' + this.state.dataParto} />
			case 'hospitais':
				return <Redirect to={`/relatorios/hospitais`} />
			default:
				return null;
		}
	};
}

export default QuestionarioInformacoesGerais;

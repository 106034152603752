import * as React from 'react';
import DatePicker from 'react-datepicker';
import Card from '../layout/Card';
import GradientCard from '../layout/GradientCard';
import ColumnChart from '../graficos/ColumnChart';
import Loading from '../api/Loading.jsx';
import moment from 'moment';
import { obterDadosChecklistFiltrado, obterDadosChecklist } from '../api/api';
import { processarChecklistBarChart } from '../utils/ProcessarDadosCharts';
import {
  Hospital,
  DadosColetaChecklist,
  LabelMomentosChecklist,
  LabelMomentosChecklistBar
} from '../modelos';
import { numero2MesAbreviado } from '../utils';
import arrayObjectsToCSV from '../utils/arrayObjectsToCSV';
import * as XLSX from 'xlsx'
import { preprocessarParaXLSX } from '../utils/calculosChecklist';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import RunChart from '../graficos/RunChart';
import { processarSerie, categorizarEmMeses } from '../utils/calculos_IRA_EPRA_IG';
import {
  calcularRunchartChecklist,
  checklistKeys,
  checklistMaeKeys,
  checklistRNKeys,
  checklistMomentoAdmissaoKeys,
  checklistMomentoAntesExpulsaoMaeKeys,
  checklistMomentoAntesExpulsaoRNKeys,
  checklistMomentoAposNascimentoMaeKeys,
  checklistMomentoAposNascimentoRNKeys,
  checklistMomentoAntesAltaMaeKeys,
  checklistMomentoAntesAltaRNKeys,
  checklistMomentosMae,
  checklistMomentosRN
} from '../utils/calculosChecklist';
import TabelaChecklistAdmissao from '../graficos/TabelaChecklistAdmissao';
import TabelaChecklistAntesExpulsao from '../graficos/TabelaChecklistAntesExpulsao';
import TabelaChecklistAposNascimento from '../graficos/TabelaChecklistAposNascimento';
import TabelaChecklistAntesAlta from '../graficos/TabelaChecklistAntesAlta';


interface Props {
  hospital: Hospital;
  onClose: Function;
}

interface State {
  dadosColetas: DadosColetaChecklist[] | null;
  dataInicio: moment.Moment;
  dataFim: moment.Moment;
  checklistEspecificoSelecionadoBar: string;
  clSelecionados: String[];
}

export default class RelatorioResumidoChecklist extends React.Component<Props, State> {
  dataInicioConsultada = null;
  dataFimConsultada = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      dataInicio: moment(props.hospital.dataCadastro),
      dataFim: moment(props.hospital.dataUltimaColetaEventosAdversos),
      dadosColetas: null,
      checklistEspecificoSelecionadoBar: 'nenhum',
      clSelecionados: []
    };
    this.dataInicioConsultada = moment(props.hospital.dataCadastro).toDate();
    this.dataFimConsultada = moment(props.hospital.dataUltimaColetaEventosAdversos).toDate();
  }

  componentDidMount() {
    this.consultarRelatorioCompleto();
  }

  consultarRelatorio = (dataInicio: string, dataFim: string) => {
    const { id } = this.props.hospital;
    obterDadosChecklistFiltrado(id, dataInicio, dataFim).then((dadosColetas) => {
      this.setState({ dadosColetas: dadosColetas }, () => {
        this.dataInicioConsultada = moment(dataInicio).toDate();
        this.dataFimConsultada = moment(dataFim).toDate();
        this.forceUpdate();
      });
    });
  };

  consultarRelatorioCompleto = () => {
    const { id } = this.props.hospital;
    obterDadosChecklist(id).then((dadosColetas) => {
      if (dadosColetas.length > 0) {
        const faixaDeTempo = this.getColetaFaixaPartos(dadosColetas);
        this.dataInicioConsultada = faixaDeTempo.primeira.toDate();
        this.dataFimConsultada = faixaDeTempo.ultima.toDate();
        this.setState({
          dadosColetas: dadosColetas,
          dataInicio: faixaDeTempo.primeira,
          dataFim: faixaDeTempo.ultima
        });
      } else {
        this.setState({ dadosColetas });
      }
    });
  };

  consultarDadosCSV = (hospitalId) => {
    obterDadosChecklist(hospitalId).then((dadosColetas) => {
      const dadosPreProcessados: any[] = dadosColetas.map(dado => ({
        ...dado,
        dataColeta: dado.dataColeta.split('-').reverse().join('-'),
        hospital: dado.hospital.nome
      }));
      const data = arrayObjectsToCSV(dadosPreProcessados);
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, dadosPreProcessados[0].hospital + '-checklist.csv');
    });
  };

  consultarDadosXLSX = (hospitalId) => {
    obterDadosChecklist(hospitalId).then((dadosColetas) => {
      const processado = preprocessarParaXLSX(dadosColetas);
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(processado);
      XLSX.utils.book_append_sheet(wb, ws, 'eventos-adversos');
      XLSX.writeFile(wb, processado[0].hospital + '-eventos-adversos.xlsx');
    });
  };

  getColetaFaixaPartos(dados: DadosColetaChecklist[]) {
    const sorted = dados.slice(0).sort((data1, data2) => {
      return DateTime.fromISO(data1.dataParto).toMillis() - DateTime.fromISO(data2.dataParto).toMillis();
    });

    return {
      primeira: moment(sorted[0].dataParto),
      ultima: moment(sorted[sorted.length - 1].dataParto)
    };
  }

  render() {
    const { dadosColetas } = this.state;

    if (!dadosColetas)
      return <Loading />;
    if (dadosColetas.length === 0)
      return (<h5>Nenhuma informação inserida no banco de dados</h5>);


    const checklistBarChart = processarChecklistBarChart(dadosColetas);
    const serieIRAChecklist = processarSerie(categorizarEmMeses(this.state.dadosColetas, 'dataParto'), checkList => calcularRunchartChecklist(checkList, checklistKeys));

    return (
      <Card titulo={"Relatório de adesão ao Checklist da instituição " + this.props.hospital.nome} onClose={this.props.onClose}>
        <h5 className="text-center">Período de consulta</h5>
        <div className="row justify-content-center">
          <div className="col-4">
            <div className="form-group d-flex justify-content-end align-items-end">
              <label>Início</label>
              <DatePicker
                className="form-control datepicker"
                selected={this.state.dataInicio}
                selectsStart
                startDate={this.state.dataInicio}
                endDate={this.state.dataFim}
                onChange={(data) => this.setState({ dataInicio: data })}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group d-flex justify-content-space-between align-items-end">
              <label>Fim</label>
              <DatePicker
                className="form-control datepicker"
                selected={this.state.dataFim}
                selectsEnd
                startDate={this.state.dataInicio}
                endDate={this.state.dataFim}
                onChange={(data) => this.setState({ dataFim: data })}
              />

              <button
                type="button"
                className="btn btn-success"
                onClick={() =>
                  this.consultarRelatorio(this.state.dataInicio.toISOString(), this.state.dataFim.toISOString())
                }>
                Consultar
              </button>
            </div>
          </div>
          <div className="col-4">
            <div className="btn-group">
              <button className="btn btn-info btn-sm dropdown" type="button" style={{ display: 'inline-block' }}>
                <span className="dropdown-toggle" data-toggle="dropdown">
                  Exportar
                </span>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="#" onClick={() => this.consultarDadosCSV(this.props.hospital.id)}>
                    <i className="fas fa-table"> </i> CSV
                  </a>
                  <a className="dropdown-item" href="#" onClick={() => this.consultarDadosXLSX(this.props.hospital.id)}>
                    <i className="fas fa-file-excel"> </i> XLSX
                  </a>
                </div>
              </button>
              <a
                href={
                  "/relatorioChecklist/"
                  + this.props.hospital.id
                  + '?dataInicio=' + this.state.dataInicio.toISOString()
                  + '&dataFim=' + this.state.dataFim.toISOString()
									+ '&runchart=' + this.state.clSelecionados
                  + '&barchart=' + this.state.checklistEspecificoSelecionadoBar
                }
                target="_blank"
              >
                <button className="btn btn-success">
                  Gerar PDF
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className="row p-3 mb-4 justify-content-center">
          <div className="col-md-6 mb-4">
            <GradientCard icon="list" estatistica={dadosColetas.length} descricao="Questionários coletados" />
          </div>
        </div>

        <h4
          style={{
            borderBottom: '1px solid gray'
          }}
        >
          1) Gráfico de tendência da adesão ao preenchimento do checklist para Parto Seguro na instituição {this.props.hospital.nome}, no período de {formatarData(this.dataInicioConsultada)} a {formatarData(this.dataFimConsultada)}, {this.props.hospital.cidade}, {(new Date()).getFullYear()}
        </h4>

        <RunChart
          dados={serieIRAChecklist}
          exportavel={true}
          mostrarLegendas={true}
          titulo='Gráfico de tendência da adesão ao Checklist'
          nome='adesaochecklist'
          yLabel="Percentual"
          corLinha="#07a7e3"
          percentual={true}
        />

        <div className="form-group">
          {Object.entries(LabelMomentosChecklist).map(([value, label]) => {
            return <div key={value}>
              <input
                type="checkbox"
                id={value}
                name={value}
                onChange={(cl) => this.atualizaClSelecionados(cl.target.value, cl.target.checked)}
                value={value}
              /><label className='lrc' htmlFor={value}>{label}</label>
            </div>
          })}
        </div>
        {this.state.clSelecionados
          .map(cl => this.getRuncharChecklistEspecifico(cl))
        }
        <br /><br />
        <h4
          style={{
            borderBottom: '1px solid gray'
          }}
        >
          2) Avaliação transversal da adesão ao preenchimento do checklist para Parto Seguro na instituição {this.props.hospital.nome}, no período de {formatarData(this.dataInicioConsultada)} a {formatarData(this.dataFimConsultada)}, {this.props.hospital.cidade}, {(new Date()).getFullYear()}
        </h4>
        <h4 style={{ borderBottom: '1px solid gray' }}>
          2.1) Gráficos de barras de adesão ao checklist por itens da mãe e/ou do recém-nascido
        </h4>
        <ColumnChart
          titulo="Percentual de itens preenchidos em todos os momentos da assistência"
          categorias={checklistBarChart.categorias}
          series={checklistBarChart.series}
          ocultarN={true}
        />

        <div className="form-group">
          <label>Gráfico de barras de adesão ao checklist:</label>
          <select
            className="form-control"
            value={this.state.checklistEspecificoSelecionadoBar}
            onChange={(ev) => this.setState({ checklistEspecificoSelecionadoBar: ev.target.value })}>
            {Object.entries(LabelMomentosChecklistBar).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        {this.getBarchartEspecificido(this.state.checklistEspecificoSelecionadoBar)}
        <h4 style={{ borderBottom: '1px solid gray' }}>
          2.2) Tabelas com descritivo do percentual de preenchimento do checklist por etapas e por itens
        </h4>
        <TabelaChecklistAdmissao dados={this.state.dadosColetas} /><br />
        <TabelaChecklistAntesExpulsao dados={this.state.dadosColetas} /><br />
        <TabelaChecklistAposNascimento dados={this.state.dadosColetas} /><br />
        <TabelaChecklistAntesAlta dados={this.state.dadosColetas} /><br />
      </Card>
    );
  }

  getBarchartEspecificido = qual => {
    let lista = null;
    switch (qual) {
      case 'geralMae':
        lista = processarChecklistBarChart(this.state.dadosColetas, checklistMomentosMae);
        break;
      case 'geralRN':
        lista = processarChecklistBarChart(this.state.dadosColetas, checklistMomentosRN);
        break;
      case 'todos':
        return ['geralMae', 'geralRN']
          .map(q => this.getBarchartEspecificido(q))
      default:
        return null;
    }

    return (
      <ColumnChart
        titulo={LabelMomentosChecklistBar[qual]}
        categorias={lista.categorias}
        series={lista.series}
        ocultarN={true}
      />
    );
  };

  atualizaClSelecionados = (cl, check) => {
    if (check) {
      this.setState(prevState => ({
        clSelecionados: [...prevState.clSelecionados, cl]
      }))
    } else { this.removeBp(cl); }
  }

  removeBp(e) {
    let arrayFiltrado = this.state.clSelecionados.filter(item => item !== e)
    this.setState({ clSelecionados: arrayFiltrado });
  }

  getRuncharChecklistEspecifico = qual => {
    let lista = null;
    switch (qual) {
      case 'admicao':
        lista = checklistMomentoAdmissaoKeys;
        break;
      case 'antesExpulsaoMae':
        lista = checklistMomentoAntesExpulsaoMaeKeys;
        break;
      case 'antesExpulsaoRN':
        lista = checklistMomentoAntesExpulsaoRNKeys;
        break;
      case 'aposNascimentoMae':
        lista = checklistMomentoAposNascimentoMaeKeys;
        break;
      case 'aposNascimentoRN':
        lista = checklistMomentoAposNascimentoRNKeys;
        break;
      case 'antesAltaMae':
        lista = checklistMomentoAntesAltaMaeKeys;
        break;
      case 'antesAltaRN':
        lista = checklistMomentoAntesAltaRNKeys;
        break;
      case 'geralMae':
        lista = checklistMaeKeys;
        break;
      case 'geralRN':
        lista = checklistRNKeys;
        break;
      default:
        return null;
    }

    return (
      <RunChart
        key={"runchart" + qual}
        dados={processarSerie(categorizarEmMeses(this.state.dadosColetas, 'dataParto'), checkList => calcularRunchartChecklist(checkList, lista))}
        exportavel={true}
        mostrarLegendas={true}
        titulo={LabelMomentosChecklist[qual]}
        nome={qual}
        yLabel="Percentual"
        corLinha="#07a7e3"
        percentual={true}
      />
    );
  };
}

function formatarData(data: Date): String {
  let yyyy = data.getFullYear();
  const mes = numero2MesAbreviado(data.getMonth() + 1);
  return mes + '/' + yyyy;
}
import * as React from 'react';
import PageHeader from '../layout/PageHeader';
import Loading from '../api/Loading.jsx';
import { getHospitais, getHospitaisRede, getRedes, getVinculosHospital, vincularHospital, removerVinculos, getUsuario } from '../api/api';
import { Hospital, Rede, RedeHospitais } from '../modelos';
import getFetchErroComponent from '../utils/erroCompotent';

interface State {
    carregando: boolean;
    loadingErro: boolean;
    atualizandoVinculos: boolean;
    hospitalSelecionado: number;
    redeSelecionada: number;
    hospitais: Hospital[];
    redes: Rede[];
    erroAutorizar: boolean;
    msgErroAutorizar: string;
}

export default class AutorizacoesRede extends React.Component<any, State> {
    constructor(props) {
        super(props);
        this.state = {
            erroAutorizar: false,
            carregando: true,
            loadingErro: false,
            atualizandoVinculos: false,
            hospitais: [],
            redes: [],
            hospitalSelecionado: 0,
            redeSelecionada: 0,
            msgErroAutorizar: ''
        };
    }

    emailUsuario = ''
    componentDidMount() {
        this.getHospitaisUser();
        this.getRedesUser();
    }

    getHospitaisUser = async () => {
        try {
            const usuario = await getUsuario();
            if (usuario.tipo == "ADMIN" || usuario.tipo == "ADMINREDE") {
                /*let hospitais;
                if (usuario.tipo == "ADMIN") {
                    hospitais = await getHospitais();
                } else {
                    hospitais = await getHospitaisRede();
                }
                const meusHospitais = hospitais.filter(h => h.usuario.id === usuario.id);*/
                const meusHospitais = await getHospitais();
                this.setState({
                    hospitais: meusHospitais,
                    hospitalSelecionado: (meusHospitais.length > 0) ? meusHospitais[0].id : 0,
                    loadingErro: false,
                    carregando: false
                });
            } else {
                this.setState({
                    loadingErro: true,
                    carregando: false,
                });
            }
        } catch (err) {
            if(err.response.status = 404)
                this.setState({
                    loadingErro: true,
                    carregando: false,
                });
            else
                this.setState({
                    erroAutorizar: true,
                    msgErroAutorizar: err.response.data.message
                });
        }
    }
    getRedesUser = async () => {
        try {
            const usuario = await getUsuario();
            if (usuario.tipo == "ADMIN" || usuario.tipo == "ADMINREDE") {
                const redes = await getRedes();
                //const minhasRedes = redes.filter(h => h.usuario.id === usuario.id);
                const minhasRedes = redes;
                this.setState({
                    redes: minhasRedes,
                    redeSelecionada: (minhasRedes.length > 0) ? minhasRedes[0].id : 0,
                    loadingErro: false,
                    carregando: false
                });
            } else {
                this.setState({
                    loadingErro: true,
                    carregando: false,
                });
            }
        } catch (err) {
            if(err.response.status = 404)
                this.setState({
                    loadingErro: true,
                    carregando: false,
                });
            else
                this.setState({
                    erroAutorizar: true,
                    msgErroAutorizar: err.response.data.message
                });
        }
    }
    vincular = async ev => {
        ev.preventDefault();
        this.setState({ atualizandoVinculos: true });
        try {
            await vincularHospital(this.state.redeSelecionada, this.state.hospitalSelecionado);
            this.setState({ erroAutorizar: false });
        } catch (err) {
            this.setState({
                erroAutorizar: true,
                msgErroAutorizar: err.response.data.message
            });
        }
        this.setState({ atualizandoVinculos: false });
    }

    render() {
        let conteudo = null;
        if (this.state.carregando)
            conteudo = <Loading />
        else if (this.state.loadingErro)
            conteudo = getFetchErroComponent(this.getHospitaisUser);
        else
            conteudo = (
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-header d-flex align-items-center">
                                <h3 className="h4 lead">Vincular Hospital a Rede de Hospitais</h3>
                            </div>
                            <div className="card-body">
                                <form
                                    style={{
                                        backgroundColor: "white",
                                        padding: '10px',
                                        borderRadius: '5px'
                                    }}
                                >
                                    <div className="form-group">
                                        <label>Hospital</label>
                                        <select
                                            className="form-control"
                                            value={this.state.hospitalSelecionado}
                                            onChange={ev => this.setState({ hospitalSelecionado: Number(ev.target.value) })}>
                                            {this.state.hospitais.map((h) => (
                                                <option key={h.id} value={h.id}>
                                                    {h.nome}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Rede de hospitais</label>
                                        <select
                                            className="form-control"
                                            value={this.state.redeSelecionada}
                                            onChange={ev => this.setState({ redeSelecionada: Number(ev.target.value) })}>
                                            {this.state.redes.map((h) => (
                                                <option key={h.id} value={h.id}>
                                                    {h.nome}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-9">
                                            {
                                                this.state.erroAutorizar
                                                    ? <div className="alert alert-danger">
                                                        {
                                                            this.state.msgErroAutorizar != ''
                                                                ? this.state.msgErroAutorizar
                                                                : 'Falha ao autorizar'
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <button
                                                className="btn btn-primary"
                                                onClick={this.vincular}
                                                disabled={this.state.atualizandoVinculos}
                                            >Vincular</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {
                            this.state.atualizandoVinculos
                                ? <Loading />
                                : <TabelaVinculos />
                        }
                    </div>
                </div>
            );

        return (
            <div className="content-inner">
                <PageHeader titulo="Vínculos" />
                <div className="container-fluid mt-4">
                    {conteudo}
                </div>
            </div>
        );
    }
}

export class TabelaVinculos extends React.Component<any, any> {

    constructor(props) {
        super(props);

        this.state = {
            lista: [],
            carregando: true,
            loadingErro: false
        };
    }

    componentDidMount() {
        this.inicializar();
    }

    inicializar = () => {
        this.getVinculos()
            .then(aut => {
                this.setState({ lista: aut }, () => {
                    if (typeof this.props.onSuccess == 'function')
                        this.props.onSuccess();
                    this.setState({
                        carregando: false,
                        loadingErro: false
                    });
                });
            })
            .catch(err => {
                if (typeof this.props.onError == 'function')
                    this.props.onError(err);
                this.setState({
                    carregando: false,
                    loadingErro: true
                });
            });
    }

    getVinculos = async () => {
        this.setState({ carregando: true });
        const usuario = await getUsuario();
        const redes = await getRedes();

        const minhasRedes = redes.filter(h => h.usuario.id === usuario.id);
        const idsRedes = minhasRedes.map(r => r.id);

        let redeh: RedeHospitais[] = [];
        for (let i = 0; i < idsRedes.length; i++) {
            let novas = [];
            try {
                novas = await getVinculosHospital(idsRedes[i]);
            } catch {/* EMPTY */ }
            redeh = [...redeh, ...novas];
        }

        return redeh;
    }

    removerVinculos = async (id: number) => {
        try {
            await removerVinculos(id);
        } finally {
            this.inicializar();
        }
    }

    render() {
        let conteudo = null;
        if (this.state.carregando)
            conteudo = <Loading />
        else if (this.state.loadingErro)
            conteudo = getFetchErroComponent(this.inicializar);
        else
            conteudo = (<div style={{ backgroundColor: 'white' }} className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Rede</th>
                            <th scope="col">Hospital</th>
                            <th scope="col">Remover</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.lista.map((RedeHospitais: RedeHospitais) =>
                                <tr key={RedeHospitais.id}>
                                    <td>{RedeHospitais.rede.nome}</td>
                                    <td>{RedeHospitais.hospital.nome}</td>
                                    <td>
                                        <i
                                            className="fas fa-trash"
                                            style={{
                                                color: 'red',
                                                fontSize: '15pt',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => this.removerVinculos(RedeHospitais.id)}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>);

        return conteudo;
    }

}

import React from 'react';

export default function getFetchErroComponent(tryagain: Function) {
	return (
		<div
			style={{
				padding: '20px'
			}}
		>
			<h2 className="text-muted">
				Não há dados coletados.
			</h2>
			<button 
				className="btn btn-outline-success"
				onClick={() => tryagain()}
			>
				Tentar novamente
			</button>
		</div>
	);
}
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Usuario } from '../modelos/usuario';
import icon from "../dashboard/Icon.png"

interface Props {
  usuario: Usuario;
}

export function SideNavbar({ usuario }: Props) {
  return (
    <nav className="side-navbar">
      {/* Sidebar Header*/}
      <div className="sidebar-header d-flex align-items-center">
        <div className="avatar">
          <img
            src={icon}
            alt="Icone da plataforma"
            className="img-fluid rounded-circle"
          />
        </div>
        <div className="title">
          <h1 className="h4">{usuario.nome}</h1>
          <p>{usuario.email}</p>
        </div>
      </div>
      {/* Sidebar Navidation Menus*/}

      <ul className="list-unstyled">
        <li>
          <NavLink activeClassName="active" to="/">
            <i className="icon-screen"> </i> Início
          </NavLink>
        </li>
        
        {
          usuario.tipo == "ADMIN"
            ?
            <li>
              <NavLink activeClassName="active" to="/relatorios/hospitais">
                <i className="far fa-hospital"> </i> Hospitais
              </NavLink>
            </li>
            : 
            <li>
              <NavLink activeClassName="active" to="/relatorios/hospitais">
                <i className="far fa-hospital"> </i> Meus Hospitais
              </NavLink>
            </li>
        }
        
        {
          usuario.tipo == "ADMINREDE"
            ?
            <li>
              <NavLink activeClassName="active" to="/relatorios/redes">
                <i className="fas fa-globe"> </i> Minhas Redes
              </NavLink>
            </li>
            : null
        }
        {
          usuario.tipo == "ADMIN"
            ?
            <li>
              <NavLink activeClassName="active" to="/relatorios/redes">
                <i className="fas fa-globe"> </i> Redes
              </NavLink>
            </li>
            : null
        }
        {
          usuario.tipo == "ADMIN" || usuario.tipo == "ADMINREDE"
            ?
            <li>
              <NavLink activeClassName="active" to="/vincular-hospital">
              <i className="fas fa-arrow-right m-0"></i><i className="far fa-hospital m-0"></i> Vincular Hospital a Rede
              </NavLink>
            </li>
            : null
        }
        <li>
          <NavLink activeClassName="active" to="/info">
            <i className="fas fa-calculator"> </i> Como medir
          </NavLink>
        </li>
        <li>
          <a className="active" href="/sobre" target="_blank">
            <i className="fas fa-info"> </i> Sobre a Plataforma
          </a>
        </li>
        <li>
          <a className="active" href="/politica-de-privacidade" target="_blank">
            <i className="fas fa-info"> </i> Política de Privacidade
          </a>
        </li>
      </ul>
      <span className="heading">Gerenciar</span>
      <ul className="list-unstyled">
        <li>
          <NavLink activeClassName="active" to="/conta">
            <i className="far fa-user"></i> Editar Conta
          </NavLink>
        </li>
        {
          usuario.tipo == "ADMIN"
            ?
            <li>
              <NavLink activeClassName="active" to="/usuario/listar">
                <i className="fas fa-user-cog"></i> Gerenciar Usuários
              </NavLink>
            </li>
            : null
        }
        {
          usuario.tipo == "ADMIN" || usuario.tipo == "ADMINREDE"
            ?
            <li>
              <NavLink activeClassName="active" to="/autorizacoes">
                <i className="fas fa-user-lock"></i> Autorizar Usuários
              </NavLink>
            </li>
            : null
        }
      </ul>
    </nav>
  );
}

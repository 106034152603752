import { DadosColetaEventosAdversos, DadosColetaChecklist } from '../modelos';
import { checklistMomentos, contarQuestoesPreenchidas } from './calculosChecklist';

export function processarEventosAdversosMaeBarChart(coletas: DadosColetaEventosAdversos[]) {
	const dados = [
		{
		  nome: "Morte materna",
		  valor: coletas.filter((c) => c.ocorreuMorteMaterna).length
		},
		{
			nome: "Mãe recebeu transfusão sanguínea",
			valor: coletas.filter((c) => c.maeRecebeuTransfusaoSangue).length
		},
		{
		  nome: "Laceração de 3º ou 4º grau",
		  valor: coletas.filter((c) => c.houveLaceracao34grau).length
		},
		{
		  nome: "Ruptura Uterina",
		  valor: coletas.filter((c) => c.houveRupturaUterina).length
		},
		{
		  nome: "Retorno a sala de parto ou cirurgia",
		  valor: coletas.filter((c) => c.reinternacaoMae).length
		},
		{
		  nome: "Internação na UTI",
		  valor: coletas.filter((c) => c.maeInternadaUTI).length
		}
	];
  
	const dadosOrdenados = dados.sort((a, b) => {
		if(a.valor < b.valor)
			return -1;
		if(a.valor > b.valor)
			return 1;
		return 0;
	}).reverse();
  
	const series = [
		{
		  name: 'Percentual',
		  data: dadosOrdenados.map(d => ({
			  y: (d.valor/coletas.length)*100,
			  n: d.valor
			})),
		  color: 'indianred'
		}
	];
  
	return {
	  categorias: dadosOrdenados.map(d => d.nome),
	  series
	};
  }

 export function processarEventosAdversosRNBarChart(coletas: DadosColetaEventosAdversos[]) {
	const dados = [
	  {
		  nome: "Morte neonatal",
		  valor: coletas.filter((c) => c.rnMorteNeonatal).length
	  },
	  {
		  nome: "Trauma ou lesão em decorrência do parto",
		  valor: coletas.filter((c) => c.rnTraumaLesao).length
	  },
	  {
		  nome: "Apgar menor que 7 no 5º minuto",
		  valor: coletas.filter((c) => c.rnApgarMenor7).length
	  },
	  {
		  nome: "Internação em UTI de recém-nascido >2500g e por >24h",
		  valor: coletas.filter((c) => c.rnInternadoUTIMaisde24h).length
	  }
	];
  
	const dadosOrdenados = dados.sort((a, b) => {
		  if(a.valor < b.valor)
			  return -1;
		  if(a.valor > b.valor)
			  return 1;
		  return 0;
	  }).reverse();

	const series = [
		{
		  name: 'Percentual',
		  data: dadosOrdenados.map(d => ({
			  y: (d.valor/coletas.length)*100,
			  n: d.valor
			})),
		  color: 'indianred'
		}
	];

	return {
	  categorias: dadosOrdenados.map(d => d.nome),
	  series
	};
 }
 
 export function processarChecklistBarChart(
	coletas: DadosColetaChecklist[],
	momentosKeys: any[] = checklistMomentos
) {
	const dados = momentosKeys.map(momento => {
		let v = 0;
		coletas.forEach(coleta => v += contarQuestoesPreenchidas(coleta, momento.keys));

		return {
			nome: momento.label,
			main: momento.main,
			valor: v,
			total: coletas.length * momento.keys.length
		}
	});
  
	// const dadosOrdenados = dados.sort((a, b) => {
	// 	  if(a.valor < b.valor && !a.main)
	// 		  return -1;
	// 	  if(a.valor > b.valor || a.main)
	// 		  return 1;
	// 	  return 0;
	//   }).reverse();

	const series = [
		{
		  name: 'Percentual',
		  data: dados.map(d => ({
			  y: (d.valor/d.total)*100,
			  n: d.valor,
			  color: d.main ? '#005300' : '#199619'
			}))
		}
	];

	return {
	  categorias: dados.map(d => d.nome),
	  series
	};
 }

 export function processarDadosTipoParto(coletas: DadosColetaEventosAdversos[]) {
	const tiposParto = coletas.map((c) => c.tipoParto);
  
	return [
	  {
		name: 'Tipos de parto',
		colorByPoint: true,
		data: [
		  {
			name: 'Normal',
			y: tiposParto.filter((tipo) => (tipo === 'Normal' ||  tipo === 'Normal/fórceps')).length,
			selected: true,
			color: 'lightgreen'
		  },
		  {
			name: 'Cesárea',
			y: tiposParto.filter((tipo) => tipo === 'Cesárea').length,
			color: 'indianred'
		  }
		]
	  }
	];
  }

import React from 'react';

const PageHeader = ({ titulo }) => {
  return (
    <header className="page-header">
      <div className="container-fluid">
        <h2 className="no-margin-bottom lead">{titulo}</h2>
      </div>
    </header>
  );
};

export default PageHeader;

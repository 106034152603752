import * as React from 'react';
import Loading from '../api/Loading.jsx';
import { Usuario } from '../modelos/usuario';
import { atualizarTipoUsuario, getUsuarioById } from '../api/api';
import copiaProfunda from '../utils/copiaProfunda';
import Swal from 'sweetalert2';
import getFetchErroComponent from '../utils/erroCompotent';

interface State {
	usuario: Usuario;
	carregando: boolean;
	loadingErro: boolean;
	bloquearBnts: boolean;
	erroAtualizarNome: boolean;
	errorMessagePass: string;
}

export default class EditarUsuarios extends React.Component<any, State> {
	id = Number(this.props.location.pathname.split('/').slice(-1)[0]);
	constructor(props) {
		super(props);
		this.state = {
			usuario: null,
			carregando: true,
			loadingErro: false,
			bloquearBnts: false,
			erroAtualizarNome: false,
			errorMessagePass: ''
		};
	}

	componentDidMount() {
		this.getUsuario();
	}

	getUsuario = async () => {
		this.setState({ loadingErro: false, carregando: true });
		try {
			let usuario = await getUsuarioById(this.id);
			this.setState({
				usuario,
				loadingErro: false,
				carregando: false
			});
		} catch (err) {
			Swal('Falha', err.response.data.message, 'error');
			this.setState({ loadingErro: true, carregando: false });
		}
	}

	atualizarUsuario = () => {
		this.setState({ bloquearBnts: true });
		let usuarioAtualizado = this.state.usuario;
		atualizarTipoUsuario(usuarioAtualizado)
			.then(() => {
				Swal(
					'Concluído',
					'Sucesso ao atualizar a coleta',
					'success'
				)
					.then(() => window.location.reload());
			})
			.catch((err) => {
				Swal('Falha', err.response.data.message, 'error');
				this.setState({ bloquearBnts: false });
		});

		this.setState({
			usuario: usuarioAtualizado,
			bloquearBnts: false,
			erroAtualizarNome: false,
			errorMessagePass: ''
		}, () =>
			Swal('Sucesso', 'Usuário atualizado com sucesso', 'success')
				.then(() => document.location.reload())
		);
	}

	render() {
		let conteudo = null;
		if (this.state.carregando)
			conteudo = <Loading />
		else if (this.state.loadingErro)
			conteudo = getFetchErroComponent(this.getUsuario);
		else
			conteudo = this.renderForm();


		return (
			<div className="content-inner">
				<div className="container-fluid mt-4">
					<div className="row">
						<div className="col">
							<div className="card">
								<div className="card-header d-flex align-items-center">
									<h3 className="h4 lead">Editar Usuário</h3>
								</div>
								<div className="card-body">
									{conteudo}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	handleChange = (chave) => {
		const copiaUsuario = copiaProfunda(this.state.usuario) as Usuario;
		copiaUsuario["tipo"] = chave;
		this.setState({ usuario: copiaUsuario });
	}

	renderForm = () => {
		return (<>
			<form
				style={{
					padding: '10px'
				}}
			>
				<div className="form-group">
					<label>Nome Completo:</label>
					<input						
						className="form-control"
						type="text"
						placeholder="Nome"
						value={this.state.usuario.nome}
						readOnly
					/>
				</div>
				<div className="form-group">
					<label>Tipo Usuário:</label>
					<select
						value={this.state.usuario.tipo}
						onChange={(e) => this.handleChange(e.target.value)}
						className="form-control">
						<option value="COMUM" key="COMUM">
							Coletador
						</option>
						<option value="ADMIN" key="ADMIN">
							Administrador Geral
						</option>
						<option value="ADMINREDE" key="ADMINREDE">
							Administrador de Rede de Hospitais
						</option>
					</select>
				</div>
				<div className="form-group">
					<label>Email:</label>
					<input
						className="form-control"
						type="text"
						value={this.state.usuario.email}
						readOnly
					/>
				</div>
				<div className="row">
					<div className="col-6 col-md-9">
						{
							this.state.erroAtualizarNome
								? <div className="alert alert-danger">
									Falha ao atualizar
								</div>
								: null
						}
					</div>
					<div className="col-6 col-md-3">
						<button
							className="btn btn-primary"
							onClick={this.atualizarUsuario}
							disabled={this.state.bloquearBnts}
						>{this.state.bloquearBnts ? 'Enviando...' : 'Enviar'}</button>
					</div>
				</div>

			</form></>);
	}
}

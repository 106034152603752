import { DadosColetaEventosAdversos } from "../modelos";
import { DateTime } from "luxon";
import { contarEventosAdversos } from "./eventos-adversos";
import { ponto } from "../graficos/RunChart";

export const tabelaPesos = [{
	chave: 'ocorreuMorteMaterna',
	valor: 750
  }, {
	chave: 'houveRupturaUterina',
	valor: 100
  }, {
	chave: 'maeInternadaUTI',
	valor: 65
  }, {
	chave: 'rnTraumaLesao',
	valor: 60
  }, {
	  chave: 'reinternacaoMae',
	  valor: 40
  }, {
	chave: 'rnInternadoUTIMaisde24h',
	valor: 35
  }, {
	chave: 'rnApgarMenor7',
	valor: 25
  }, {
	chave: 'maeRecebeuTransfusaoSangue',
	valor: 20
  }, {
	chave: 'houveLaceracao34grau',
	valor: 5
  }];
  
export function calcularPontuacao(coleta: DadosColetaEventosAdversos): number {
	let pontuacao = 0;
  
	tabelaPesos.forEach(evento => {
	  if(coleta[evento.chave])
		pontuacao += evento.valor;
	});

	// Caso especial: Morte intraparto ou neonatal > 2.500g
	if(coleta.rnPeso >= 2500 && coleta.rnMorteNeonatal)
	  pontuacao += 400; // Este EA vale 400 pontos na média ponderada
  
	return pontuacao;
  }
  
export function categorizarEmMeses(dados: any[], chaveData: string = 'dataAltaObito'): Array<any> {
	const sorted = dados.slice(0).sort((data1, data2) => {
	  return DateTime.fromISO(data1[chaveData]).toMillis() - DateTime.fromISO(data2[chaveData]).toMillis();
	});
  
	const meses = [];
	let parsed = DateTime.fromISO(sorted[0][chaveData]);
	let mes = parsed.month + '/' + parsed.year;
	let i = 0;
	// primeira coleta do primeiro mês setada
	meses[i] = { mes, coletas: [] };
  
	sorted.forEach(coleta => {
	  parsed = DateTime.fromISO(coleta[chaveData]);
	  mes = parsed.month + '/' + parsed.year;
	  if(meses[i].mes === mes) {
		meses[i].coletas.push(coleta);
	  } else {
		i++;
		meses[i] = {
		  mes,
		  coletas: [coleta]
		};
	  }
	});
  
	return meses;
  }

  
export function categorizarEmDias(dados: DadosColetaEventosAdversos[]): Array<any> {
	const sorted = dados.slice(0).sort((data1, data2) => {
	  return DateTime.fromISO(data1.dataAltaObito).toMillis() - DateTime.fromISO(data2.dataAltaObito).toMillis();
	});
  
	const dias = [];
	let parsed = DateTime.fromISO(sorted[0].dataAltaObito);
	let dia = parsed.day + '/' + parsed.month + '/' + parsed.year;
	let i = 0;
	// primeira coleta do primeiro mês setada
	dias[i] = { dia, coletas: [] };
  
	sorted.forEach(coleta => {
	  parsed = DateTime.fromISO(coleta.dataAltaObito);
	  dia = parsed.day + '/' + parsed.month + '/' + parsed.year;
	  if(dias[i].dia === dia) {
		dias[i].coletas.push(coleta);
	  } else {
		i++;
		dias[i] = {
		  dia,
		  coletas: [coleta]
		};
	  }
	});
  
	return dias;
  }
  
  /*
	Soma das pontuações (pesos) dos resultados adversos de todos os eventos dividido pelo número total de partos 
  */
 export function calcularEPRA(dados: DadosColetaEventosAdversos[]) {
	let totalpartos = dados.length;
	let pontuacaoTotal = 0;
	dados.forEach(coleta => pontuacaoTotal += calcularPontuacao(coleta));
	
	return {
				n: totalpartos,
				p: pontuacaoTotal/totalpartos
	  };
  }
  
  /*
	Mesma soma de pontuações (PESO) e dividindo pelo número de partos complicados por um ou mais eventos adversos 
  */
 export  function calcularIG(dados: DadosColetaEventosAdversos[]) {
	let nPartosComEA = 0;
	dados.forEach(coleta => {
	  const ocorreram = contarEventosAdversos(coleta);
	  if(ocorreram > 0) // Ocorreu ao menos um EA neste parto
		nPartosComEA++;
	});
  
	let pontuacaoTotal = 0;
	dados.forEach(coleta => pontuacaoTotal += calcularPontuacao(coleta));
  
	if(nPartosComEA === 0)
	  return {
		  n: 0,
		  p: 0
	  };
	  
	return {
		  n: nPartosComEA,
		  p: pontuacaoTotal/nPartosComEA
	  };
  }
  
  /*
	Número de partos complicados por um ou mais resultados identificados dividido pelo número total de partos
  */
 export  function calcularIRA(dados: DadosColetaEventosAdversos[]) {
	let n = 0;
	dados.forEach(coleta => {
	  const ocorreram = contarEventosAdversos(coleta);
	  if(ocorreram > 0) // Ocorreu ao menos um EA neste parto
		n++;
	});
  
	let total = dados.length;
  
	return {
		n,
		p: (n/total)*100};
  }
  
  export  function calcularMenorDia(dados: DadosColetaEventosAdversos[], f: Function): number {
	  const categorizado = categorizarEmDias(dados);
	  return calcularMenor(categorizado, f);
  }
  
  export  function calcularMaiorDia(dados: DadosColetaEventosAdversos[], f: Function): number {
	  const categorizado = categorizarEmDias(dados);
	  return calcularMaior(categorizado, f);
  }
  
  export  function calcularMaior(dados: any[], f: Function): number {
	  let maior = -Infinity;
	  dados.forEach(amostra => {
		const x = f(amostra.coletas);
		if(x.p > maior)
		  maior = x.p;
	  });
	  return maior;
  }
  
  export  function calcularMenor(dados: any[], f: Function): number {
	  let menor = Infinity;
	  dados.forEach(amostra => {
		const x = f(amostra.coletas);
		if(x.p < menor)
		  menor = x.p;
	  });
	  return menor;
  }
  
 export function processarSerie(dados: any[], fn: Function): ponto[] {
	  const serie = dados.map(mes => ({
		  x: DateTime.fromFormat('01/'+mes.mes, 'dd/M/yyyy').toMillis(),
		  y: fn(mes.coletas).p
	  }));
	  return serie;
  }

import * as React from 'react';

interface Props {
  titulo?: string;
  onClose?: Function;
  children: any;
}

export default function Card(props: Props) {
  return (
    <div className="card">
      {props.titulo && (
        <div className="card-header d-flex align-items-center">
          <h3 className="h4">{props.titulo}</h3>
			{(props.onClose &&
				<button
						className="btn btn-danger ml-auto"
						onClick={() => props.onClose()}
				>
					X
				</button>
			)}
        </div>
      )}
      <div className="card-body">{props.children}</div>
    </div>
  );
}

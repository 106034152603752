import { DadosColetaEventosAdversos } from '../modelos';

// Este array tem como 'source of truth': 'DadosColetaEventosAdversos' em /src/modelos/index.ts.
export const eventosAdversosKeys = [
    'ocorreuMorteMaterna',
    'houveLaceracao34grau',
    'houveRupturaUterina',
    'reinternacaoMae',
	'maeInternadaUTI',
	'maeRecebeuTransfusaoSangue',
    'rnMorteNeonatal',
    'rnTraumaLesao',
	'rnApgarMenor7',
	'rnInternadoUTIMaisde24h'
 ];

// Este array tem como 'source of truth'
// os EAs maternos em 'DadosColetaEventosAdversos' em /src/modelos/index.ts.
 export const eventosAdversosMaeKeys = [
	'maeRecebeuTransfusaoSangue',
    'ocorreuMorteMaterna',
    'houveLaceracao34grau',
    'houveRupturaUterina',
    'reinternacaoMae',
    'maeInternadaUTI'
 ];

// Este array tem como 'source of truth'
// os EAs neonatais em 'DadosColetaEventosAdversos' em /src/modelos/index.ts.
 export const eventosAdversosRNKeys = [
    'rnMorteNeonatal',
    'rnTraumaLesao',
	'rnApgarMenor7',
	'rnInternadoUTIMaisde24h'
 ];

export function contarEventosAdversos(coleta: DadosColetaEventosAdversos): number {
  let total = 0;
  eventosAdversosKeys.forEach(evento => {
    if(coleta[evento])
      total++;
  });

  return total;
}

export function contarEventosAdversosMae(coleta: DadosColetaEventosAdversos): number {
	let total = 0;
	eventosAdversosMaeKeys.forEach((evento) => {
	  if (coleta[evento]) {
		total++;
	  }
	});
  
	return total;
}

export function contarEventosAdversosRN(coleta: DadosColetaEventosAdversos): number {
	let total = 0;
	eventosAdversosRNKeys.forEach((evento) => {
	  if(coleta[evento])
		total++;
	});
	return total;
}

export function calcularProporcaoEA(coletas: DadosColetaEventosAdversos[], qual: string) {
	const n = coletas.filter(coleta => coleta[qual]).length;
	return {
		p: ((n / coletas.length)*100).toFixed(1), // Porcentagem
		n										  //Número de ocorrências
	};
}

export function percentualEventosAdversosMae(coletas: DadosColetaEventosAdversos[]) {
	let n = 0;
	coletas.forEach(coleta => {
		if(contarEventosAdversosMae(coleta) > 0)
			n++;
	});

	return {
		p: (n / coletas.length)*100, // Porcentagem
		n							//Número de ocorrências
	};;
}

export function percentualEventosAdversosRN(coletas: DadosColetaEventosAdversos[]) {
	let n = 0;
	coletas.forEach(coleta => {
		if(contarEventosAdversosRN(coleta) > 0)
			n++;
		// Caso especial: Morte intraparto ou neonatal > 2.500g
		else if(coleta.rnPeso >= 2500 && coleta.rnMorteNeonatal)
			n++;
	});

	return {
		p: (n / coletas.length)*100, // Porcentagem
		n							 //Número de ocorrências
	};;
}

export function calcularMediaTempoMaeInternada(coletas: DadosColetaEventosAdversos[]) {
	const dias = coletas.map(coleta => {
		if(coleta.dataAltaObito && coleta.dataInternacao) {
			let ano = Number(coleta.dataInternacao.split('-')[0]);
			let mes = Number(coleta.dataInternacao.split('-')[1]) -1;
			let dia = Number(coleta.dataInternacao.split('-')[2]);
			const internacao = new Date(ano, mes, dia);

			ano = Number(coleta.dataAltaObito.split('-')[0]);
			mes = Number(coleta.dataAltaObito.split('-')[1]) -1;
			dia = Number(coleta.dataAltaObito.split('-')[2]);
			const alta = new Date(ano, mes, dia);

			const diffTime = Math.abs(alta.getTime() - internacao.getTime());
			const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
			return diffDays;
		}
	}).filter(x => (x !== undefined));

	if(dias.length == 0)
		return {media: 0, n: 0};
	
	const media = Math.round(dias.reduce((acumulado, atual) => (acumulado + atual), 0)
					/ dias.length);

	return {media, n: dias.length};
}

export function preprocessarParaXLSX(source: any[]) {
	return source.map(objeto => {
		const keys = Object.keys(objeto);
		keys.forEach(chave => {
			if(typeof(objeto[chave]) == 'boolean')
				objeto[chave] = objeto[chave] ? "SIM" : "NÃO";
		});

		objeto.hospital = objeto.hospital.nome;
		objeto.dataColeta = objeto.dataColeta.split('-').reverse().join('-');
		objeto.dataAltaObito = objeto.dataAltaObito.split('-').reverse().join('-');
		objeto.dataInternacao = objeto.dataInternacao.split('-').reverse().join('-');
		objeto.dataNascimento = objeto.dataNascimento.split('-').reverse().join('-');

		return objeto;
	});
}

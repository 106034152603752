import * as React from 'react';
import { DateTime } from 'luxon';
import { DadosColetaEventosAdversos, LabelEventosAdversos } from '../modelos';
import { contarEventosAdversos } from '../utils/eventos-adversos';
import RunChart from './RunChart';

interface Props {
  eventoAdversoSelecionado: string;
  dados: DadosColetaEventosAdversos[];
}

export default function RunchartEAMensal(props: Props) {
  const eaSelecionado = props.eventoAdversoSelecionado;
  if(eaSelecionado === 'nenhum')
	return null;

  let dados = [];
  const agrupadoPorMes = [];
  let label = LabelEventosAdversos[eaSelecionado];

  if (eaSelecionado) {
    const eventosAdversosPorQuinzena = {};

    // Separar o evento adverso por quinzenas
    props.dados.forEach((coleta) => {
      // Já foi registrada essa data?
      if (eventosAdversosPorQuinzena[coleta.dataAltaObito]) {
        eventosAdversosPorQuinzena[coleta.dataAltaObito].total += 1;
      } else {
        // Ainda não, registrar pela primeira vez
        eventosAdversosPorQuinzena[coleta.dataAltaObito] = {
          total: 1,
          ea: 0
        };
      }

      if (eaSelecionado === 'GERAL') {
        label = 'Percentual de partos onde ocorreram ao menos um evento adverso a cada mês';
        const totalEventos = contarEventosAdversos(coleta);
        if(totalEventos > 0)
            eventosAdversosPorQuinzena[coleta.dataAltaObito].ea += 1;
		} else if (coleta[eaSelecionado]) { // Houve evento?
			eventosAdversosPorQuinzena[coleta.dataAltaObito].ea += 1;
		}
    });

    const sorted = Object.keys(eventosAdversosPorQuinzena)
    .sort((data1, data2) => {
      return DateTime.fromISO(data1).toMillis() - DateTime.fromISO(data2).toMillis();
    });

    dados = sorted.map((dataAltaObito) => ({
			x: DateTime.fromISO(dataAltaObito).toMillis(),
			y: (eventosAdversosPorQuinzena[dataAltaObito].ea/eventosAdversosPorQuinzena[dataAltaObito].total)*100,
			total: eventosAdversosPorQuinzena[dataAltaObito].total
		}));
    
	  let mesAtual = '';
	  let nSomas = 1;
	  let i = -1;
	  dados.forEach((ponto, index) => {
      let d = DateTime.fromMillis(ponto.x).toObject();
      let mes = d.year+'-'+((d.month>9) ? d.month : '0'+d.month);
      if(mesAtual !== mes) {
        if(i >= 0)
          agrupadoPorMes[i].y /= nSomas;
        i++;
        agrupadoPorMes[i] = {
          x: DateTime.fromISO(mes+'-01').toMillis(),
          y: ponto.y,
          total: ponto.total
        };
        mesAtual = mes;
        nSomas = 1;
      } else {
        agrupadoPorMes[i].y += ponto.y;
        agrupadoPorMes[i].total += ponto.total;
        nSomas++;
      }
      if(index == dados.length-1) // Ultima iteração
        agrupadoPorMes[i].y /= nSomas;
      }
    );
  } else {
	  return null;
  }

  return <RunChart
			dados={agrupadoPorMes}
			corLinha="#07a7e3"
			exportavel={true}
			mostrarLegendas={true}
			percentual={true}
			titulo={label}
      nome='eventosadversosmensal'
			yLabel="Percentual de EA"
		/>;
}

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { obterDadosChecklistFiltrado, obterDadosChecklist } from '../../api/api';
import Loading from '../../api/Loading';
import { DadosColetaEventosAdversos, LabelMomentosChecklistBar, LabelMomentosChecklist } from '../../modelos';
import { DateTime } from 'luxon';
import { getUrlParameter, numero2MesAbreviado, calcularIdadeMae } from '../../utils';
import { categorizarEmMeses, processarSerie } from '../../utils/calculos_IRA_EPRA_IG';
import ColumnChart from '../../graficos/ColumnChart';
import RunChart from '../../graficos/RunChart';
import { processarChecklistBarChart } from '../../utils/ProcessarDadosCharts';
import {
	calcularRunchartChecklist,
	checklistKeys,
	checklistMomentoAdmissaoKeys,
	checklistMomentoAntesExpulsaoMaeKeys,
	checklistMomentoAntesExpulsaoRNKeys,
	checklistMomentoAposNascimentoMaeKeys,
	checklistMomentoAposNascimentoRNKeys,
	checklistMomentoAntesAltaMaeKeys,
	checklistMomentoAntesAltaRNKeys,
	checklistMaeKeys,
	checklistRNKeys,
	checklistMomentosMae,
	checklistMomentosRN
} from '../../utils/calculosChecklist';
import TabelaChecklistAdmissao from '../../graficos/TabelaChecklistAdmissao';
import TabelaChecklistAntesExpulsao from '../../graficos/TabelaChecklistAntesExpulsao';
import TabelaChecklistAposNascimento from '../../graficos/TabelaChecklistAposNascimento';
import TabelaChecklistAntesAlta from '../../graficos/TabelaChecklistAntesAlta';

interface stateI {
	loadingErro: Boolean
	loading: Boolean
	dados: DadosColetaEventosAdversos[]
	clSelecionados: String[]
};

class RelatorioHospitalChecklist extends React.Component<any, stateI> {

	hospitalID = Number(window.location.pathname.split('/').slice(-1)[0]);
	categorizadoMeses = [];

	state = {
		loading: true,
		loadingErro: false,
		dados: [],
		clSelecionados: []
	}

	componentDidMount() {
		if (!this.props.root) {
			this.inicializar();
		} else {
			this.getDados();
		}
	}

	inicializar = () => {
		// Forçando que este componente seja o único na tela
		ReactDOM.render(<RelatorioHospitalChecklist root={true} />, document.getElementsByTagName('body')[0]);
		aplicarEstilos();
	}

	getDados = async () => {
		const dataInicio = getUrlParameter('dataInicio');
		const dataFim = getUrlParameter('dataFim');
		const clSelecionados = getUrlParameter('runchart');
		if (clSelecionados)
			this.state.clSelecionados = clSelecionados.split(',');
		try {
			let dados = null;
			if (dataInicio && dataFim)
				dados = await obterDadosChecklistFiltrado(this.hospitalID, dataInicio, dataFim);
			else
				dados = await obterDadosChecklist(this.hospitalID);

			if (dados.length < 1) {
				this.setState({ loadingErro: true, loading: false });
				return;
			}

			this.categorizadoMeses = categorizarEmMeses(dados);

			this.setState({
				dados,
				loadingErro: false,
				loading: false
			});
		} catch (_) {
			this.setState({ loadingErro: true, loading: false });
		}
	}

	getPeriodoColetar = () => {
		const sorted = this.state.dados
			.slice(0)
			.sort(
				(a, b) => DateTime.fromISO(a.dataParto).toMillis() - DateTime.fromISO(b.dataParto).toMillis()
			);

		const inicio = DateTime.fromISO(sorted[0].dataParto);
		const fim = DateTime.fromISO(sorted[sorted.length - 1].dataParto);

		const dataInicio = numero2MesAbreviado(inicio.month) + '/' + inicio.year;
		const dataFim = numero2MesAbreviado(fim.month) + '/' + fim.year;

		if (dataInicio === dataFim)
			return dataInicio;

		return dataInicio + ' a ' + dataFim;
	}

	imprimir = () => {
		const nomeHospital = this.state.dados[0].hospital.nome.replace(/\s+/g, '_')
		document.title = `Relatório_Qualiparto_${nomeHospital}`;
		window.print();
	};

	render() {
		if (this.state.loading)
			return <Loading />;
		if (this.state.loadingErro)
			return <h2>Não há dados coletados.</h2>;

		const checklistBarChart = processarChecklistBarChart(this.state.dados);
		const serieIRAChecklist = processarSerie(categorizarEmMeses(this.state.dados, 'dataParto'), checkList => calcularRunchartChecklist(checkList, checklistKeys));


		return (
			<div>
				<div className="pagina">
					<div className="d-flex flex-row justify-content-around mb-3 d-print-none">
						<button
							type="button"
							className="btn btn-primary"
							onClick={this.imprimir}
						>
							Baixar Relatório em PDF
						</button>
					</div>
					<div className="d-flex flex-row justify-content-around mb-3">
						<img src="/theme/img/logos/qualisaude_wide.png" height="50px" />
						<img src="/theme/img/logos/imd.png" height="50px" />
						<img src="/theme/img/logos/ufrn.png" height="50px" />
					</div>
					{/* Lista de IDs */}
					<span style={{ fontSize: '9pt' }}>Questionários de IDs: {this.getColetasIds()}</span><br />
					<span style={{ fontSize: '9pt' }}>{`Relatório gerado a partir de ${this.state.dados.length} coletas realizadas`}</span>

					<div className="mt-4">
						<h3 className="text-center">
							RELATÓRIO DE MONITORAMENTO DE ADESÃO À LISTA DE VERIFICAÇÃO PARA PARTO SEGURO DA INSTITUIÇÃO {this.state.dados[0].hospital.nome.toUpperCase()}
						</h3>

						<br /><br />
						<h4
							style={{
								borderBottom: '1px solid gray'
							}}
						>
							1) Gráfico de tendência da adesão ao preenchimento do checklist para Parto Seguro na instituição {this.state.dados[0].hospital.nome}, no período de {this.getPeriodoColetar()}, {this.state.dados[0].hospital.cidade}, {(new Date()).getFullYear()}
						</h4>

						<RunChart
							dados={serieIRAChecklist}
							exportavel={true}
							mostrarLegendas={true}
							titulo='Runchart de Adesão ao Checklist'
							nome='indexchecklist'
							yLabel="Percentual"
							corLinha="#07a7e3"
							percentual={true}
						/>
						{this.state.clSelecionados
							.map(cl => this.getRuncharChecklistEspecifico(cl))
						}

						<br /><br />
						<h4
							style={{
								borderBottom: '1px solid gray'
							}}
						>
							2) Avaliação transversal da adesão ao preenchimento do checklist para Parto Seguro na instituição {this.state.dados[0].hospital.nome}, no período de {this.getPeriodoColetar()}, {this.state.dados[0].hospital.cidade}, {(new Date()).getFullYear()}
						</h4>
						<h4 style={{ borderBottom: '1px solid gray' }}>
							2.1) Grafico de barras do percentual de preenchimento do checklist geral e por etapas
						</h4>
						<ColumnChart
							titulo="Percentual de itens preenchidos em todos os momentos da assistência"
							categorias={checklistBarChart.categorias}
							series={checklistBarChart.series}
							ocultarN={true}
						/>
						{this.getBarchartEspecificido(getUrlParameter('barchart'))}
						<h4 style={{ borderBottom: '1px solid gray' }}>
							2.2) Tabelas com descritivo do percentual de preenchimento do checklist por etapas e por itens
						</h4>
						<TabelaChecklistAdmissao dados={this.state.dados} /><br />
						<TabelaChecklistAntesExpulsao dados={this.state.dados} /><br />
						<TabelaChecklistAposNascimento dados={this.state.dados} /><br />
						<TabelaChecklistAntesAlta dados={this.state.dados} /><br />
					</div>
				</div>
			</div>
		);
	}


	getRuncharChecklistEspecifico = qual => {
		let lista = null;
		switch (qual) {
			case 'admicao':
				lista = checklistMomentoAdmissaoKeys;
				break;
			case 'antesExpulsaoMae':
				lista = checklistMomentoAntesExpulsaoMaeKeys;
				break;
			case 'antesExpulsaoRN':
				lista = checklistMomentoAntesExpulsaoRNKeys;
				break;
			case 'aposNascimentoMae':
				lista = checklistMomentoAposNascimentoMaeKeys;
				break;
			case 'aposNascimentoRN':
				lista = checklistMomentoAposNascimentoRNKeys;
				break;
			case 'antesAltaMae':
				lista = checklistMomentoAntesAltaMaeKeys;
				break;
			case 'antesAltaRN':
				lista = checklistMomentoAntesAltaRNKeys;
				break;
			case 'geralMae':
				lista = checklistMaeKeys;
				break;
			case 'geralRN':
				lista = checklistRNKeys;
				break;
			default:
				return null;
		}

		return (
			<RunChart
				dados={processarSerie(categorizarEmMeses(this.state.dados, 'dataParto'), checkList => calcularRunchartChecklist(checkList, lista))}
				exportavel={true}
				mostrarLegendas={true}
				titulo={LabelMomentosChecklist[qual]}
				nome={qual}
				yLabel="Percentual"
				corLinha="#07a7e3"
				percentual={true}
			/>
		);
	}

	getBarchartEspecificido = qual => {
		let lista = null;
		switch (qual) {
			case 'geralMae':
				lista = processarChecklistBarChart(this.state.dados, checklistMomentosMae);
				break;
			case 'geralRN':
				lista = processarChecklistBarChart(this.state.dados, checklistMomentosRN);
				break;
			case 'todos':
				return ['geralMae', 'geralRN']
					.map(q => this.getBarchartEspecificido(q))
			default:
				return null;
		}

		return (
			<ColumnChart
				titulo={LabelMomentosChecklistBar[qual]}
				categorias={lista.categorias}
				series={lista.series}
				ocultarN={true}
			/>
		);
	};

	getColetasIds = () => {
		const dados = this.state.dados;
		if (dados.length > 20) {
			return dados.slice(0, 20).reduce((ac, coleta) => (ac += ', ' + (coleta.dadosColetaInformacoesGerais ? coleta.dadosColetaInformacoesGerais.id : null)), '').slice(2) + '...';
		} else {
			return dados.reduce((ac, coleta) => (ac += ', ' + (coleta.dadosColetaInformacoesGerais ? coleta.dadosColetaInformacoesGerais.id : null)), '').slice(2);
		}
	}
}

export default RelatorioHospitalChecklist;

function aplicarEstilos() {
	const body = document.getElementsByTagName('body')[0];
	const pages = document.getElementsByClassName('pagina');

	body.style.width = "250mm";
	body.style.height = "297mm";
	body.style.padding = '5mm';
	body.style.font = '11pt "Arial"';

	for (let i = 0; i < pages.length; i++) {
		let page = pages.item(i) as HTMLElement;

		page.style.width = "250mm";
		page.style.height = "297mm";
	}
}

function formatarData(data: Date): String {
	let yyyy = data.getFullYear();
	const mes = numero2MesAbreviado(data.getMonth() + 1);
	return mes + '/' + yyyy;
}

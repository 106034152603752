import { DadosColetaBoasPraticas } from '../modelos';

export function processarDadosTipoParto(coletas: DadosColetaBoasPraticas[]) {
	const tiposParto = coletas.map((c) => c.tipoParto);
  
	return [
	  {
		name: 'Tipos de parto',
		colorByPoint: true,
		data: [
		  {
			name: 'Normal',
			y: tiposParto.filter((tipo) => tipo === 'Normal').length,
			selected: true,
			color: 'lightgreen'
		  },
		  {
			name: 'Cesárea',
			y: tiposParto.filter((tipo) => tipo === 'Cesárea').length,
			color: 'indianred'
		  }
		]
	  }
	];
  }
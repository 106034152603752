import * as React from 'react';
import PageHeader from '../layout/PageHeader';
import { Route } from 'react-router-dom';
import QuestionarioInformacoesGerais from './InformacoesGerais/QuestionarioInformacoesGerais';
import QuestionarioEventosAdversos from './EventosAdversos/QuestionarioEventosAdversos';
import QuestionarioChecklist from './Checklist/QuestionarioChecklist';
import QuestionarioBoasPraticas from './BoasPraticas/QuestionarioBoasPraticas';

import ContinuarQuestionarioInformacoesGerais from './InformacoesGerais/QuestionarioInformacoesGerais';
import ContinuarQuestionarioEventosAdversos from './EventosAdversos/QuestionarioEventosAdversos';
import ContinuarQuestionarioChecklist from './Checklist/QuestionarioChecklist';
import ContinuarQuestionarioBoasPraticas from './BoasPraticas/QuestionarioBoasPraticas';

import EditarQuestionarioBoasPraticas from './BoasPraticas/EditarQuestionarioBoasPraticas';
import EditarInformacoesGerais from './InformacoesGerais/EditarInformacoesGerais';

export default class Questionarios extends React.Component<any> {
  render() {
    return (
      <div className="content-inner">
        <PageHeader titulo="Questionários" />
        <Route path={`${this.props.match.url}/informacoes-gerais/:id`} component={QuestionarioInformacoesGerais} />
        <Route path={`${this.props.match.url}/eventos-adversos/:id`} component={QuestionarioEventosAdversos} />
        <Route path={`${this.props.match.url}/adesao-checklist/:id`} component={QuestionarioChecklist} />
        <Route path={`${this.props.match.url}/boas-praticas/:id`} component={QuestionarioBoasPraticas} />

        <Route path={`${this.props.match.url}/continuar/informacoes-gerais/:id`} component={ContinuarQuestionarioInformacoesGerais} />
        <Route path={`${this.props.match.url}/continuar/eventos-adversos/:id`} component={ContinuarQuestionarioEventosAdversos} />
        <Route path={`${this.props.match.url}/continuar/adesao-checklist/:id`} component={ContinuarQuestionarioChecklist} />
        <Route path={`${this.props.match.url}/continuar/boas-praticas/:id`} component={ContinuarQuestionarioBoasPraticas} />

        <Route path={`${this.props.match.url}/boaspraticas/editar/:id`} component={EditarQuestionarioBoasPraticas} />
        <Route path={`${this.props.match.url}/informacoes-gerais/editar/:id`} component={EditarInformacoesGerais} />
      </div>
    );
  }
}
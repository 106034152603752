/*
  Os campos 'chave' de cada uma destas perguntas precisam
  ter um mapeamento "um para um" com 'DadosColetaBoasPraticas'
  em /src/modelos/index.ts.
*/
export default [
  { type: 'header', text: 'Identificação do prontuário' },
  {
    identificador: 'P1',
    chave: 'numeroProntuario',
    type: 'text',
    label: 'Número do prontuário',
    value: '',
    obrigatorio: true
  },
  {
    identificador: 'P2',
    chave: 'dataParto',
    type: 'date',
    label: 'Data do parto',
    obrigatorio: true
  },
  { type: 'header', text: 'Informações do parto' },
  {
    identificador: 'P3',
    chave: 'iniciouPartograma',
    type: 'radio',
    label: 'Iniciou o partograma?',
    options: ['Sim', 'Não', 'Não se aplica'],
    descricao: 'Marcar “sim” quando foi preenchido pelo menos um dos quatro critérios do Partograma: temperatura, frequência cardíaca da mãe e do feto, pressão arterial e dilatação cervical. Marque “Não se aplica” para mulheres com cesárea programada ou que não entraram em trabalho de parto (dilatação de colo do útero maior que 4 cm).',
    value: null,
    obrigatorio: true
  },
  {
    identificador: 'P4',
    chave: 'presencaAcompanhante',
    type: 'radio',
    label: 'Há o registro de acompanhante durante o parto?',
    options: ['Sim', 'Não'],
    descricao: 'Considere “Sim” quando existir o registro explícito de presença de acompanhante durante o parto.',
    value: null,
    obrigatorio: true
  },
  {
    identificador: 'P5',
    chave: 'administradaOcitocina',
    type: 'radio',
    label: 'Foi administrada ocitocina após o parto?',
    options: ['Sim', 'Não'],
    descricao: 'A ocitocina (10 UI, IM / IV) é o medicamento uterotônico recomendado para prevenir a hemorragia pós-parto (HPP). Esta informação pode ser encontrada Folha de Prescrição de Medicamentos ou de Evolução Interdisciplinar.',
    value: null,
    obrigatorio: true
  },
  {
    identificador: 'P6',
    chave: 'clampeamentoOportuno',
    type: 'radio',
    label: 'Foi realizado o clampeamento oportuno do cordão umbilical?',
    descricao: 'Esta informação pode ser encontrada na Ficha de Identificação do Recém-Nascido, na folha da Evolução da sala de cirurgia ou ainda no checklist para parto seguro. Marque “Não se aplica” para o recém-nascido que precisou de reanimação. Considera-se oportuno o clampeamento do cordção quando este ocorre em não menos de 1 minuto depois do nascimento.',
    options: ['Sim', 'Não', 'Não se aplica'],
    value: null,
    obrigatorio: true
  },
  {
    identificador: 'P7',
    chave: 'iniciouContatoPeleaPela',
    type: 'radio',
    label: 'Foi realizado contato pele a pele entre a mãe e o recém-nascido?',
    descricao: 'Esta informação pode ser encontrada na Ficha de Identificação do Recém-Nascido, na folha da Evolução da sala de cirurgia ou ainda no checklist para parto seguro. Marque “Não se aplica” para o recém-nascido que precisou de reanimação',
    options: ['Sim', 'Não', 'Não se aplica'],
    value: null,
    obrigatorio: true
  },
  {
    identificador: 'P8',
    chave: 'rnIniciouAmamentacaoNa1Hora',
    type: 'radio',
    label: 'Foi iniciada a amamentação na primeira hora após o parto?',
    descricao: 'Esta informação pode ser encontrada na Ficha de Identificação do Recém-Nascido ou na folha da Evolução da sala de cirurgia. Procurar também nas Folhas de Evolução Interdisciplinar. Marque “Não se aplica” para o recém-nascido que precisou de reanimação.',
    options: ['Sim', 'Não', 'Não se aplica'],
    value: null,
    obrigatorio: true
  },
  {
    identificador: 'P9',
    chave: 'administradaVitaminaK',
    type: 'radio',
    label: 'Foi administrada vitamina K no recém-nascido?',
    options: ['Sim', 'Não'],
    descricao: 'Deve ser administrado a todo recém-nascido 1 mg de vitamina K por via intramuscular após o nascimento para profilaxia para doença hemorrágica. Esta informação pode ser encontrada na Ficha de Identificação do Recém-Nascido, na folha da Evolução da sala de cirurgia ou no checklist para parto seguro.',
    value: null,
    obrigatorio: true
  },
  {
    identificador: 'P10',
    chave: 'identificacaoRnPulseira',
    type: 'radio',
    label: 'Foi realizada a identificação do recém-nascido com pulseira?',
    descricao: 'Esta informação pode ser encontrada na Ficha de Identificação do Recém-Nascido, na folha da Evolução da sala de cirurgia ou no checklist para parto seguro.',
    options: ['Sim', 'Não'],
    value: null,
    obrigatorio: true
  },
  { type: 'header', text: 'Observações a respeito da coleta' },
  {
    identificador: 'P11',
    chave: 'observacoes',
    type: 'textarea',
    label: 'Observações(opcional)',
    value: ''
  }
];

import * as React from 'react';
import PageHeader from '../layout/PageHeader';
import Swal2 from 'sweetalert2';
import Swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { obterDadosInformacoesGerais, deletarInformacoesGeraisById } from '../api/api';
import Loading from '../api/Loading.jsx';
import { DadosAgregadosColetaInformacoesGerais } from '../modelos';
import { DateTime } from 'luxon';
import getFetchErroComponent from '../utils/erroCompotent';
import { Redirect } from 'react-router';

interface state {
  carregando: boolean;
  fetchErro: boolean;
  nomeHospital: String;
  coletasInformacoesGerais: DadosAgregadosColetaInformacoesGerais[];
  mudarTela: String;
  numPront: String;
  dataParto: String;
  hospId: number;
}

export default class ListagemInformacoesGerais extends React.Component<any, state> {
  id = Number(this.props.location.pathname.split('/').slice(-1)[0]);
  constructor(props) {
    super(props);
    this.state = {
      carregando: true,
      fetchErro: false,
      nomeHospital: "",
      coletasInformacoesGerais: [],
      mudarTela: null,
      numPront: null,
      dataParto: null,
      hospId: null
    }
  }
  componentDidMount() {
    this.getInformacoesGeraisByHospitalId();
  }
  getInformacoesGeraisByHospitalId = async () => {
    try {
      const infogerais = await obterDadosInformacoesGerais(this.id);
      this.setState({
        coletasInformacoesGerais: infogerais,
        nomeHospital: infogerais[0][4].hospital.nome
      });
    } catch (e) {
      //Swal2('Falha', e.response.data.message, 'error');
      this.setState({ fetchErro: true });
    } finally {
      this.setState({ carregando: false });
    }
  }
  selecionarOpcoesTela = (hospId, numPront, dParto, contemEA, contemCL, contemBP) => {
		let configProxPag = null;
		let sair = false;
		let cont = 0;

    configProxPag = {
			buttons: {
				cancel: true
			}
		}
    if (contemEA == 0 || (contemEA < contemCL || contemEA < contemBP)) {
			Object.assign(configProxPag["buttons"], {
				ea: {
					text: "Eventos Adversos",
					value: "ea",
				}
			});
			cont++;
		}
		if (contemCL == 0 || (contemCL < contemEA || contemCL < contemBP)) {
			Object.assign(configProxPag["buttons"], {
				cl: {
					text: "Adesão ao Checklist",
					value: "checklist",
				}
			});
			cont++;
		}
		if (contemBP == 0 || (contemBP < contemCL || contemBP < contemEA)) {
			Object.assign(configProxPag["buttons"], {
				bp: {
					text: "Boas Práticas",
					value: "bp",
				}
			});
			cont++;
		} 

    Swal("Qual o próximo formulário que você deseja preencher?", configProxPag)
      .then((value) => {
        this.setState({
          mudarTela: value,
          numPront: numPront,
          dataParto: dParto,
          hospId: hospId
        });
        this.mudarTelaColetaSeNescessario();
      });
  };
  mudarTelaColetaSeNescessario = () => {
    switch (this.state.mudarTela) {
      case 'ea':
        return <Redirect to={`/questionarios/continuar/eventos-adversos/` + this.state.hospId
          + '?numeroProntuario=' + this.state.numPront
          + '&dataParto=' + this.state.dataParto} />
      case 'bp':
        return <Redirect to={`/questionarios/continuar/boas-praticas/` + this.state.hospId
          + '?numeroProntuario=' + this.state.numPront
          + '&dataParto=' + this.state.dataParto} />
      case 'checklist':
        return <Redirect to={`/questionarios/continuar/adesao-checklist/` + this.state.hospId
          + '?numeroProntuario=' + this.state.numPront
          + '&dataParto=' + this.state.dataParto} />
      default:
        return null;
    }
  };
  deletarInformacoesGerais = async id => {
    const r = await Swal2({
      title: 'Tem certeza?',
      text: "Esta operação vai remover completamente a coleta!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, delete!'
    });

    if (!r.value) { return; }

    Swal2({
      title: 'Deletando...'
    });
    Swal2.showLoading();

    try {
      await deletarInformacoesGeraisById(id);
      await Swal2('Concluído', 'Coleta deletada com sucesso', 'success');
      const arrayCopy = this.state.coletasInformacoesGerais.filter((infogeral) => infogeral[4].id !== id);
      this.setState({ coletasInformacoesGerais: arrayCopy });

    } catch (err) {
      Swal2('Falha', err.response.data.message, 'error');
    }
  }

  render() {
    const linhas = this.state.coletasInformacoesGerais.map((infogeral) => (
      <tr key={infogeral[4].id}>
        {this.mudarTelaColetaSeNescessario()}
        <td>{infogeral[4].id}</td>
        <td>{infogeral[4].numeroProntuario}</td>
        <td>{infogeral[4].hospital.usuario.nome}</td>
        <td>{DateTime.fromISO(infogeral[4].dataColeta.toString()).toFormat('dd/MM/yyyy')}</td>
        <td>{infogeral[4].tipoParto}</td>
        <td>{DateTime.fromISO(infogeral[4].dataParto).toFormat('dd/MM/yyyy')}</td>
        <td>{infogeral[4].rnPeso}</td>
        <td>{infogeral[4].maeIdadeGestacional}---{infogeral[1]}{infogeral[2]}{infogeral[3]}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <div className="btn-group">
            {(((infogeral[1] == 0 || infogeral[2] == 0 || infogeral[3] == 0) && infogeral[4].partoGemelar == false) 
            || ((infogeral[1] == infogeral[2] || infogeral[2] == infogeral[3]) && infogeral[4].partoGemelar == true) 
            ) ?
              <a onClick={() => this.selecionarOpcoesTela(
                infogeral[4].hospital.id, 
                infogeral[4].numeroProntuario, 
                infogeral[4].dataParto,
                infogeral[1],
                infogeral[2],
                infogeral[3],
              )}>
                <button className="btn btn-info btn-sm"> Continuar coleta</button>
              </a> : <span style={{ pointerEvents: 'none' }} className="btn btn-success btn-sm">Coleta Completa
                <i className="fa fa-check"></i>
              </span>}
            <Link to={"/informacoes-gerais/editar/" + infogeral[4].id}>
              <button title="Editar coleta" className="btn btn-warning btn-sm">
                <i className="far fa-edit"></i>
              </button>
            </Link>
            <button
              className="btn btn-danger btn-sm ml-auto"
              onClick={() => this.deletarInformacoesGerais(infogeral[4].id)}
            ><i className="fas fa-trash" /></button>
          </div>
        </td>
      </tr>
    ));

    const tabela = (<div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Prontuário</th>
            <th>Usuário</th>
            <th>Data Coleta</th>
            <th>Tipo Parto</th>
            <th>Data Parto</th>
            <th>Peso RN</th>
            <th>Idade Gestacional</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>{linhas}</tbody>
      </table>
    </div>);

    const conteudo = (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h3 className="h4 lead">Coletas de informações gerais: {this.state.nomeHospital}</h3>
              <Link to={`/questionarios/informacoes-gerais/${this.id}`}>
                <button className="btn btn-sm btn-success">
                  Novo <i className="fas fa-plus" />
                </button>
              </Link>
            </div>
            <div className="card-body">
              {
                this.state.carregando
                  ? <Loading />
                  : (
                    this.state.fetchErro
                      ? getFetchErroComponent(this.getInformacoesGeraisByHospitalId)
                      : tabela
                  )
              }
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="content-inner">
        <PageHeader titulo="Coletas" />
        <div className="container-fluid mt-4">
          {conteudo}
        </div>
      </div>
    );
  }
}

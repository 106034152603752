import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { obterDadosEventosAdversos, obterDadosEventosAdversosFiltrado } from '../../api/api';
import Loading from '../../api/Loading';
import { DadosColetaEventosAdversos } from '../../modelos';
import TabelaSociodemografica from '../../graficos/TabelaSociodemografica';
import ColumnChart from '../../graficos/ColumnChart';
import { processarEventosAdversosMaeBarChart, processarEventosAdversosRNBarChart, processarDadosTipoParto } from '../../utils/ProcessarDadosCharts';
import RunChartEAMae from '../../graficos/RunChartEAMae';
import { DateTime } from 'luxon';
import RunChartEARN from '../../graficos/RunChartEARN';
import { getUrlParameter, numero2MesAbreviado, calcularIdadeMae } from '../../utils';
import PieChart from '../../graficos/PieChart';
import Histograma from '../../graficos/Histograma';
import RunChart from '../../graficos/RunChart';
import { processarSerie, calcularIRA, calcularEPRA, calcularIG, categorizarEmMeses } from '../../utils/calculos_IRA_EPRA_IG';
import RunchartEAMensal from '../../graficos/RunchartEAMensal';

interface stateI {
	loadingErro: Boolean
	loading: Boolean
	dados: DadosColetaEventosAdversos[]
};

class RelatorioHospital extends React.Component<any, stateI> {

	hospitalID = Number(window.location.pathname.split('/').slice(-1)[0]);
	categorizadoMeses = [];
	eaSelecionados = [];

	state = {
		loading: true,
		loadingErro: false,
		dados: []
	}

	componentDidMount() {
		if (!this.props.root) {
			this.inicializar();
		} else {
			this.getDados();
		}
	}

	inicializar = () => {
		// Forçando que este componente seja o único na tela
		ReactDOM.render(<RelatorioHospital root={true} />, document.getElementsByTagName('body')[0]);
		aplicarEstilos();
	}

	getDados = async () => {
		const dataInicio = getUrlParameter('dataInicio');
		const dataFim = getUrlParameter('dataFim');
		const eaSelecionadosUrl = getUrlParameter('eaSelecionados');
		if (eaSelecionadosUrl)
			this.eaSelecionados = eaSelecionadosUrl.split(',');

		try {
			let dados = null;
			if (dataInicio && dataFim)
				dados = await obterDadosEventosAdversosFiltrado(this.hospitalID, dataInicio, dataFim);
			else
				dados = await obterDadosEventosAdversos(this.hospitalID);

			if (dados.length < 1) {
				this.setState({ loadingErro: true, loading: false });
				return;
			}

			this.categorizadoMeses = categorizarEmMeses(dados);

			this.setState({
				dados,
				loadingErro: false,
				loading: false
			});
		} catch (_) {
			this.setState({ loadingErro: true, loading: false });
		}
	}

	getPeriodoColetar = () => {
		const sorted = this.state.dados
			.slice(0)
			.sort(
				(a, b) => DateTime.fromISO(a.dataAltaObito).toMillis() - DateTime.fromISO(b.dataAltaObito).toMillis()
			);

		const inicio = DateTime.fromISO(sorted[0].dataAltaObito);
		const fim = DateTime.fromISO(sorted[sorted.length - 1].dataAltaObito);

		const dataInicio = numero2MesAbreviado(inicio.month) + '/' + inicio.year;
		const dataFim = numero2MesAbreviado(fim.month) + '/' + fim.year;

		if (dataInicio === dataFim)
			return dataInicio;

		return dataInicio + ' a ' + dataFim;
	}

	imprimir = () => {
		const nomeHospital = this.state.dados[0].hospital.nome.replace(/\s+/g, '_')
		document.title = `Relatório_Qualiparto_${nomeHospital}`;
		window.print();
	};

	render() {
		if (this.state.loading)
			return <Loading />;
		if (this.state.loadingErro)
			return <h2>Não há dados coletados.</h2>;

		const eventosAdversosMaeBarChart = processarEventosAdversosMaeBarChart(this.state.dados);
		const eventosAdversosRNBarChart = processarEventosAdversosRNBarChart(this.state.dados);

		return (
			<div>
				<div className="pagina">
					<div className="d-flex flex-row justify-content-around mb-3 d-print-none">
						<button
							type="button"
							className="btn btn-primary"
							onClick={this.imprimir}
						>
							Baixar Relatório em PDF
						</button>
					</div>
					<div className="d-flex flex-row justify-content-around mb-3">
						<img src="/theme/img/logos/qualisaude_wide.png" height="50px" />
						<img src="/theme/img/logos/imd.png" height="50px" />
						<img src="/theme/img/logos/ufrn.png" height="50px" />
					</div>
					{/* Lista de IDs */}
					<span style={{ fontSize: '9pt' }}>Questionários de IDs: {this.getColetasIds()}</span><br />
					<span style={{ fontSize: '9pt' }}>{`Relatório gerado a partir de ${this.state.dados.length} coletas realizadas`}</span>

					<div className="mt-4">
						<h3 className="text-center">
							RELATÓRIO DE MONITORAMENTO DE EVENTOS ADVERSOS MATERNOS E NEONATAIS EM {this.state.dados[0].hospital.nome.toUpperCase()}
						</h3>

						<br /><br />

						<h4
							style={{
								borderBottom: '1px solid gray'
							}}
						>
							1) Caracterização dos partos na instituição {this.state.dados[0].hospital.nome}, no período de {this.getPeriodoColetar()}, {this.state.dados[0].hospital.cidade}, {(new Date()).getFullYear()}
						</h4>
						<div className="row">
							<div className="col-md-6 offset-md-3">
								<PieChart
									id="pieChartTipoParto"
									titulo="Tipos de Parto"
									series={processarDadosTipoParto(this.state.dados)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<Histograma
									arredondarFaixas={true}
									titulo="Histograma idade da mãe"
									corBarras="indianred"
									nClasses={3}
									dados={this.state.dados.map(c => calcularIdadeMae(c))}
								/>
							</div>
							<div className="col-md-6">
								{<Histograma
									arredondarFaixas={true}
									titulo="Histograma idade gestacional(em semanas)"
									corBarras="indianred"
									nClasses={3}
									dados={this.state.dados.map(c => c.maeIdadeGestacional)}
								/>}
							</div>
						</div>

						<br /><br />

						<p className="text-justify mb-0">
							<b>Tabela 1.</b> Caracterização demográfica e clínica da mãe e seu recém-nascido atendidos no(a) {this.state.dados[0].hospital.nome} no período de {this.getPeriodoColetar()}, {this.state.dados[0].hospital.cidade + '/' + this.state.dados[0].hospital.estado}
						</p>
						<TabelaSociodemografica dados={this.state.dados} />

						<br /><br />

						<h4
							style={{
								borderBottom: '1px solid gray'
							}}
						>
							2) Eventos Adversos na instituição {this.state.dados[0].hospital.nome}, no período de {this.getPeriodoColetar()}, {this.state.dados[0].hospital.cidade}, {(new Date()).getFullYear()}
						</h4>
						<p className="text-justify mb-0">
							<b>Figura 1.</b> Gráfico de barras da proporção de Eventos Adversos(EA) maternos do total de casos analisados no(a) {this.state.dados[0].hospital.nome} no período de {this.getPeriodoColetar()}, {this.state.dados[0].hospital.cidade + '/' + this.state.dados[0].hospital.estado}
						</p>
						<ColumnChart
							titulo="Eventos adversos maternos"
							categorias={eventosAdversosMaeBarChart.categorias}
							series={eventosAdversosMaeBarChart.series}
						/>

						<br /><br />

						<p className="text-justify mb-0">
							<b>Figura 2.</b> Gráfico Run Chart de monitoramento dos Eventos Adversos(EA) maternos no(a) {this.state.dados[0].hospital.nome} no período de {this.getPeriodoColetar()}, {this.state.dados[0].hospital.cidade + '/' + this.state.dados[0].hospital.estado}
						</p>
						<RunChartEAMae dados={this.state.dados} />

						<br /><br />

						<p className="text-justify mb-0">
							<b>Figura 3.</b> Gráfico de barras da proporção de Eventos Adversos(EA) neonatais do total de casos analisados no(a) {this.state.dados[0].hospital.nome} no período de {this.getPeriodoColetar()}, {this.state.dados[0].hospital.cidade + '/' + this.state.dados[0].hospital.estado}
						</p>
						<ColumnChart
							titulo="Eventos adversos neonatais"
							categorias={eventosAdversosRNBarChart.categorias}
							series={eventosAdversosRNBarChart.series}
						/>

						<br /><br />

						<p className="text-justify mb-0">
							<b>Figura 4.</b> Gráfico Run Chart de monitoramento dos Eventos Adversos(EA) neonatais no(a) {this.state.dados[0].hospital.nome} no período de {this.getPeriodoColetar()}, {this.state.dados[0].hospital.cidade + '/' + this.state.dados[0].hospital.estado}
						</p>
						<RunChartEARN dados={this.state.dados} />

						<br /><br />

						<h4
							style={{
								borderBottom: '1px solid gray'
							}}
						>
							3) Controle de qualidade do cuidado
						</h4>
						<h5
							style={{
								borderBottom: '1px solid gray',
								marginTop: '20px'
							}}
						>
							3.1) Indicadores compostos de eventos adversos na assistência ao parto
						</h5>
						<RunChart
							dados={processarSerie(this.categorizadoMeses, calcularIRA)}
							exportavel={true}
							mostrarLegendas={true}
							titulo='Indice de Resultados Adversos - IRA (Estimativa mensal de partos nos quais ocorreram ao menos um evento adverso)'
							nome='indexIRA'
							yLabel="Percentual de IRA"
							corLinha="#07a7e3"
							percentual={true}
						/>
						<hr />
						<RunChart
							dados={processarSerie(this.categorizadoMeses, calcularEPRA)}
							exportavel={true}
							mostrarLegendas={true}
							titulo='Escore Ponderado de Resultados Adversos - EPRA'
							nome='indexEPRA'
							yLabel="Percentual de EPRA"
							corLinha="#07a7e3"
							percentual={false}
						/>
						<hr />
						<RunChart
							dados={processarSerie(this.categorizadoMeses, calcularIG)}
							exportavel={true}
							mostrarLegendas={true}
							titulo='Índice de Gravidade - IG'
							nome='indexIG'
							yLabel="Percentual de IG"
							corLinha="#07a7e3"
							percentual={false}
						/>
						<hr />
						<br /><br />
						{
							this.eaSelecionados.length > 0
								? (<>
									<h5
										style={{
											borderBottom: '1px solid gray',
											marginTop: '20px'
										}}
									>
										3.2) Indicador simples de eventos adversos na assistência ao parto
									</h5>
									{this.eaSelecionados
										.map(cl =>
											<RunchartEAMensal
												dados={this.state.dados}
												eventoAdversoSelecionado={cl}
											/>)
									}
								</>)
								: null
						}
					</div>
				</div>
			</div>
		);
	}

	getColetasIds = () => {
		const dados = this.state.dados;
		if (dados.length > 20) {
			return dados.slice(0, 20).reduce((ac, coleta) => (ac += ', ' + coleta.id), '').slice(2) + '...';
		} else {
			return dados.reduce((ac, coleta) => (ac += ', ' + coleta.id), '').slice(2);
		}
	}
}

export default RelatorioHospital;

function aplicarEstilos() {
	const body = document.getElementsByTagName('body')[0];
	const pages = document.getElementsByClassName('pagina');

	body.style.width = "250mm";
	body.style.height = "297mm";
	body.style.padding = '5mm';
	body.style.font = '11pt "Arial"';

	for (let i = 0; i < pages.length; i++) {
		let page = pages.item(i) as HTMLElement;

		page.style.width = "250mm";
		page.style.height = "297mm";
	}
}

import moment from 'moment';
/*
  Os campos 'chave' de cada uma destas perguntas precisam
  ter um mapeamento "um para um" com 'DadosColetaBoasPraticas'
  em /src/modelos/index.ts.
*/
export default function getPerguntasComDados(coleta) {
  return [
    { type: 'header', text: 'Identificação do prontuário' },
    {
      identificador: 'P1',
      chave: 'numeroProntuario',
      type: 'text',
      label: 'Número do prontuário',
      value: coleta.numeroProntuario,
      obrigatorio: true
    },
    {
      identificador: 'P2',
      chave: 'rnPeso',
      type: 'number',
      label: 'Qual o peso (em gramas) do recém-nascido ao nascer?',
      value: coleta.rnPeso,
      descricao: 'Esta informação pode ser encontrada na Declaração de Nascido Vivo ou na Ficha de Identificação do Recém-Nascido',
      obrigatorio: true,
      valorMinimo: 0
    },
    {
      identificador: 'P3',
      chave: 'partoGemelar',
      type: 'radio',
      label: 'Parto Gemelar',
      options: ['Sim', 'Não'],
      obrigatorio: true,
      value: coleta.partoGemelar ? 'Sim' : 'Não',
      observacao: "OBS: Se o parto for gemelar, a coleta deve ser preenchida para cada recém-nascido"
    },
    {
      identificador: 'P4',
      chave: 'dataParto',
      type: 'date',
      value: moment(coleta.dataParto),
      label: 'Data do parto',
      obrigatorio: true
    },
    {
      identificador: 'P5',
      chave: 'dataInternacao',
      type: 'date',
      label: 'Data de internação',
      obrigatorio: true,
      value: moment(coleta.dataInternacao)
    },
    {
      identificador: 'P6',
      chave: 'dataAltaObito',
      type: 'date',
      label: 'Data da alta/óbito',
      obrigatorio: true,
      value: moment(coleta.dataAltaObito)
    },
    {
      identificador: 'P7',
      chave: 'tipoParto',
      type: 'radio',
      label: 'Tipo de parto',
      options: ['Normal', 'Cesárea', 'Normal/fórceps'],
      value: coleta.tipoParto
    },
    {
      identificador: 'P8',
      chave: 'maeIdadeGestacional',
      type: 'number',
      label: 'Idade gestacional (semanas completas de gestação)',
      value: coleta.maeIdadeGestacional,
      obrigatorio: true,
      valorMinimo: 0
    },
    {
      identificador: 'P9',
      chave: 'dataNascimento',
      type: 'date',
      label: 'Data de nascimento da mãe',
      obrigatorio: true,
      value: moment(coleta.dataNascimento)
    }
  ];
}

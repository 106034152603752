import * as React from 'react';
import { logout as apiLogout } from '../api/api';
import { Usuario } from '../modelos/usuario';

interface Props {
  usuario: Usuario;
}

function logout() {
	apiLogout().then(() => document.location.href = '/');
}

export function HeaderNavbar(props: Props) {
  return (
    <header className="header">
      <nav className="navbar bg-primary">
        <div className="container-fluid">
          <div className="navbar-holder d-flex align-items-center justify-content-between">
            {/* Navbar Header*/}
            <div className="navbar-header">
              {/* Navbar Brand */}
              <a href="/" className="navbar-brand d-none d-sm-inline-block">
                <div className="brand-text d-none d-lg-inline-block">
                  <span>Quali</span>
                  <strong>Parto</strong>
                </div>
              </a>
              {/* Toggle Button*/}
              <a id="toggle-btn" className="menu-btn active cursorPointer">
                <span />
                <span />
                <span />
              </a>
              <a href="http://avaqualisaude.sedis.ufrn.br/" className="external">
			  	      <img
					        src="/theme/img/logos/qualisaude_wide_nobg.png"
					        style={{ height: '45px', marginRight: '30px' }}
				        />
              </a>
            </div>
            <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
              <li className="nav-item">
                <span className="nav-link logout cursorPointer">
                  <span className="d-inline" onClick={logout}>
                    Sair
					<i className="fas fa-sign-out-alt" />
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

import * as React from 'react';
import Highcharts from 'highcharts';
import Histogram from 'highcharts/modules/histogram-bellcurve'
import HighchartsReact from 'highcharts-react-official';

interface Props { 
	dados: number[];
	nClasses: number;
	corBarras: string;
	titulo: string;
	arredondarFaixas: boolean;
};

interface classe {
	min: number;
	n: number;
};

export default function Histograma(props: Props) {
	Histogram(Highcharts);
	
	const classes = [] as classe[];
	const maior = props.dados.reduce((m, n) => n > m ? n : m, -Infinity);
	const menor = props.dados.reduce((m, n) => n < m ? n : m, Infinity);

	if(maior > menor) {
		const amplitude = maior-menor;
		let intervalo = amplitude/props.nClasses;
		if(intervalo <= 0)
			if(props.arredondarFaixas)
				intervalo = 1;
			else
				intervalo = 0.5
		if(props.arredondarFaixas) {
			for(let x = menor; x < maior; x = corrigirArredondamento(x+intervalo)) {
				const inferior = Math.floor(x);
				if(corrigirArredondamento(x+intervalo) < maior) {
					const superior = Math.floor(corrigirArredondamento(x+intervalo));
					classes.push({
						min: inferior,
						n: props.dados.filter(n => (n >= inferior && n < superior)).length
					});
				} else {// Caso especial, ultima faixa deve ser de intervalo fechado a esquerda e a direita
					const superior = Math.ceil(corrigirArredondamento(x+intervalo));
					classes.push({
						min: inferior,
						n: props.dados.filter(n => (n >= inferior && n <= superior)).length
					});
				}
			}
		} else {
			for(let x = menor; x < maior; x += intervalo)
				classes.push({
					min: x,
					n: props.dados.filter(n => (n >= x && n < x+intervalo)).length
				});
		}
	} else { // Caso onde não é possível criar faixas
		classes.push({
			min: menor,
			n: props.dados.length
		});
	}

  return (
    <div style={{ width: '100%' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: { type: 'column', height: 400 },
          credits: { enabled: false },
		  title: { text: props.titulo },
		  legend: {
            enabled: false
          },
          xAxis: {
            categories: classes.map(c => c.min)
          },
          yAxis: { title: { text: '' } },
		  tooltip: { enabled: false },
		  plotOptions: {
			series: {
				borderWidth: 0,
				dataLabels: {
					enabled: true,
					formatter: function() {
						return `<span>${this.y}</span>`;
					}
				}
			}
		  },
		  series: [{
				name: 'Quantidade',
				type: 'histogram',
				data: classes.map(c => ({ y: c.n })),
				color: props.corBarras,
				borderWidth: 1
			}],
		  exporting: {
			filename: props.titulo.replace(/\s+/g, '_')
		  }
        }}
      />
    </div>
  );
}

function corrigirArredondamento(n: number): number {
	const floor = Math.floor(n);
	const dif = n - floor;
	if(dif > 0.9999) {
		return Math.ceil(n);
	}
	if(dif < 0.00001) {
		return Math.floor(n);
	}
	return n;
}

import * as React from 'react';
import PageHeader from '../layout/PageHeader';
import Loading from '../api/Loading.jsx';
import { getHospitais, getAutorizacoesHospital, autorizarUsuario, getUsuarioByEmail, removerAutorizacao, getUsuario } from '../api/api';
import { Autorizacao, Hospital } from '../modelos';
import getFetchErroComponent from '../utils/erroCompotent';
import Swal from 'sweetalert2';

interface State {
    carregando: boolean;
    loadingErro: boolean;
    semHospitais: boolean;
    atualizandoAutorizacoes: boolean;
    hospitalSelecionado: number;
    hospitais: Hospital[];
    erroAutorizar: boolean;
    msgErroAutorizar: string;
}

export default class Autorizacoes extends React.Component<any, State> {
    constructor(props) {
        super(props);
        this.state = {
            erroAutorizar: false,
            carregando: true,
            loadingErro: false,
            semHospitais: false,
            atualizandoAutorizacoes: false,
            hospitais: [],
            hospitalSelecionado: 0,
            msgErroAutorizar: ''
        };
    }

    emailUsuario = ''
    componentDidMount() {
        this.getHospitais();
    }

    getHospitais = async () => {
        try {
            const usuario = await getUsuario();
            if (usuario.tipo == "ADMIN" || usuario.tipo == "ADMINREDE") {
                const hospitais = await getHospitais();
                //const meusHospitais = hospitais.filter(h => h.usuario.id === usuario.id);
                const meusHospitais = hospitais;
                if (meusHospitais.length == 0) {
                    this.setState({
                        semHospitais: true,
                        carregando: false,
                    });
                } else {
                    this.setState({
                        hospitais: meusHospitais,
                        hospitalSelecionado: (meusHospitais.length > 0) ? meusHospitais[0].id : 0,
                        loadingErro: false,
                        carregando: false
                    });
                }
            } else {
                this.setState({
                    loadingErro: true,
                    carregando: false,
                });
            }
        }
        catch {
            this.getHospitais();
        }
    }

    autorizar = async ev => {
        ev.preventDefault();
        this.setState({ atualizandoAutorizacoes: true });
        try {
            const user = await getUsuarioByEmail(this.emailUsuario);
            await autorizarUsuario(user.id, this.state.hospitalSelecionado);
            this.setState({ erroAutorizar: false });
        } catch (err) {
            this.setState({
                erroAutorizar: true,
                msgErroAutorizar: err.response.data
            });
        }
        this.setState({ atualizandoAutorizacoes: false });
    }

    render() {
        let conteudo = null;
        if (this.state.carregando)
            conteudo = <Loading />
        else if (this.state.loadingErro)
            conteudo = getFetchErroComponent(this.getHospitais);
        else if (this.state.semHospitais)
            conteudo = (
                <div
                    style={{
                        padding: '20px'
                    }}
                >
                    <h2 className="text-muted">
                        Não há hospitais cadastrados para autorizar
                    </h2>
                    <button
                        className="btn btn-outline-success"
                        onClick={() => window.location.reload()}
                    >
                        Tentar novamente
                    </button>
                </div>
            )
        else
            conteudo = (
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-header d-flex align-items-center">
                                <h3 className="h4 lead">Conceder Autorização Para Hospitais</h3>
                            </div>
                            <div className="card-body">
                                <form
                                    style={{
                                        backgroundColor: "white",
                                        padding: '10px',
                                        borderRadius: '5px'
                                    }}
                                >
                                    <div className="form-group">
                                        <label>Email:</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="E-Mail"
                                            onChange={ev => this.emailUsuario = ev.target.value}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <select
                                            className="form-control"
                                            value={this.state.hospitalSelecionado}
                                            onChange={ev => this.setState({ hospitalSelecionado: Number(ev.target.value) })}>
                                            {this.state.hospitais.map((h) => (
                                                <option key={h.id} value={h.id}>
                                                    {h.nome}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-md-9">
                                            {
                                                this.state.erroAutorizar
                                                    ? <div className="alert alert-danger">
                                                        {
                                                            this.state.msgErroAutorizar != ''
                                                                ? this.state.msgErroAutorizar
                                                                : 'Falha ao autorizar'
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                        <div className="col-6 col-md-3">
                                            <button
                                                className="btn btn-primary"
                                                onClick={this.autorizar}
                                                disabled={this.state.atualizandoAutorizacoes}
                                            >Autorizar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {
                            this.state.atualizandoAutorizacoes
                                ? <Loading />
                                : <TabelaAutorizados />
                        }
                    </div>
                </div>
            );

        return (
            <div className="content-inner">
                <PageHeader titulo="Autorizações" />
                <div className="container-fluid mt-4">
                    {conteudo}
                </div>
            </div>
        );
    }
}

export class TabelaAutorizados extends React.Component<any, any> {

    constructor(props) {
        super(props);

        this.state = {
            lista: [],
            carregando: true,
            loadingErro: false
        };
    }

    componentDidMount() {
        this.inicializar();
    }

    inicializar = () => {
        this.getAutorizacoes()
            .then(aut => {
                this.setState({ lista: aut }, () => {
                    if (typeof this.props.onSuccess == 'function')
                        this.props.onSuccess();
                    this.setState({
                        carregando: false,
                        loadingErro: false
                    });
                });
            })
            .catch(err => {
                if (typeof this.props.onError == 'function')
                    this.props.onError(err);
                Swal('Falha', err.response.data, 'error');
                this.setState({
                    carregando: false,
                    loadingErro: true
                });
            });
    }

    getAutorizacoes = async () => {
        this.setState({ carregando: true });

        const usuario = await getUsuario();
        const hospitais = await getHospitais();
        const meusHospitais = hospitais.filter(h => h.usuario.id === usuario.id);
        const idsHospitais = meusHospitais.map(h => h.id);

        let autorizacoes: Autorizacao[] = [];
        for (let i = 0; i < idsHospitais.length; i++) {
            let novas = [];
            try {
                novas = await getAutorizacoesHospital(idsHospitais[i]);
            } catch {/* EMPTY */ }
            autorizacoes = [...autorizacoes, ...novas];
        }

        return autorizacoes;
    }

    removerAutorizacao = async (id: number) => {
        try {
            await removerAutorizacao(id);
        } finally {
            this.inicializar();
        }
    }

    render() {
        let conteudo = null;
        if (this.state.carregando)
            conteudo = <Loading />
        else if (this.state.loadingErro)
            conteudo = getFetchErroComponent(this.inicializar);
        else
            conteudo = (<div style={{ backgroundColor: 'white' }} className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Usuário</th>
                            <th scope="col">Hospital</th>
                            <th scope="col">Remover</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.lista.map((autorizacao: Autorizacao) =>
                                <tr key={autorizacao.id}>
                                    <td>{autorizacao.usuario.nome}</td>
                                    <td>{autorizacao.hospital.nome}</td>
                                    <td>
                                        <i
                                            className="fas fa-trash"
                                            style={{
                                                color: 'red',
                                                fontSize: '15pt',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => this.removerAutorizacao(autorizacao.id)}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>);

        return conteudo;
    }

}

export default function copiaProfunda(source: any): any {
	switch(typeof source) {
		case "undefined":
			return;
		case "object":
			// Objeto nulo
			if(source === null)
				return null;
			// O objeto tem a capacidade de se clonar sozinho
			if(source.clone && typeof source.clone === "function")
				return source.clone();
			// O objeto na verdade é um array
			if(Array.isArray(source))
				return source.map(x => copiaProfunda(x));

			// O objeto é um objeto javascript composto por chave e valor
			const copia = {};
			const keys = Object.keys(source);
			keys.forEach(key => {
				copia[key] = copiaProfunda(source[key]);
			});
			return copia;
		default:
			return source;
	}
}

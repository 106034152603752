import * as React from 'react';
import "../utils/estilos.css"
import gpap from './gpapps.png';
import Swal from 'sweetalert2';
import imgParceiros from './Parceiros.png';
import { logar, cadastrar as cadastrarAPI, forgotpassw } from '../api/api';
import { Link } from 'react-router-dom';
import copiaProfunda from '../utils/copiaProfunda';
import { POSITION } from 'react-toastify/dist/utils';

export interface LoginProps { }

export interface LoginState {
	resetNewPass: boolean;
	email: string;
	senha: string;
	logando: boolean;
	cadastrar: boolean;
	forgot: boolean;
	cadastro: {
		email: string,
		senha: string,
		nome: string
	},
	newPass: {
		senha: string,
		token: string
	},
	erroNewPass?: string;
	erro?: string;
	erroEmail?: string;
	erroForgot?: string;
	erroSenhas?: string;
	erroPoliticas?: string;
	lembrarUsuario: boolean;
	aceitePoliticas: boolean;
	resetpass: {
		email: string,
		token: string
	}
}

class Login extends React.Component<LoginProps, LoginState> {
	senhaConfirm = '';
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			senha: '',
			cadastrar: false,
			resetNewPass: false,
			forgot: false,
			logando: false,
			aceitePoliticas: false,
			cadastro: {
				email: '',
				senha: '',
				nome: ''
			},
			lembrarUsuario: false,
			resetpass: {
				email: '',
				token: ''
			},
			newPass: {
				senha: '',
				token: ''
			}
		};

		this.handleChange = this.handleChange.bind(this);
		this.login = this.login.bind(this);
	}

	handleChange(event) {
		const target = event.target;
		const { value, name } = target;
		this.setState({
			[name]: value
		} as LoginState);
	}

	handleChangeInsertNewPass = event => {
		const target = event.target;
		const { value, name } = target;

		const copia = copiaProfunda(this.state.newPass);
		copia[name] = value;

		this.setState({ newPass: copia });
	}

	handleChangeCadastro = event => {
		const target = event.target;
		const { value, name } = target;

		const copia = copiaProfunda(this.state.cadastro);
		copia[name] = value;

		this.setState({ cadastro: copia });
	}

	handleChangeResetPass = event => {
		const target = event.target;
		const { value, name } = target;

		const copia = copiaProfunda(this.state.resetpass);
		copia[name] = value;

		this.setState({ resetpass: copia });
	}

	login() {
		this.setState({ logando: true });
		logar(this.state.email, this.state.senha, this.state.lembrarUsuario)
			.then(r => {
				if (r.logado) {
					document.location.reload(true);
				} else {
					this.setState({ erro: r.erro, logando: false });
				}
			})
			.catch(r => {
				this.setState({ erro: "Email ou senha incorretos", logando: false });
			});
	}
	/* Método que realiza o cadastro de novo usuário */
	cadastrar = async () => {
		if (this.state.cadastro.senha !== this.senhaConfirm) {
			this.setState({ erroSenhas: 'As senhas não conferem' });
			return;
		} else {
			this.setState({ erroSenhas: '' });
		}
		if (!this.state.aceitePoliticas) {
			this.setState({ erroPoliticas: 'A política de privacidade não foi aceita.' });
			return;
		} else {
			this.setState({ erroPoliticas: '' });
		}

		this.setState({ logando: true });
		try {
			let r = await cadastrarAPI(
				this.state.cadastro.nome,
				this.state.cadastro.email,
				this.state.cadastro.senha
			);
			if (r) {
				let login = await logar(this.state.cadastro.email, this.state.cadastro.senha, false);
				if (login.logado)
					document.location.reload(true);
			}
		} catch (err) {
			if (err.response) {
				if (err.response.status != 500) {
					if (err.response.data.includes("senha")) {
						this.setState({ erroSenhas: err.response.data, logando: false });
					} else if (err.response.data.includes("e-mail")) {
						this.setState({ erroEmail: err.response.data, logando: false });
					}
				} else {
					this.setState({ logando: false });
					Swal('Erro', 'Erro no cadastro. Verifique se os dados foram digitados corretamente.', 'error');
				}
			} else {
				this.setState({ logando: false });
				Swal('Erro', 'O servidor não está respondendo, tente mais tarde.', 'error');
			}
		}
	}
	/* Método que realiza o reset de senha consulta email*/
	forgot = async () => {

		this.setState({ logando: true });

		let r = await forgotpassw(
			this.state.resetpass.email

		);

		if ((r !== 400) && (r !== 500)) {
			Swal('Concluído', r, 'success');
			this.setState({ logando: false });
		}
		if (r == 400) {
			Swal('Erro', 'Email não localizado em nossa base de dados', 'error');
			this.setState({ logando: false });
		}
		if (r == 500) {
			Swal('Erro', 'O servidor não está respondendo, tente mais tarde.', 'error');
			this.setState({ logando: false });
		}
	}

	render() {
		const isCadastro = this.state.cadastrar;
		const isForgot = this.state.forgot;
		let showCadastro;
		let showForgot;


		if (isCadastro) {
			showCadastro = this.state.cadastrar;
		} else if (isForgot) {
			showForgot = this.state.forgot;
		}

		return (

			<div className="page login-page bgContain">
				<div className="container d-flex align-items-center">
					<div className="form-holder has-shadow">
						<div className="row">
							{/* Logo & Information Panel*/}
							<div className="col-lg-6">
								<div className="info d-flex align-items-center">
									<div className="content">
										<div
											style={{
												textAlign: 'justify',
												paddingBottom: '5px'
											}}
										>
											<img
												src="/theme/img/logos/full_logo_branca.png"
												width="100%"
											/>
											<p
												style={{
													color: 'white',
													marginLeft: '20px',
													marginRight: '20px',
													fontSize: '11pt',
													textShadow: '2px 2px 5px black'
												}}
											>
												A plataforma QualiParto é uma tecnologia desenvolvida para auxiliar
												o monitoramento da qualidade do cuidado e segurança do paciente na
												assistência ao parto. Inclui coleta e análise automática de indicadores
												de boas práticas obstétricas, de adesão ao Checklist para Parto Seguro da Organização
												Mundial de Saúde (OMS) e de eventos adversos.
											</p>
										</div>
									</div>
								</div>
							</div>
							{/* Form Panel*/}

							<div className="col-lg-6 bg-white">
								<div className="form d-flex align-items-center">
									{
										showCadastro ?
											this.getTelaCadastro() :
											this.getTelaLogin() &&
												showForgot ?
												this.getTelaResetSenha() :
												this.getTelaLogin()

									}
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* Tela de aplicativos */}
				<div id="aplicativos" className="container d-flex align-items-center">
					<div className="form-holder has-shadow">
						<div className="row">
							<div className="col-12 text-center">
								<div className="info bg-black">
									<h1 className="text-center font-size: large" style={{ marginBottom: 10 }}>
										Aplicativos
									</h1>
									<br />
									<h2><b>EM BREVE</b></h2>
									<div className="row row justify-content-center">
										<img
											className="img-fluid"
											style={{
												width: '0.5',
												height: '0.5',
												marginTop: 5,
												marginBottom: 20
											}}
											src={gpap}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Tela de parceiros */}
				<div id="parceiros" className="container d-flex align-items-center">
					<div className="form-holder has-shadow">
						<div className="row">
							<div className="col-12 text-center">
								<div className="info bg-black">
									<h2 className="text-center" style={{ marginBottom: 5 }}>
										Instituições Parceiras
									</h2>
									<div className="row justify-content-center"></div>
									<div className="col-10" style={{ marginBottom: 10 }}></div>
									<img className="img-fluid" src={imgParceiros}></img>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	/* Formulário para tela de login*/

	getTelaLogin = () => (<div className="content">
		<form className="form-validate">
			<div className="form-group">
				<input
					type="text"
					name="email"
					required
					value={this.state.email}
					onChange={this.handleChange}
					className={
						"input-material form-control "
						+ (this.state.erro
							? "is-invalid"
							: ""
						)
					}
					placeholder="Email"
					onKeyPress={e => e.key === 'Enter' ? this.login() : null}
				/>
				<div className="invalid-feedback">
					Email ou senha inválido
				</div>
			</div>
			<div className="form-group">
				<input
					id="login-password"
					type="password"
					name="senha"
					required
					value={this.state.senha}
					onChange={this.handleChange}
					className="input-material form-control"
					placeholder="Senha"
					onKeyPress={e => e.key === 'Enter' ? this.login() : null}
				/>
			</div>
			<div >
				<input

					type="checkbox"
					name="lembrarUser"
					id="lembrarUser"

					checked={this.state.lembrarUsuario}
					onChange={ev => this.setState({ lembrarUsuario: ev.target.checked })}
				/>


				<label style={{
					fontSize: '10pt',
					color: 'gray',


				}} htmlFor='lembrarUser' >
					Lembrar minha senha
				</label>


			</div>
			<button
				disabled={this.state.logando}
				type="button"
				id="login"
				className="btn btn-primary"
				onClick={this.login}
			>
				{!this.state.logando ? "Entrar" : "Aguarde..."}
			</button>
		</form>
		<div>
			<a
				href="#"
				className="forgot-pass"
				onClick={() => this.setState({ cadastrar: true })}
			>
				Criar conta
			</a>
			<span className='m-1'> | </span>
			<a
				href='#' //envia a requisição para a api retornar a página
				className='forgot-pass'
				onClick={() => this.setState({ forgot: true })}
			>
				Esqueceu a Senha?
			</a>


		</div>

		<br />
		<br />

		<div className="row mt-5 border-bottom-light">
			<div className="col-12 text-right">
				<Link
					className="ml-auto"
					to="/sobre"
				>
					Sobre
				</Link>
				<span className="m-1">|</span>
				<Link
					className="ml-auto"
					to="/politica-de-privacidade"
				>
					Politica de Privacidade
				</Link>
				<span className="m-1">|</span>
				<a href="#aplicativos" >
					Aplicativos
				</a>
				<span className="m-1">|</span>
				<a href="#parceiros">
					Parceiros
				</a>
			</div>
		</div>
	</div>);

	/* Formulário para tela de cadastro de novo usuário*/
	getTelaCadastro = () => (<div className="content">
		<form method="post" className="form-validate">
			<div className="form-group">
				<input
					type="text"
					name="nome"
					required
					value={this.state.cadastro.nome}
					onChange={this.handleChangeCadastro}
					className="input-material form-control"
					placeholder="Nome Completo"
				/>
			</div>
			<div className="form-group">
				<input
					type="email"
					name="email"
					required
					value={this.state.cadastro.email}
					onChange={this.handleChangeCadastro}
					className={
						"input-material form-control "
						+ (this.state.erroEmail
							? "is-invalid"
							: ""
						)
					}
					placeholder="Email"
				/>
				<div className="invalid-feedback">
					{this.state.erroEmail}
				</div>
			</div>
			<div className="form-group">
				<input
					type="password"
					name="senha"
					required
					value={this.state.cadastro.senha}
					onChange={this.handleChangeCadastro}
					className={
						"input-material form-control "
						+ (this.state.erroSenhas
							? "is-invalid"
							: ""
						)
					}
					placeholder="Senha"
				/>
				<div className="invalid-feedback">
					{this.state.erroSenhas}
				</div>
			</div>
			<div className="form-group">
				<input
					type="password"
					name="senha_confirm"
					required
					onChange={e => this.senhaConfirm = e.target.value}
					className="input-material form-control"
					placeholder="Repita a senha"
				/>
			</div>
			<button
				disabled={this.state.logando}
				type="button"
				id="login"
				className="btn btn-primary"
				onClick={this.cadastrar}
			>
				{!this.state.logando ? "Cadastrar" : "Aguarde..."}
			</button>
			<div>
				<input
					type="checkbox"
					name="checkPolP"
					id="polPrivavidade"
					required
					checked={this.state.aceitePoliticas}
					onChange={ev => this.setState({ aceitePoliticas: ev.target.checked })}
					className={
						"form-control "
						+ (this.state.erroPoliticas
							? "is-invalid"
							: ""
						)
					}
				/>
				<label style={{
					fontSize: '10pt',
					color: 'gray'
				}} htmlFor='polPrivavidade' >
					*Declaro que estou de acordo com a <a className="ml-auto"
						href="/politica-de-privacidade">política de privacidade</a> do Qualiparto.
				</label>
				<div className="invalid-feedback">
					{this.state.erroPoliticas}
				</div>


			</div>
			{/* This should be submit button but I replaced it with <a> for demo purposes*/}
		</form>
		<a
			href="#"
			className="forgot-pass"
			onClick={() => this.setState({ cadastrar: false })}
		>
			Já tenho uma conta
		</a>
		<br />
		<br />
		<div className="row mt-5 border-bottom-light">
			<div className="col-12 text-right">
				<Link
					className="ml-auto"
					to="/sobre"
				>
					Sobre
				</Link>
				<span className="m-1">|</span>
				<Link
					className="ml-auto"
					to="/politica-de-privacidade"
				>
					Politica de Privacidade
				</Link>
				<span className="m-1">|</span>
				<Link
					to="/parceiros"
				>
					Parceiros
				</Link>
				<span className="m-1">|</span>
				<Link
					to="/Aplicativos"
				>
					Aplicativos
				</Link>
			</div>
		</div>
	</div>);

	/* Tela de formulário para reset de senha*/
	getTelaResetSenha = () => (<div className="content">
		<div><p style={{
			color: 'grey',
			fontSize: '19pt',
		}}>Recuperar Senha
		</p>
		</div>
		<form method="post" className="form-validate">
			<div className="form-group">
				<input
					type="text"
					name="email"
					required
					value={this.state.resetpass.email}
					onChange={this.handleChangeResetPass}
					className="input-material form-control"
					placeholder="Email"
				/>
				<div className="invalid-feedback">
					{this.state.erroEmail}
				</div>
			</div>
			<button
				disabled={this.state.logando}
				type="button"
				id="login"
				className="btn btn-primary"
				onClick={this.forgot}
			>
				{!this.state.logando ? "Enviar" : "Aguarde..."}
			</button>
			{/* This should be submit button but I replaced it with <a> for demo purposes*/}
		</form>
		<a
			href="#"
			className="forgot-pass"
			onClick={() => this.setState({ forgot: false })}
		>
			Já tenho uma conta
		</a>

		<br />
		<br />
	</div>
	);

}

export default Login;

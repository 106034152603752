/*
  Os campos 'chave' de cada uma destas perguntas precisam
  ter um mapeamento "um para um" com 'DadosColetaChecklist'
  em /src/modelos/index.ts.
*/
export default [
  { type: 'header', text: 'Identificação do prontuário' },
  {
    secao: 1,
    identificador: 'P1',
    chave: 'numeroProntuario',
    type: 'text',
    label: 'Número do prontuário',
    value: '',
    obrigatorio: true
  },
  {
    secao: 1,
    identificador: 'P2',
    chave: 'dataParto',
    type: 'date',
    label: 'Data do parto',
    obrigatorio: true
  },
  {
    secao: 1,
    identificador: 'P3',
    chave: 'checklistNoProntuario',
    type: 'checkbox',
    label: 'Existe checklist no prontuário?',
    value: false,
    obrigatorio: true
  },
  { type: 'header', text: 'No momento da admissão' },
  {
    secao: 2,
    identificador: 'P4',
    chave: 'cartaoPrenatal',
    type: 'radio',
    label: 'A mulher levou o cartão do pré-natal?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não, classificar o risco',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 2,
    identificador: 'P4.1',
    chave: 'cartaoPrenatalResultadosImportantes',
    type: 'text',
    label: 'Resultados importantes',
    descricao: "Revisar: grupo sanguíneo e fator RH, Hemograma, HIV, VDRL, Urina, Ultrassonografia, IGM para toxoplasmose e Hepatite B",
    value: "",
    obrigatorio: false
  },
  {
    secao: 2,
    identificador: 'P5',
    chave: 'referenciada',
    type: 'radio',
    label: 'A parturiente necessita ser referenciada para outro hospital?',
    options: [
      {
        texto: 'Sim, providenciado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 2,
    identificador: 'P6',
    chave: 'iniciouPartograma',
    type: 'radio',
    label: 'Iniciou o partograma?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não, iniciará quando a dilatação for ≥ 4 cm',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Iniciar o registro quando o colo do útero estiver ≥4 cm. A partir de então o colo deve dilatar ≥1 cm/h em média. Registrar as contrações, frequência cardíaca da mãe e do feto a cada 30 minutos. Registrar a temperatura a cada seis horas. Registrar pressão arterial a cada quatro horas ou a cada 2 horas se em uso de Sulfato de Magnésio'
  },
  {
    secao: 2,
    identificador: 'P7',
    chave: 'parturienteNecessitaAntibiotico',
    type: 'radio',
    label: 'Parturiente necessita receber antibiótico?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não, necessita de reavaliação clínica e/ou laboratorial',
        valor: 'não, necessita de reavaliação clínica e/ou laboratorial'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  { secao: 2, identificador: 'P7.0', type: 'label', label: 'Considerar a administração de antibiótico na presença do sinal abaixo ou outros motivos:' },
  {
    secao: 2,
    identificador: 'P7.1',
    chave: 'parturienteNecessitaAntibioticoRupturaMembranas',
    type: 'checkbox',
    label: 'Ruptura das membranas >18 horas',
    value: false,
    obrigatorio: false
  },
  {
    secao: 2,
    identificador: 'P7.2',
    chave: 'parturienteNecessitaAntibioticoOutroMotivo',
    type: 'text',
    label: 'Outro motivo',
    value: "",
    obrigatorio: false
  },
  {
    secao: 2,
    identificador: 'P8',
    chave: 'parturienteNecessitaAntihipertensivo',
    type: 'radio',
    label: 'Parturiente necessita receber anti-hipertensivo?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 2,
    identificador: 'P8.1',
    chave: 'parturienteNecessitaAntihipertensivoNome',
    type: 'text',
    label: 'Nome do anti-hipertensivo',
    value: "",
    obrigatorio: false
  },
  {
    secao: 2,
    identificador: 'P9',
    chave: 'parturienteNecessitaSulfatoMagnesio',
    type: 'radio',
    label: 'Parturiente necessita receber sulfato de magnésio?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Administrar Sulfato de Magnésio à parturiente se: Pré-eclâmpsia grave, pura ou sobreposta à hipertensão arterial crônica ; PAD ≥110mmHg e/ou sintomas clínicos: cefaleia, distúrbios visuais e alteração do nível de consciência; Dor epigástrica, dor “em barra” no hipocôndrio direito;  Náuseas e vômitos; Reflexos patelares exaltados (aumento da amplitude e/ou da área de obtenção).'
  },
  {
    secao: 2,
    identificador: 'P10',
    chave: 'parturienteNecessitaAntirretroviral',
    type: 'radio',
    label: 'Parturiente necessita receber antirretroviral?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não, exame negativo',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Administrar antirretroviral se soropositividade confirmada.'
  },
  {
    secao: 2,
    identificador: 'P11',
    chave: 'disponibilidadeMaterialExame',
    type: 'radio',
    label: 'Há disponibilidade de material para higienizar as mãos e luvas para cada exame vaginal?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 2,
    identificador: 'P11.1',
    chave: 'disponibilidadeMaterialExameAgua',
    type: 'checkbox',
    label: 'Água disponível?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 2,
    identificador: 'P11.2',
    chave: 'disponibilidadeMaterialExameSabao',
    type: 'checkbox',
    label: 'Sabão disponível?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 2,
    identificador: 'P11.3',
    chave: 'disponibilidadeMaterialExamePapelToalha',
    type: 'checkbox',
    label: 'Papel toalha disponível?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 2,
    identificador: 'P11.4',
    chave: 'disponibilidadeMaterialExameSolucaoAlcoolica',
    type: 'checkbox',
    label: 'Solução alcoólica disponível?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 2,
    identificador: 'P11.5',
    chave: 'disponibilidadeMaterialExameLuvas',
    type: 'checkbox',
    label: 'Luvas disponiveis?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 2,
    identificador: 'P12',
    chave: 'presencaAcompanhante',
    type: 'radio',
    label: 'Foi estimulada a presença de um acompanhante durante o parto?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 2,
    identificador: 'P13',
    chave: 'parturienteAcompanhanteSolicitouAjuda',
    type: 'radio',
    label: 'A parturiente ou o acompanhante foram orientados quanto aos sinais de alerta para pedir ajuda, se necessário?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Sinais de alerta para pedir ajuda: Sangramento; Forte dor abdominal; Forte dor de cabeça ou alterações visuais; Incapacidade de urinar; Sensação de urgência de parir; Diminuição dos movimentos fetais.'
  },

  { type: 'header', text: 'Imediatamente antes da expulsão (ou cesariana)' },

  {
    secao: 3,
    identificador: 'P14',
    chave: 'indicacaoCesarea',
    type: 'radio',
    label: 'A parturiente apresenta indicação de cesárea?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  { secao: 3, identificador: 'P14.0', type: 'label', label: 'Marque a indicação de cesárea:' },
  {
    secao: 3,
    identificador: 'P14.1',
    chave: 'indicacaoCesarea2CesareasPrevias',
    type: 'checkbox',
    label: '2 cesáreas prévias',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.2',
    chave: 'indicacaoCesareaSituacaoTransversa',
    type: 'checkbox',
    label: 'Situação transversa',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.3',
    chave: 'indicacaoCesareaCardiopatiaClasseiiiEiv',
    type: 'checkbox',
    label: 'Cardiopatia classe III e IV',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.4',
    chave: 'indicacaoCesareaHidrocefaliaFeta',
    type: 'checkbox',
    label: 'Hidrocefalia feta',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.5',
    chave: 'indicacaoCesareaTumor',
    type: 'checkbox',
    label: 'Tumor que obstrua o canal de parto',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.6',
    chave: 'indicacaoCesareaDesprendimentoPrematuroPlacenta',
    type: 'checkbox',
    label: 'Desprendimento prematuro da placenta normoinserida',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.7',
    chave: 'indicacaoCesareaTrabalhoPartoMais24Horas',
    type: 'checkbox',
    label: 'Trabalho de parto por mais de 24 horas',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.8',
    chave: 'indicacaoCesareaPlacentaPreviaTotal',
    type: 'checkbox',
    label: 'Placenta prévia total',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.9',
    chave: 'indicacaoCesareaDesproporcaoCefaloPelvica',
    type: 'checkbox',
    label: 'Desproporção céfalo-pélvica',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.10',
    chave: 'indicacaoCesareaApresentacaoAnomala',
    type: 'checkbox',
    label: 'Apresentação anômala',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.11',
    chave: 'indicacaoCesareaHerpesGenitalAtivo',
    type: 'checkbox',
    label: 'Herpes genital ativo',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.12',
    chave: 'indicacaoCesareaHivPositivo',
    type: 'checkbox',
    label: 'HIV positivo, exceto comprovada baixa carga viral',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P14.13',
    chave: 'indicacaoCesareaOutra',
    type: 'text',
    label: 'Outra',
    value: "",
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P15',
    chave: 'parturienteEpisiotomia',
    type: 'radio',
    label: 'A parturiente apresenta indicação de episiotomia?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 3,
    identificador: 'P15.1',
    chave: 'parturienteEpisiotomiaMotivo',
    type: 'text',
    label: 'Motivo',
    value: "",
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P16',
    chave: 'antesExpulsaoParturienteNecessitaAntibiotico',
    type: 'radio',
    label: 'Parturiente necessita receber antibiótico?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não, necessita de reavaliação clínica e/ou laboratorial',
        valor: 'não, necessita de reavaliação clínica e/ou laboratorial'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  { secao: 3, identificador: 'P16.0', type: 'label', label: 'Considerar a administração de antibiótico se:' },
  {
    secao: 3,
    identificador: 'P16.1',
    chave: 'antesExpulsaoParturienteNecessitaAntibioticoRupturaMembranas',
    type: 'checkbox',
    label: 'Ruptura das membranas >18 horas',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P16.2',
    chave: 'antesExpulsaoParturienteNecessitaAntibioticoOutroMotivo',
    type: 'text',
    label: 'Outro motivo',
    value: "",
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P17',
    chave: 'antesExpulsaoParturienteNecessitaAntihipertensivo',
    type: 'radio',
    label: 'Parturiente necessita receber anti-hipertensivo?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 3,
    identificador: 'P17.1',
    chave: 'antesExpulsaoParturienteNecessitaAntihipertensivoNome',
    type: 'text',
    label: 'Nome do anti-hipertensivo',
    value: "",
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P18',
    chave: 'antesExpulsaoParturienteNecessitaSulfatoMagnesio',
    type: 'radio',
    label: 'Parturiente necessita receber sulfato de magnésio?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Administrar à parturiente Sulfato de Magnésio se: Pré-eclâmpsia grave, pura ou sobreposta à hipertensão arterial crônica; PAD ≥110mmHg e/ou sintomas clínicos: cefaleia, distúrbios visuais e alteração do nível de consciência; Dor epigástrica, dor “em barra” no hipocôndrio direito; Náuseas e vômitos; Reflexos patelares exaltados (aumento da amplitude e/ou da área de obtenção).'
  },
  { identificador: 'P19', type: 'label', label: '(P19) O material essencial para o parto está disponível próximo da cama?' },
  {
    secao: 3,
    identificador: 'P19.1',
    chave: 'disponivelMaterialPertoCamaLuvas',
    type: 'checkbox',
    label: 'Luvas',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P19.2',
    chave: 'disponivelMaterialPertoCamaSolucaoAlcoolicaOuSabaoeAgua',
    type: 'checkbox',
    label: 'Solução alcoólica ou sabão e água',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P19.3',
    chave: 'disponivelMaterialPertoCamaOcitocina',
    type: 'checkbox',
    label: 'Ocitocina – 10 unidades',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P19.4',
    chave: 'disponivelMaterialPertoCama2PincasKelly',
    type: 'checkbox',
    label: '2 pinças Kelly',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P20',
    chave: 'profissionalAssistenteParto',
    type: 'radio',
    label: 'Identificou e informou um segundo profissional para auxiliar o parto, caso necessário?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 3,
    identificador: 'P20.1',
    chave: 'profissionalAssistentePartoNome',
    type: 'text',
    label: 'Nome',
    value: "",
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P21',
    chave: 'profissionalCapacitadoReanimacaoNeonatal',
    type: 'radio',
    label: 'Está presente algum profissional com capacitação atualizada em reanimação neonatal (máximo 2 anos)?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 3,
    identificador: 'P21.1',
    chave: 'profissionalCapacitadoReanimacaoNeonatalNome',
    type: 'text',
    label: 'Nome',
    value: "",
    obrigatorio: false
  },
  { identificador: 'P22', type: 'label', label: '(P22) Marque o material essencial que está disponível próximo da cama:' },
  {
    secao: 3,
    identificador: 'P22.1',
    chave: 'materialAssistenciaRnSondasTraqueais',
    type: 'checkbox',
    label: 'Sondas traqueais Nº 6,8 e 10 e gástricas curtas Nº 6 e 8',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.2',
    chave: 'materialAssistenciaRnDispositivoAspiracao',
    type: 'checkbox',
    label: 'Dispositivo para aspiração de mecônio',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.3',
    chave: 'materialAssistenciaRnAspiradorVacuo',
    type: 'checkbox',
    label: 'Aspirador a vácuo com manômetro',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.4',
    chave: 'materialAssistenciaRnReanimadorManual',
    type: 'checkbox',
    label: 'Reanimador manual neonatal/Balão auto-inflável?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.5',
    chave: 'materialAssistenciaRnMascarasVentilacao',
    type: 'checkbox',
    label: 'Máscaras de ventilação 00, 0 e 1?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.6',
    chave: 'materialAssistenciaRnOximetroPulso',
    type: 'checkbox',
    label: 'Oxímetro de pulso?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.7',
    chave: 'materialAssistenciaRnLaringoscopioLamina',
    type: 'checkbox',
    label: 'Laringoscópio com lâmina reta Nº 00, 0 e 1?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.8',
    chave: 'materialAssistenciaRnCanulasIntubacao',
    type: 'checkbox',
    label: 'Cânulas de intubação traqueal Nº 2,5/3/3,5/4?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.9',
    chave: 'materialAssistenciaRnAdrenalina',
    type: 'checkbox',
    label: 'Adrenalina',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.10',
    chave: 'materialAssistenciaRnExpansorVolume',
    type: 'checkbox',
    label: 'Expansor de volume (SF 0,9% ou Riger-lactato)',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.11',
    chave: 'materialAssistenciaRnCamposEstereis',
    type: 'checkbox',
    label: 'Campos estéreis',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.12',
    chave: 'materialAssistenciaRnSondaTraqueal',
    type: 'checkbox',
    label: 'Sonda traqueal Nº 6 ou 8 ou cateter umbilical 5F ou 8F',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.13',
    chave: 'materialAssistenciaRnLuvasOculos',
    type: 'checkbox',
    label: 'Luvas e óculos',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.14',
    chave: 'materialAssistenciaRnLaminaEsteril',
    type: 'checkbox',
    label: 'Lâmina estéril para cortar o cordão umbilical',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.15',
    chave: 'materialAssistenciaRnClampe',
    type: 'checkbox',
    label: 'Clampe para cordão umbilical',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.16',
    chave: 'materialAssistenciaRnFontesOxigenio',
    type: 'checkbox',
    label: 'Fontes de oxigênio/ar comprimido',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.17',
    chave: 'materialAssistenciaRnFonteCalor',
    type: 'checkbox',
    label: 'Fonte de calor radiante',
    value: false,
    obrigatorio: false
  },
  {
    secao: 3,
    identificador: 'P22.18',
    chave: 'materialAssistenciaRnRelogioParede',
    type: 'checkbox',
    label: 'Relógio de parede',
    value: false,
    obrigatorio: false
  },

  { type: 'header', text: 'Logo após o nascimento' },

  {
    secao: 4,
    identificador: 'P23',
    chave: 'puerperaSangrando',
    type: 'radio',
    label: 'A puérpera está sangrando além do esperado?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Se está sangrando além do esperado: Massagem do útero; Considerar uterotônico adicional; Iniciar via intravenosa; Misoprostol retal; Ativar equipe de resposta rápida para emergência; Tratar a causa: atonia uterina, retenção da placenta/fragmentos, lacerações vaginais, ruptura uterina.'
  },
  {
    secao: 4,
    identificador: 'P24',
    chave: 'puerperaNecessitaAntibiotico',
    type: 'radio',
    label: 'Puérpera necessita receber antibiótico?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não, necessita de reavaliação clínica e/ou laboratoria',
        valor: 'não, necessita de reavaliação clínica e/ou laboratoria'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 4,
    identificador: 'P24.1',
    chave: 'puerperaNecessitaAntibioticoPartoMuitoManipulado',
    type: 'checkbox',
    label: 'Parto muito manipulado',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P24.2',
    chave: 'puerperaNecessitaAntibioticoForceps',
    type: 'checkbox',
    label: 'Fórceps',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P24.3',
    chave: 'puerperaNecessitaAntibioticoCesarea',
    type: 'checkbox',
    label: 'Cesárea',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P24.4',
    chave: 'puerperaNecessitaAntibioticoOutroMotivo',
    type: 'text',
    label: 'Outro motivo',
    value: "",
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P25',
    chave: 'puerperaNecessitaAntihipertensivo',
    type: 'radio',
    label: 'Puérpera necessita receber anti-hipertensivo?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 4,
    identificador: 'P25.1',
    chave: 'puerperaNecessitaAntihipertensivoNome',
    type: 'text',
    label: 'Nome do anti-hipertensivo',
    value: "",
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P26',
    chave: 'puerperaNecessitaSulfatoMagnesio',
    type: 'radio',
    label: 'Puérpera necessita receber sulfato de magnésio?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Administrar Sulfato de Magnésio se: Pré-eclâmpsia grave, pura ou sobreposta à hipertensão arterial crônica; PAD ≥110mmHg e/ou sintomas clínicos: cefaleia, distúrbios visuais e alteração do nível de consciência; Dor epigástrica, dor “em barra” no hipocôndrio direito; Náuseas e vômitos; Reflexos patelares exaltados (aumento da amplitude e/ou da área de obtenção).'
  },
  {
    secao: 4,
    identificador: 'P27',
    chave: 'rnNecessitaSerReferenciado',
    type: 'radio',
    label: 'O recém-nascido precisa ser referenciado para outro hospital?',
    options: [
      {
        texto: 'Sim, providenciado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 4,
    identificador: 'P28',
    chave: 'rnNecessitaReceberAntibiotico',
    type: 'radio',
    label: 'O recém-nascido necessita receber antibiótico?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não, necessita de reavaliação clínica e/ou laboratorial',
        valor: 'não, necessita de reavaliação clínica e/ou laboratorial'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  { identificador: 'P29.0', type: 'label', label: '(P29)Marque se o bebê apresenta algum desses sintomas e necessidade de reavaliação clínica e/ou laboratorial' },
  {
    secao: 4,
    identificador: 'P29.1',
    chave: 'rnNecessitaReceberAntibioticoRespiracao',
    type: 'checkbox',
    label: 'Respiração rápida (>60/min) ou lenta (<30/min)?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P29.2',
    chave: 'rnNecessitaReceberAntibioticoTriagem',
    type: 'checkbox',
    label: 'Tiragem intercostal, ruídos respiratórios ou convulsões?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P29.3',
    chave: 'rnNecessitaReceberAntibioticoPoucaMobilidade',
    type: 'checkbox',
    label: 'Pouca mobilidade ou nula, mesmo quando estimulado?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P29.4',
    chave: 'rnNecessitaReceberAntibioticoTemperatura',
    type: 'checkbox',
    label: 'Temperatura <35°C (não aumentando após ser aquecido) ou temperatura >38°C?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P29.5',
    chave: 'rnNecessitaReceberAntibioticoRupturaMembranas',
    type: 'checkbox',
    label: 'Ruptura das membranas >18 horas?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P29.6',
    chave: 'rnNecessitaReceberAntibioticoOutro',
    type: 'text',
    label: 'Outro',
    value: "",
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P30',
    chave: 'rnNecessitaCuidadosEspeciais',
    type: 'radio',
    label: 'O recém-nascido necessita de cuidado especial ou vigilância?',
    options: [
      {
        texto: 'Sim, providenciado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  { identificador: 'P30.0', type: 'label', label: 'Marque ou descreva o motivo' },
  {
    secao: 4,
    identificador: 'P30.1',
    chave: 'rnNecessitaCuidadosEspeciaisPrematuridade',
    type: 'checkbox',
    label: 'Prematuridade?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P30.2',
    chave: 'rnNecessitaCuidadosEspeciaisBaixoPeso',
    type: 'checkbox',
    label: 'Peso ao nascer <2500 g?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P30.3',
    chave: 'rnNecessitaCuidadosEspeciaisAntibioticos',
    type: 'checkbox',
    label: 'Precisa de antibiótico?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P30.4',
    chave: 'rnNecessitaCuidadosEspeciaisReanimacao',
    type: 'checkbox',
    label: 'Precisou de reanimação?',
    value: false,
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P30.5',
    chave: 'rnNecessitaCuidadosEspeciaisOutro',
    type: 'text',
    label: 'Outro',
    value: "",
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P31',
    chave: 'rnNecessitaAntirretroviral',
    type: 'radio',
    label: 'O recém-nascido necessita iniciar terapia antirretroviral?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Se a mãe tiver HIV+, iniciar a profilaxia nas primeiras 4 horas após o nascimento'
  },
  {
    secao: 4,
    identificador: 'P32',
    chave: 'clampeamentoCordao1a3min',
    type: 'radio',
    label: 'Clampeou o cordão de 1 a 3 minutos?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 4,
    identificador: 'P32.1',
    chave: 'clampeamentoCordao1a3minMotivo',
    type: 'text',
    label: 'Motivo (caso não)',
    value: "",
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P33',
    chave: 'iniciouContatoPeleaPela',
    type: 'radio',
    label: 'Realizou contato pele a pele?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 4,
    identificador: 'P33.1',
    chave: 'iniciouContatoPeleaPelaMotivo',
    type: 'text',
    label: 'Motivo (caso não)',
    value: "",
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P34',
    chave: 'rnIniciouAmamentacaoNa1Hora',
    type: 'radio',
    label: 'Iniciou amamentação na primeira hora?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 4,
    identificador: 'P34.1',
    chave: 'rnIniciouAmamentacaoNa1HoraMotivo',
    type: 'text',
    label: 'Motivo (caso não)',
    value: "",
    obrigatorio: false
  },
  {
    secao: 4,
    identificador: 'P35',
    chave: 'administradaVitaminaK',
    type: 'radio',
    label: 'Administrou vitamina K?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 4,
    identificador: 'P36',
    chave: 'identificacaoRNPulseira',
    type: 'radio',
    label: 'Identificou o RN com pulseira?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 4,
    identificador: 'P37',
    chave: 'puerperaAcompanhanteIraoPedirAjudaParaRN',
    type: 'radio',
    label: 'Orientou a puérpera e o acompanhante a pedir ajuda caso existam sinais de alerta?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Sinais de alerta: Icterícia; Respiração rápida ou dificuldade de respirar; Frio extremo; Cianose ou palidez; Febre; Interrupção da alimentação; Menos atividade que o normal.'
  },
  { type: 'header', text: 'Antes da alta' },
  {
    secao: 5,
    identificador: 'P38',
    chave: 'puerperaSangramentoControlado',
    type: 'radio',
    label: 'O sangramento da puérpera está controlado?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não, tratar e adiar alta',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Se está sangrando além do esperado: Massagear o útero; Considerar uterotônico adicional; Iniciar via intravenosa; Tratar a causa: atonia uterina, retenção da placenta/fragmentos, lacerações vaginais, ruptura uterina.'
  },
  {
    secao: 5,
    identificador: 'P39',
    chave: 'antesAltaPuerperaNecessitaAntibiotico',
    type: 'radio',
    label: 'Puérpera necessita de antibiótico?',
    options: [
      {
        texto: 'Sim, administrado',
        valor: 'sim'
      },
      {
        texto: 'Não, necessita de reavaliação clínica e/ou laboratorial',
        valor: 'não, necessita de reavaliação clínica e/ou laboratorial'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  { identificador: 'P39.0', type: 'label', label: 'Considerar a administração de antibiótico se:' },
  {
    secao: 5,
    identificador: 'P39.1',
    chave: 'antesAltaPuerperaNecessitaAntibioticoEndometrite',
    type: 'checkbox',
    label: 'Suspeita de endometrite',
    value: false,
    obrigatorio: false
  },
  {
    secao: 5,
    identificador: 'P39.2',
    chave: 'antesAltaPuerperaNecessitaAntibioticoMotivo',
    type: 'text',
    label: 'Outro motivo',
    value: "",
    obrigatorio: false
  },
  {
    secao: 5,
    identificador: 'P40',
    chave: 'puerperaAcompanhanteIraoPedirAjudaSinaisAlertaPuerpera',
    type: 'radio',
    label: 'A puérpera foi orientada sobre a necessidade de seu acompanhamento após alta e sinais de alerta para pedir ajuda?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Sinais de alerta da Mãe: Hemorragia; Dor de cabeça; Alteração do estado de consciência; Dor abdominal intensa; Alterações visuais; Dificuldade respiratória; Dificuldade para esvaziar a bexiga; Febre ou calafrios.'
  },
  {
    secao: 5,
    identificador: 'P41',
    chave: 'tratamentoAntibioticoRNFinalizado',
    type: 'radio',
    label: 'Se o recém-nascido fazia uso de antibiótico, o tratamento foi finalizado?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não se aplica',
        valor: 'não se aplica'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 5,
    identificador: 'P42',
    chave: 'mamandoBem',
    type: 'radio',
    label: 'O recém-nascido está mamando bem?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não, orientar as boas práticas de amamentação e adiar alta',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 5,
    identificador: 'P43',
    chave: 'maeRNReceberamAntirretroviral6semanas',
    type: 'radio',
    label: 'Se a mãe tiver HIV+, a mãe e o recém-nascido receberam suficiente antirretrovirais para o período de seis semanas?',
    options: [
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Sim, para o bebê',
        valor: 'sim, para o bebê'
      },
      {
        texto: 'Sim, para a mãe',
        valor: 'sim, para a mãe'
      },
      {
        texto: 'Sim, para a mãe e o bebê',
        valor: 'sim, para a mãe e o bebê'
      },
      {
        texto: 'Não se aplica',
        valor: 'não se aplica'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 5,
    identificador: 'P44',
    chave: 'maeAcompanhanteIraoProcurarAjudaRN',
    type: 'radio',
    label: 'Orientou a mãe sobre o acompanhamento do bebê após alta e os sinais de alerta para pedir ajuda?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true,
    descricao: 'Sinais de Alerta do Bebê: Febre; Respiração rápida ou dificuldade de respirar; Frio extremo; Não urina ou não evacua; Interrupção da alimentação correta; Crises convulsivas; Menos atividade que o normal; Icterícia; Regurgitação por via oral ou vômitos; Cordão enrijecido, supurativo e com mau odor; Cianose.'
  },
  {
    secao: 5,
    identificador: 'P45',
    chave: 'rnApresentouIctericia',
    type: 'radio',
    label: 'O RN apresenta icterícia?',
    options: [
      {
        texto: 'Sim (Voltar para reavaliação com 48 horas ou adiar alta)',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 5,
    identificador: 'P46',
    chave: 'rnRealizouExameGrupoSanguineoeFatorRH',
    type: 'radio',
    label: 'Realizou exame para o grupo sanguíneo e fator RH?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 5,
    identificador: 'P47',
    chave: 'vacinaBCG',
    type: 'radio',
    label: 'Vacina BCG?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Encaminhado',
        valor: 'encaminhado'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 5,
    identificador: 'P48',
    chave: 'antesAltaVacinaHEPB',
    type: 'radio',
    label: 'Vacina Hepatite B?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Encaminhado',
        valor: 'encaminhado'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 5,
    identificador: 'P49',
    chave: 'testePezinho',
    type: 'radio',
    label: 'Teste do pezinho?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Encaminhado',
        valor: 'encaminhado'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 5,
    identificador: 'P50',
    chave: 'testeOrelhinha',
    type: 'radio',
    label: 'Teste da orelhinha?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Encaminhado',
        valor: 'encaminhado'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 5,
    identificador: 'P51',
    chave: 'testeOlhinho',
    type: 'radio',
    label: 'Teste do olhinho?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Encaminhado',
        valor: 'encaminhado'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 5,
    identificador: 'P52',
    chave: 'testeLinguinha',
    type: 'radio',
    label: 'Teste do Linguinha?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Encaminhado',
        valor: 'encaminhado'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  {
    secao: 5,
    identificador: 'P53',
    chave: 'testeCoracaozinho',
    type: 'radio',
    label: 'Teste do Coraçãozinho?',
    options: [
      {
        texto: 'Sim',
        valor: 'sim'
      },
      {
        texto: 'Não',
        valor: 'não'
      },
      {
        texto: 'Encaminhado',
        valor: 'encaminhado'
      },
      {
        texto: 'Não preenchido',
        valor: 'não preenchido'
      }
    ],
    value: null,
    obrigatorio: true
  },
  { type: 'header', text: 'Observações a respeito da coleta' },
  {
    identificador: 'P54',
    chave: 'observacoes',
    type: 'textarea',
    label: 'Observações(opcional)',
    value: ''
  }
];

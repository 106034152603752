import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PageHeader from '../layout/PageHeader';
import CadastroRedes from './CadastroRedes';
import EditarRede from './EditarRede';
import PageNotFound from '../pages/PageNotFound';

class Redes extends Component<any, any> {
  render() {
    return (
      <div className="content-inner">
        <PageHeader titulo="Gerenciamento de Redes de Hospitais" />
        <div className="container-fluid mt-4">
          <Switch>
            <Route path="/redes/novo" component={CadastroRedes} />
            <Route path="/redes/editar/*" component={EditarRede} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Redes;

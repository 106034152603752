import React, { Component } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { atualizarHospital, getHospitailById, deletarHospitailById } from '../api/api';
import Loading from '../api/Loading';
import copiaProfunda from '../utils/copiaProfunda';
import validarCNES from "../utils/validarCNES";
import getFetchErroComponent from '../utils/erroCompotent';

class EditarHospital extends Component<any, any> {
	id = Number(this.props.location.pathname.split('/').slice(-1)[0])
  constructor(props) {
    super(props);
    this.state = {
	  carregando: true,
	  fetchError: false,
      hospital: {
        nome: '',
        cnes: '',
        estado: '',
        cidade: ''
      },
      cidades: [],
      estados: []
	};
  }

  componentDidMount() {
	this.getEstados();
  }

  getHospitalInfos() {
	  this.setState({carregando: true});
	  getHospitailById(this.id)
	  				.then(h => {
						const estado = this.state.estados.find(estado => estado.abreviacao === h.estado);
						this.setState({
						  hospital: {
							nome: h.nome,
							cidade: h.cidade,
							estado: h.estado,
							cnes: h.cnes
						  },
						  carregando: false,
						  fetchError: false
					  }, () => {
									this.selecionouItem(estado.id, 'estado')
									.then(() => {
										const cidade = this.state.cidades.find(cidade => cidade.nome === h.cidade);
										this.handleChange(cidade.id, 'cidade');
									});
								});
					})
					.catch(() => this.setState({carregando: false, fetchError: true}));
  }

  getEstados() {
    axios.get('https://www.geonames.org/childrenJSON?geonameId=3469034').then(res => {
      this.setState({
        estados: res.data.geonames.map(geo => ({
          id: geo.geonameId,
          nome: geo.toponymName,
          abreviacao: geo.adminCodes1.ISO3166_2
        }))
      }, () => this.getHospitalInfos());
	})
	.catch(() => this.setState({carregando: false, fetchError: true}));
  }

  handleChange = (valor, input) => {
    this.setState(state => {
	  const copia = copiaProfunda(state.hospital);
	  copia[input] = valor;
	  return {hospital: copia};
    });
  };

  selecionouItem = async (valor, item) => {
    if (item === 'estado')
      await this.carregarCidades(valor);
	this.handleChange(valor, item);
  };

  carregarCidades = estado => {
	  return new Promise((resolve, rej) =>{
		axios.get(`https://www.geonames.org/childrenJSON?geonameId=${estado}`).then(res => {
			this.setState({
			  cidades: res.data.geonames.map(geo => ({ id: geo.geonameId, nome: geo.toponymName }))
			}, resolve);
		  });
	  });
  }

  handleSubmit = event => {
	event.preventDefault();
	if(!validarCNES(this.state.hospital.cnes)) {
		return;
	}

    const estado = this.state.estados.find(estado => estado.id === +this.state.hospital.estado);
    const cidade = this.state.cidades.find(cidade => cidade.id === +this.state.hospital.cidade);

	if(!estado || !cidade || this.state.hospital.nome === "") { Swal.hideLoading(); return; }

    Swal({
      title: 'Salvando'
    });
    Swal.showLoading();

    atualizarHospital({ ...this.state.hospital, id: this.id, estado: estado.abreviacao, cidade: cidade.nome })
      .then(res => {
		  if(!res) {
			Swal('Falha', 'Falha ao salvar o hospital', 'error');
		  } else {
			Swal('Concluído', 'Hospital salvo com sucesso', 'success')
			.then(() => this.props.history.push('/relatorios/hospitais'));
		  }
      })
      .catch(err => {
        Swal('Falha', err.response.data, 'error');
      });
  };

  deletarHospital = async id => {
	const r = await Swal({
		title: 'Tem certeza?',
		text: "Esta operação vai remover completamente o hospital!",
		type: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#d33',
		cancelButtonColor: '#3085d6',
		confirmButtonText: 'Sim, delete!'
	  });

	if(!r.value) { return; }

	Swal({
		title: 'Deletando...'
	  });
	Swal.showLoading();

	try {
		await deletarHospitailById(id);
		await Swal('Concluído', 'Hospital deletado com sucesso', 'success');
		this.props.history.push('/relatorios/hospitais');
	} catch(err) {
		Swal('Falha', err.response.data.message, 'error');
	}
  }

  render() {
    return (
      <div className="card">
        <div className="card-header d-flex align-items-center">
          <h3 className="h4 lead">Editar Hospital</h3>
		  <button
				  className="btn btn-danger btn-sm ml-auto"
				  onClick={() => this.deletarHospital(this.id)}
			>Deletar <i className="fas fa-trash" /></button>
        </div>
        <div className="card-body">
			{this.state.carregando ? <Loading /> 
			: (this.state.fetchError ? getFetchErroComponent(this.getEstados) : this.getForm())
			}
        </div>
      </div>
    );
  }

	getForm() {
		return (
		<form onSubmit={this.handleSubmit}>
		<div className="row">
		  <div className="col-6">
			<div className="form-group">
			  <label className="form-control-label">Nome</label>
			  <input
				type="text"
				name="nome"
				placeholder="Nome"
				className="form-control"
				value={this.state.hospital.nome}
				onChange={e => this.handleChange(e.target.value, 'nome')}
			  />
			</div>
		  </div>
		  <div className="col-6">
			<div className="form-group">
			  <label className="form-control-label">CNES</label>
			  <input
				type="text"
				name="cnes"
				placeholder="CNES"
				className={"form-control" + 
					((validarCNES(this.state.hospital.cnes) || this.state.hospital.cnes === "") 
					? "" : " is-invalid" )
				}
				value={this.state.hospital.cnes}
				onChange={e => this.handleChange(e.target.value, 'cnes')}
			  />
			</div>
		  </div>
		  <div className="col-6">
			<div className="form-group">
			  <label className="form-control-label">Estado</label>
			  <select
				disabled={!this.state.estados.length}
				name="estado"
				placeholder="Estado"
				className="form-control"
				value={this.state.hospital.estado}
				onChange={e => this.selecionouItem(e.target.value, 'estado')}>
				<option value="" />
				{this.state.estados.map(estado => (
				  <option key={estado.id} value={estado.id}>
					{estado.nome}
				  </option>
				))}
			  </select>
			</div>
		  </div>
		  <div className="col-6">
			<div className="form-group">
			  <label className="form-control-label">Cidade</label>
			  <select
				disabled={!this.state.cidades.length}
				name="cidade"
				placeholder="Cidade"
				className="form-control"
				value={this.state.hospital.cidade}
				onChange={e => this.selecionouItem(e.target.value, 'cidade')}>
				<option value="" />
				{this.state.cidades.map(cidade => (
				  <option key={cidade.id} value={cidade.id}>
					{cidade.nome}
				  </option>
				))}
			  </select>
			</div>
		  </div>
		</div>

		<div className="form-group">
		  <input type="submit" value="Cadastrar" className="btn btn-primary" />
		</div>
	  </form>);
	}

}

export default withRouter(EditarHospital);

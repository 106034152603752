import { Usuario } from "./usuario";

export interface Hospital {
  id?: number;
  nome: string;
  cnes: string;
  cidade: string;
  estado: string;
  dataCadastro?: string;
  dataUltimaColetaEventosAdversos?: string;
  dataUltimaColetaBoasPraticas?: string;
  coletaBloqueada?: boolean;
  totalColetas: number;
  usuario?: Usuario;
}

export interface Rede {
	id?: number;
	nome: string;
	cidade: string;
	estado: string;
	dataCadastro?: string;
	usuario?: Usuario;
  }

export interface DadosColetaEventosAdversos {
	id: number;
	hospital: Hospital;
	dadosColetaInformacoesGerais: DadosColetaInformacoesGerais;
	dataColeta: string;
	numeroProntuario: string;
	tipoParto: 'Normal' | 'Cesárea' | 'Normal/fórceps';
	partoGemelar: boolean;
	dataInternacao: string;
	dataAltaObito: string;
	dataNascimento: string;
	ocorreuMorteMaterna: boolean;
	houveLaceracao34grau: boolean;
	houveRupturaUterina: boolean;
	reinternacaoMae: boolean;
	maeRecebeuTransfusaoSangue: boolean;
	maeIdadeGestacional: number;
	maeInternadaUTI: boolean;
	rnMorteNeonatal: boolean;
	rnTraumaLesao: boolean;
	rnApgarMenor7: boolean;
	rnInternadoUTIMaisde24h: boolean;
	rnPeso: number;
	observacoes: string;
};

export interface DadosColetaBoasPraticas {
	id: number;
	hospital: Hospital;
	dadosColetaInformacoesGerais: DadosColetaInformacoesGerais;
	dataColeta: String;
	numeroProntuario: String;
	maeIdadeGestacional: number;
	rnPeso: number;
	tipoParto: 'Normal' | 'Cesárea';
	dataParto: string;
	iniciouPartograma: 'Sim' | 'Não' | 'NA';
	presencaAcompanhante: boolean;
	clampeamentoOportuno:  'Sim' | 'Não' | 'NA';
	iniciouContatoPeleaPela: 'Sim' | 'Não' | 'NA';
	rnIniciouAmamentacaoNa1Hora: 'Sim' | 'Não' | 'NA';
	administradaVitaminaK: boolean;
	identificacaoRnPulseira: boolean;
	administradaOcitocina: boolean;
}

export interface DadosColetaInformacoesGerais {
	id: number;
	hospital: Hospital;
	dataColeta: String;
	numeroProntuario: String;
	rnPeso: number;
	dataParto: string;
	dataInternacao: string;
	dataAltaObito: string;
	tipoParto: 'Normal' | 'Cesárea';
	maeIdadeGestacional: number;
	dataNascimento: string;
}

export interface DadosAgregadosColetaInformacoesGerais {
	0: number;
	1: number;
	2: number;
	3: number;
	4: {
		id: number;
		hospital: Hospital;
		dataColeta: String;
		numeroProntuario: String;
		rnPeso: number;
		dataParto: string;
		dataInternacao: string;
		dataAltaObito: string;
		tipoParto: 'Normal' | 'Cesárea';
		maeIdadeGestacional: number;
		dataNascimento: string;
		partoGemelar: boolean;
	}
}

export interface DadosColetaChecklist {
	id: number;
	hospital: Hospital;
	dadosColetaInformacoesGerais: DadosColetaInformacoesGerais;
	dataColeta: string;
	numeroProntuario: string;
	dataParto: string;
	checklistNoProntuario: boolean;
	cartaoPrenatal: 'sim' | 'não' | 'não preenchido';
	cartaoPrenatalResultadosImportantes: string;
	referenciada: 'sim' | 'não' | 'não preenchido';
	iniciouPartograma: 'sim' | 'não' | 'não preenchido';
	parturienteNecessitaAntibiotico: 'sim' | 'não' | 'não preenchido' | 'não, necessita de reavaliação clínica e/ou laboratorial';
	parturienteNecessitaAntibioticoRupturaMembranas: boolean;
	parturienteNecessitaAntibioticoOutroMotivo: string;
	parturienteNecessitaAntihipertensivo: 'sim' | 'não' | 'não preenchido';
	parturienteNecessitaAntihipertensivoNome: string;
	parturienteNecessitaSulfatoMagnesio: 'sim' | 'não' | 'não preenchido';
	parturienteNecessitaAntirretroviral: 'sim' | 'não' | 'não preenchido';
	disponibilidadeMaterialExame: 'sim' | 'não' | 'não preenchido';
	disponibilidadeMaterialExameAgua: boolean;
	disponibilidadeMaterialExameSabao: boolean;
	disponibilidadeMaterialExamePapelToalha: boolean;
	disponibilidadeMaterialExameSolucaoAlcoolica: boolean;
	disponibilidadeMaterialExameLuvas: boolean;
	presencaAcompanhante: 'sim' | 'não' | 'não preenchido';
	parturienteAcompanhanteSolicitouAjuda: 'sim' | 'não' | 'não preenchido';
	indicacaoCesarea: 'sim' | 'não' | 'não preenchido';
	indicacaoCesarea2CesareasPrevias: boolean;
	indicacaoCesareaSituacaoTransversa: boolean;
	indicacaoCesareaCardiopatiaClasseiiiEiv: boolean;
	indicacaoCesareaHidrocefaliaFeta: boolean;
	indicacaoCesareaTumor: boolean;
	indicacaoCesareaDesprendimentoPrematuroPlacenta: boolean;
	indicacaoCesareaTrabalhoPartoMais24Horas: boolean;
	indicacaoCesareaPlacentaPreviaTotal: boolean;
	indicacaoCesareaDesproporcaoCefaloPelvica: boolean;
	indicacaoCesareaApresentacaoAnomala: boolean;
	indicacaoCesareaHerpesGenitalAtivo: boolean;
	indicacaoCesareaHivPositivo: boolean;
	indicacaoCesareaOutra: string;
	parturienteEpisiotomia: 'sim' | 'não' | 'não preenchido';
	parturienteEpisiotomiaMotivo: string;
	antesExpulsaoParturienteNecessitaAntibiotico: 'sim' | 'não' | 'não preenchido' | 'não, necessita de reavaliação clínica e/ou laboratorial';
	antesExpulsaoParturienteNecessitaAntibioticoRupturaMembranas: boolean;
	antesExpulsaoParturienteNecessitaAntibioticoOutroMotivo: string;
	antesExpulsaoParturienteNecessitaAntihipertensivo: 'sim' | 'não' | 'não preenchido';
	antesExpulsaoParturienteNecessitaAntihipertensivoNome: string;
	antesExpulsaoParturienteNecessitaSulfatoMagnesio: 'sim' | 'não' | 'não preenchido';
	disponivelMaterialPertoCamaLuvas: boolean;
	disponivelMaterialPertoCamaSolucaoAlcoolicaOuSabaoeAgua: boolean;
	disponivelMaterialPertoCamaOcitocina: boolean;
	disponivelMaterialPertoCama2PincasKelly: boolean;
	profissionalAssistenteParto: 'sim' | 'não' | 'não preenchido';
	profissionalAssistentePartoNome: string;
	profissionalCapacitadoReanimacaoNeonatal: 'sim' | 'não' | 'não preenchido';
	profissionalCapacitadoReanimacaoNeonatalNome: string;
	materialAssistenciaRnSondasTraqueais: boolean;
	materialAssistenciaRnDispositivoAspiracao: boolean;
	materialAssistenciaRnAspiradorVacuo: boolean;
	materialAssistenciaRnReanimadorManual: boolean;
	materialAssistenciaRnMascarasVentilacao: boolean;
	materialAssistenciaRnOximetroPulso: boolean;
	materialAssistenciaRnLaringoscopioLamina: boolean;
	materialAssistenciaRnCanulasIntubacao: boolean;
	materialAssistenciaRnAdrenalina: boolean;
	materialAssistenciaRnExpansorVolume: boolean;
	materialAssistenciaRnCamposEstereis: boolean;
	materialAssistenciaRnSondaTraqueal: boolean;
	materialAssistenciaRnLuvasOculos: boolean;
	materialAssistenciaRnLaminaEsteril: boolean;
	materialAssistenciaRnClampe: boolean;
	materialAssistenciaRnFontesOxigenio: boolean;
	materialAssistenciaRnFonteCalor: boolean;
	materialAssistenciaRnRelogioParede: boolean;
	puerperaSangrando: 'sim' | 'não' | 'não preenchido';
	puerperaNecessitaAntibiotico: 'sim' | 'não' | 'não preenchido' | 'não, necessita de reavaliação clínica e/ou laboratorial';
	puerperaNecessitaAntibioticoPartoMuitoManipulado: boolean;
	puerperaNecessitaAntibioticoForceps: boolean;
	puerperaNecessitaAntibioticoCesarea: boolean;
	puerperaNecessitaAntibioticoOutroMotivo: string;
	puerperaNecessitaAntihipertensivo: 'sim' | 'não' | 'não preenchido';
	puerperaNecessitaAntihipertensivoNome: string;
	puerperaNecessitaSulfatoMagnesio: 'sim' | 'não' | 'não preenchido';
	rnNecessitaSerReferenciado: 'sim' | 'não' | 'não preenchido';
	rnNecessitaReceberAntibiotico: 'sim' | 'não' | 'não preenchido' | 'não, necessita de reavaliação clínica e/ou laboratorial';
	rnNecessitaReceberAntibioticoRespiracao: boolean;
	rnNecessitaReceberAntibioticoTriagem: boolean;
	rnNecessitaReceberAntibioticoPoucaMobilidade: boolean;
	rnNecessitaReceberAntibioticoTemperatura: boolean;
	rnNecessitaReceberAntibioticoRupturaMembranas: boolean;
	rnNecessitaReceberAntibioticoOutro: string;
	rnNecessitaCuidadosEspeciais: 'sim' | 'não' | 'não preenchido';
	rnNecessitaCuidadosEspeciaisPrematuridade: boolean;
	rnNecessitaCuidadosEspeciaisBaixoPeso: boolean;
	rnNecessitaCuidadosEspeciaisAntibioticos: boolean;
	rnNecessitaCuidadosEspeciaisReanimacao: boolean;
	rnNecessitaCuidadosEspeciaisOutro: string;
	rnNecessitaAntirretroviral: 'sim' | 'não' | 'não preenchido';
	clampeamentoCordao1a3min: 'sim' | 'não' | 'não preenchido';
	clampeamentoCordao1a3minMotivo: string;
	iniciouContatoPeleaPela: 'sim' | 'não' | 'não preenchido';
	iniciouContatoPeleaPelaMotivo: string;
	rnIniciouAmamentacaoNa1Hora: 'sim' | 'não' | 'não preenchido';
	rnIniciouAmamentacaoNa1HoraMotivo: string;
	administradaVitaminaK: 'sim' | 'não' | 'não preenchido';
	identificacaoRNPulseira: 'sim' | 'não' | 'não preenchido';
	puerperaAcompanhanteIraoPedirAjudaParaRN: 'sim' | 'não' | 'não preenchido';
	puerperaSangramentoControlado: 'sim' | 'não' | 'não preenchido';
	antesAltaPuerperaNecessitaAntibiotico: 'sim' | 'não' | 'não preenchido' | 'não, necessita de reavaliação clínica e/ou laboratorial';
	antesAltaPuerperaNecessitaAntibioticoEndometrite: boolean;
	antesAltaPuerperaNecessitaAntibioticoMotivo: string;
	puerperaAcompanhanteIraoPedirAjudaSinaisAlertaPuerpera: 'sim' | 'não' | 'não preenchido';
	tratamentoAntibioticoRNFinalizado: 'sim' | 'não' | 'não se aplica' | 'não preenchido';
	mamandoBem: 'sim' | 'não' | 'não preenchido';
	maeRNReceberamAntirretroviral6semanas: 'sim, para o bebê' | 'sim, para a mãe' | 'sim, para a mãe e o bebê' | 'não se aplica' | 'não' | 'não preenchido';
	maeAcompanhanteIraoProcurarAjudaRN: 'sim' | 'não' | 'não preenchido';
	rnApresentouIctericia: 'sim' | 'não' | 'não preenchido';
	rnRealizouExameGrupoSanguineoeFatorRH:  'sim' | 'não' | 'não preenchido';
	vacinaBCG: 'sim' | 'não' | 'encaminhado' | 'não preenchido';
	antesAltaVacinaHEPB: 'sim' | 'não' | 'encaminhado' | 'não preenchido';
	testePezinho: 'sim' | 'não' | 'encaminhado' | 'não preenchido';
	testeOrelhinha: 'sim' | 'não' | 'encaminhado' | 'não preenchido';
	testeOlhinho: 'sim' | 'não' | 'encaminhado' | 'não preenchido';
	testeLinguinha: 'sim' | 'não' | 'encaminhado' | 'não preenchido';
	testeCoracaozinho: 'sim' | 'não' | 'encaminhado' | 'não preenchido';
};

export interface Autorizacao {
	id: number;
	hospital: Hospital;
	usuario:  Usuario;
}

export interface RedeHospitais {
	id: number;
	hospital: Hospital;
	rede:  Rede;
}

export const LabelEventosAdversos = {
	maeRecebeuTransfusaoSangue: 'Mãe recebeu transfusão sanguínea',
	ocorreuMorteMaterna: 'Morte materna',
	houveLaceracao34grau: 'Laceração de 3º ou 4º grau',
	houveRupturaUterina: 'Ruptura uterina',
	reinternacaoMae: 'Retorno a sala de parto ou cirurgia',
	maeInternadaUTI: 'Internação da mãe na UTI',
	rnMorteNeonatal: 'Morte neonatal',
	rnTraumaLesao: 'Trauma ou lesão no recém-nascido em decorrência do parto',
	rnApgarMenor7: 'Apgar menor que 7 no 5º minuto',
	rnInternadoUTIMaisde24h: 'Recém-nascido com peso > 2500g internado na UTI por > 24h'
};

export const LabelMomentosChecklist = {
	geralMae: 'Preenchimento relacionados à mãe',
	geralRN: 'Preenchimento relacionados ao recém-nascido',
	admicao: 'Preenchimento no momento da admissão',
	antesExpulsaoMae: 'Preenchimento para a mãe imediatamente antes da expulsão (ou cesariana)',
	antesExpulsaoRN: 'Preenchimento para o recém-nascido imediatamente antes da expulsão (ou cesariana)',
	aposNascimentoMae: 'Preenchimento para a mãe logo após o nascimento',
	aposNascimentoRN: 'Preenchimento para o recém-nascido logo após o nascimento',
	antesAltaMae: 'Preenchimento para a mãe antes da alta',
	antesAltaRN: 'Preenchimento para o recém-nascido antes da alta',
};

export const LabelMomentosChecklistBar = {
	nenhum: 'Nenhuma opção selecionada',
	todos: 'Todos',
	geralMae: 'Preenchimento relacionados à mãe',
	geralRN: 'Preenchimento relacionados ao recém-nascido'
};

export const LabelMomentosBp = {
	iniciouPartograma: 'Iniciou partograma',
	presencaAcompanhante: 'Teve presença de acompanhante',
	administradaOcitocina: 'Administrada ocitocina',
	administradaVitaminaK: 'Vitamina K',
	rnIniciouAmamentacaoNa1Hora: 'Recém-nascido iniciou amamentação após o parto',
	clampeamentoOportuno: 'Clampeamento oportuno do cordão umbilical',
	iniciouContatoPeleaPela: 'Iniciou contato pele a pele',
	identificacaoRnPulseira: 'Identificação do recém-nascido com pulseira',
};
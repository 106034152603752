export interface ChecklistRegrasAplicadasRunChart {
    outlierPositivo: boolean;
    outlierNegativo: boolean;
    pontosConsecutivosAcimaDaMediana: boolean;
    pontosConsecutivosAbaixoDaMediana: boolean;
    quedasConsecutivas: boolean;
    subidasConsecutivas: boolean;
};

export function calcularMediana(dados, maxVal: number): Number {
    const sorted = dados.slice(0).sort((a, b) => a.y-b.y);
    let mediana = sorted[Math.ceil(sorted.length/2)-1].y;
  
    if(sorted.length % 2 == 0) { // Número par de elementos
      mediana = (sorted[(sorted.length/2)-1].y + sorted[(sorted.length/2)].y)/2;
    }

  // A mediana não pode ser 0 nem o valor máximo
  // caso ocorra, deve-se substituir pela média
	if(mediana <= 0 || mediana >= maxVal) {
		const soma = sorted.reduce((ac, cur) => ac+cur.y, 0);
		return soma/sorted.length;
	}

    return mediana;
}

export function calcularMedianas(dados, maxVal: number): any[] {
  if(dados.length <= 6) { // Precisa de mais de 6 pontos para criar mais de uma mediana
    const valorMediana = calcularMediana(dados, maxVal)
    return [{
      name: 'Mediana',
      marker: {enabled: false},
      data: [{ // Ponto Inicial
        x: dados[0].x,
        y: valorMediana
      },
      { // Ponto final
        x: dados[dados.length-1].x,
        y: valorMediana
      }],
      color: '#008B00',
      showInLegend: false,
    }];
  }

  let inicio = 0;
  const medianas = [];
  let markerDetectado = !!dados[0].marker;
  for(let i = 0; i < dados.length;  i++) {
     if(
        (!markerDetectado && dados[i].marker) ||
        (markerDetectado && !dados[i].marker) ||
        i == dados.length-1
      ) {
        // Ou havia um marcador que acabou, ou acabou de começar um novo
        markerDetectado = !!dados[i].marker;
       const valorMediana = calcularMediana(dados.slice(inicio, i+1), maxVal)
       medianas.push([{ // Ponto Inicial
                      x: dados[inicio].x,
                      y: valorMediana
                    },
                    { // Ponto final
                      x: dados[
                        i == dados.length-1
                        ? i
                        : i-1
                      ].x,
                      y: valorMediana
                    }]);
      inicio = i;
     }
  }

  return medianas.map((med, i) => ({
    name: `Mediana ${i+1}`,
    marker: {enabled: false},
    data: med,
    showInLegend: false,
    color: '#008B00',
  }));
}

  /*
    Esta função aplica a seguinte regra de run chart:
    This is a data point that is clearly different from all others.
    This is a judgement call.
    Different people looking at the same graph would be expected to recognise the same data point as astronomical.
  */
 export function marcarOutliers(dados, cb?: Function) {
    if(dados.length <= 1)
      return;
  
    const sorted = dados.slice(0).sort((a, b) => a.y-b.y);
    const ultimo = sorted[sorted.length-1];
    const penultimo = sorted[sorted.length-2];
  
    if(ultimo.y > penultimo.y*2) {
      if(cb) cb(1); // avisando que a regra foi aplicada para um outlier alto
      ultimo.marker = {
        enabled: true,
        symbol: 'url(/theme/img/circle_red.png)',
        height: 10,
        width: 10
      };
      ultimo.name = `Outlier`;
        ultimo.color = '#FF0000';
    }
  
    const primeiro = sorted[0];
    const segundo = sorted[1];
  
    if(primeiro*2 < segundo) {
      if(cb) cb(-1); // avisando que a regra foi aplicada para um outlier baixo
      ultimo.marker = {
        enabled: true,
        symbol: 'url(/theme/img/circle_red.png)',
        height: 10,
        width: 10
      };
      ultimo.name = `Outlier`;
        ultimo.color = '#00FF00';
    }
  }
  
  /*
    Esta função aplica a seguinte regra de run chart:
    Six or more consecutive points all above the centre line (CL).
    Values that fall on the CL do not add to nor break a shift.
    Skip values that fall on the median and continue counting.
  */
 export function marcarPontosConsecutivosAcimaDaMediana(dados, medianas, quantos, cb?: Function) {
    if(dados.length <= 1)
      return;
  
    let consecutivos = 0;
    let pintar = 0;
    for(let i = 0; i <= dados.length; i++) { // Percorre até um elemento depois do arrays
      const mediana = getMedianaAnterior(dados, medianas, i-pintar+1);
      if(i == dados.length) { // Caso especial, ultima iteração
        i--; // Voltando para o último elemento do array
        if(consecutivos >= quantos) { // Pontos consecutivos encontrados e não tem marcador nesse range de pontos
          if(cb) cb(); // avisando que a regra foi aplicada

          for(let j = i; i-j < pintar; j--) { // Aplicando a marcação nos pontos até aqui
            if(dados[j].y == mediana) // Ignore pontos que estão na mediana
              continue
            dados[j].marker = {
              enabled: true,
              symbol: 'url(/theme/img/square_red.png)',
              height: 10,
              width: 10
            };
            dados[j].name = `${consecutivos} Pontos Acima da Mediana`;
              dados[j].color = '#FF0000';
          }
        }
        return; // Ultima iteração finalizada
      }
  
      if(dados[i].y == mediana) { // Ignore os que são iguais a mediana
        pintar++; // mas caso a regra seja obedecida pinte todos inclusive os iguais a mediana
        continue
      }

      if(consecutivos >= quantos && dados[i].y <= mediana) { // Pontos consecutivos encontrados
        if(cb) cb(); // avisando que a regra foi aplicada
        for(let j = i-1; (i-1)-j < pintar; j--) { // Aplicando a marcação nos pontos até aqui
          if(dados[j].y == mediana) // Ignore pontos que estão na mediana
            continue
          dados[j].marker = {
            enabled: true,
            symbol: 'url(/theme/img/square_red.png)',
            height: 10,
            width: 10
          };
          dados[j].name = `${consecutivos} Pontos Acima da Mediana`;
            dados[j].color = '#FF0000';
        }
      }
  
      if(dados[i].y > mediana && !dados[i].marker) {
        pintar++;
        consecutivos++;
      }
      else {
        pintar = consecutivos = 0;
      }
    }
  }
  
function temMarcador(dados, inicio, fim) {
  if(fim >= dados.length)
    fim = dados.length-1;

  for(let i = inicio; i <= fim; i++)
    if(dados[i].marker)
      return true;
  return false;
}

function getMedianaAnterior(dados, medianas, fim: number)  {
  if(fim >= dados.length)
    fim = dados.length-1;
  if(fim > 0)
    fim--

  for(let i = fim; i >= 0; i--)
    for(let j = 0; j < medianas.length; j++)
      if(medianas[j].data[0].x === dados[i].x)
        return medianas[j].data[0].y
  
  return medianas[0].data[0].y
}

  /*
    Esta função aplica a seguinte regra de run chart:
    Six or more consecutive points all bellow the centre line (CL).
    Values that fall on the CL do not add to nor break a shift.
    Skip values that fall on the median and continue counting.
  */
 export function marcarPontosConsecutivosAbaixoDaMediana(dados, medianas, quantos, cb?: Function) {
    if(dados.length <= 1)
      return;
  
    let consecutivos = 0;
    let pintar = 0;
    for(let i = 0; i <= dados.length; i++) { // Percorre até um elemento depois do arrays
      const mediana = getMedianaAnterior(dados, medianas, i-pintar+1);
      if(i == dados.length) { // Caso especial, ultima iteração
        i--; // Voltando para o último elemento do array
        if(consecutivos >= quantos) { // Pontos consecutivos encontrados
          if(cb) cb(); // avisando que a regra foi aplicada
          for(let j = i; i-j < pintar; j--) { // Aplicando a marcação nos pontos até aqui
            if(dados[j].y == mediana) // Ignore pontos que estão na mediana
              continue
            dados[j].marker = {
              enabled: true,
              symbol: 'url(/theme/img/square_red.png)',
              height: 10,
              width: 10
            };
            dados[j].name = `${consecutivos} Pontos Abaixo da Mediana`;
              dados[j].color = '#39ed39';
          }
        }
        return; // Ultima iteração finalizada
      }

      if(dados[i].y == mediana) { // Ignore os que são iguais a mediana
        pintar++; // mas caso a regra seja obedecida pinte todos inclusive os iguais a mediana
        continue
      }
  
      if(consecutivos >= quantos && (dados[i].y >= mediana || dados[i].marker)) { // Pontos consecutivos encontrados
        if(cb) cb(); // avisando que a regra foi aplicada
        for(let j = i-1; (i-1)-j < pintar; j--) { // Aplicando a marcação nos pontos até aqui
          if(dados[j].y == mediana) // Ignore pontos que estão na mediana
            continue
          dados[j].marker = {
            enabled: true,
            symbol:'url(/theme/img/square_red.png)',
            height: 10,
            width: 10
          };
          dados[j].name = `${consecutivos} Pontos Abaixo da Mediana`;
            dados[j].color = '#39ed39';
        }
      }
  
      if(dados[i].y < mediana && !dados[i].marker) {
        pintar++;
        consecutivos++;
      }
      else {
        pintar = consecutivos = 0;
      }
    }
  }
  
  /*
    Esta função aplica a seguinte regra de run chart:
    Five or more consecutive points "all going down".
    If the value of two or more successive points is the same (repeats), ignore the like points when counting.
  */
 export function marcarQuedasConsecutivas(dados, quantas, cb?: Function) {
    if(dados.length <= 1)
      return;
  
    let consecutivas = 1;
    let pintar = 1;
    for(let i = 0; i < dados.length; i++) {
  
      if(i == dados.length-1) { // Caso especial, ultima iteração
        if(consecutivas >= quantas) { // Pontos consecutivos encontrados
          if(cb) cb(); // avisando que a regra foi aplicada
          for(let j = i; i-j < pintar; j--) { // Aplicando a marcação nos pontos até aqui
            dados[j].marker = {
              enabled: true,
              symbol: 'url(/theme/img/triangle_red.png)',
              height: 15,
              width: 15
            };
            dados[j].name = `${consecutivas-1} Quedas Consecutivas`;
            dados[j].color = '#39ed39';
          }
        }
        return; // Ultima iteração finalizada
      }
  
      if(dados[i].y == dados[i+1].y) { // Ignore repetições
        pintar++; // mas caso a regra seja obedecida pinte todos inclusive os repetidos
        continue
      }
  
      if(consecutivas >= quantas && dados[i].y <= dados[i+1].y) { // Pontos consecutivos encontrados
        if(cb) cb(); // avisando que a regra foi aplicada
        for(let j = i; i-j < pintar; j--) { // Aplicando a marcação nos pontos até aqui
          dados[j].marker = {
            enabled: true,
            symbol: 'url(/theme/img/triangle_red.png)',
            height: 15,
            width: 15
          };
          dados[j].name = `${consecutivas-1} Quedas Consecutivas`;
            dados[j].color = '#39ed39';
        }
      }
  
      if(dados[i].y > dados[i+1].y) {
        pintar++;
        consecutivas++;
      }
      else
        pintar = consecutivas = 1;
  
    }
  }
  
  /*
    Esta função aplica a seguinte regra de run chart:
    Five or more consecutive points "all going up".
    If the value of two or more successive points is the same (repeats), ignore the like points when counting.
  */
 export function marcarSubidasConsecutivas(dados, quantas, cb?: Function) {
    if(dados.length <= 1)
      return;
  
    let consecutivas = 1;
    let pintar = 1;
    for(let i = 0; i < dados.length; i++) { // percorrendo gráfico
  
      if(i == dados.length-1) { // Caso especial, ultima iteração
        if(consecutivas >= quantas) { // Pontos consecutivos encontrados
          if(cb) cb(); // avisando que a regra foi aplicada
          for(let j = i; i-j < pintar; j--) { // Aplicando a marcação nos pontos até aqui
            dados[j].marker = {
              enabled: true,
              symbol: 'url(/theme/img/triangle_red.png)',
              height: 15,
              width: 15
            };
            
            dados[j].name = `${consecutivas-1} Subidas Consecutivas`;
            dados[j].color = '#FF0000';
          }
        }
        return; // Ultima iteração finalizada
      }
  
      if(dados[i].y == dados[i+1].y) { // Ignore repetições
        pintar++; // mas caso a regra seja obedecida pinte todos inclusive os repetidos
        continue
      }
  
      if(consecutivas >= quantas && dados[i].y >= dados[i+1].y) { // Pontos consecutivos encontrados
        if(cb) cb(); // avisando que a regra foi aplicada
        for(let j = i; i-j < pintar; j--) { // Aplicando a marcação nos pontos até aqui
          dados[j].marker = {
            enabled: true,
            symbol: 'url(/theme/img/triangle_red.png)',
            height: 15,
            width: 15
          };
          dados[j].name = `${consecutivas-1} Subidas Consecutivas`;
            dados[j].color = '#FF0000';
        }
      }
  
      if(dados[i].y < dados[i+1].y) {
        pintar++;
        consecutivas++;
      }
      else
        pintar = consecutivas = 1;
  
    }
  }
  
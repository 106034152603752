import * as React from 'react';
import PageHeader from '../layout/PageHeader';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { obterDadosChecklist, deletarChecklistById } from '../api/api';
import Loading from '../api/Loading.jsx';
import { DadosColetaChecklist } from '../modelos';
import { DateTime } from 'luxon';
import getFetchErroComponent from '../utils/erroCompotent';

interface state {
  carregando: boolean;
  fetchErro: boolean;
  nomeHospital: String;
  coletasChecklist: DadosColetaChecklist[];
}

export default class ListagemChecklist extends React.Component<any, state> {
  id = Number(this.props.location.pathname.split('/').slice(-1)[0]);
  constructor(props) {
    super(props);
    this.state = {
      carregando: true,
      fetchErro: false,
      nomeHospital: "",
      coletasChecklist: []
    }
  }

  componentDidMount() {
    this.getChecklistByHospitalId();
  }

  getChecklistByHospitalId = async () => {
    this.setState({ carregando: true });
    try {
      const Checklist = await obterDadosChecklist(this.id);
      this.setState({
        coletasChecklist: Checklist,
        nomeHospital: Checklist[0].hospital.nome
      });
    } catch (e) {
      //Swal('Falha', e.response.data.message, 'error');
      this.setState({ fetchErro: true });
    } finally {
      this.setState({ carregando: false });
    }
  }
  deletarChecklist = async id => {
    const r = await Swal({
      title: 'Tem certeza?',
      text: "Esta operação vai remover completamente a coleta!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, delete!'
    });

    if (!r.value) { return; }

    Swal({
      title: 'Deletando...'
    });
    Swal.showLoading();

    try {
      await deletarChecklistById(id);
      await Swal('Concluído', 'Coleta deletada com sucesso', 'success');
      const arrayCopy = this.state.coletasChecklist.filter((checklist) => checklist.id !== id);
      this.setState({ coletasChecklist: arrayCopy });

    } catch (err) {
      Swal('Falha', err.response.data.message, 'error');
    }
  }

  render() {
    const linhas = this.state.coletasChecklist.map((checklist) => (
      <tr key={checklist.id}>
        <td>{checklist.numeroProntuario}</td>
        <td>{checklist.hospital.usuario.nome}</td>
        <td>{DateTime.fromISO(checklist.dataColeta.toString()).toFormat('dd/MM/yyyy')}</td>
        <td>{DateTime.fromISO(checklist.dataParto).toFormat('dd/MM/yyyy')}</td>
        <td>
          Pezinho:{checklist.testePezinho}<br />
          Linguinha:{checklist.testeLinguinha}<br />
          Orelhinha:{checklist.testeOrelhinha}<br />
          Olhinho:{checklist.testeOlhinho}<br />
          Coracaozinho:{checklist.testeCoracaozinho}<br /></td>
        <td>{checklist.indicacaoCesarea}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <div className="btn-group">
            <Link to={"/checklist/editar/" + checklist.id}>
              <button title="Editar coleta" className="btn btn-success btn-sm">
                <i className="far fa-edit"></i>
              </button>
            </Link>
            <button
              className="btn btn-danger btn-sm ml-auto"
              onClick={() => this.deletarChecklist(checklist.id)}
            ><i className="fas fa-trash" /></button>
          </div>
        </td>
      </tr>
    ));

    const tabela = (<div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Prontuário</th>
            <th>Usuário</th>
            <th>Data Coleta</th>
            <th>Data Parto</th>
            <th>Testes</th>
            <th>Cesárea</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>{linhas}</tbody>
      </table>
    </div>);

    const conteudo = (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h3 className="h4 lead">Coletas de adesão ao checklist para parto seguro: {this.state.nomeHospital}</h3>
              <Link to={`/questionarios/adesao-checklist/${this.id}`}>
                <button className="btn btn-sm btn-success">
                  Novo <i className="fas fa-plus" />
                </button>
              </Link>
            </div>
            <div className="card-body">
              {
                this.state.carregando
                  ? <Loading />
                  : (
                    this.state.fetchErro
                      ? getFetchErroComponent(this.getChecklistByHospitalId)
                      : tabela
                  )
              }
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="content-inner">
        <PageHeader titulo="Coletas" />
        <div className="container-fluid mt-4">
          {conteudo}
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react';
import PageHeader from '../layout/PageHeader';

export default class Info extends Component {

	state = {
		tela: 'principal'
	}

  render() {
    return (
      <div className="content-inner">
        <PageHeader titulo="Como medir" />

		<div className="container-fluid mt-4">
			<div className="row">
				<div className="col">
					<div className="card">
						<div className="card-header">
							<ul className="nav nav-tabs card-header-tabs">
							<li className="nav-item">
								<a
									className={"nav-link"+(this.state.tela === 'principal' ? ' active' : '')}
									onClick={() => this.setState({tela: 'principal'})}
									href="#"
								>Principal</a>
							</li>
							<li className="nav-item">
								<a
									className={"nav-link"+(this.state.tela === 'boasPraticas' ? ' active' : '')}
									onClick={() => this.setState({tela: 'boasPraticas'})}
									href="#"
								>Boas práticas</a>
							</li>
							<li className="nav-item">
								<a
									className={"nav-link"+(this.state.tela === 'checklist' ? ' active' : '')}
									onClick={() => this.setState({tela: 'checklist'})}
									href="#"
								>Adesão ao checklist para parto seguro</a>
							</li>
							<li className="nav-item">
								<a
									className={"nav-link"+(this.state.tela === 'ea' ? ' active' : '')}
									onClick={() => this.setState({tela: 'ea'})}
									href="#"
								>Eventos adversos</a>
							</li>
							</ul>
						</div>

						<div
							className="card-body"
							style={{display: this.state.tela === 'principal' ? 'block' : 'none'}}
						>
							<h5 className="card-title">Orientações para preparação da coleta de dados</h5>
							<p className="card-text">
								<ol>
									<li>
										<p className="text-justify">
										Defina o tamanho da amostra que utilizará e a periodicidade do monitoramento. A coleta desenvolvida no estudo que criou este sistema (<a className="muted-link" href="https://repositorio.ufrn.br/jspui/handle/123456789/29054" target="_blank">Sousa, 2020</a>) foi realizada de forma retrospectiva com amostras aleatórias sistemáticas de 30 prontuários por quinzena, durante 1 ano (24 quinzenas). A amostragem sistemática é um tipo de método de amostragem probabilística no qual os participantes são selecionados de acordo com um ponto de partida aleatório (sorteio) e um intervalo periódico fixo. Esse intervalo deve ser calculado dividindo o tamanho da população (N) pelo tamanho de amostra (n) desejado. Quando um dos prontuários selecionados não for encontrado ou atendeu ao critério de exclusão, novos prontuários devem ser selecionados utilizando o mesmo processo de amostragem. Por exemplo, se no mês de janeiro de 2019 foram atendidos um total de 300 partos (N=300) e foi decidido o tamanho da amostra de monitoramento de 30 casos (n=30), sorteia-se um <a className="muted-link" href="https://repositorio.ufrn.br/jspui/handle/123456789/29054" target="_blank">número</a> aleatório entre 1 e 300. Pode-se utilizar a função =ALEATÓRIOENTRE do Excel e, então, será sorteado o primeiro indivíduo da amostra. Segue-se o sorteio dos demais participantes, obedecendo ao intervalo fixo de 10 indivíduos até que se chegue ao tamanho mínimo da amostra desejada (30 casos). Se preferir uma amostragem aleatória simples, basta realizar o sorteio do número de casos definidos para compor a amostra, ou seja, para este exemplo, sortear 30 casos de uma vez, sem utilizar o intervalo fixo.
										</p>
									</li>
									<li>
										<p className="text-justify">
											Selecione os óbitos maternos ou neonatais do período para fazer parte da amostra a partir da data de alta. Consideramos importante incluir estes casos porque constituem eventos-sentinela que precisam ser investigados e analisados. Além disso, a Agência Nacional de Vigilância Sanitária em seu manual “Serviços de atenção materna e neonatal: segurança e qualidade”, recomenda que os eventos-sentinela, os procedimentos de maior frequência e as situações e procedimentos que apresentam maior risco de complicações devem ser incluídos em um programa para a promoção da qualidade e segurança na atenção materna e neonatal (<a style={{textIndent: '0px'}} target="blank" href='https://www20.anvisa.gov.br/segurancadopaciente/index.php/publicacoes/item/servicos-de-atencao-materna-e-neonatal-seguranca-e-qualidade'>ANVISA, 2014</a>).
										</p>
									</li>
									<li>Complemente os casos da amostra de forma aleatória (amostragem aleatória simples ou sistemática) de acordo com a lista de todos os casos que tiveram alta no período escolhido.</li>
									<li>Selecione os prontuários e, então, utilize os questionários para coleta de dados presentes na plataforma.</li>
								</ol>
							</p>
							<h5 className="card-title">Como coletar os dados na plataforma</h5>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Para coletar os dados na plataforma, clique na opção "Meus Hospitais" no menu esquerdo e, em seguida, clique em "Coletar" e escolha o que deseja coletar (“Boas práticas”, “Adesão ao checklist para parto seguro” e “Eventos Adversos”). A página irá abrir o formulário de perguntas para cada módulo e quando este for preenchido, clique em "Enviar" para concluir a coleta. Após preencher cada formulário, uma página da nova coleta será aberta automaticamente.  A página de coleta também permite alternar o módulo de indicadores. Certifique-se de salvar o formulário preenchido antes de iniciar a coleta de um novo módulo. De todo modo, o sistema irá gerar uma mensagem automática alertando-o disso.
								</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								<b>Importante:</b> Preencha com atenção as informações sobre datas (ex.: data do parto, data de nascimento da mãe, data da alta), pois as tabelas e gráficos do relatório, especialmente os gráficos de tendências, são calculados de acordo com o preenchimento destas informações. Logo, erros nesse registro podem gerar gráficos e títulos de tabelas equivocados.
							</p>
							<h5 className="card-title">Como analisar os dados</h5>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Os principais gráficos desenvolvidos na Plataforma QualiParto são do tipo run chart, também conhecidos como gráficos de corridas ou gráficos de tendência, nos quais se recomenda um monitoramento mínimo de 10 pontos ou 10 amostras para que as regras sejam identificadas.  Este tipo de gráfico foi escolhido porque tem sido considerado uma ferramenta útil e simples (pouca complexidade matemática) para compreender as variações nos processos de assistência à saúde. O mesmo permite a exibição visual dos dados em ordem cronológica, sendo possível detectar se o desempenho dos indicadores está melhorando ou piorando de acordo com as melhorias implementadas.Além disso, visualizar dados ao longo do tempo, e não em estatísticas resumidas, produz dados mais ricos e conclusões mais precisas para projetos de melhoria. (<a style={{textIndent: '0px'}} target="blank" href='https://qualitysafety.bmj.com/content/20/1/46.info'>Perla; Provost; Murray, 2011</a>). As principais regras identificadas no run charts e mensuradas na Plataforma QualiParto são:
							</p>
							<ul>
								<li><b>Regra 1 (Mudança ou Shift)</b>: Seis ou mais pontos consecutivos, todos acima ou todos abaixo da mediana. Valores que caem na mediana não adicionam nem quebram uma mudança. Pulam-se todos os valores que caem na mediana e continua-se a contagem.</li>
								<li><b>Regra 2  (Tendência ou Trend)</b>: Cinco ou mais pontos consecutivos, todos subindo ou descendo. Se o valor de dois ou mais pontos consecutivos for o mesmo, conta-se apenas o primeiro ponto e ignora-se os valores repetidos; valores semelhantes não fazem ou quebram uma tendência.</li>
							</ul>
							<p className="card-text">
								Fique atento também às outras duas regras do gráfico de run charts descrita no estudo de <a style={{textIndent: '0px'}} target="blank" href='https://qualitysafety.bmj.com/content/20/1/46.info'>Perla; Provost; Murray, 2011</a>, são elas:
							</p>
							<ul>
								<li><b>Regra 3 (Corridas ou runs)</b>: Um padrão não aleatório é sinalizado por "muito poucas" ou "muitas corridas" ou cruzamentos da linha mediana. Uma "corrida" ou “run” é uma série de pontos seguidos em um lado da mediana. Uma maneira fácil de determinar o número de "runs" é contar o número de vezes que a linha que liga os pontos de dados cruza a mediana e adiciona uma.</li>
								<li><b>Regra 4 (Ponto astronômico)</b>: Utilizado na detecção de números extraordinariamente grandes ou pequenos (um <i>outlier</i>). Um ponto de dados astronômicos é claramente óbvio, diferente do restante dos pontos. No entanto, um valor máximo normal de um conjunto de dados não é um ponto astronômico.</li>
							</ul>
							<p>
								<b>OBS.:</b> Uma mudança, tendência ou ponto astronômico são todos sinais de padrões não aleatórios e devem ser investigados para obter uma melhor compreensão do processo em análise.
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								A mediana é usada como linha central do gráfico run chart porque, além de não ser influenciada por valores extremos, fornece o ponto em que metade das observações deve estar acima e abaixo da linha central. Porém, se 50% ou mais dos dados em um gráfico run chart representam os valores extremos absolutos (por exemplo, 0 ou 100% em uma escala percentual), os critérios para detectar um sinal estatístico não aleatório usando a mediana não podem ser aplicados. Nestes casos, pode-se usar a média como linha central. A plataforma QualiParto está configurada para identificar estas situações e projetar o gráfico de acordo com essas especificações.
							</p>
							<p
							className="card-text text-justify"
							style={{ textIndent: '30px' }}
							>
								Além da substituição pela média quando a linha da mediana é 0 ou 100%, outra inovação que plataforma permite à análise dos dados com gráfico run chart é o cálculo da mediana a partir de uma linha de base de 6 pontos e, se houver alguma mudança ou tendência, recalcula-se uma nova mediana ou linha central com base nos pontos que caracterizaram a mudança (regra 1) ou tendência (regra 2) deste tipo de gráfico. 
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Com isso, as regras do run chart pretendem sinalizar se a mudança identificada está associada a uma melhoria sustentável no serviço onde a intervenção foi testada, considerando que o objetivo de qualquer monitoramento vai além de coletar, mas inclui identificar situações potenciais para intervenções de melhoria da qualidade.
							</p>
							<h5 className="card-title">O que fazer após a análise</h5>
								<ul>
									<li>Imprima ou faça o download dos gráficos ou do relatório geral.</li>
									<li>Comunique de forma eficaz às lideranças do hospital e a todos os interessados</li>
									<li>Inicie ciclos de melhoria da qualidade para os indicadores mais problemáticos</li>
								</ul>
						</div>

						<div
							className="card-body"
							style={{display: this.state.tela === 'ea' ? 'block' : 'none'}}
						>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Eventos adversos (EA) são incidentes que resultam em dano ao paciente, considerados como resultados indesejáveis dos cuidados de saúde que expressam problemas de qualidade relacionados com a segurança do paciente, podendo levar à morte, incapacidade ou insatisfação com o serviço.
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Os eventos adversos obstétricos e neonatais da plataforma devem ser coletados segundo registro explícito no prontuário da sua ocorrência. Estas informações podem ser encontradas nas folhas de Evolução Interdisciplinar, onde são registrados os procedimentos e intervenções realizadas, bem como nos registros de caracterização do parto e nascimento. A coleta dos Eventos Adversos na Plataforma QualiParto pode ser realizada seguindo o caminho: Meus Hospitais {">>"} Coletar {">>"} Eventos Adversos.
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Os EA presentes na plataforma são indicadores de qualidade padronizados por <a style={{textIndent: '0px'}} target="blank" href='https://www.sciencedirect.com/science/article/abs/pii/S155372500632065X'>Mann et al. (2006)</a> em um grande estudo multicêntrico, controlado randomizado, envolvendo 15 hospitais norte-americanos e mais de 28.000 pacientes. Os indicadores e seus respectivos escores de gravidade desenvolvidos neste estudo e monitorados na plataforma são:
							</p>
							<ul>
								<li>Morte Materna (750);</li>
								<li>Morte intraparto ou neonatal de recém-nascido com peso {">"} 2.500g (400);</li>
								<li>Ruptura uterina (100);</li>
								<li>Admissão materna em UTI (65);</li>
								<li>Tocotraumatismo (60);</li>
								<li>Retorno da mãe à sala de cirurgia/parto (40);</li>
								<li>Admissão em UTI neonatal de recém-nascido com peso {">"} 2.500g por um tempo {">"} 24 horas (35);</li>
								<li>APGAR &#x3C; 7 no 5º minuto (25);</li>
								<li>Transfusão de sangue (20) e;</li>
								<li>Laceração perineal de 3º ou 4º grau (5)</li>
							</ul>
							<p className="card-text text-justify">
								Como muitos eventos adversos obstétricos são pouco frequentes, estes indicadores simples foram agregados em indicadores compostos, originando três outras medidas de qualidade:
							</p>
							<ul>
								<li><b>Índice de Resultados Adversos–IRA</b> (do inglês <i>Adverse Outcome Index-AOI</i>): porcentagem de partos com um ou mais eventos adversos.</li>
								<li><b>Escore Ponderado de Resultado Adverso–EPRA</b> (do inglês <i>Weighted Adverse Outcome Score-WAOS</i>): soma dos pontos atribuídos aos casos com resultados adversos divididos pelo total de partos analisados.</li>
								<li><b>Índice de Gravidade-IG</b> (do inglês <i>Severity Index-SI</i>) soma dos escores dos resultados adversos dividido pelo número de partos complicados pelos EA;</li>
							</ul>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								O IRA fornece uma medida de frequência de partos com eventos adversos. O EPRA pondera os EA segundo sua gravidade em relação ao total de partos analisados. O IG mede a gravidade média de cada parto com um evento adverso.
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Estes indicadores simples e compostos de eventos adversos foram coletados no estudo que originou a plataforma (<a className="muted-link" href="https://repositorio.ufrn.br/jspui/handle/123456789/29054" target="_blank">Sousa, 2020</a>), sendo considerados viáveis e confiáveis para sua utilização nos serviços obstétricos brasileiros.
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Os eventos adversos obstétricos e neonatais da plataforma devem ser coletados segundo o registro explícito no prontuário da sua ocorrência. Estas informações podem ser encontradas nas folhas de Evolução Interdisciplinar, onde são registrados os procedimentos e intervenções realizadas, bem como nos registros de caracterização do parto e nascimento.
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								A coleta dos Eventos Adversos na Plataforma QualiParto pode ser realizada seguindo o caminho: Meus Hospitais {">>"} Coletar {">>"} Eventos Adversos.
							</p>
						</div>

						<div
							className="card-body"
							style={{display: this.state.tela === 'boasPraticas' ? 'block' : 'none'}}
						>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Boas práticas obstétricas são práticas baseadas em evidências científicas, com comprovada efetividade, eficiência e segurança que garantem à mulher uma assistência ao parto alinhada às suas preferências e expectativas, sem desconsiderar suas necessidades clínicas.
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								São exemplos de boas práticas a presença do acompanhante durante todo o período de hospitalização, o incentivo à amamentação e ao contato pele a pele na primeira hora de vida, entre outras. As boas práticas que compõem a plataforma foram extraídas do checklist para parto seguro da OMS, adaptado para a realidade brasileira (<a className="muted-link" href="https://www.who.int/publications/i/item/9789241550215" target="_blank">Carvalho et al., 2018</a>) e estão presentes na atual diretriz clínica da OMS sobre cuidados intraparto (<a className="muted-link" href="https://www.who.int/patientsafety/implementation/checklists/childbirth-checklist_implementation-guide/en/" target="_blank">World Health Organization, 2018</a>).
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Selecionou-se apenas as boas práticas de elevada evidência científica e que devem ser oferecidas a todos os pacientes envolvidos (mãe e recém-nascido), independentemente do tipo de parto. As boas práticas incluídas na plataforma foram:
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								<b>Boas práticas para a mãe:</b>
								<li>
								Utilização do Partograma para monitoramento do progresso do parto; 
								</li>
								<li>
								Presença de Acompanhante durante o parto; 
								</li>
								<li>
								Administração da Ocitocina no 1º minuto pós parto para prevenir hemorragia.
								</li>
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								<b>Boas práticas para o recém-nascido:</b>
								<li>Administração da Vitamina K; </li>
								<li>Início da amamentação na primeira hora após o parto; </li>
								<li>Contato pele a pele imediatamente após o parto; </li>
								<li>Clampeamento oportuno do cordão umbilical; </li>
								<li>Identificação do recém-nascido com pulseira. </li>
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
							A coleta de informações sobre as boas práticas também deve respeitar seu registro explícito no prontuário, sendo necessário realizar a busca dessas informações nas folhas de Evolução Interdisciplinar, no checklist para parto seguro (quando existir), além de outros registros no prontuário.
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
							Para coletar usando o módulo de Boas Práticas, o seguinte caminho deve ser percorrido: Meus Hospitais {">>"} Coletar {">>"} Boas Práticas. 
							</p>
						</div>

						<div
							className="card-body"
							style={{display: this.state.tela === 'checklist' ? 'block' : 'none'}}
						>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								O checklist para parto seguro da OMS (Safe Childbirth Checklist da OMS -SCC) foi adaptado transculturalmente para a realidade brasileira em um dos estudos(<a style={{textIndent: '0px'}} target="blank" href='http://www.scielo.br/scielo.php?pid=S1519-38292018000200401&script=sci_arttext&tlng=pt'>Carvalho et al., 2018</a>) do grupo de pesquisa que desenvolveu esta plataforma (QualiSaúde/DSC/UFRN). A versão aprovada incluiu os 29 itens do checklist original e 20 novos itens. Justificativas de cesárea e episiotomia, clampeamento oportuno do cordão umbilical e cuidados ao recém-nascido como administração de vitamina K, vacinas e exames diagnósticos (por exemplo, teste do pezinho) foram alguns dos itens brasileiros acrescentados ao SCC. 
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
							A coleta da adesão ao checklist para parto seguro deve ser realizada observando-se o preenchimento ou não de todos os itens da lista, independente da sua resposta. O questionário da plataforma contém as mesmas perguntas e respostas presentes no checklist, além da opção de resposta “Não preenchido”, devendo esta última ser marcada quando o item estiver em branco.
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Para acessar ao formulário deste módulo, o seguinte caminho deve ser percorrido: Meus Hospitais {">>"} Coletar {">>"} Adesão ao checklist para parto seguro. 
							</p>
							<p
								className="card-text text-justify"
								style={{textIndent: '30px'}}
							>
								Para visualizar e baixar o checklist, basta clicar no link abaixo.  
							</p>

							<div id="accordion">
								<div className="card">
									<div className="card-header" id="headPDF">
									<h5 className="mb-0">
										<button className="btn btn-outline-info" data-toggle="collapse" data-target="#collapsePDF" aria-controls="collapsePDF">
											Checklist adotado
										</button>
									</h5>
									</div>

									<div id="collapsePDF" className="collapse" aria-labelledby="headPDF" data-parent="#accordion">
										<div className="card-body">
											<div className="embed-responsive embed-responsive-16by9">
												<iframe className="embed-responsive-item" src="/theme/Checklist.pdf"/>
											</div>
										</div>
										<a className="d-block text-right" href="/theme/Checklist.pdf" target="blank">Acessar diretamente</a>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import PageHeader from '../layout/PageHeader';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { obterDadosUsuarios, deletarUsuarioById } from '../api/api';
import Loading from '../api/Loading.jsx';
import { Usuario } from '../modelos/usuario';
import { DateTime } from 'luxon';
import getFetchErroComponent from '../utils/erroCompotent';

interface state {
  carregando: boolean;
  fetchErro: boolean;
  usuarios: Usuario[];
}

export default class ListagemUsuarios extends React.Component<any, state> {
  id = Number(this.props.location.pathname.split('/').slice(-1)[0]);
  constructor(props) {
    super(props);
    this.state = {
      carregando: true,
      fetchErro: false,
      usuarios: []
    }
  }

  componentDidMount() {
    this.getUsuarios();
  }

  getUsuarios = async () => {
    this.setState({ carregando: true });
    try {
      const Usuarios = await obterDadosUsuarios();
      this.setState({
        usuarios: Usuarios
      });
    } catch (e) {
      //Swal('Falha', e.response.data.message, 'error');
      this.setState({ fetchErro: true });
    } finally {
      this.setState({ carregando: false });
    }
  }
  deletarUsuarios = async id => {
    const r = await Swal({
      title: 'Tem certeza?',
      text: "Esta operação vai remover completamente a coleta!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, delete!'
    });

    if (!r.value) { return; }

    Swal({
      title: 'Deletando...'
    });
    Swal.showLoading();

    try {
      await deletarUsuarioById(id);
      await Swal('Concluído', 'Coleta deletada com sucesso', 'success');
      const arrayCopy = this.state.usuarios.filter((usuario) => usuario.id !== id);
      this.setState({ usuarios: arrayCopy });

    } catch (err) {
      Swal('Falha', err.response.data.message, 'error');
    }
  }

  render() {
    const linhas = this.state.usuarios.map((usuario) => (
      <tr key={usuario.id}>
        <td>{usuario.nome}</td>
        <td>{usuario.email}</td>
        <td>
            {(() => {
              if (usuario.tipo == 'COMUM') {
                return ( "Coletador" )
              } else if (usuario.tipo == 'ADMIN') {
                return ( "Administrador Geral" )
              } else if (usuario.tipo == 'ADMINREDE') {
                return ( "Administrador de Rede de Hospitais" )
              } else {
                return ( "Administrador de Hospitais" )
              }
            })()}
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <div className="btn-group">
            <Link to={"/usuario/editar/" + usuario.id}>
              <button title="Editar Usuário" className="btn btn-success btn-sm">
                <i className="far fa-edit"></i>
              </button>
            </Link>
            <button
              className="btn btn-danger btn-sm ml-auto"
              onClick={() => this.deletarUsuarios(usuario.id)}
            ><i className="fas fa-trash" /></button>
          </div>
        </td>
      </tr>
    ));

    const tabela = (<div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Tipo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>{linhas}</tbody>
      </table>
    </div>);

    const conteudo = (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h3 className="h4 lead">Usuarios </h3>
            </div>
            <div className="card-body">
              {
                  this.state.carregando
                  ? <Loading />
                  : (
                    this.state.fetchErro
                      ? getFetchErroComponent(this.getUsuarios)
                      : tabela
                  )
              }
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="content-inner">
        <PageHeader titulo="Gerenciar Usuários" />
        <div className="container-fluid mt-4">
          {conteudo}
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import { DadosColetaEventosAdversos}  from '../modelos';
import { calcularMediaTempoMaeInternada } from '../utils/eventos-adversos';
import '../utils/estilos.css';
import { calcularIdadeMae } from '../utils';

interface Props {
  dados: DadosColetaEventosAdversos[];
}

function processarDadosTipoParto(coletas: DadosColetaEventosAdversos[]) {
	const tiposParto = coletas.map((c) => c.tipoParto);

	const normal = tiposParto.filter((tipo) => tipo === 'Normal').length;
	const cesarea = tiposParto.filter((tipo) => tipo === 'Cesárea').length;
	const normalForceps = tiposParto.filter((tipo) => tipo === 'Normal/fórceps').length;
	
	return {
		normal,
		cesarea,
		normalForceps,
		total: normal+cesarea+normalForceps
	};
}

function calcularPorcentagemRNBaixoPeso(coletas: DadosColetaEventosAdversos[]) {
	const quantidade = coletas.filter(coleta => coleta.rnPeso < 2500).length;
	const total = coletas.length;

	return {
			p: ((quantidade/total)*100).toFixed(1),
			n: quantidade
		};
}

function calcularIdadeMediaDaMae(coletas: DadosColetaEventosAdversos[]) {
	let contabilizados = 0;
	const soma = coletas.reduce((acumulador, atual) => {
		const idade = calcularIdadeMae(atual);
		if(idade > 0 && idade < 200) {
			contabilizados++;
			return acumulador += idade;
		}
		return acumulador;
	}, 0);
	if(contabilizados == 0)
		return 0;
	return Math.round(soma / contabilizados);
}

function calcularIdadeGestacionalCategorizada37Semanas(coletas: DadosColetaEventosAdversos[]) {
	let maior = 0;
	let menorIgual = 0;
	coletas.forEach(atual => {
		const idade = atual.maeIdadeGestacional;
		if(idade > 37)
			maior++;
		else 
			menorIgual++;
	});

	return {
		'maior': ((maior / coletas.length)*100).toFixed(1),
		'menorIgual': ((menorIgual / coletas.length)*100).toFixed(1)
	};
}

function calcularDesvioPadraoIdadeMae(coletas: DadosColetaEventosAdversos[], media: number) {
	const mediasElevadas = coletas.map(coleta => {
		const idade = calcularIdadeMae(coleta);
		if(idade > 0 && idade < 200)
			return Math.pow((idade - media), 2);
	})
	.filter(x => (x !== undefined));

	const n = mediasElevadas.length;
	let variancia = 0;
	if(n > 1)
		variancia = mediasElevadas.reduce((acumulardor, atual) => (acumulardor+atual), 0)
							/ (n-1);

	const desvio = Math.sqrt(variancia).toFixed(1);

	return { desvio, n };
}

export default function TabelaSociodemografica(props: Props) {
	const dadosPartos = processarDadosTipoParto(props.dados);
	const dadosDiasInternada = calcularMediaTempoMaeInternada(props.dados);
	const mediaIdadeMae = calcularIdadeMediaDaMae(props.dados);
	const desvioIdadeMae = calcularDesvioPadraoIdadeMae(props.dados, mediaIdadeMae);
	const idadeGestacional = calcularIdadeGestacionalCategorizada37Semanas(props.dados);
	const rnBaixoPeso = calcularPorcentagemRNBaixoPeso(props.dados);

return (
	<table style={{fontSize: '11pt', color: 'black'}} className="table table-striped-reverse">
		<thead>
		<tr className="linha-destaque">
			<th scope="col">Variável</th>
			<th scope="col">Resultado<br/> % (n) </th>
			<th scope="col" className="text-center">Total de casos analisados</th>
		</tr>
		</thead>
		<tbody>
		<tr>
			<td>Tipo de parto</td>
			<td>
				<span>
					Normal: {((dadosPartos.normal/dadosPartos.total)*100).toFixed(1)}%
					({dadosPartos.normal})
				</span><br />
				<span>
					Cesária: {((dadosPartos.cesarea/dadosPartos.total)*100).toFixed(1)}%
					({dadosPartos.cesarea})
				</span><br />
				<span>
					Normal/Fórceps: {((dadosPartos.normalForceps/dadosPartos.total)*100).toFixed(1)}%
					({dadosPartos.normalForceps})
				</span><br />
			</td>
			<td className="text-center">{dadosPartos.total}</td>
		</tr>
		<tr>
			<td>Média de dias de internação da mãe</td>
			<td>{dadosDiasInternada.media}</td>
			<td className="text-center">{dadosDiasInternada.n}</td>
		</tr>
		<tr>
			<td>Idade gestacional no momento do parto</td>
			<td>
				<span>Maior que 37 semanas: {idadeGestacional.maior} %</span><br />
				<span>Menor ou igual a 37 semanas: {idadeGestacional.menorIgual} %</span><br />
			</td>
			<td className="text-center">{props.dados.length}</td>
		</tr>
		<tr>
			<td>Idade média da mãe</td>
			<td>
				<span>Média: {mediaIdadeMae} anos</span><br />
				<span>Desvio padrão: {desvioIdadeMae.desvio}</span><br />
			</td>
			<td className="text-center">{desvioIdadeMae.n}</td>
		</tr>
		<tr className="linha-destaque-final">
			<td>Recém-nascido com baixo peso (&#x3C; 2.500g)</td>
			<td>
				{rnBaixoPeso.p}% ({rnBaixoPeso.n})
			</td>
			<td className="text-center">{props.dados.length}</td>
		</tr>
		</tbody>
   </table>
  );
}

import React, { Component } from 'react';
import { Hospital, DadosColetaEventosAdversos, DadosColetaChecklist, DadosColetaBoasPraticas } from '../modelos';
import { obterDadosEventosAdversos, obterDadosChecklist, obterDadosBoasPraticas } from '../api/api';
import Loading from '../api/Loading';
import getFetchErroComponent from '../utils/erroCompotent';
import RunChart from '../graficos/RunChart';
import { processarSerie, calcularIRA, categorizarEmMeses } from '../utils/calculos_IRA_EPRA_IG';
import GradientCard from '../layout/GradientCard';
import { calcularRunchartChecklist, checklistKeys } from '../utils/calculosChecklist';
import { calcularRunchartBoasPraticas, boasPraticasKeys } from '../utils/calculosBoasPraticas';
import { listenerCount } from 'process';
import Swal from 'sweetalert2';

interface ponto {
	 y: number;
	 x: Date;
}
interface props {
	hospital: Hospital;
	recarregar: boolean;
	listaHospitais: Hospital[];
}
interface state {
	carregando: boolean;
	fetchErro: boolean;
	coletas: DadosColetaEventosAdversos[];
	coletasChecklist: DadosColetaChecklist[];
	coletasBoasPraticas: DadosColetaBoasPraticas[];
	nomeHospital: String;
};

export default class ResumoHospital extends Component<props, state> {
	constructor(props) {
		super(props);
		this.state = {
			carregando: true,
			fetchErro: false,
			coletas: [],
			coletasChecklist: [],
			coletasBoasPraticas: [],
			nomeHospital: ""
		};
	}

	componentDidMount() {
		if(this.props.listaHospitais.length > 0) {
			this.setState({nomeHospital: this.props.hospital.nome})
			this.getColetas();
		} else {
			this.setState({ carregando: false });
		}
	}

	getColetas = async () => {
		const { id } = this.props.hospital;
		this.setState({ carregando: true });
		try {
			const eas = await obterDadosEventosAdversos(id);
			const checklist = await obterDadosChecklist(id);
			const boaspraticas = await obterDadosBoasPraticas(id);

			this.setState({ 
				coletas: eas,
				coletasChecklist: checklist,
				coletasBoasPraticas: boaspraticas,
			 });
		} catch(e) {
			//Swal('Falha', e.response.data.message, 'error');
			this.setState({ fetchErro: true });
		} finally {
			this.setState({ carregando: false });
		}
	};

	setColetas = async (valor) => {
		const id = this.props.listaHospitais[valor].id;
		const nome = this.props.listaHospitais[valor].nome;
		this.setState({ carregando: true, nomeHospital: nome });
		try {
			const eas = await obterDadosEventosAdversos(id);
			const checklist = await obterDadosChecklist(id);
			const boaspraticas = await obterDadosBoasPraticas(id);
			this.setState({ 
				coletas: eas,
				coletasChecklist: checklist,
				coletasBoasPraticas: boaspraticas,
			 });
		} catch(e) {
			//Swal('Falha', e.response.data.message, 'error');
			this.setState({ fetchErro: true });
		} finally {
			this.setState({ carregando: false });
		}
	};

  render() {
	let conteudo = null;
	if(this.state.carregando)
		conteudo = <Loading />
	else if(this.state.fetchErro)
		conteudo = getFetchErroComponent(this.getColetas);
	else if(this.state.coletas.length > 0 || this.state.coletasChecklist.length > 0 || this.state.coletasBoasPraticas.length > 0) {
		let serieIRA = [];
		if(this.state.coletas.length > 0)
			serieIRA = processarSerie(categorizarEmMeses(this.state.coletas), calcularIRA);
		
		let serieIRAChecklist = [];
		if(this.state.coletasChecklist.length > 0)
			serieIRAChecklist = processarSerie(categorizarEmMeses(this.state.coletasChecklist, 'dataParto'), checklist => calcularRunchartChecklist(checklist, checklistKeys));

		let serieIRABoasPraticas = [];
		if(this.state.coletasBoasPraticas.length > 0)
			serieIRABoasPraticas = processarSerie(categorizarEmMeses(this.state.coletasBoasPraticas, 'dataParto'), boasPraticas => calcularRunchartBoasPraticas(boasPraticas, boasPraticasKeys));
		conteudo = (<>
		 <div className="row p-3 mb-4">
			<div className="col-md-4">
            <GradientCard
				icon="list"
				estatistica={this.state.coletasBoasPraticas.length}
				descricao="Questionários de boas práticas"
			/>
          </div>
          <div className="col-md-4">
            <GradientCard
				icon="list"
				estatistica={this.state.coletasChecklist.length}
				descricao="Questionários de adesão ao checklist"
			/>
          </div>
		  <div className="col-md-4">
            <GradientCard
				icon="list"
				estatistica={this.state.coletas.length}
				descricao="Questionários de eventos adversos"
			/>
          </div>
        </div>
		<h4
			style={{
				borderBottom: '1px solid lightGray',
				textAlign: 'right'
			}}
		>
			Boas Práticas
		</h4>
		<div className="row">
			<div className="col-12">
			{
				serieIRABoasPraticas.length > 0
					? (<RunChart
					dados={serieIRABoasPraticas}
					exportavel={true}
					mostrarLegendas={true}
					titulo='Indice de Boas Práticas'
					nome="resumoboaspraticas"
					yLabel="Percentual de Boas Práticas"
					corLinha="#07a7e3"
					percentual={true}
				/>)
				: <h4>Nenhuma coleta de boas práticas realizada</h4>
			}
			</div>
		</div>
		<br />
		<h4
			style={{
				borderBottom: '1px solid lightGray',
				textAlign: 'right'
			}}
		>
			Adesão ao Checklist
		</h4>
		<div className="row">
			<div className="col-12">
				{
					serieIRAChecklist.length > 0
					? (<RunChart
						dados={serieIRAChecklist}
						exportavel={true}
						mostrarLegendas={true}
						titulo='Gráfico de tendência da adesão ao checklist'
						nome='resumochecklist'
						yLabel="Percentual"
						corLinha="#07a7e3"
						percentual={true}
					/>)
					: <h4>Nenhuma coleta de adesão ao checklist realizada</h4>
				}
			</div>
		</div>
		<h4
			style={{
				borderBottom: '1px solid lightGray',
				textAlign: 'right'
			}}
		>
			Eventos Adversos
		</h4>
		<div className="row">
			<div className="col-12">
			{
				serieIRA.length > 0
					? (<RunChart
					dados={serieIRA}
					exportavel={true}
					mostrarLegendas={true}
					titulo='Indice de Resultados Adversos - IRA (Estimativa mensal de partos nos quais ocorreram ao menos um evento adverso)'
					nome='resumoeventosadversos'
					yLabel="Percentual de IRA"
					corLinha="#07a7e3"
					percentual={true}
				/>)
				: <h4>Nenhuma coleta de eventos adversos realizada</h4>
			}
			</div>
		</div>
	  </>);
	} else
		conteudo = <h3>Nenhuma coleta realizada ainda</h3>;

    return (
		<div className="container-fluid mt-4">
			<div className="row">
				<div className="col">
					<div className="card">
						<h3 className="small mt-2 ml-2">Selecione um hospital:</h3>
						<select
							disabled={!this.props.listaHospitais.length}
							name="hospital"
							placeholder="Hospital"
							className="mb-2 ml-2 mr-2"
							value={"teste"}
							onChange={e => this.setColetas(e.target.value)}>
							<option value="" />
							{this.props.listaHospitais.map((hospital, index) => (
								<option key={index} value={index}>
									{hospital.nome}
								</option>
							))}
						</select>
						<div className="card-header d-flex align-items-center">
							<h3 className="h4 lead">{this.state.nomeHospital}</h3>
						</div>
						<div className="card-body">
							{conteudo}
						</div>
					</div>
				</div>
			</div>
        </div>
    );
  }
}

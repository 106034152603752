export function setCookie(cname: string, cvalue: string, exdays: number): boolean {
	var expires = "";
	if(exdays > 0) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays*24*60*60*1000));
		expires = "expires="+ d.toUTCString()+";";
	}

	document.cookie = cname + "=" + cvalue + ";" + expires + "path=/";
	return true;
}

export function getCookie(cname: string): string {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for(var i = 0; i < ca.length; i++) {
	  var c = ca[i];
	  while (c.charAt(0) == ' ') {
		c = c.substring(1);
	  }
	  if (c.indexOf(name) == 0) {
		return c.substring(name.length, c.length);
	  }
	}
	return null;
}

export function deleteCookie(cname: string) {
	document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
}
import { DadosColetaChecklist } from "../modelos";

  /*
	Número de prontuários nos quais existe questão não preenchida dividido pelo número total de prontuários
  */
 export function calcularRunchartChecklist(
	 dados: DadosColetaChecklist[],
	 listKeys: any[]
	) {
	let n = 0;
	dados.forEach(coleta => {
	  n += contarQuestoesPreenchidas(coleta, listKeys);
	});
  
	let total = dados.length * listKeys.length;

	return {
		n,
		p: (n/total)*100
	};
}

  export function contarQuestoesPreenchidas(
		  coleta: DadosColetaChecklist,
		  listaChaves: string[]
		): number {
	  let n = 0;

	  listaChaves.forEach(chave => {
		  if(coleta[chave] !== 'não preenchido')
		  	n++;
	  });

	  return n;
  }

  export function processarFaseAdmissao(prontuarios: DadosColetaChecklist[]) {
	const dados = [
			{
			  label: 'Cartão Pré-natal',
			  n: prontuarios.filter(p => p.cartaoPrenatal !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.cartaoPrenatal !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
			},
			{
			  label: 'A parturiente precisa ser referenciada',
			  n: prontuarios.filter(p => p.referenciada !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.referenciada !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Iniciou o partograma',
			  n: prontuarios.filter(p => p.iniciouPartograma !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.iniciouPartograma !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A parturiente precisa de Antibiótico',
			  n: prontuarios.filter(p => p.parturienteNecessitaAntibiotico !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.parturienteNecessitaAntibiotico !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A parturiente precisa de Anti-hipertensivo',
			  n: prontuarios.filter(p => p.parturienteNecessitaAntihipertensivo !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.parturienteNecessitaAntihipertensivo !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A parturiente precisa de Sulfato de Magnésio',
			  n: prontuarios.filter(p => p.parturienteNecessitaSulfatoMagnesio !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.parturienteNecessitaSulfatoMagnesio !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A parturiente precisa de Antirretrovirais',
			  n: prontuarios.filter(p => p.parturienteNecessitaAntirretroviral !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.parturienteNecessitaAntirretroviral !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Material para higienizar as mãos e luvas para cada exame vaginal',
			  n: prontuarios.filter(p => p.disponibilidadeMaterialExame !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.disponibilidadeMaterialExame !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Foi estimulada a presença de um acompanhante',
			  n: prontuarios.filter(p => p.presencaAcompanhante !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.presencaAcompanhante !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A parturiente ou o acompanhante foram orientados a pedir ajuda',
			  n: prontuarios.filter(p => p.parturienteAcompanhanteSolicitouAjuda !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.parturienteAcompanhanteSolicitouAjuda !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
	];

	return dados.sort((a, b) => b.n-a.n)
}


export function processarAntesExpulsaoMae(prontuarios: DadosColetaChecklist[]) {
  const dados = [
		  {
			label: 'A parturinte apresenta indicação de cesárea',
			n: prontuarios.filter(p => p.indicacaoCesarea !== 'não preenchido').length,
			p: ((prontuarios.filter(p => p.indicacaoCesarea !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A parturiente apresenta indicação de episiotomia',
			  n: prontuarios.filter(p => p.parturienteEpisiotomia !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.parturienteEpisiotomia !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A parturiente necessita de Antibiótico',
			  n: prontuarios.filter(p => p.antesExpulsaoParturienteNecessitaAntibiotico !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.antesExpulsaoParturienteNecessitaAntibiotico !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A parturiente precisa de Anti-hipertensivo',
			  n: prontuarios.filter(p => p.antesExpulsaoParturienteNecessitaAntihipertensivo !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.antesExpulsaoParturienteNecessitaAntihipertensivo !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A parturiente precisa de Sulfato de Magnésio',
			  n: prontuarios.filter(p => p.antesExpulsaoParturienteNecessitaSulfatoMagnesio !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.antesExpulsaoParturienteNecessitaSulfatoMagnesio !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  }
  ];

  return dados.sort((a, b) => b.n-a.n)
}

export function processarAntesExpulsaoRN(prontuarios: DadosColetaChecklist[]) {
  const dados = [
		  {
			label: 'Segundo profissional para auxiliar o parto',
			n: prontuarios.filter(p => p.profissionalAssistenteParto !== 'não preenchido').length,
			p: ((prontuarios.filter(p => p.profissionalAssistenteParto !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Está presente profissional com capacitação em reanimação neonatal',
			  n: prontuarios.filter(p => p.profissionalCapacitadoReanimacaoNeonatal !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.profissionalCapacitadoReanimacaoNeonatal !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Está disponível material para aspiração',
			  n: prontuarios.filter(p => (p.materialAssistenciaRnSondasTraqueais || p.materialAssistenciaRnDispositivoAspiracao || p.materialAssistenciaRnAspiradorVacuo)).length,
			  p: ((prontuarios.filter(p => (p.materialAssistenciaRnSondasTraqueais || p.materialAssistenciaRnDispositivoAspiracao || p.materialAssistenciaRnAspiradorVacuo)).length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Está disponível material para ventilação',
			  n: prontuarios.filter(p => (p.materialAssistenciaRnReanimadorManual || p.materialAssistenciaRnMascarasVentilacao || p.materialAssistenciaRnOximetroPulso)).length,
			  p: ((prontuarios.filter(p => (p.materialAssistenciaRnReanimadorManual || p.materialAssistenciaRnMascarasVentilacao || p.materialAssistenciaRnOximetroPulso)).length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Está disponível material para intubação traqueal',
			  n: prontuarios.filter(p => (p.materialAssistenciaRnLaringoscopioLamina || p.materialAssistenciaRnCanulasIntubacao)).length,
			  p: ((prontuarios.filter(p => (p.materialAssistenciaRnLaringoscopioLamina || p.materialAssistenciaRnCanulasIntubacao)).length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Está disponível medicamentos',
			  n: prontuarios.filter(p => (p.materialAssistenciaRnAdrenalina || p.materialAssistenciaRnExpansorVolume)).length,
			  p: ((prontuarios.filter(p => (p.materialAssistenciaRnAdrenalina || p.materialAssistenciaRnExpansorVolume)).length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Está disponível material para cateterismo umbilical',
			  n: prontuarios.filter(p => (p.materialAssistenciaRnCamposEstereis || p.materialAssistenciaRnSondaTraqueal)).length,
			  p: ((prontuarios.filter(p => (p.materialAssistenciaRnCamposEstereis || p.materialAssistenciaRnSondaTraqueal)).length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Estão disponíveis outros materiais',
			  n: prontuarios.filter(p => (p.materialAssistenciaRnLuvasOculos || p.materialAssistenciaRnLaminaEsteril || p.materialAssistenciaRnClampe || p.materialAssistenciaRnFontesOxigenio || p.materialAssistenciaRnFonteCalor || p.materialAssistenciaRnRelogioParede)).length,
			  p: ((prontuarios.filter(p => (p.materialAssistenciaRnLuvasOculos || p.materialAssistenciaRnLaminaEsteril || p.materialAssistenciaRnClampe || p.materialAssistenciaRnFontesOxigenio || p.materialAssistenciaRnFonteCalor || p.materialAssistenciaRnRelogioParede)).length/prontuarios.length)*100).toFixed(1)+'%'
		  }
  ];

  return dados.sort((a, b) => b.n-a.n)
}


export function processarAposNascimentoRN(prontuarios: DadosColetaChecklist[]) {
  const dados = [
		  {
			label: 'O recém-nascido precisa ser referenciado',
			n: prontuarios.filter(p => p.rnNecessitaSerReferenciado !== 'não preenchido').length,
			p: ((prontuarios.filter(p => p.rnNecessitaSerReferenciado !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'O recém-nascido precisa iniciar tratamento com antibiótico',
			  n: prontuarios.filter(p => p.rnNecessitaReceberAntibiotico !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.rnNecessitaReceberAntibiotico !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'O recém-nascido precisa de Cuidado especial ou vigilância',
			  n: prontuarios.filter(p => p.rnNecessitaCuidadosEspeciais !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.rnNecessitaCuidadosEspeciais !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'O recém-nascido precisa iniciar terapia antirretroviral',
			  n: prontuarios.filter(p => p.rnNecessitaAntirretroviral !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.rnNecessitaAntirretroviral !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Iniciou amamentação na primeira hora',
			  n: prontuarios.filter(p => p.rnIniciouAmamentacaoNa1Hora !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.rnIniciouAmamentacaoNa1Hora !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Realizou contato pele a pele',
			  n: prontuarios.filter(p => p.iniciouContatoPeleaPela !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.iniciouContatoPeleaPela !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Clampeou o cordão de 1 a 3 minutos',
			  n: prontuarios.filter(p => p.clampeamentoCordao1a3min !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.clampeamentoCordao1a3min !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Administrou vitamina K',
			  n: prontuarios.filter(p => p.administradaVitaminaK !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.administradaVitaminaK !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Identificou o RN com pulseira',
			  n: prontuarios.filter(p => p.identificacaoRNPulseira !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.identificacaoRNPulseira !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Orientou a puérpera e o acompanhante a pedir ajuda',
			  n: prontuarios.filter(p => p.puerperaAcompanhanteIraoPedirAjudaParaRN !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.puerperaAcompanhanteIraoPedirAjudaParaRN !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  }
  ];

  return dados.sort((a, b) => b.n-a.n)
}

export function processarAposNascimentoMae(prontuarios: DadosColetaChecklist[]) {
  const dados = [
		  {
			label: 'Puérpera está sangrando além do esperado',
			n: prontuarios.filter(p => p.puerperaSangrando !== 'não preenchido').length,
			p: ((prontuarios.filter(p => p.puerperaSangrando !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A puérpera necessita de Antibiótico',
			  n: prontuarios.filter(p => p.puerperaNecessitaAntibiotico !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.puerperaNecessitaAntibiotico !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A puérpera necessita de Anti-hipertensivo',
			  n: prontuarios.filter(p => p.puerperaNecessitaAntihipertensivo !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.puerperaNecessitaAntihipertensivo !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A puérpera necessita de Sulfato de magnésio',
			  n: prontuarios.filter(p => p.puerperaNecessitaSulfatoMagnesio !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.puerperaNecessitaSulfatoMagnesio !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  }
  ];

  return dados.sort((a, b) => b.n-a.n)
}

export function processarAntesAltaMae(prontuarios: DadosColetaChecklist[]) {
  const dados = [
		  {
			label: 'O sangramento da puérpera está controlado',
			n: prontuarios.filter(p => p.puerperaSangramentoControlado !== 'não preenchido').length,
			p: ((prontuarios.filter(p => p.puerperaSangramentoControlado !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A puérpera necessita de Antibiótico',
			  n: prontuarios.filter(p => p.antesAltaPuerperaNecessitaAntibiotico !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.antesAltaPuerperaNecessitaAntibiotico !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A puérpera foi orientada sobre sobre sinais de alerta para pedir ajuda',
			  n: prontuarios.filter(p => p.puerperaAcompanhanteIraoPedirAjudaSinaisAlertaPuerpera !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.puerperaAcompanhanteIraoPedirAjudaSinaisAlertaPuerpera !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  }
  ];

  return dados.sort((a, b) => b.n-a.n)
}

export function processarAntesAltaRN(prontuarios: DadosColetaChecklist[]) {
  const dados = [
		  {
			label: 'Se o recém-nascido fazia uso de antibiótico, o tratamento foi finalizado',
			n: prontuarios.filter(p => p.tratamentoAntibioticoRNFinalizado !== 'não preenchido').length,
			p: ((prontuarios.filter(p => p.tratamentoAntibioticoRNFinalizado !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'O recém-nascido está mamando bem',
			  n: prontuarios.filter(p => p.mamandoBem !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.mamandoBem !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'A mãe e o recém-nascido receberam antirretrovirais para seis semanas',
			  n: prontuarios.filter(p => p.maeRNReceberamAntirretroviral6semanas !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.maeRNReceberamAntirretroviral6semanas !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Orientou a mãe sobre o acompanhamento do bebê após alta',
			  n: prontuarios.filter(p => p.maeAcompanhanteIraoProcurarAjudaRN !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.maeAcompanhanteIraoProcurarAjudaRN !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'O recém-nascido apresenta icterícia',
			  n: prontuarios.filter(p => p.rnApresentouIctericia !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.rnApresentouIctericia !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Realizou exame para o grupo sanguíneo e fator RH',
			  n: prontuarios.filter(p => p.rnRealizouExameGrupoSanguineoeFatorRH !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.rnRealizouExameGrupoSanguineoeFatorRH !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Vacina BCG',
			  n: prontuarios.filter(p => p.vacinaBCG !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.vacinaBCG !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Vacina Hepatite B',
			  n: prontuarios.filter(p => p.antesAltaVacinaHEPB !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.antesAltaVacinaHEPB !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Teste do Pezinho',
			  n: prontuarios.filter(p => p.testePezinho !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.testePezinho !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Teste da Orelhinha',
			  n: prontuarios.filter(p => p.testeOrelhinha !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.testeOrelhinha !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Teste da Olhinho',
			  n: prontuarios.filter(p => p.testeOlhinho !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.testeOlhinho !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Teste da Linguinha',
			  n: prontuarios.filter(p => p.testeLinguinha !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.testeLinguinha !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Teste da Coraçãozinho',
			  n: prontuarios.filter(p => p.testeCoracaozinho !== 'não preenchido').length,
			  p: ((prontuarios.filter(p => p.testeCoracaozinho !== 'não preenchido').length/prontuarios.length)*100).toFixed(1)+'%'
		  }
  ];

  return dados.sort((a, b) => b.n-a.n)
}

export function calcularPreenchimentoFase(dados: DadosColetaChecklist[], momento): number {
  const somatorioPreenchidas = dados.reduce((p, c) => p+contarQuestoesPreenchidas(c, momento), 0);
  return (somatorioPreenchidas/(dados.length*momento.length))*100;
}

// Esta fase possui perguntas que não são mapeadas como não preenchido
// Por isso a nescessidade de uma função só pra esta fase
export function calcularPreenchimentoFaseAntesExpulsao(dados: DadosColetaChecklist[]): number {
	
	let somatorioPreenchidas = dados.reduce((p, c) => {
		let n = 0;
		checklistMomentoAntesExpulsaoKeys.forEach(chave => {
			if(c[chave] !== 'não preenchido')
				n++;
		});

		if(c.materialAssistenciaRnLuvasOculos || c.materialAssistenciaRnLaminaEsteril || c.materialAssistenciaRnClampe || c.materialAssistenciaRnFontesOxigenio || c.materialAssistenciaRnFonteCalor || c.materialAssistenciaRnRelogioParede)
			n++;
		if(c.materialAssistenciaRnCamposEstereis || c.materialAssistenciaRnSondaTraqueal)
			n++;
		if((c.materialAssistenciaRnAdrenalina || c.materialAssistenciaRnExpansorVolume))
			n++;
		if(c.materialAssistenciaRnLaringoscopioLamina || c.materialAssistenciaRnCanulasIntubacao)
			n++
		if(c.materialAssistenciaRnReanimadorManual || c.materialAssistenciaRnMascarasVentilacao || c.materialAssistenciaRnOximetroPulso)
			n++;
		if(c.materialAssistenciaRnSondasTraqueais || c.materialAssistenciaRnDispositivoAspiracao || c.materialAssistenciaRnAspiradorVacuo)
			n++;
		
		return p+n
	}, 0);

	return (somatorioPreenchidas/(dados.length*(checklistMomentoAntesExpulsaoKeys.length+6)))*100;
}

export function preprocessarParaXLSX(source: any[]) {
	return source.map(objeto => {
		const keys = Object.keys(objeto);
		keys.forEach(chave => {
			if(typeof(objeto[chave]) == 'boolean')
				objeto[chave] = objeto[chave] ? "SIM" : "NÃO";
		});

		objeto.hospital = objeto.hospital.nome;
		objeto.dataColeta = objeto.dataColeta.split('-').reverse().join('-');
		objeto.dataAltaObito = objeto.dataAltaObito.split('-').reverse().join('-');
		objeto.dataInternacao = objeto.dataInternacao.split('-').reverse().join('-');
		objeto.dataNascimento = objeto.dataNascimento.split('-').reverse().join('-');

		return objeto;
	});
}

  export const checklistKeys = [
		'cartaoPrenatal',
		'referenciada',
		'iniciouPartograma',
		'parturienteNecessitaAntibiotico',
		'parturienteNecessitaAntihipertensivo',
		'parturienteNecessitaSulfatoMagnesio',
		'parturienteNecessitaAntirretroviral',
		'disponibilidadeMaterialExame',
		'presencaAcompanhante',
		'parturienteAcompanhanteSolicitouAjuda',
		'indicacaoCesarea',
		'parturienteEpisiotomia',
		'antesExpulsaoParturienteNecessitaAntibiotico',
		'antesExpulsaoParturienteNecessitaAntihipertensivo',
		'antesExpulsaoParturienteNecessitaSulfatoMagnesio',
		'profissionalAssistenteParto',
		'profissionalCapacitadoReanimacaoNeonatal',
		'puerperaSangrando',
		'puerperaNecessitaAntibiotico',
		'puerperaNecessitaAntihipertensivo',
		'puerperaNecessitaSulfatoMagnesio',
		'rnNecessitaSerReferenciado',
		'rnNecessitaReceberAntibiotico',
		'rnNecessitaCuidadosEspeciais',
		'rnNecessitaAntirretroviral',
		'clampeamentoCordao1a3min',
		'iniciouContatoPeleaPela',
		'rnIniciouAmamentacaoNa1Hora',
		'administradaVitaminaK',
		'identificacaoRNPulseira',
		'puerperaAcompanhanteIraoPedirAjudaParaRN',
		'puerperaSangramentoControlado',
		'antesAltaPuerperaNecessitaAntibiotico',
		'puerperaAcompanhanteIraoPedirAjudaSinaisAlertaPuerpera',
		'tratamentoAntibioticoRNFinalizado',
		'mamandoBem',
		'maeRNReceberamAntirretroviral6semanas',
		'maeAcompanhanteIraoProcurarAjudaRN',
		'rnApresentouIctericia',
		'rnRealizouExameGrupoSanguineoeFatorRH',
		'vacinaBCG',
		'antesAltaVacinaHEPB',
		'testePezinho',
		'testeOrelhinha',
		'testeOlhinho',
		'testeLinguinha',
		'testeCoracaozinho',
];

export const checklistMaeKeys = [
	// Admissão
	'cartaoPrenatal',
	'referenciada',
	'iniciouPartograma',
	'parturienteNecessitaAntibiotico',
	'parturienteNecessitaAntihipertensivo',
	'parturienteNecessitaSulfatoMagnesio',
	'parturienteNecessitaAntirretroviral',
	'disponibilidadeMaterialExame',
	'presencaAcompanhante',
	'parturienteAcompanhanteSolicitouAjuda',
	// Antes Epulsão
	'indicacaoCesarea',
	'parturienteEpisiotomia',
	'antesExpulsaoParturienteNecessitaAntibiotico',
	'antesExpulsaoParturienteNecessitaAntihipertensivo',
	'antesExpulsaoParturienteNecessitaSulfatoMagnesio',
	// Após Nascimento
	'puerperaSangrando',
	'puerperaNecessitaAntibiotico',
	'puerperaNecessitaAntihipertensivo',
	'puerperaNecessitaSulfatoMagnesio',
	// Antes alta
	'puerperaSangramentoControlado',
	'antesAltaPuerperaNecessitaAntibiotico',
	'puerperaAcompanhanteIraoPedirAjudaSinaisAlertaPuerpera',
];

export const checklistRNKeys = [
	// Admissão
	/* NADA */
	// Antes Epulsão
	'profissionalAssistenteParto',
	'profissionalCapacitadoReanimacaoNeonatal',
	// Após Nascimento
	'rnNecessitaSerReferenciado',
	'rnNecessitaReceberAntibiotico',
	'rnNecessitaCuidadosEspeciais',
	'rnNecessitaAntirretroviral',
	'rnIniciouAmamentacaoNa1Hora',
	'iniciouContatoPeleaPela',
	'clampeamentoCordao1a3min',
	'administradaVitaminaK',
	'identificacaoRNPulseira',
	'puerperaAcompanhanteIraoPedirAjudaParaRN',
	// Antes alta
	'tratamentoAntibioticoRNFinalizado',
	'mamandoBem',
	'maeRNReceberamAntirretroviral6semanas',
	'maeAcompanhanteIraoProcurarAjudaRN',
	'rnApresentouIctericia',
	'rnRealizouExameGrupoSanguineoeFatorRH',
	'vacinaBCG',
	'antesAltaVacinaHEPB',
	'testePezinho',
	'testeOrelhinha',
	'testeOlhinho',
	'testeLinguinha',
	'testeCoracaozinho',
];

 export const checklistMomentoAdmissaoKeys = [
	'cartaoPrenatal',
	'referenciada',
	'iniciouPartograma',
	'parturienteNecessitaAntibiotico',
	'parturienteNecessitaAntihipertensivo',
	'parturienteNecessitaSulfatoMagnesio',
	'parturienteNecessitaAntirretroviral',
	'disponibilidadeMaterialExame',
	'presencaAcompanhante',
	'parturienteAcompanhanteSolicitouAjuda'
 ];

 export const checklistMomentoAdmissaoMaeKeys = [
	'cartaoPrenatal',
	'referenciada',
	'iniciouPartograma',
	'parturienteNecessitaAntibiotico',
	'parturienteNecessitaAntihipertensivo',
	'parturienteNecessitaSulfatoMagnesio',
	'parturienteNecessitaAntirretroviral',
	'disponibilidadeMaterialExame',
	'presencaAcompanhante',
	'parturienteAcompanhanteSolicitouAjuda'
 ];

 export const checklistMomentoAntesExpulsaoKeys = [
	'indicacaoCesarea',
	'parturienteEpisiotomia',
	'antesExpulsaoParturienteNecessitaAntibiotico',
	'antesExpulsaoParturienteNecessitaAntihipertensivo',
	'antesExpulsaoParturienteNecessitaSulfatoMagnesio',
	'profissionalAssistenteParto',
	'profissionalCapacitadoReanimacaoNeonatal',
 ];

 export const checklistMomentoAntesExpulsaoMaeKeys = [
	'indicacaoCesarea',
	'parturienteEpisiotomia',
	'antesExpulsaoParturienteNecessitaAntibiotico',
	'antesExpulsaoParturienteNecessitaAntihipertensivo',
	'antesExpulsaoParturienteNecessitaSulfatoMagnesio',
 ];

 export const checklistMomentoAntesExpulsaoRNKeys = [
	'profissionalAssistenteParto',
	'profissionalCapacitadoReanimacaoNeonatal',
 ];

 export const checklistMomentoAposNascimentoKeys = [
	'puerperaSangrando',
	'puerperaNecessitaAntibiotico',
	'puerperaNecessitaAntihipertensivo',
	'puerperaNecessitaSulfatoMagnesio',
	'rnNecessitaSerReferenciado',
	'rnNecessitaReceberAntibiotico',
	'rnNecessitaCuidadosEspeciais',
	'rnNecessitaAntirretroviral',
	'rnIniciouAmamentacaoNa1Hora',
	'iniciouContatoPeleaPela',
	'clampeamentoCordao1a3min',
	'administradaVitaminaK',
	'identificacaoRNPulseira',
	'puerperaAcompanhanteIraoPedirAjudaParaRN'
 ];

 export const checklistMomentoAposNascimentoMaeKeys = [
	'puerperaSangrando',
	'puerperaNecessitaAntibiotico',
	'puerperaNecessitaAntihipertensivo',
	'puerperaNecessitaSulfatoMagnesio'
 ];

 export const checklistMomentoAposNascimentoRNKeys = [
	'rnNecessitaSerReferenciado',
	'rnNecessitaReceberAntibiotico',
	'rnNecessitaCuidadosEspeciais',
	'rnNecessitaAntirretroviral',
	'rnIniciouAmamentacaoNa1Hora',
	'iniciouContatoPeleaPela',
	'clampeamentoCordao1a3min',
	'administradaVitaminaK',
	'identificacaoRNPulseira',
	'puerperaAcompanhanteIraoPedirAjudaParaRN',
 ];

 export const checklistMomentoAntesAltaKeys = [
	'puerperaSangramentoControlado',
	'antesAltaPuerperaNecessitaAntibiotico',
	'puerperaAcompanhanteIraoPedirAjudaSinaisAlertaPuerpera',
	'tratamentoAntibioticoRNFinalizado',
	'mamandoBem',
	'maeRNReceberamAntirretroviral6semanas',
	'maeAcompanhanteIraoProcurarAjudaRN',
	'rnApresentouIctericia',
	'rnRealizouExameGrupoSanguineoeFatorRH',
	'vacinaBCG',
	'antesAltaVacinaHEPB',
	'testePezinho',
	'testeOrelhinha',
	'testeOlhinho',
	'testeLinguinha',
	'testeCoracaozinho'
 ];

 export const checklistMomentoAntesAltaMaeKeys = [
	'puerperaSangramentoControlado',
	'antesAltaPuerperaNecessitaAntibiotico',
	'puerperaAcompanhanteIraoPedirAjudaSinaisAlertaPuerpera'
 ];

 export const checklistMomentoAntesAltaRNKeys = [
	'tratamentoAntibioticoRNFinalizado',
	'mamandoBem',
	'maeRNReceberamAntirretroviral6semanas',
	'maeAcompanhanteIraoProcurarAjudaRN',
	'rnApresentouIctericia',
	'rnRealizouExameGrupoSanguineoeFatorRH',
	'vacinaBCG',
	'antesAltaVacinaHEPB',
	'testePezinho',
	'testeOrelhinha',
	'testeOlhinho',
	'testeLinguinha',
	'testeCoracaozinho',
 ];
 
 export const checklistMomentos = [
	{
		label: 'No momento da admissão',
		keys: checklistMomentoAdmissaoKeys,
		main: false
	},
	{
		label: 'Imediatamente antes da expulsão',
		keys: checklistMomentoAntesExpulsaoKeys,
		main: false
	},
	{
		label: 'Logo após o nascimento',
		keys: checklistMomentoAposNascimentoKeys,
		main: false
	},
	{
		label: 'Antes da alta',
		keys: checklistMomentoAntesAltaKeys,
		main: false
	},
	{
		label: 'Geral',
		keys: checklistKeys,
		main: true
	}
 ];

 export const checklistMomentosMae = [
	{
		label: 'No momento da admissão',
		keys: checklistMomentoAdmissaoMaeKeys,
		main: false
	},
	{
		label: 'Imediatamente antes da expulsão',
		keys: checklistMomentoAntesExpulsaoMaeKeys,
		main: false
	},
	{
		label: 'Logo após o nascimento',
		keys: checklistMomentoAposNascimentoMaeKeys,
		main: false
	},
	{
		label: 'Antes da alta',
		keys: checklistMomentoAntesAltaMaeKeys,
		main: false
	},
	{
		label: 'Geral',
		keys: checklistMaeKeys,
		main: true
	}
 ];

 export const checklistMomentosRN = [
	{
		label: 'Imediatamente antes da expulsão',
		keys: checklistMomentoAntesExpulsaoRNKeys,
		main: false
	},
	{
		label: 'Logo após o nascimento',
		keys: checklistMomentoAposNascimentoRNKeys,
		main: false
	},
	{
		label: 'Antes da alta',
		keys: checklistMomentoAntesAltaRNKeys,
		main: false
	},
	{
		label: 'Geral',
		keys: checklistRNKeys,
		main: true
	}
 ];

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { obterDadosBoasPraticas, obterDadosBoasPraticasFiltrado } from '../../api/api';
import Loading from '../../api/Loading';
import { DadosColetaBoasPraticas, LabelMomentosBp } from '../../modelos';
import TabelaSociodemografica from '../../graficos/TabelaSociodemografica';
import ColumnChart from '../../graficos/ColumnChart';
import { processarEventosAdversosMaeBarChart, processarEventosAdversosRNBarChart, processarDadosTipoParto } from '../../utils/ProcessarDadosCharts';
import RunChartEAMae from '../../graficos/RunChartEAMae';
import { DateTime } from 'luxon';
import RunChartEARN from '../../graficos/RunChartEARN';
import { getUrlParameter, numero2MesAbreviado, calcularIdadeMae } from '../../utils';
import PieChart from '../../graficos/PieChart';
import Histograma from '../../graficos/Histograma';
import RunChart from '../../graficos/RunChart';
import { processarSerie, calcularIRA, calcularEPRA, calcularIG, categorizarEmMeses } from '../../utils/calculos_IRA_EPRA_IG';
import RunchartEAMensal from '../../graficos/RunchartEAMensal';
import TabelaBoasPraticas from '../../graficos/TabelaBoasPraticas';
import TabelaBoasPraticasMae from '../../graficos/TabelaBoasPraticasMae';
import TabelaBoasPraticasRn from '../../graficos/TabelaBoasPraticasRn';
import {
	calcularRunchartBoasPraticas,
	calcularRunchartEspecificoBoasPraticas,
	boasPraticasKeys,
	boasPraticasMaeKeys,
	boasPraticasRnKeys
} from '../../utils/calculosBoasPraticas';

interface stateI {
	loadingErro: Boolean
	loading: Boolean
	dados: DadosColetaBoasPraticas[],
	bpSelecionados: String[]
};

class RelatorioBoasPraticas extends React.Component<any, stateI> {

	hospitalID = Number(window.location.pathname.split('/').slice(-1)[0]);
	categorizadoMeses = [];

	state = {
		loading: true,
		loadingErro: false,
		dados: [],
		bpSelecionados: []
	}

	componentDidMount() {
		if (!this.props.root) {
			this.inicializar();
		} else {
			this.getDados();
		}
	}

	inicializar = () => {
		// Forçando que este componente seja o único na tela
		ReactDOM.render(<RelatorioBoasPraticas root={true} />, document.getElementsByTagName('body')[0]);
		aplicarEstilos();
	}

	getDados = async () => {
		const dataInicio = getUrlParameter('dataInicio');
		const dataFim = getUrlParameter('dataFim');
		const bpSelecionados = getUrlParameter('bpSelecionados');
		if (bpSelecionados)
			this.state.bpSelecionados = bpSelecionados.split(',');

		try {
			let dados = null;
			if (dataInicio && dataFim)
				dados = await obterDadosBoasPraticasFiltrado(this.hospitalID, dataInicio, dataFim);
			else
				dados = await obterDadosBoasPraticas(this.hospitalID);

			if (dados.length < 1) {
				this.setState({ loadingErro: true, loading: false });
				return;
			}

			this.categorizadoMeses = categorizarEmMeses(dados);

			this.setState({
				dados,
				loadingErro: false,
				loading: false
			});
		} catch (_) {
			this.setState({ loadingErro: true, loading: false });
		}
	}

	getPeriodoColetar = () => {
		const sorted = this.state.dados
			.slice(0)
			.sort(
				(a, b) => DateTime.fromISO(a.dataParto).toMillis() - DateTime.fromISO(b.dataParto).toMillis()
			);

		const inicio = DateTime.fromISO(sorted[0].dataParto);
		const fim = DateTime.fromISO(sorted[sorted.length - 1].dataParto);

		const dataInicio = numero2MesAbreviado(inicio.month) + '/' + inicio.year;
		const dataFim = numero2MesAbreviado(fim.month) + '/' + fim.year;

		if (dataInicio === dataFim)
			return dataInicio;

		return dataInicio + ' a ' + dataFim;
	}

	imprimir = () => {
		const nomeHospital = this.state.dados[0].hospital.nome.replace(/\s+/g, '_')
		document.title = `Relatório_Qualiparto_${nomeHospital}`;
		window.print();
	};

	render() {
		if (this.state.loading)
			return <Loading />;
		if (this.state.loadingErro)
			return <h2>Não há dados coletados.</h2>;

		let runchartBoasPraticasGeral = [];
		if (this.state.dados.length > 0)
			runchartBoasPraticasGeral = processarSerie(categorizarEmMeses(this.state.dados, 'dataParto'), boasPraticas => calcularRunchartBoasPraticas(boasPraticas, boasPraticasKeys));

		let runchartBoasPraticasMae = [];
		if (this.state.dados.length > 0)
			runchartBoasPraticasMae = processarSerie(categorizarEmMeses(this.state.dados, 'dataParto'), boasPraticas => calcularRunchartBoasPraticas(boasPraticas, boasPraticasMaeKeys));

		let runchartBoasPraticasRn = [];
		if (this.state.dados.length > 0)
			runchartBoasPraticasRn = processarSerie(categorizarEmMeses(this.state.dados, 'dataParto'), boasPraticas => calcularRunchartBoasPraticas(boasPraticas, boasPraticasRnKeys));

		return (
			<div>
				<div className="pagina">
					<div className="d-flex flex-row justify-content-around mb-3 d-print-none">
						<button
							type="button"
							className="btn btn-primary"
							onClick={this.imprimir}
						>
							Baixar Relatório em PDF
						</button>
					</div>
					<div className="d-flex flex-row justify-content-around mb-3">
						<img src="/theme/img/logos/qualisaude_wide.png" height="50px" />
						<img src="/theme/img/logos/imd.png" height="50px" />
						<img src="/theme/img/logos/ufrn.png" height="50px" />
					</div>
					{/* Lista de IDs */}
					<span style={{ fontSize: '9pt' }}>Questionários de IDs: {this.getColetasIds()}</span>

					<div className="mt-4">
						<h3 className="text-center">
							RELATÓRIO DE MONITORAMENTO DAS BOAS PRATICAS MATERNAS E NEONATAIS EM {this.state.dados[0].hospital.nome.toUpperCase()}
						</h3>

						<br /><br />

						<h4
							style={{
								borderBottom: '1px solid gray'
							}}
						>
							1) Caracterização clínica dos partos na instituição {this.state.dados[0].hospital.nome}, no período de {this.getPeriodoColetar()}, {this.state.dados[0].hospital.cidade}, {(new Date()).getFullYear()}
						</h4>
						<div className="row">
							<div className="col-md-6 offset-md-3">
								<PieChart
									id="pieChartTipoParto"
									titulo="Tipos de Parto"
									series={processarDadosTipoParto(this.state.dados)}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<Histograma
									arredondarFaixas={true}
									titulo="Histograma peso recém-nascido"
									corBarras="indianred"
									nClasses={3}
									dados={this.state.dados.map(c => c.rnPeso)}
								/>
							</div>
							<div className="col-md-6">
								{<Histograma
									arredondarFaixas={true}
									titulo="Histograma idade gestacional(em semanas)"
									corBarras="indianred"
									nClasses={3}
									dados={this.state.dados.map(c => c.maeIdadeGestacional)}
								/>}
							</div>
						</div>
						<TabelaBoasPraticas dados={this.state.dados} />
						<br></br>
						<h4
							style={{
								borderBottom: '1px solid gray'
							}}
						>
							2) Gráfico de tendência do nível de cumprimento das
							boas práticas na assistência ao parto
						</h4>

						<div className="row">
							<div className="col-12">
								{
									runchartBoasPraticasGeral.length > 0
										? (<RunChart
											dados={runchartBoasPraticasGeral}
											exportavel={true}
											mostrarLegendas={true}
											titulo='Percentual de cumprimento das boas práticas (geral)'
											nome='relmediageral'
											yLabel="Percentual de Boas Práticas Geral"
											corLinha="#07a7e3"
											percentual={true}
										/>)
										: <h4>Nenhuma coleta de boas práticas realizada</h4>
								}
							</div>
						</div>

						<h4
							style={{
								borderBottom: '1px solid gray'
							}}
						>
							2.1) Gráfico de tendência do nível de cumprimento das boas
							práticas maternas.
						</h4>

						<div className="row">
							<div className="col-12">
								{
									runchartBoasPraticasMae.length > 0
										? (<RunChart
											dados={runchartBoasPraticasMae}
											exportavel={true}
											mostrarLegendas={true}
											titulo='Percentual de cumprimento das boas práticas maternas'
											nome='relmediamaternas'
											yLabel="Percentual de Boas Práticas maternas"
											corLinha="#07a7e3"
											percentual={true}
										/>)
										: <h4>Nenhuma coleta de boas práticas realizada</h4>
								}
								<TabelaBoasPraticasMae dados={this.state.dados} />
							</div>
						</div>

						<h4
							style={{
								borderBottom: '1px solid gray'
							}}
						>
							2.2) Gráfico de tendência do nível de cumprimento das boas
							práticas neonatais.
						</h4>

						<div className="row">
							<div className="col-12">
								{
									runchartBoasPraticasRn.length > 0
										? (<RunChart
											dados={runchartBoasPraticasRn}
											exportavel={true}
											mostrarLegendas={true}
											titulo='Percentual de cumprimento das boas práticas neonatais'
											nome='relmedianeonatais'
											yLabel="Percentual de Boas Práticas neonatais"
											corLinha="#07a7e3"
											percentual={true}
										/>)
										: <h4>Nenhuma coleta de boas práticas realizada</h4>
								}
								<TabelaBoasPraticasRn dados={this.state.dados} />
							</div>
						</div>
						<div className="form-group">
							{this.state.bpSelecionados.length > 0 ?
								<h4
									style={{
										borderBottom: '1px solid gray'
									}}
								>
									2.3) Gráfico de tendência do nível de cumprimento das boas
									práticas:
								</h4> :
								null}
						</div>
						{this.state.bpSelecionados
							.map(bp => this.getRunchartBpEspecifico(bp))
						}
						<br /><br />
					</div>
				</div>
			</div>
		);
	}

	getColetasIds = () => {
		const dados = this.state.dados;
		if (dados.length > 20) {
			return dados.slice(0, 20).reduce((ac, coleta) => (ac += ', ' + (coleta.dadosColetaInformacoesGerais ? coleta.dadosColetaInformacoesGerais.id : null)), '').slice(2) + '...';
		} else {
			return dados.reduce((ac, coleta) => (ac += ', ' + (coleta.dadosColetaInformacoesGerais ? coleta.dadosColetaInformacoesGerais.id : null)), '').slice(2);
		}
	}

	getRunchartBpEspecifico = qual => {
		let lista = null;
		switch (qual) {
			case 'iniciouPartograma':
				lista = boasPraticasMaeKeys.map(p => p = 'iniciouPartograma');
				break;
			case 'presencaAcompanhante':
				lista = boasPraticasMaeKeys.map(p => p = 'presencaAcompanhante');
				break;
			case 'administradaOcitocina':
				lista = boasPraticasMaeKeys.map(p => p = 'administradaOcitocina');
				break;
			case 'administradaVitaminaK':
				lista = boasPraticasRnKeys.map(p => p = 'administradaVitaminaK');
				break;
			case 'identificacaoRnPulseira':
				lista = boasPraticasRnKeys.map(p => p = 'identificacaoRnPulseira');
				break;
			case 'rnIniciouAmamentacaoNa1Hora':
				lista = boasPraticasRnKeys.map(p => p = 'rnIniciouAmamentacaoNa1Hora');
				break;
			case 'clampeamentoOportuno':
				lista = boasPraticasRnKeys.map(p => p = 'clampeamentoOportuno');
				break;
			case 'iniciouContatoPeleaPela':
				lista = boasPraticasRnKeys.map(p => p = 'iniciouContatoPeleaPela');
				break;
			default:
				return null;
		}

		return (
			<>
				<RunChart
					key={"runchart" + qual}
					dados={processarSerie(categorizarEmMeses(this.state.dados, 'dataParto'), boaspraticas => calcularRunchartEspecificoBoasPraticas(boaspraticas, lista))}
					exportavel={true}
					mostrarLegendas={true}
					titulo={LabelMomentosBp[qual]}
					nome={qual}
					yLabel="Percentual"
					corLinha="#07a7e3"
					percentual={true}
				/>
			</>
		);
	};
}

export default RelatorioBoasPraticas;

function aplicarEstilos() {
	const body = document.getElementsByTagName('body')[0];
	const pages = document.getElementsByClassName('pagina');

	body.style.width = "250mm";
	body.style.height = "297mm";
	body.style.padding = '5mm';
	body.style.font = '11pt "Arial"';

	for (let i = 0; i < pages.length; i++) {
		let page = pages.item(i) as HTMLElement;

		page.style.width = "250mm";
		page.style.height = "297mm";
	}
}

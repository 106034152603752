import { DadosColetaEventosAdversos } from "../modelos";
import { DateTime } from "luxon";
import moment from 'moment';
import {
	obterDadosEventosAdversosProntuario,
	obterDadosBoasPraticasProntuario,
	obterDadosChecklistProntuario,
	verificaPartoGemelarInfoGerais,
	verificaPartoGemelar
} from '../api/api';

export function getUrlParameter(name: string): string {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
	const results = regex.exec(location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export async function getNumColetas(numeroProntuario: string, dataParto: string, hospitalId: number) {
	const quantEA = await obterDadosEventosAdversosProntuario(parseInt(numeroProntuario), hospitalId, dataParto.concat("T00:00:00.000Z"));
	const quantCL = await obterDadosChecklistProntuario(parseInt(numeroProntuario), hospitalId, dataParto.concat("T00:00:00.000Z"));
	const quantBP = await obterDadosBoasPraticasProntuario(parseInt(numeroProntuario), hospitalId, dataParto.concat("T00:00:00.000Z"));
	const quantEAPartoGemelar = await verificaPartoGemelar(parseInt(numeroProntuario), hospitalId, dataParto.concat("T00:00:00.000Z"));
	const quantPartoGemelarInfoGerais = await verificaPartoGemelarInfoGerais(parseInt(numeroProntuario), hospitalId, dataParto.concat("T00:00:00.000Z"));

	let jaTemEA = false, jaTemCL = false, jaTemBP = false;
	let quantRealPartoGemelar;
	if (quantEA > 0) { jaTemEA = true; }
	if (quantCL > 0) { jaTemCL = true; }
	if (quantBP > 0) { jaTemBP = true; }
	if(quantPartoGemelarInfoGerais > quantEAPartoGemelar)
	{ quantRealPartoGemelar = quantPartoGemelarInfoGerais; }
	else{ quantRealPartoGemelar = quantEAPartoGemelar; }

	const dadosNumColeta = {
		quantEAPartoGemelar: quantRealPartoGemelar,
		quantEA: quantEA,
		quantCL: quantCL,
		quantBP: quantBP,
		jaTemEA: jaTemEA,
		jaTemCL: jaTemCL,
		jaTemBP: jaTemBP
	}
	return dadosNumColeta;
}

export function isValidDate(d) {
	return moment(d).isValid();
}

export function forceHTTPS() {
	if (location.protocol != 'https:')
		location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
}

export function criaMsgError(cod, mensagem) {
	if (cod == 401) {
		return mensagem;
	} else if (cod == 404) {
		return mensagem;
	} else if (cod == 500) {
		return mensagem;
	}
}

export function calcularIdadeMae(coleta: DadosColetaEventosAdversos, defaul: number = 25): number {
	if (coleta.dataInternacao == null || coleta.dataNascimento == null)
		return defaul;

	const momentoInternacao = DateTime.fromISO(coleta.dataInternacao).toMillis();
	const momentoNascimentoMae = DateTime.fromISO(coleta.dataNascimento).toMillis();

	const milisDecorridos = momentoInternacao - momentoNascimentoMae;
	const anosDecorridos = (milisDecorridos / (1000 * 60 * 60 * 24 * 365));

	return Math.floor(anosDecorridos);
}

export function numero2MesAbreviado(mes: number): string {
	switch (mes) {
		case 1:
			return 'Jan';
		case 2:
			return 'Fev';
		case 3:
			return 'Mar';
		case 4:
			return 'Abr';
		case 5:
			return 'Mai';
		case 6:
			return 'Jun';
		case 7:
			return 'Jul';
		case 8:
			return 'Ago';
		case 9:
			return 'Set';
		case 10:
			return 'Out';
		case 11:
			return 'Nov';
		case 12:
			return 'Dez';

		default:
			return '???';
	}
}
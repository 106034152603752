import * as React from 'react';
import PageHeader from '../layout/PageHeader';
import RelatorioResumidoColetas from './RelatorioResumidoColetas';
import { NavLink, Link } from 'react-router-dom';
import { getHospitais, obterDadosEventosAdversos, obterDadosChecklist, obterDadosBoasPraticas, getUsuario } from '../api/api';
import Loading from '../api/Loading.jsx';
import { saveAs } from 'file-saver';
import { Hospital } from '../modelos';
import { Usuario } from '../modelos/usuario';
import * as XLSX from 'xlsx'
import { DateTime } from 'luxon';
import arrayObjectsToCSV from '../utils/arrayObjectsToCSV';
import getFetchErroComponent from '../utils/erroCompotent';
import { preprocessarParaXLSX } from '../utils/eventos-adversos';
import RelatorioResumidoChecklist from './RelatorioResumidoChecklist';
import RelatorioResumidoBoasPraticas from './RelatorioResumidoBoasPraticas';
import { Redirect } from 'react-router';
import Swal from 'sweetalert';

interface State {
  hospitais: Hospital[];
  carregando: boolean;
  loadingErro: boolean;
  mostrandoRelatorio: boolean;
  relatorioSelecionado: 'ea' | 'checklist' | 'boaspraticas';
  hospitalId?: number;
  usuario: Usuario;
  usuarioTipo: String;
  mudarTela: String;
}

export default class ListagemHospitais extends React.Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      carregando: true,
      loadingErro: false,
      hospitais: [],
      mostrandoRelatorio: false,
      relatorioSelecionado: 'ea',
      usuario: null,
      usuarioTipo: 'COMUM',
      mudarTela: null
    };
  }

  componentDidMount() {
    this.getUsuario();
    this.getHospitais();
  }
  getUsuario = async () => {
    const usuario = await getUsuario();
    this.setState({
      usuario: usuario,
      usuarioTipo: usuario.tipo
    });
  }

  getHospitais = () => {
    this.setState({
      carregando: true,
      loadingErro: false
    });
    getHospitais()
      .then((hospitais) => {
        this.setState({
          carregando: false,
          loadingErro: false,
          hospitais: hospitais
        });
      })
      .catch(err => {
        Swal('Falha', err.response.data.message, 'error');
        this.setState({
          carregando: false,
          loadingErro: true
        });
      });
  }

  visualizarColeta = (hospitalId, qual) => {
    // Se já existe um relatório na tela
    if (this.state.mostrandoRelatorio) {
      // remova o relatório da tela antes de mostra o novo
      this.setState({ mostrandoRelatorio: false },
        () => this.setState({
          mostrandoRelatorio: true,
          relatorioSelecionado: qual,
          hospitalId
        }));
    } else { // Se não
      // Apenas mostre o novo
      this.setState({
        mostrandoRelatorio: true,
        relatorioSelecionado: qual,
        hospitalId
      });
    }
  };
  consultarDadosCSV = (hospitalId) => {
    obterDadosEventosAdversos(hospitalId).then((dadosColetas) => {
      const dadosPreProcessados: any[] = dadosColetas.map(dado => ({
        ...dado,
        dataColeta: dado.dataColeta.split('-').reverse().join('-'),
        dataAltaObito: dado.dataAltaObito.split('-').reverse().join('-'),
        dataInternacao: dado.dataInternacao.split('-').reverse().join('-'),
        dataNascimento: dado.dataNascimento.split('-').reverse().join('-'),
        hospital: dado.hospital.nome
      }));
      const data = arrayObjectsToCSV(dadosPreProcessados);
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, dadosPreProcessados[0].hospital + '-eventos-adversos.csv');
    });
    obterDadosBoasPraticas(hospitalId).then((dadosColetas) => {
      const dadosPreProcessados: any[] = dadosColetas.map(dado => ({
        dataColeta: dado.dataColeta.split('-').reverse().join('-'),
        iniciouPartograma: dado.iniciouPartograma.split('-').reverse().join('-'),
        presencaAcompanhante: dado.presencaAcompanhante,
        administradaOcitocina: dado.administradaOcitocina,
        administradaVitaminaK: dado.administradaVitaminaK,
        rnIniciouAmamentacaoNa1Hora: dado.rnIniciouAmamentacaoNa1Hora.split('-').reverse().join('-'),
        clampeamentoOportuno: dado.clampeamentoOportuno.split('-').reverse().join('-'),
        iniciouContatoPeleaPela: dado.iniciouContatoPeleaPela.split('-').reverse().join('-'),
        identificacaoRnPulseira: dado.identificacaoRnPulseira,
        hospital: dado.hospital.nome
      }));
      const data = arrayObjectsToCSV(dadosPreProcessados);
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, dadosPreProcessados[0].hospital + '-boas-praticas.csv');
    });
    obterDadosChecklist(hospitalId).then((dadosColetas) => {
      const dadosPreProcessados: any[] = dadosColetas.map(dado => ({
        ...dado,
        dataColeta: dado.dataColeta.split('-').reverse().join('-'),
        hospital: dado.hospital.nome
      }));
      const data = arrayObjectsToCSV(dadosPreProcessados);
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
      saveAs(blob, dadosPreProcessados[0].hospital + '-checklist.csv');
    });
  };

  consultarDadosXLSX = (hospitalId) => {
    obterDadosEventosAdversos(hospitalId).then((dadosColetas) => {
      const processado = preprocessarParaXLSX(dadosColetas);
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(processado);
      XLSX.utils.book_append_sheet(wb, ws, 'eventos-adversos');
      XLSX.writeFile(wb, processado[0].hospital + '-eventos-adversos.xlsx');
    });
  };

  render() {
    const linhas = this.state.hospitais.map((hospital) => (
      <tr key={hospital.id}>
        <td>{hospital.usuario.nome}</td>
        <td>{hospital.nome}</td>
        <td>{DateTime.fromISO(hospital.dataUltimaColetaEventosAdversos).toFormat('dd/MM/yyyy')}</td>
        <td>{hospital.coletaBloqueada ? 'Finalizada' : 'Em andamento'}</td>
        <td>{hospital.totalColetas}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <div className="btn-group">
            {!hospital.coletaBloqueada ?
              ((this.state.usuario.tipo == "ADMIN" && hospital.usuario.nome == this.state.usuario.nome) ||
                this.state.usuario.tipo != "ADMIN" ?
                <Link to={`/questionarios/informacoes-gerais/${hospital.id}`}>
                  <button
                    className={`btn btn-info btn-sm ${hospital.coletaBloqueada ? 'disabled' : ''}`}
                    type="button">
                    Coletar
                  </button>
                </Link> : <div></div>)
              : <button className="btn btn-info btn-sm disabled" type="button">
                  Coleta Bloqueada
                </button>
            }
            <button className="btn btn-default btn-sm dropdown" type="button">
              <span className="dropdown-toggle" data-toggle="dropdown">
                Listar Coletas
              </span>
              <div className="dropdown-menu">
                <Link to={"/relatorios/informacoes-gerais/" + hospital.id} className="dropdown-item">
                  Informações Gerais
                </Link>
                <Link to={"/relatorios/boaspraticas/" + hospital.id} className="dropdown-item">
                  Boas práticas
                </Link>
                <Link to={"/relatorios/adesao-checklist/" + hospital.id} className="dropdown-item">
                  Adesão ao checklist
                </Link>
                <Link to={"/relatorios/eventos-adversos/" + hospital.id} className="dropdown-item">
                  Eventos adversos
                </Link>
              </div>
            </button>
            {this.state.usuarioTipo == "COMUM" ? <div></div> : <button className="btn btn-warning btn-sm dropdown" type="button">
              <span className="dropdown-toggle" data-toggle="dropdown">
                Relatório
              </span>
              <div className="dropdown-menu">
                <a className="dropdown-item" onClick={() => this.visualizarColeta(hospital.id, 'boaspraticas')}>
                  Boas práticas
                </a>
                <a className="dropdown-item" onClick={() => this.visualizarColeta(hospital.id, 'checklist')}>
                  Adesão ao checklist
                </a>
                <a className="dropdown-item" onClick={() => this.visualizarColeta(hospital.id, 'ea')}>
                  Eventos adversos
                </a>
              </div>
            </button>}
            {this.state.usuarioTipo == "COMUM" ? <div></div> :
              ((this.state.usuario.tipo == "ADMIN" && hospital.usuario.nome == this.state.usuario.nome) ||
                this.state.usuario.tipo == "ADMINREDE" ?
                <Link to={"/hospitais/editar/" + hospital.id}>
                  <button title="Editar hospital" className="btn btn-success btn-sm">
                    <i className="far fa-edit"></i>
                  </button>
                </Link> : <div></div>)
            }
          </div>
        </td>
      </tr>
    ));

    const tabela = (<div className="table-responsive-md">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Hospital</th>
            <th>Última coleta</th>
            <th>Status</th>
            <th>Total de coletas</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>{linhas}</tbody>
      </table>
    </div>);
    const cadastro = (this.state.usuarioTipo == "COMUM" ? <div></div> : (<Link to="/hospitais/novo">
      <button className="btn btn-sm btn-success">
        Novo <i className="fas fa-plus" />
      </button>
    </Link>))
    const conteudo = (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h3 className="h4 lead">{this.state.usuarioTipo == "ADMIN" ? "Hospitais" : "Meus hospitais"}</h3>
              {cadastro}
            </div>
            <div className="card-body">
              {
                this.state.carregando
                  ? <Loading />
                  : (
                    this.state.loadingErro
                      ? getFetchErroComponent(this.getHospitais)
                      : tabela
                  )
              }
            </div>
          </div>
        </div>
      </div>
    );

    let relatorio = null;
    if (this.state.mostrandoRelatorio) {
      switch (this.state.relatorioSelecionado) {
        case "boaspraticas":
          relatorio = <RelatorioResumidoBoasPraticas key={'rboaspraticas'}
            hospital={this.state.hospitais.find((hospital) => hospital.id === this.state.hospitalId)}
            onClose={() => this.setState({ mostrandoRelatorio: false })}
          />;
          break;
        case "checklist":
          relatorio = <RelatorioResumidoChecklist key={'rchecklist'}
            hospital={this.state.hospitais.find((hospital) => hospital.id === this.state.hospitalId)}
            onClose={() => this.setState({ mostrandoRelatorio: false })}
          />;
          break;
        case "ea":
          relatorio = <RelatorioResumidoColetas key={'rcoletas'}
            hospital={this.state.hospitais.find((hospital) => hospital.id === this.state.hospitalId)}
            onClose={() => this.setState({ mostrandoRelatorio: false })}
          />;
          break;
      }
    }

    return (
      <div className="content-inner">
        <PageHeader titulo="Coletas" />
        <div className="container-fluid mt-4">
          {conteudo}
          {relatorio}
        </div>
      </div>
    );
  }

}

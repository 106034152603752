import * as React from 'react';
import { DadosColetaBoasPraticas }  from '../modelos';
import '../utils/estilos.css';
import {
		boasPraticasKeys,
		calcularPreenchimentoFase,
		processarBoasPraticasMae,
	} from '../utils/calculosBoasPraticas';

interface Props {
  dados: DadosColetaBoasPraticas[];
}

function processarDadosTipoParto(coletas: DadosColetaBoasPraticas[]) {
	const tiposParto = coletas.map((c) => c.tipoParto);

	const normal = tiposParto.filter((tipo) => tipo === 'Normal').length;
	const cesarea = tiposParto.filter((tipo) => tipo === 'Cesárea').length;
	const normalForceps = tiposParto.filter((tipo) => tipo === 'Normal').length;
	
	return {
		normal,
		cesarea,
		normalForceps,
		total: normal+cesarea+normalForceps
	};
}

function calcularPorcentagemRNBaixoPeso(coletas: DadosColetaBoasPraticas[]) {
	const quantidade = coletas.filter(coleta => coleta.rnPeso < 2500).length;
	const total = coletas.length;

	return {
			p: ((quantidade/total)*100).toFixed(1),
			n: quantidade
		};
}

function calcularPorcentagemIdadeGestacionalCategorizada37Semanas(coletas: DadosColetaBoasPraticas[]) {
	let maior = 0;
	let menorIgual = 0;
	coletas.forEach(atual => {
		const idade = atual.maeIdadeGestacional;
		if(idade > 37)
			maior++;
		else 
			menorIgual++;
	});

	return {
		'maior': ((maior / coletas.length)*100).toFixed(1),
		'menorIgual': ((menorIgual / coletas.length)*100).toFixed(1)
	};
}

function calcularIdadeGestacionalCategorizada37Semanas(coletas: DadosColetaBoasPraticas[]) {
	let maior = 0;
	let menorIgual = 0;
	coletas.forEach(atual => {
		const idade = atual.maeIdadeGestacional;
		if(idade > 37)
			maior++;
		else 
			menorIgual++;
	});

	return {
		'maior': maior,
		'menorIgual': menorIgual
	};
}

export default function TabelaSociodemografica(props: Props) {
	const dadosPartos = processarDadosTipoParto(props.dados);
	const idadeGestacionalPorcentagem = calcularPorcentagemIdadeGestacionalCategorizada37Semanas(props.dados);
	const idadeGestacional = calcularIdadeGestacionalCategorizada37Semanas(props.dados);
	const rnBaixoPeso = calcularPorcentagemRNBaixoPeso(props.dados);

return (
	<table style={{fontSize: '11pt', color: 'black'}} className="table table-striped-reverse">
		<thead>
		<tr className="linha-destaque">
			<th scope="row">Tabela 1 – Caracterização clínica das mães e dos recém-nascidos e do tipo de parto</th>
			<th scope="col"></th>
			<th scope="col"></th>
			<th scope="col"></th>
			<th scope="col"></th>
		</tr>
		<tr className="linha-destaque">
			<th scope="col">Variável</th>
			<th scope="col">Categoria</th>
			<th scope="col"> n </th>
			<th scope="col"> % </th>
			<th scope="col" className="text-center">Total de casos analisados</th>
		</tr>
		</thead>
		<tbody>
		<tr>
			<td>
				Tipo de parto
			</td>
			<td>
				<span>
					Normal
				</span><br />
				<span>
					Cesária
				</span><br />
			</td>
			<td>
				<span>
					{dadosPartos.normal}
				</span><br />
				<span>
				{dadosPartos.cesarea}
				</span><br />
			</td>
			<td>
				<span>
					{((dadosPartos.normal/dadosPartos.total)*100).toFixed(1)}%
				</span><br />
				<span>
					{((dadosPartos.cesarea/dadosPartos.total)*100).toFixed(1)}%
				</span><br />
			</td>
			<td className="text-center">
				<span>
					{dadosPartos.total}
				</span><br />
				<span>
					{dadosPartos.total}
				</span><br />
			</td>
		</tr>
		<tr>
			<td>
				Idade gestacional no momento do parto
			</td>
			<td>
				<span>
					Maior que 37 semanas
				</span><br />
				<span>
					Menor ou igual a 37 semanas
				</span><br />
			</td>
			<td>
				<span>
				{idadeGestacional.maior}
				</span><br />
				<span>
					{idadeGestacional.menorIgual}
				</span><br />
			</td>
			<td>
				<span>
					{idadeGestacionalPorcentagem.maior}%
				</span><br/>
				<span>
					{idadeGestacionalPorcentagem.menorIgual}%
				</span><br/>
			</td>
			<td className="text-center">
				<span>
					{props.dados.length}
				</span><br/>
				<span>
					{props.dados.length}
				</span><br/>
			</td>
		</tr>
		<tr className="linha-destaque-final">
			<td>Peso do recém-nascido</td>
			<td>
				<span>
					baixo peso (&#x3C; 2.500g)
				</span><br />
				<span>
					peso normal (&#x2265; 2.500g)
				</span><br />
			</td>
			<td>
				<span>
					{rnBaixoPeso.n}
				</span><br/>
				<span>
					{props.dados.length - rnBaixoPeso.n}
				</span><br/>
			</td>
			<td>
				<span>
					{rnBaixoPeso.p}%
				</span><br/>
				<span>
				{(((props.dados.length - rnBaixoPeso.n)/props.dados.length)*100).toFixed(1)}%
				</span><br/>
			</td>
			<td className="text-center">
				<span>
					{props.dados.length}
				</span><br/>
				<span>
					{props.dados.length}
				</span><br/>
			</td>
		</tr>
		</tbody>
   </table>
  );
}

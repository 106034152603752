import { Hospital, Autorizacao, DadosColetaChecklist, DadosColetaBoasPraticas, DadosAgregadosColetaInformacoesGerais, Rede, RedeHospitais } from '../modelos';
import { Usuario } from '../modelos/usuario';
import Axios, { AxiosResponse } from 'axios';
import { DadosColetaEventosAdversos } from '../modelos';
import { getCookie, setCookie, deleteCookie } from '../utils/cookies';
import Swal from 'sweetalert2';

//export const API_URL = 'http://localhost:8080/api/v1';
export const API_URL = 'http://qualiparto.ccs.ufrn.br/qualiparto/api/v1';

function getJWTHeaderConfig() {
	const token = getCookie('token');
	return {
		headers: {
			'Authorization': 'Bearer ' + token
		}
	};
}
//------------------INIT CRUD HOSPITAIS------------------
export function getHospitais(): Promise<Hospital[]> {
	return Axios.get(`${API_URL}/hospitais`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function getHospitaisRede(): Promise<Hospital[]> {
	return Axios.get(`${API_URL}/hospitais/redehospitais`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function getHospitaisColetados(): Promise<Hospital[]> {
	return Axios.get(`${API_URL}/hospitais/coletados`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function getHospitailById(id): Promise<Hospital> {
	return Axios.get(`${API_URL}/hospitais/${id}`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function deletarHospitailById(id): Promise<any> {
	return Axios.delete(`${API_URL}/hospitais/${id}`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function salvarHospital(hospital: Hospital): Promise<any> {
	return Axios.post(`${API_URL}/hospitais`, { ...hospital }, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function atualizarHospital(hospital: any): Promise<any> {
	return Axios.put(`${API_URL}/hospitais`, hospital, getJWTHeaderConfig())
		.then((res) => res.data);
}
//------------------END CRUD HOSPITAIS--------------------
export function getRedes(): Promise<Rede[]> {
	return Axios.get(`${API_URL}/redes`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function getRedesAdmin(): Promise<Rede[]> {
	return Axios.get(`${API_URL}/redes/admin`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function salvarRede(rede: Rede): Promise<any> {
	return Axios.post(`${API_URL}/redes`, { ...rede }, getJWTHeaderConfig())
		.then((res) => res.data);
}export function atualizarRede(rede: any): Promise<any> {
	return Axios.put(`${API_URL}/redes`, rede, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function deletarRedeById(id): Promise<any> {
	return Axios.delete(`${API_URL}/redes/${id}`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function getRedeById(id): Promise<Hospital> {
	return Axios.get(`${API_URL}/redes/${id}`, getJWTHeaderConfig())
		.then((res) => res.data);
}
//------------------INIT CRUD COLETAS---------------------
//------------------INIT C - CREATE COLETAS---------------
export function salvarDadosInformacoesGerais(dadosColeta) {
	const config = getJWTHeaderConfig();
	return Axios.post(API_URL + '/informacoes-gerais', dadosColeta, {
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	});
}
export function salvarDadosEventosAdversos(dadosColeta) {
	const config = getJWTHeaderConfig();
	return Axios.post(API_URL + '/eventos-adversos', dadosColeta, {
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	});
}
export function salvarDadosChecklist(dadosColeta) {
	const config = getJWTHeaderConfig();
	return Axios.post(API_URL + '/checklist', dadosColeta, {
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	});
}
export function salvarDadosBoasPraticas(dadosColeta) {
	const config = getJWTHeaderConfig();
	return Axios.post(API_URL + '/boaspraticas', dadosColeta, {
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	});
}

//obterDadosUsuarios, deletarUsuariosById
//------------------INIT R - READ COLETAS-----------------
export function obterDadosEventosAdversos(hospitalId): Promise<DadosColetaEventosAdversos[]> {
	let config = getJWTHeaderConfig();
	config.headers['Content-Type'] = 'application/json';
	return Axios.get(API_URL + '/eventos-adversos/hospital/' + hospitalId, config).then((res) => res.data);
}
export function obterDadosChecklist(hospitalId): Promise<DadosColetaChecklist[]> {
	let config = getJWTHeaderConfig();
	config.headers['Content-Type'] = 'application/json';
	return Axios.get(API_URL + '/checklist/hospital/' + hospitalId, config).then((res) => res.data);
}
export function obterDadosChecklistFiltrado(
	hospitalId: number,
	dataInicio: string,
	dataFim: string
): Promise<DadosColetaChecklist[]> {
	const config = getJWTHeaderConfig();
	return Axios.get(API_URL + '/checklist/filtrado/' + hospitalId, {
		params: {
			dataInicio,
			dataFim
		},
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	}).then((res) => res.data);
}
export function obterDadosUsuarios(): Promise<Usuario[]> {
	return Axios.get(`${API_URL}/usuario/listar`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function getInformacoesGerais(coletaId): Promise<DadosColetaBoasPraticas> {
	return Axios.get(`${API_URL}/informacoes-gerais/`+ coletaId, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function getBoasPraticas(coletaId): Promise<DadosColetaBoasPraticas> {
	return Axios.get(`${API_URL}/boaspraticas/`+ coletaId, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function getChecklist(coletaId): Promise<DadosColetaBoasPraticas> {
	return Axios.get(`${API_URL}/checklist/`+ coletaId, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function getEventosAdversos(coletaId): Promise<DadosColetaBoasPraticas> {
	return Axios.get(`${API_URL}/eventos-adversos/`+ coletaId, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function obterDadosBoasPraticas(hospitalId): Promise<DadosColetaBoasPraticas[]> {
	let config = getJWTHeaderConfig();
	config.headers['Content-Type'] = 'application/json';
	return Axios.get(API_URL + '/boaspraticas/hospital/' + hospitalId, config).then((res) => res.data);
}
export function obterDadosInformacoesGerais(hospitalId): Promise<DadosAgregadosColetaInformacoesGerais[]> {
	let config = getJWTHeaderConfig();
	config.headers['Content-Type'] = 'application/json';
	return Axios.get(API_URL + '/informacoes-gerais/hospital/' + hospitalId, config).then((res) => res.data);
}
export function obterDadosBoasPraticasFiltrado(
	hospitalId: number,
	dataInicio: string,
	dataFim: string
): Promise<DadosColetaBoasPraticas[]> {
	const config = getJWTHeaderConfig();
	return Axios.get(API_URL + '/boaspraticas/filtrado/' + hospitalId, {
		params: {
			dataInicio,
			dataFim
		},
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	}).then((res) => res.data);
}
export function obterDadosEventosAdversosFiltrado(
	hospitalId: number,
	dataInicio: string,
	dataFim: string
): Promise<DadosColetaEventosAdversos[]> {
	const config = getJWTHeaderConfig();
	return Axios.get(API_URL + '/eventos-adversos/filtrado/' + hospitalId, {
		params: {
			dataInicio,
			dataFim
		},
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	}).then((res) => res.data);
}
export function obterDadosEventosAdversosProntuario(
	prontuarioId: number,
	hospitalId: number,
	dataParto: string
): Promise<number> {
	const config = getJWTHeaderConfig();
	return Axios.get(API_URL + '/eventos-adversos/prontuario/' + prontuarioId, {
		params: {
			hospitalId,
			dataParto
		},
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	}).then((res) => res.data);
}
export function verificaPartoGemelar(
	prontuarioId: number,
	hospitalId: number,
	dataParto: string
): Promise<number> {
	const config = getJWTHeaderConfig();
	return Axios.get(API_URL + '/eventos-adversos/parto-gemelar/' + prontuarioId, {
		params: {
			hospitalId,
			dataParto
		},
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	}).then((res) => res.data);
}
export function verificaPartoGemelarInfoGerais(
	prontuarioId: number,
	hospitalId: number,
	dataParto: string
): Promise<number> {
	const config = getJWTHeaderConfig();
	return Axios.get(API_URL + '/informacoes-gerais/parto-gemelar/' + prontuarioId, {
		params: {
			hospitalId,
			dataParto
		},
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	}).then((res) => res.data);
}
export function obterDadosBoasPraticasProntuario(
	prontuarioId: number,
	hospitalId: number,
	dataParto: string
): Promise<number> {
	const config = getJWTHeaderConfig();
	return Axios.get(API_URL + '/boaspraticas/prontuario/' + prontuarioId, {
		params: {
			hospitalId,
			dataParto
		},
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	}).then((res) => res.data);
}
export function obterColetaContinuar(
	prontuarioId: number,
	hospitalId: number,
	dataParto: string,
	endpoint: string
): Promise<DadosColetaBoasPraticas[]> {
	const config = getJWTHeaderConfig();
	return Axios.get(API_URL + endpoint + prontuarioId, {
		params: {
			hospitalId,
			dataParto
		},
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	}).then((res) => res.data);
}
export function obterDadosChecklistProntuario(
	prontuarioId: number,
	hospitalId: number,
	dataParto: string
): Promise<number> {
	const config = getJWTHeaderConfig();
	return Axios.get(API_URL + '/checklist/prontuario/' + prontuarioId, {
		params: {
			hospitalId,
			dataParto
		},
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	}).then((res) => res.data);
}

//------------------INIT U - UPDATE COLETAS-----------------
export function atualizarDadosInformacoesGerais(dadosColeta) {
	const config = getJWTHeaderConfig();
	return Axios.put(API_URL + '/informacoes-gerais/atualizar', dadosColeta, {
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	});
}
export function atualizarDadosBoasPraticas(dadosColeta) {
	const config = getJWTHeaderConfig();
	return Axios.put(API_URL + '/boaspraticas/atualizar', dadosColeta, {
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	});
}
export function atualizarDadosChecklist(dadosColeta) {
	const config = getJWTHeaderConfig();
	return Axios.put(API_URL + '/checklist/atualizar', dadosColeta, {
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	});
}
export function atualizarDadosEventosAdversos(dadosColeta) {
	const config = getJWTHeaderConfig();
	return Axios.put(API_URL + '/eventos-adversos/atualizar', dadosColeta, {
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	});
}
//------------------INIT D - DELETE COLETAS-----------------
export function deletarInformacoesGeraisById(id): Promise<any> {
	return Axios.delete(`${API_URL}/informacoes-gerais/${id}`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function deletarBoasPraticasById(id): Promise<any> {
	return Axios.delete(`${API_URL}/boaspraticas/${id}`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function deletarChecklistById(id): Promise<any> {
	return Axios.delete(`${API_URL}/checklist/${id}`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function deletarEventosAdversosById(id): Promise<any> {
	return Axios.delete(`${API_URL}/eventos-adversos/${id}`, getJWTHeaderConfig())
		.then((res) => res.data);
}
export function deletarUsuarioById(id): Promise<any> {
	return Axios.delete(`${API_URL}/usuario/${id}`, getJWTHeaderConfig())
		.then((res) => res.data);
}
//------------------END CRUD COLETAS------------------------
interface RespostaLogin {
	logado: boolean;
	erro?: string;
};
//------------------INIT LOGIN USER-------------------------
export async function logar(email: string, senha: string, manterLogado: boolean): Promise<RespostaLogin> {
	let r = await Axios.post(
		API_URL + '/login',
		{
			'username': email,
			'password': senha
		},
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	if (r.status == 200) {
		let tk = r.data.token as string;
		if (manterLogado)
			setCookie('token', tk, 3); // 3 dias
		else
			setCookie('token', tk, 0); // não manter logado
		return { logado: true };
	}

	return {
		logado: false,
		erro: "Erro desconhecido"
	};
}

export async function cadastrar(nome: string, email: string, senha: string): Promise<Boolean> {

	let r = await Axios.post(
		API_URL + '/registrar',
		{
			'nome': nome,
			'email': email,
			'senha': senha
		},
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	);

	if (r.status == 200)
		return true
	return false
}

export async function resetNovaSenha(senha: string, token: string) {

	try {
		let r = await Axios.post(
			API_URL + '/reset_password',
			{
				'token': token,
				'senha': senha
			},
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}
		);
		if (r.status == 200)
			return r.data
	} catch (e) {
		
		let a: string = String(e);
		
		if (a.indexOf("500") != -1) {			
			return 500;
		} else if (a.indexOf("400") != -1) {			
			return 400;
		}
	}

}

export async function forgotpassw(email: string) {

	try {
		const r = await Axios.post(
			API_URL + '/forgot_password', {
			"email": email
		},
			{
				headers: {
					'Content-Type': 'application/json'
				}
			}

		);
		return r.data
	} catch (e) {
		
		let a: string = String(e);
		
		if (a.indexOf("500") != -1) {			
			return 500;
		} else if (a.indexOf("400") != -1) {
			return 400;
		}

	}
}

export async function getValidacaoEmailLink(token: String) {

	try {
		const r = await Axios.get(`${API_URL}/reset_password?token=${token}`);		
		return r.data
	} catch (e) {
		
		let a: string = String(e);		

		if (a.indexOf("500") != -1) {			
			return 500;
		} else if (a.indexOf("400") != -1) {			
			return 400;
		} else if (a.indexOf("Error: Network Error") != -1){
			return 100;

		}

	}

}
//------------------END LOGIN,CREATE,RESET USER------------------

//------------------INIT ADMIN USER FUNCTIONS--------------------
//FALTA REMOVER USUARIOS
export function logout(): Promise<boolean> {
	return new Promise((resolve, reject) => {
		deleteCookie('token');
		resolve(true);
	});
}

export async function getUsuario(): Promise<Usuario> {
	const r = await Axios.get(API_URL + '/login', getJWTHeaderConfig());
	return r.data as Usuario;
}
export async function obterDadosUsuario(): Promise<Usuario> {
	const r = await Axios.get(API_URL + '/login', getJWTHeaderConfig());
	return r.data as Usuario;
}
export async function getUsuarioById(id: Number): Promise<Usuario> {
	try {
		const r = await Axios.get(API_URL + '/usuario/editar/' + id, getJWTHeaderConfig());
		return r.data as Usuario;
	} catch (err) {
		Swal('Falha', err.response.data.message, 'error');
	}
}
export function atualizarTipoUsuario(dadosUsuario) {
	const config = getJWTHeaderConfig();
	return Axios.put(API_URL + '/usuario/atualizar', dadosUsuario, {
		headers: {
			'Content-Type': 'application/json',
			...config.headers
		}
	});
}
export async function getUsuarioByEmail(email: string): Promise<Usuario> {
	try {
		const r = await Axios.get(API_URL + '/usuario?email=' + email, getJWTHeaderConfig());
		return r.data as Usuario;
	} catch (err) {
		Swal('Falha', err.response.data.message, 'error');
	}
}

interface nomeesenha {
	nome?: string;
	senha?: string;
};

export async function atualizarUsuario(alteracoes: nomeesenha): Promise<Usuario> {
	const r = await Axios.put(`${API_URL}/usuario`, alteracoes, getJWTHeaderConfig());
	if (r.status >= 200 && r.status < 300)
		return r.data as Usuario
	throw Error(`Falha ao atualizar (${r.data})`);
}

export async function autorizarUsuario(idUser: number, idHospital: number): Promise<boolean> {
	let r = await Axios.post(
		API_URL + '/autorizacao',
		{
			'usuarioId': idUser,
			'hospitalId': idHospital,
		},
		{
			headers: {
				'Content-Type': 'application/json',
				...(getJWTHeaderConfig().headers)
			}
		}
	);
	if (r.status >= 200 && r.status < 300)
		return true
	if (r.status == 404)
		throw Error('Usuário não encontrado, verifique o E-Mail');
	throw Error(`Falha ao autorizar (${r.data})`);
}
export async function vincularHospital(idRede: number, idHospital: number): Promise<boolean> {
	let r = await Axios.post(
		API_URL + '/redehospitais',
		{
			'redeId': idRede,
			'hospitalId': idHospital,
		},
		{
			headers: {
				'Content-Type': 'application/json',
				...(getJWTHeaderConfig().headers)
			}
		}
	);
	if (r.status >= 200 && r.status < 300)
		return true
	if (r.status == 404)
		throw Error("Hospital não encontrado.");
	throw Error(`Falha ao vincular (${r.data})`);
}

export async function removerAutorizacao(id: number): Promise<boolean> {
	let r = await Axios.delete(
		API_URL + '/autorizacao/' + id,
		getJWTHeaderConfig()
	);
	if (r.status >= 200 && r.status < 300)
		return true
	throw Error(`Falha ao remover (${r.data})`);
}
export async function removerVinculos(id: number): Promise<boolean> {
	let r = await Axios.delete(
		API_URL + '/redehospitais/' + id,
		getJWTHeaderConfig()
	);
	if (r.status >= 200 && r.status < 300)
		return true
	throw Error(`Falha ao remover (${r.data})`);
}
export async function getAutorizacoesHospital(id: number): Promise<Autorizacao[]> {
	const r = await Axios.get(`${API_URL}/autorizacao/hospital/${id}`, getJWTHeaderConfig());
	if (r.status >= 200 && r.status < 300)
		return r.data as Autorizacao[]
	throw Error(`Falha ao obter (${r.data})`);
}
export async function getVinculosHospital(id: number): Promise<RedeHospitais[]> {
	const r = await Axios.get(`${API_URL}/redehospitais/${id}`, getJWTHeaderConfig());
	if (r.status >= 200 && r.status < 300)
		return r.data as RedeHospitais[]
	throw Error(`Falha ao obter (${r.data})`);
}
//------------------END ADMIN USER FUNCTIONS--------------------

import { DadosColetaBoasPraticas } from "../modelos";

export function calcularRunchartBoasPraticas(
	dados: DadosColetaBoasPraticas[],
	listKeys: any[]
 ) {
	let n = 0;
	dados.forEach(coleta => {
	  n += contarBoasPraticas(coleta, listKeys);
	});
  
	let total = dados.length * listKeys.length;

	return {
		n,
		p: (n/total)*100
	};
 }
	export function contarBoasPraticas(
		coleta: DadosColetaBoasPraticas,
		listaChaves: string[]
	): number {
	  let n = 0;

	  listaChaves.forEach(chave => {
			if(coleta[chave] === 'Sim' || coleta[chave] === true) {
				n++;
			}
	  });

	  return n;
	}

	export function calcularRunchartEspecificoBoasPraticas(
		dados: DadosColetaBoasPraticas[],
		listKeys: any[]
	 ) {
		let n = 0;
		dados.forEach(coleta => {
			n += contarBoasPraticasEspecificas(coleta, listKeys);
		});
		
		let total = dados.length * listKeys.length;
	
		return {
			n,
			p: (n/total)*100
		};
	 }

	 export function contarBoasPraticasEspecificas(
		coleta: DadosColetaBoasPraticas,
		listaChaves: string[]
	): number {
	  let n = 0;

	  listaChaves.forEach(chave => {
			if(coleta[chave] === 'Sim' || coleta[chave] === true) {
				n++;
			}
	  });

	  return n;
	}

	export function preprocessarParaXLSX(source: any[]) {
		return source.map(objeto => {
			const keys = Object.keys(objeto);
			keys.forEach(chave => {
				if(typeof(objeto[chave]) == 'boolean')
					objeto[chave] = objeto[chave] ? "SIM" : "NÃO";
			});

			objeto.dataColeta = objeto.dataColeta.split('-').reverse().join('-'),
			objeto.iniciouPartograma = objeto.iniciouPartograma.split('-').reverse().join('-'),
			objeto.presencaAcompanhante = objeto.presencaAcompanhante,
			objeto.administradaOcitocina = objeto.administradaOcitocina,
			objeto.administradaVitaminaK = objeto.administradaVitaminaK,
			objeto.rnIniciouAmamentacaoNa1Hora = objeto.rnIniciouAmamentacaoNa1Hora.split('-').reverse().join('-'),
			objeto.clampeamentoOportuno = objeto.clampeamentoOportuno.split('-').reverse().join('-'),
			objeto.iniciouContatoPeleaPela = objeto.iniciouContatoPeleaPela.split('-').reverse().join('-'),
			objeto.identificacaoRnPulseira = objeto.identificacaoRnPulseira,
			objeto.hospital = objeto.hospital.nome
	
			return objeto;
		});
	}

  export function processarBoasPraticasGeral(prontuarios: DadosColetaBoasPraticas[]) {
	const dados = [
		  {
			  label: 'Iniciou Partograma',
			  n: prontuarios.filter(p => p.iniciouPartograma === 'Sim').length,
			  p: ((prontuarios.filter(p => p.iniciouPartograma === 'Sim').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Teve presença de Acompanhante',
			  n: prontuarios.filter(p => p.presencaAcompanhante === true).length,
			  p: ((prontuarios.filter(p => p.presencaAcompanhante === true).length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Clampeamento Oportuno',
			  n: prontuarios.filter(p => p.clampeamentoOportuno === 'Sim').length,
			  p: ((prontuarios.filter(p => p.clampeamentoOportuno === 'Sim').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Iniciou contato pele a pele',
			  n: prontuarios.filter(p => p.iniciouContatoPeleaPela === 'Sim').length,
			  p: ((prontuarios.filter(p => p.iniciouContatoPeleaPela === 'Sim').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Recém-nascido iniciou amamentação na primeira hora',
			  n: prontuarios.filter(p => p.rnIniciouAmamentacaoNa1Hora === 'Sim').length,
			  p: ((prontuarios.filter(p => p.rnIniciouAmamentacaoNa1Hora === 'Sim').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Vitamina K administrada',
			  n: prontuarios.filter(p => p.administradaVitaminaK === true).length,
			  p: ((prontuarios.filter(p => p.administradaVitaminaK === true).length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Identificação do recém-nascido com pulseira',
			  n: prontuarios.filter(p => p.identificacaoRnPulseira === true).length,
			  p: ((prontuarios.filter(p => p.identificacaoRnPulseira === true).length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Administrada Ocitocina',
			  n: prontuarios.filter(p => p.administradaOcitocina === true).length,
			  p: ((prontuarios.filter(p => p.administradaOcitocina === true).length/prontuarios.length)*100).toFixed(1)+'%'
		  }
	];

	return dados.sort((a, b) => b.n-a.n);
}

export function processarBoasPraticasMae(prontuarios: DadosColetaBoasPraticas[]) {
	const dados = [
		{
			label: 'Iniciou Partograma',
			n: prontuarios.filter(p => p.iniciouPartograma === 'Sim').length,
			p: ((prontuarios.filter(p => p.iniciouPartograma === 'Sim').length/prontuarios.length)*100).toFixed(1)+'%'
		},
		{
			label: 'Teve presença de Acompanhante',
			n: prontuarios.filter(p => p.presencaAcompanhante === true).length,
			p: ((prontuarios.filter(p => p.presencaAcompanhante === true).length/prontuarios.length)*100).toFixed(1)+'%'
		},
		{
			label: 'Administrada Ocitocina',
			n: prontuarios.filter(p => p.administradaOcitocina === true).length,
			p: ((prontuarios.filter(p => p.administradaOcitocina === true).length/prontuarios.length)*100).toFixed(1)+'%'
		}
	];

	return dados.sort((a, b) => b.n-a.n);
}

export function processarBoasPraticasRn(prontuarios: DadosColetaBoasPraticas[]) {
	const dados = [
		  {
			  label: 'Clampeamento Oportuno do cordão umbilical',
			  n: prontuarios.filter(p => p.clampeamentoOportuno === 'Sim').length,
			  p: ((prontuarios.filter(p => p.clampeamentoOportuno === 'Sim').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Iniciou contato pele a pele',
			  n: prontuarios.filter(p => p.iniciouContatoPeleaPela === 'Sim').length,
			  p: ((prontuarios.filter(p => p.iniciouContatoPeleaPela === 'Sim').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Recém-nascido iniciou amamentação após o parto',
			  n: prontuarios.filter(p => p.rnIniciouAmamentacaoNa1Hora === 'Sim').length,
			  p: ((prontuarios.filter(p => p.rnIniciouAmamentacaoNa1Hora === 'Sim').length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Vitamina K',
			  n: prontuarios.filter(p => p.administradaVitaminaK === true).length,
			  p: ((prontuarios.filter(p => p.administradaVitaminaK === true).length/prontuarios.length)*100).toFixed(1)+'%'
		  },
		  {
			  label: 'Identificação do recém-nascido com pulseira',
			  n: prontuarios.filter(p => p.identificacaoRnPulseira === true).length,
			  p: ((prontuarios.filter(p => p.identificacaoRnPulseira === true).length/prontuarios.length)*100).toFixed(1)+'%'
		  }
	];

	return dados.sort((a, b) => b.n-a.n);	
}

export function calcularPreenchimentoFase(dados: DadosColetaBoasPraticas[], momento): number {
  const somatorioBoasPraticas = dados.reduce((p, c) => p+contarBoasPraticas(c, momento), 0);
  return (somatorioBoasPraticas/(dados.length*momento.length))*100;
}

  export const boasPraticasKeys = [
    'iniciouPartograma',
    'presencaAcompanhante',
    'clampeamentoOportuno',
    'iniciouContatoPeleaPela',
    'rnIniciouAmamentacaoNa1Hora',
    'administradaVitaminaK',
    'identificacaoRnPulseira',
    'administradaOcitocina',
];

export const boasPraticasMaeKeys = [
	'iniciouPartograma',
	'presencaAcompanhante',
	'administradaOcitocina',
];

export const boasPraticasRnKeys = [
	'iniciouContatoPeleaPela',
	'rnIniciouAmamentacaoNa1Hora',
	'clampeamentoOportuno',
	'administradaVitaminaK',
	'identificacaoRnPulseira',
];
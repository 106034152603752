import * as React from 'react';
import '../Estilos.css';
import copiaProfunda from '../../utils/copiaProfunda';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import getPerguntasComDados from './PerguntasBoasPraticasComDados';
import { atualizarDadosBoasPraticas, getBoasPraticas } from '../../api/api';
import Swal from 'sweetalert2';

const endDate = new Date();
export default class EditarQuestionarioBoasPraticas extends React.Component<any, any> {
	id = Number(this.props.location.pathname.split('/').slice(-1)[0]);

	constructor(props) {
		super(props);
		this.state = {
			carregando: false,
			perguntas: [],
			mudarTela: null,
			CNES: '',
			coletaBoasPraticas: []
		};
	}

	componentDidMount() {
		this.getBoasPraticasById();
	}

	getBoasPraticasById = async () => {
		this.setState({ carregando: true });
		try {
			const boaspraticas = await getBoasPraticas(this.id);
			const coleta = await getPerguntasComDados(boaspraticas);
			this.setState({
				coletaBoasPraticas: boaspraticas,
				CNES: boaspraticas.hospital.cnes,
				perguntas: coleta,
			});
		} catch (e) {
			//Swal('Falha', e.response.data.message, 'error');
			this.setState({ fetchErro: true });
		} finally {
			this.setState({ carregando: false });
		}
	}

	enviar = () => {
		if (!this.validarQuestionarios()) {
			Swal(
				'Oops',
				'Verifique os campos obrigatórios',
				'error'
			);
			return;
		}
		const dadosColeta = {id: this.state.coletaBoasPraticas.id}
		this.state.perguntas.forEach((pergunta) => {
			let valor = pergunta.value;

			if (pergunta.type === 'radio' && pergunta.options.length === 2) {
				if (pergunta.value !== null) {
					if (pergunta.value === 'Sim')
						valor = true;
					else if (pergunta.value === 'Não')
						valor = false;
					else
						valor = pergunta.value
				}
				else
					valor = null
			} else if (pergunta.type === 'date' && pergunta.value) {
				const d: Date = pergunta.value.toDate()
				valor = `${d.getFullYear()}-${(d.getMonth() + 1) > 9
					? (d.getMonth() + 1)
					: '0' + (d.getMonth() + 1)
					}-${d.getDate() > 9
						? d.getDate()
						: '0' + d.getDate()
					}`;
			} else if(pergunta.type === 'number' && pergunta.value) {
				valor = Number(pergunta.value)
			}

			dadosColeta[pergunta.chave] = valor === 'Não se aplica' ? 'NA' : valor;
		});
		this.setState({ carregando: true });

		atualizarDadosBoasPraticas(dadosColeta)
			.then(() => {
				Swal(
					'Concluído',
					'Sucesso ao atualizar a coleta',
					'success'
				)
					.then(() => window.location.reload());
			})
			.catch((err) => {
				Swal('Falha', err.response.data.message, 'error');
				this.setState({ carregando: false });
		});
	};

	handleChange(valor, identificador) {
		const i = this.identificador2Indice(this.state.perguntas, identificador);
		this.setState((state) => {
			const copia = copiaProfunda(state.perguntas);
			copia[i].value = valor;
			return { perguntas: copia };
		});
	}

	indicesInvalidos = [];
	campoInvalidado(pergunta, identificador): boolean {
		const indice = this.identificador2Indice(this.state.perguntas, identificador);
		if (
			pergunta.obrigatorio
			&&
			this.indicesInvalidos.includes(indice)
		)
			return true;
		else
			return false;
	}

	identificador2Indice(perguntas, identificador) {
		for (let i = 0; i < perguntas.length; i++)
			if (perguntas[i].identificador === identificador)
				return i;
		throw new Error(`Identificador(${identificador}) não encontrado`);
	}

	chave2Indice(perguntas, chave) {
		for (let i = 0; i < perguntas.length; i++)
			if (perguntas[i].chave === chave)
				return i;
		throw new Error(`Chave(${chave}) não encontrada`);
	}

	/*
	  Retorna se o questionário possui campos obrigatórios não preenchidos
	  e registra os indices das perguntas invalidas no 'indicesInvalidos'
	*/
	validarQuestionarios(): boolean {
		this.indicesInvalidos = [];
		this.state.perguntas.forEach((pergunta: any, i: number) => {

			const v = pergunta.value;
			let invalidado = false;

			if (pergunta.obrigatorio) {
				// Se esta pergunta só deve ser exibida de acordo com o valor de outra
				// Mas a outra não atendeu ao valor
				if (
					pergunta.condicao &&
					this.state.perguntas[this.chave2Indice(this.state.perguntas, pergunta.condicao)].value !== pergunta.valorCondicao
				) {
					invalidado = false;
				} else { // Se esta pergunta está na tela
					// Campo obrigatório não preenchido
					if (v === '' || v === null || v === undefined) {
						this.indicesInvalidos.push(i);
						invalidado = true;
					}
				}
			}

			if (!invalidado) { // O Campo não foi invalidado na anterior
				// Se o campo era obrigatório e foi preenchido com algum valor não zero
				if (pergunta.obrigatorio || Number(pergunta.value) !== 0) {
					if (
						pergunta.type === 'number'
						&& typeof pergunta.valorMinimo === 'number'
						&& Number(pergunta.value) < pergunta.valorMinimo
					) { // Valor inválido informado
						this.indicesInvalidos.push(i);
						invalidado = true;
					}
				}
			}

		});
		this.forceUpdate(); // Mostrando no front os campos invalidados
		return this.indicesInvalidos.length === 0;
	}

	render() {
		return (
			<div className="container-fluid mt-4">
				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className="col-md-12">
								<h4 className="text-muted">CNES do Hospital: {this.state.CNES}</h4>
							</div>
						</div>
						{
							this.state.perguntas
								.map(pergunta => this.perguntaToFront(pergunta,this.state.coletaBoasPraticas))
						}
						<div className="row justify-content-center">
							<div className="col-6">
								<button
									disabled={this.state.carregando}
									type="button"
									className="btn btn-primary btn-block"
									onClick={this.enviar}
								>
									{this.state.carregando ? 'Atualizando...' : 'Atualizar'}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	/*
		  Esta função mapeia cada pergunta passada para um conjunto de perguntas no front
	*/
	perguntaToFront = (pergunta,coleta) => {
		if (
			pergunta.condicao &&
			this.state.perguntas[this.chave2Indice(this.state.perguntas, pergunta.condicao)].value !== pergunta.valorCondicao
		) {
			return null;
		}

		if (pergunta.type === 'header') {
			return (
				<div key={pergunta.text}>
					<h4 className="text-center mt-4">{pergunta.text}</h4>
					<hr className="mb-4" />
				</div>
			);
		}
		if (pergunta.type === 'text' || pergunta.type === 'number') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<label>
						<b>({pergunta.identificador})</b> - {pergunta.label}:
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
						{
							pergunta.descricao
								? <a
									className="fas fa-info ml-2"
									data-toggle="collapse"
									title="Saber mais"
									href={"#collapse" + pergunta.identificador}
									role="button"
									aria-expanded="false"
									aria-controls={"collapse" + pergunta.identificador}
								/>
								: null
						}
					</label>
					{
						pergunta.descricao
							? (<div
								className="collapse"
								id={"collapse" + pergunta.identificador}
								style={{
									paddingLeft: '30px',
									marginBottom: '30px'
								}}
							>
								<span
									className="text-muted"
									style={{
										fontSize: '11pt'
									}}
								>
									{pergunta.descricao}
								</span>
							</div>)
							: null
					}
					<input
						type={pergunta.type}
						value={pergunta.value}
						onChange={(e) => this.handleChange(e.target.value, pergunta.identificador)}
						className={
							"form-control"
							+ (this.campoInvalidado(pergunta, pergunta.identificador)
								? " is-invalid"
								: ""
							)
						}
					/>

					<div className="invalid-feedback">
						Campo obrigatório
					</div>
				</div>
			);
		}
		if (pergunta.type === 'date') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<label>
						<b>({pergunta.identificador})</b> - {pergunta.label}
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
						{
							pergunta.descricao
								? <a
									className="fas fa-info ml-2"
									data-toggle="collapse"
									title="Saber mais"
									href={"#collapse" + pergunta.identificador}
									role="button"
									aria-expanded="false"
									aria-controls={"collapse" + pergunta.identificador}
								/>
								: null
						}
					</label>
					<DatePicker
						selected={pergunta.value}
						onChange={(date) => this.handleChange(date, pergunta.identificador)}
						className={
							"form-control datepicker"
							+ (this.campoInvalidado(pergunta, pergunta.identificador)
								? " is-invalid"
								: ""
							)
						}
					/>
					{
						this.campoInvalidado(pergunta, pergunta.identificador)
							? (
								<div className="invalid-feedback d-block">
									Campo obrigatório
								</div>
							)
							: null
					}
					{
						pergunta.descricao
							? (<div
								className="collapse"
								id={"collapse" + pergunta.identificador}
								style={{
									paddingLeft: '30px',
									marginBottom: '30px'
								}}
							>
								<span
									className="text-muted"
									style={{
										fontSize: '11pt'
									}}
								>
									{pergunta.descricao}
								</span>
							</div>)
							: null
					}
				</div>
			);
		}
		if (pergunta.type === 'radio') {
			return (
				<div className="form-group" key={pergunta.identificador}>
					<p
						style={{
							marginBottom: this.campoInvalidado(pergunta, pergunta.identificador) || pergunta.descricao
								? '0px'
								: '1rem'
						}}
					>
						<b>({pergunta.identificador})</b> - {pergunta.label}
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
						{
							pergunta.descricao
								? <a
									className="fas fa-info ml-2"
									data-toggle="collapse"
									title="Saber mais"
									href={"#collapse" + pergunta.identificador}
									role="button"
									aria-expanded="false"
									aria-controls={"collapse" + pergunta.identificador}
								/>
								: null
						}
					</p>
					{
						this.campoInvalidado(pergunta, pergunta.identificador)
							? (
								<div className="invalid-feedback d-block">
									Campo obrigatório
								</div>
							)
							: null
					}
					{
						pergunta.descricao
							? (<div
								className="collapse"
								id={"collapse" + pergunta.identificador}
								style={{
									paddingLeft: '20px',
								}}
							>
								<span
									className="text-muted"
									style={{
										fontSize: '11pt'
									}}
								>
									{pergunta.descricao}
								</span>
							</div>)
							: null
					}
					{pergunta.options.map((p, i) => (
						<button
							key={i}
							type="button"
							className={`btn ${p === pergunta.value ? 'btn-info' : 'btn-outline-info'} mr-2`}
							onClick={(e) => this.handleChange(p, pergunta.identificador)}>
							{p}
						</button>
					))}
				</div>
			);
		}
		if (pergunta.type === 'label') {
			return (
				<h5 className="text-center mt-4" key={pergunta.identificador}>
					{pergunta.label}
				</h5>
			);
		}
		if (pergunta.type === 'textarea') {
			return (
				<div
					className={
						"form-group" +
						(pergunta.identificador.includes('.')
							? ' ml-5'
							: '')
					}
					key={pergunta.identificador}
				>
					<label>
						<b>({pergunta.identificador})</b> - {pergunta.label}:
						{
							pergunta.obrigatorio
								? <b style={{ color: '#138496' }}>*</b>
								: null
						}
					</label>
					<textarea
						rows={5}
						placeholder={pergunta.descricao}
						value={pergunta.value}
						onChange={(e) => this.handleChange(e.target.value, pergunta.identificador)}
						className={
							"form-control"
							+ (this.campoInvalidado(pergunta, pergunta.identificador)
								? " is-invalid"
								: ""
							)
						}
					/>
					<div className="invalid-feedback">
						Campo obrigatório
					</div>
				</div>
			);
		}
		return null;
	};



}

import * as React from 'react';
import PageHeader from '../layout/PageHeader';
import { Link } from 'react-router-dom';
import { getRedes, getRedesAdmin, getUsuario } from '../api/api';
import Loading from '../api/Loading.jsx';
import { Rede } from '../modelos';
import { DateTime } from 'luxon';
import getFetchErroComponent from '../utils/erroCompotent';
import { contarBoasPraticas } from '../utils/calculosBoasPraticas';
import Swal from 'sweetalert2';
import { Usuario } from '../modelos/usuario';

interface State {
  minhasRedes: Rede[];
  outrasRedes: Rede[];
  carregando: boolean;
  loadingErro: boolean;
  tipoUsuario: 'ADMIN' | 'COMUM' | 'ADMINREDE';
  redeId?: number;
}

export default class ListagemRedes extends React.Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      carregando: true,
      loadingErro: false,
      tipoUsuario: 'COMUM',
      minhasRedes: [],
      outrasRedes: []
    };
  }

  componentDidMount() {
    this.getRedes();
    this.getRedesAdmin();
  }
  getRedes = () => {
    this.setState({
      carregando: true,
      loadingErro: false
    });

    getRedes()
      .then((minhasRedes) => {
        this.setState({
          carregando: false,
          loadingErro: false,
          minhasRedes
        });
      })
      .catch(err => {
        if(err.response.status != 404)
          Swal('Falha', err.response.data.message, 'error');
        this.setState({
          carregando: false,
          loadingErro: true
        });
      });
  }

  getRedesAdmin = async () => {
    const usuario = await getUsuario();
    //USUÁRIO ADMIN VER REDES DE OUTROS USUÁRIOS
    if (usuario.tipo == "ADMIN") {
      const redesAdmin = await getRedesAdmin();
      this.setState({
        outrasRedes: redesAdmin
      });
      this.setState({ tipoUsuario: usuario.tipo  });
    }
  }
  render() {
    const linhasMinhasRedes = this.state.minhasRedes.map((rede) => (
      <tr key={rede.id}>
        <td>{rede.nome}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <Link to={"/redes/editar/" + rede.id}>
            <button title="Editar rede" className="btn btn-success btn-sm">
              <i className="far fa-edit"></i>
            </button>
          </Link>
        </td>
      </tr>
    ));

    const tabelaMinhasRedes = (<div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>{linhasMinhasRedes}</tbody>
      </table>
    </div>);

    const conteudoMinhasRedes = (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h3 className="h4 lead">Minhas redes</h3>
              <Link to="/redes/novo">
                <button className="btn btn-sm btn-success">
                  Novo <i className="fas fa-plus" />
                </button>
              </Link>
            </div>
            <div className="card-body">
              {
                this.state.carregando
                  ? <Loading />
                  : (
                    this.state.loadingErro
                      ? getFetchErroComponent(this.getRedes)
                      : tabelaMinhasRedes
                  )
              }
            </div>
          </div>
        </div>
      </div>
    );

    const linhasOutrasRedes = this.state.outrasRedes.map((rede) => (
      <tr key={rede.id}>
        <td>{rede.nome}</td>
      </tr>
    ));

    const tabelaOutrasRedes = (<div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Nome</th>
          </tr>
        </thead>
        <tbody>{linhasOutrasRedes}</tbody>
      </table>
    </div>);

    const conteudoAdmin = (
      <div>
        {conteudoMinhasRedes}
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h3 className="h4 lead">Outras Redes</h3>
              </div>
              <div className="card-body">
                {
                  this.state.carregando
                    ? <Loading />
                    : (
                      this.state.loadingErro
                        ? getFetchErroComponent(this.getRedes)
                        : tabelaOutrasRedes
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const conteudo = this.state.tipoUsuario == 'ADMIN'
      ? conteudoAdmin
      : conteudoMinhasRedes;

    return (
      <div className="content-inner">
        <PageHeader titulo="Coletas" />
        <div className="container-fluid mt-4">
          {conteudo}
        </div>
      </div>
    );
  }

}

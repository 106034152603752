import * as React from 'react';
import { DadosColetaBoasPraticas }  from '../modelos';
import '../utils/estilos.css';
import {
		boasPraticasMaeKeys,
		calcularPreenchimentoFase,
		processarBoasPraticasMae,
	} from '../utils/calculosBoasPraticas';

interface Props {
  dados: DadosColetaBoasPraticas[];
}

export default function TabelaBoasPraticasMae(props: Props) {
const { dados } = props;

return (
	<table style={{fontSize: '11pt', color: 'black'}} className="table table-sm table-striped-reverse">
		<thead>
			<tr className="linha-destaque">
				<th scope="col">Tabela 2 - Descritivo do percentual de cumprimento das BP para a mãe</th>
				<th scope="col">n</th>
				<th scope="col" className="text-center">%</th>
			</tr>
		</thead>
		<thead>
			<tr>
				<th scope="col" colSpan={2}>Percentual de cumprimento das BP no período</th>
				<th scope="col" colSpan={1} className="text-center">
					{calcularPreenchimentoFase(dados, boasPraticasMaeKeys).toFixed(1)+'%'}
				</th>
			</tr>
		</thead>
		<tbody>
			{
				processarBoasPraticasMae(dados).map(
					d =>
					<tr key={d.label}>
						<td>{d.label}</td>
						<td>{d.n}</td>
						<td className="text-center">{d.p}</td>
					</tr>
				)
			}
		</tbody>
   </table>
  );

}

import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface Props {
  id?: string;
  titulo: string;
  series: any[];
}

export default function PieChart(props: Props) {
  return (
    <div style={{ width: '100%' }}>
      <HighchartsReact
        id={props.id}
        highcharts={Highcharts}
        options={{
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 300
          },
          credits: { enabled: false },
          colors: [
            '#7cb5ec',
            '#90ed7d',
            '#f7a35c',
            '#8085e9',
            '#f15c80',
            '#e4d354',
            '#2b908f',
            '#f45b5b',
            '#91e8e1'
          ],
          title: { text: props.titulo },
          tooltip: { enabled: false },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f}% ({point.y})'
              },
              showInLegend: true
            }
          },
		  series: props.series,
		  exporting: {
			filename: props.titulo
		  }
        }}
      />
    </div>
  );
}

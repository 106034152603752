/*
  Os campos 'chave' de cada uma destas perguntas precisam
  ter um mapeamento "um para um" com 'DadosColetaEventosAdversos'
  em /src/modelos/index.ts.
*/
export default [
  { type: 'header', text: 'Identificação do prontuário' },
  {
    identificador: 'P1',
    chave: 'numeroProntuario',
    type: 'text',
    label: 'Número do prontuário',
    value: '',
    obrigatorio: true
  },
  {
    identificador: 'P2',
    chave: 'rnPeso',
    type: 'number',
    label: 'Qual o peso do Recém-nascido ao nascer? (em gramas)',
    value: '',
    obrigatorio: true,
    valorMinimo: 0
  },
  {
    identificador: 'P3',
    chave: 'partoGemelar',
    type: 'radio',
    label: 'Parto Gemelar',
    options: ['Sim', 'Não'],
    obrigatorio: true,
    value: null,
    observacao: "OBS: Se o parto for gemelar, a coleta deve ser preenchida para cada recém-nascido"
  },
  {
    identificador: 'P4',
    chave: 'dataParto',
    type: 'date',
    label: 'Data do parto',
    obrigatorio: true
  },
  {
    identificador: 'P5',
    chave: 'dataInternacao',
    type: 'date',
    label: 'Data de internação da mãe',
    obrigatorio: true
  },
  {
    identificador: 'P6',
    chave: 'dataAltaObito',
    type: 'date',
    label: 'Data da alta/óbito da mãe',
    obrigatorio: true
  },
  {
    identificador: 'P7',
    chave: 'tipoParto',
    type: 'radio',
    label: 'Tipo de parto',
    options: ['Normal', 'Cesárea', 'Normal/fórceps'],
    obrigatorio: true,
    value: 'Normal'
  },

  { type: 'header', text: 'Identificação da mãe' },
  {
    identificador: 'P8',
    chave: 'maeIdadeGestacional',
    type: 'number',
    label: 'Idade gestacional(semanas)',
    value: '',
    obrigatorio: true,
    valorMinimo: 0
  },
  {
    identificador: 'P9',
    chave: 'dataNascimento',
    type: 'date',
    label: 'Data de nascimento da mãe',
    obrigatorio: true
  }
];
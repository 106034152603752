import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './Login';
import Info from './info';
import politicaPrivacidade from './politicaPrivacidade';
import ResetPassword from './ResetPassword';

class AuthRouter extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/sobre" component={Info} />
                    <Route exact path="/politica-de-privacidade" component={politicaPrivacidade} />
                    <Route exact path="/resetpassword" component={ResetPassword} />
					<Route exact path="/*" component={Login} />
                </Switch>
            </Router>
        );
    }
}

export default AuthRouter;
import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HeaderNavbar } from './layout/HeaderNavbar';
import { SideNavbar } from './layout/SideNavbar';
import jqueryTema from './jqueryTema';
import Hospitais from './hospitais/Hospitais';
import Redes from './redes/Redes';
import Questionarios from './questionarios/Questionarios';
import { Usuario } from './modelos/usuario';
import { getUsuario } from './api/api';
import { Provider } from 'unstated';
import { AppStore } from './AppStore';
import ListagemHospitais from './pages/ListagemHospitais';
import ListagemRedes from './pages/ListagemRedes';
import ListagemInformacoesGerais from './pages/ListagemInformacoesGerais';
import ListagemBoasPraticas from './pages/ListagemBoasPraticas';
import ListagemCheckList from './pages/ListagemChecklist';
import ListagemEventosAdversos from './pages/ListagemEventosAdversos';
import ListagemUsuarios from './pages/ListagemUsuarios';
import PageNotFount from "./pages/PageNotFound";
import Inicio from "./dashboard/Inicio";
import RelatorioHospital from "./relatorios/RelatorioHospital";
import EditarConta from './pages/EditarConta';
import EditarUsuarios from './pages/EditarUsuarios';
import Autorizacoes from './pages/Autorizacoes';
import AutorizacoesRede from './pages/AutorizacoesRede';
import Footer from './layout/Footer';
import Info from './pages/Info';
import Sobre from './login/info';
import PoliticaPrivacidade from './login/politicaPrivacidade';
import RelatorioHospitalChecklist from './relatorios/RelatorioChecklist';
import RelatorioHospitalBoasPraticas from './relatorios/RelatorioBoasPraticas';
import EditarQuestionarioBoasPraticas from './questionarios/BoasPraticas/EditarQuestionarioBoasPraticas';
import EditarQuestionarioChecklist from "./questionarios/Checklist/EditarQuestionarioChecklist";
import EditarQuestionarioEventosAdversos from './questionarios/EventosAdversos/EditarQuestionarioEventosAdversos';
import EditarInformacoesGerais from './questionarios/InformacoesGerais/EditarInformacoesGerais';

interface Props {
  usuario: Usuario;
}

interface State {
  usuario: Usuario;
}

export default class App extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      usuario: props.usuario
    };
  }

  componentDidMount() {
    jqueryTema();
    getUsuario()
      .then((usuario) => {
        this.setState(
          {
            usuario: {
              ...this.props.usuario,
              ...usuario
            }
          }
        );
      })
      .catch();
  }

  render() {
    let store = new AppStore(this.state.usuario);
    return (
      <Provider inject={[store]}>
        <Router>
          <Switch>
            <Route exact path="/sobre" component={Sobre} />
            <Route exact path="/politica-de-privacidade" component={PoliticaPrivacidade} />
            <Route exact path="/*" component={() =>
                      <div>
                        <HeaderNavbar usuario={this.state.usuario} />
                        <div className="page-content d-flex align-items-stretch">
                          <SideNavbar usuario={this.state.usuario} />
                          <Switch>
                            <Route exact path="/" component={Inicio} />
                            <Route path="/info" component={Info} />
                            <Route path="/hospitais" component={Hospitais} />
                            <Route path="/redes" component={Redes} />
                            <Route path="/questionarios" component={Questionarios} />
                            <Route path="/conta" component={EditarConta} />
                            <Route path="/autorizacoes" component={Autorizacoes} />
                            <Route path="/vincular-hospital" component={AutorizacoesRede} />
                            <Route path="/relatorio/*" component={RelatorioHospital} />
                            <Route path="/relatorioChecklist/*" component={RelatorioHospitalChecklist} />
                            <Route path="/relatorioBoasPraticas/*" component={RelatorioHospitalBoasPraticas} />
                            <Route exact path="/relatorios/hospitais" component={ListagemHospitais} />
                            <Route exact path="/relatorios/redes" component={ListagemRedes} />
                            <Route exact path="/relatorios/informacoes-gerais/*" component={ListagemInformacoesGerais} />
                            <Route exact path="/relatorios/boaspraticas/*" component={ListagemBoasPraticas} />
                            <Route exact path="/relatorios/adesao-checklist/*" component={ListagemCheckList} />
                            <Route exact path="/relatorios/eventos-adversos/*" component={ListagemEventosAdversos} />
                            <Route exact path="/usuario/listar" component={ListagemUsuarios} />
                            <Route exact path="/usuario/editar/*" component={EditarUsuarios} />
                            <Route exact path="/informacoes-gerais/editar/*" component={EditarInformacoesGerais} />
                            <Route exact path="/boaspraticas/editar/*" component={EditarQuestionarioBoasPraticas} />
                            <Route exact path="/checklist/editar/*" component={EditarQuestionarioChecklist} />
                            <Route exact path="/eventos-adversos/editar/*" component={EditarQuestionarioEventosAdversos} />
                            <Route exact path="/*" component={PageNotFount} />
                          </Switch>
                        </div>
                      <Footer />
                    </div>
            } />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

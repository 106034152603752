import React from 'react';
import loading from './loading.svg';

export default function Loading() {
  return (
    <div className="row justify-content-center">
      <img src={loading} width="80" height="80" alt="Imagem de carregamento" />
    </div>
  );
}

import * as React from 'react';
import DatePicker from 'react-datepicker';
import Card from '../layout/Card';
import GradientCard from '../layout/GradientCard';
import Loading from '../api/Loading.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { obterDadosBoasPraticas, obterDadosBoasPraticasFiltrado } from '../api/api';
import {
	Hospital,
	DadosColetaBoasPraticas,
	LabelMomentosBp,
} from '../modelos';
import RunChart from '../graficos/RunChart';
import PieChart from '../graficos/PieChart';
import Histograma from '../graficos/Histograma';
import { processarDadosTipoParto } from '../utils/ProcessarDadosBoasPraticas';
import { processarSerie, categorizarEmMeses } from '../utils/calculos_IRA_EPRA_IG';
import { numero2MesAbreviado } from '../utils';
import arrayObjectsToCSV from '../utils/arrayObjectsToCSV';
import * as XLSX from 'xlsx'
import { preprocessarParaXLSX } from '../utils/calculosBoasPraticas';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import {
	calcularRunchartBoasPraticas,
	calcularRunchartEspecificoBoasPraticas,
	boasPraticasKeys,
	boasPraticasMaeKeys,
	boasPraticasRnKeys
} from '../utils/calculosBoasPraticas';
import TabelaBoasPraticas from '../graficos/TabelaBoasPraticas';
import TabelaBoasPraticasMae from '../graficos/TabelaBoasPraticasMae';
import TabelaBoasPraticasRn from '../graficos/TabelaBoasPraticasRn';
import { CloseButton } from 'react-toastify/dist/components';

interface Props {
	hospital: Hospital;
	onClose: Function;
}

interface State {
	dadosColetas: DadosColetaBoasPraticas[] | null;
	dataInicio: moment.Moment;
	dataFim: moment.Moment;
	bpSelecionados: String[];
}

export default class RelatorioResumidoBoasPraticas extends React.Component<Props, State> {
	dataInicioConsultada = null;
	dataFimConsultada = null;

	constructor(props: Props) {
		super(props);
		this.state = {
			dataInicio: moment(props.hospital.dataCadastro),
			dataFim: moment(props.hospital.dataUltimaColetaEventosAdversos),
			dadosColetas: null,
			bpSelecionados: []
		};

		this.dataInicioConsultada = moment(props.hospital.dataCadastro).toDate();
		this.dataFimConsultada = moment(props.hospital.dataUltimaColetaEventosAdversos).toDate();
	}

	componentDidMount() {
		this.consultarRelatorioCompleto();
	}

	consultarRelatorio = (dataInicio: string, dataFim: string) => {
		const { id } = this.props.hospital;
		obterDadosBoasPraticasFiltrado(id, dataInicio, dataFim).then((dadosColetas) => {
			this.setState({ dadosColetas: dadosColetas }, () => {
				this.dataInicioConsultada = moment(dataInicio).toDate();
				this.dataFimConsultada = moment(dataFim).toDate();
				this.forceUpdate();
			});
		});
	};

	consultarRelatorioCompleto = () => {
		const { id } = this.props.hospital;
		obterDadosBoasPraticas(id).then((dadosColetas) => {
			const faixaDeTempo = this.getColetaFaixaPartos(dadosColetas);
			this.dataInicioConsultada = faixaDeTempo.primeira.toDate();
			this.dataFimConsultada = faixaDeTempo.ultima.toDate();
			this.setState({
				dadosColetas: dadosColetas,
				dataInicio: faixaDeTempo.primeira,
				dataFim: faixaDeTempo.ultima
			});
		});
	};

	getColetaFaixaPartos(dados: DadosColetaBoasPraticas[]) {
		const sorted = dados.slice(0).sort((data1, data2) => {
			return DateTime.fromISO(data1.dataParto).toMillis() - DateTime.fromISO(data2.dataParto).toMillis();
		});
		return {
			primeira: moment(sorted[0] ? sorted[0].dataParto : null),
			ultima: moment(sorted[0] ? sorted[sorted.length - 1].dataParto : null)
		};
	}

	consultarDadosCSV = (hospitalId) => {
		obterDadosBoasPraticas(hospitalId).then((dadosColetas) => {
			const dadosPreProcessados: any[] = dadosColetas.map(dado => ({
				dataColeta: dado.dataColeta.split('-').reverse().join('-'),
				iniciouPartograma: dado.iniciouPartograma.split('-').reverse().join('-'),
				presencaAcompanhante: dado.presencaAcompanhante,
				administradaOcitocina: dado.administradaOcitocina,
				administradaVitaminaK: dado.administradaVitaminaK,
				rnIniciouAmamentacaoNa1Hora: dado.rnIniciouAmamentacaoNa1Hora.split('-').reverse().join('-'),
				clampeamentoOportuno: dado.clampeamentoOportuno.split('-').reverse().join('-'),
				iniciouContatoPeleaPela: dado.iniciouContatoPeleaPela.split('-').reverse().join('-'),
				identificacaoRnPulseira: dado.identificacaoRnPulseira,
				hospital: dado.hospital.nome
			}));
			const data = arrayObjectsToCSV(dadosPreProcessados);
			const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
			saveAs(blob, dadosPreProcessados[0].hospital + '-boas-praticas.csv');
		});
	};

	consultarDadosXLSX = (hospitalId) => {
		obterDadosBoasPraticas(hospitalId).then((dadosColetas) => {
			const processado = preprocessarParaXLSX(dadosColetas);
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(processado);
			XLSX.utils.book_append_sheet(wb, ws, 'boas-praticas');
			XLSX.writeFile(wb, processado[0].hospital + '-boas-praticas.xlsx');
		});
	};

	render() {
		const { dadosColetas } = this.state;

		if (!dadosColetas)
			return <Loading />;
		if (dadosColetas.length === 0)
			return (<h5>Nenhuma informação inserida no banco de dados</h5>);

		let runchartBoasPraticasGeral = [];
		if (this.state.dadosColetas.length > 0)
			runchartBoasPraticasGeral = processarSerie(categorizarEmMeses(this.state.dadosColetas, 'dataParto'), boasPraticas => calcularRunchartBoasPraticas(boasPraticas, boasPraticasKeys));

		let runchartBoasPraticasMae = [];
		if (this.state.dadosColetas.length > 0)
			runchartBoasPraticasMae = processarSerie(categorizarEmMeses(this.state.dadosColetas, 'dataParto'), boasPraticas => calcularRunchartBoasPraticas(boasPraticas, boasPraticasMaeKeys));

		let runchartBoasPraticasRn = [];
		if (this.state.dadosColetas.length > 0)
			runchartBoasPraticasRn = processarSerie(categorizarEmMeses(this.state.dadosColetas, 'dataParto'), boasPraticas => calcularRunchartBoasPraticas(boasPraticas, boasPraticasRnKeys));

		return (
			<Card titulo={"Relatório de Boas Práticas da instituição " + this.props.hospital.nome} onClose={this.props.onClose}>
				<h5 className="text-center">Período de consulta</h5>
				<div className="row justify-content-center">
					<div className="col-4">
						<div className="form-group d-flex justify-content-end align-items-end">
							<label>Início</label>
							<DatePicker
								className="form-control datepicker"
								selected={this.state.dataInicio}
								selectsStart
								startDate={this.state.dataInicio}
								endDate={this.state.dataFim}
								onChange={(data) => this.setState({ dataInicio: data })}
							/>
						</div>
					</div>
					<div className="col-4">
						<div className="form-group d-flex justify-content-space-between align-items-end">
							<label>Fim</label>
							<DatePicker
								className="form-control datepicker"
								selected={this.state.dataFim}
								selectsEnd
								startDate={this.state.dataInicio}
								endDate={this.state.dataFim}
								onChange={(data) => this.setState({ dataFim: data })}
							/>

							<button
								type="button"
								className="btn btn-success"
								onClick={() =>
									this.consultarRelatorio(this.state.dataInicio.toISOString(), this.state.dataFim.toISOString())
								}>
								Consultar
							</button>
						</div>
					</div>
					<div className="col-4">
						<div className="btn-group">
							<button className="btn btn-info btn-sm dropdown" type="button" style={{ display: 'inline-block' }}>
								<span className="dropdown-toggle" data-toggle="dropdown">
									Exportar
								</span>
								<div className="dropdown-menu">
									<a className="dropdown-item" href="#" onClick={() => this.consultarDadosCSV(this.props.hospital.id)}>
										<i className="fas fa-table"> </i> CSV
									</a>
									<a className="dropdown-item" href="#" onClick={() => this.consultarDadosXLSX(this.props.hospital.id)}>
										<i className="fas fa-file-excel"> </i> XLSX
									</a>
								</div>
							</button>
							<a
								href={
									"/relatorioBoasPraticas/"
									+ this.props.hospital.id
									+ '?dataInicio=' + this.state.dataInicio.toISOString()
									+ '&dataFim=' + this.state.dataFim.toISOString()
									+ '&bpSelecionados=' + this.state.bpSelecionados
								}
								target="_blank"
							>
								<button className="btn btn-success">
									Gerar PDF
								</button>
							</a>
						</div>
					</div>
				</div>
				<div className="row p-3 mb-4 justify-content-center">
					<div className="col-md-6 mb-4">
						<GradientCard icon="list" estatistica={dadosColetas.length} descricao="Questionários coletados" />
					</div>
				</div>

				<h4
					style={{
						borderBottom: '1px solid gray'
					}}
				>
					1) Caracterização clínica dos partos na instituição {this.props.hospital.nome}, no período de {formatarData(this.dataInicioConsultada)} a {formatarData(this.dataFimConsultada)}, {this.props.hospital.cidade}, {(new Date()).getFullYear()}
				</h4>
				<div className="row">
					<div className="col-md-6 offset-md-3">
						<PieChart
							id="pieChartTipoParto"
							titulo="Tipos de Parto"
							series={processarDadosTipoParto(dadosColetas)}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<Histograma
							arredondarFaixas={true}
							titulo="Histograma peso recém-nascido"
							corBarras="indianred"
							nClasses={3}
							dados={dadosColetas.map(c => c.rnPeso)}
						/>
					</div>
					<div className="col-md-6">
						{<Histograma
							arredondarFaixas={true}
							titulo="Histograma idade gestacional(em semanas)"
							corBarras="indianred"
							nClasses={3}
							dados={dadosColetas.map(c => c.maeIdadeGestacional)}
						/>}
					</div>
				</div>
				<TabelaBoasPraticas dados={dadosColetas} />

				<br /><br />

				<h4
					style={{
						borderBottom: '1px solid gray'
					}}
				>
					2) Gráfico de tendência do nível de cumprimento das
					boas práticas na assistência ao parto
				</h4>

				<div className="row">
					<div className="col-12">
						{
							runchartBoasPraticasGeral.length > 0
								? (<RunChart
									dados={runchartBoasPraticasGeral}
									exportavel={true}
									mostrarLegendas={true}
									titulo='Percentual de cumprimento das boas práticas (geral)'
									nome='mediageral'
									yLabel="Percentual de Boas Práticas Geral"
									corLinha="#07a7e3"
									percentual={true}
								/>)
								: <h4>Nenhuma coleta de boas práticas realizada</h4>
						}
					</div>
				</div>

				<h4
					style={{
						borderBottom: '1px solid gray'
					}}
				>
					2.1) Gráfico de tendência do nível de cumprimento das boas
					práticas maternas.
				</h4>

				<div className="row">
					<div className="col-12">
						{
							runchartBoasPraticasMae.length > 0
								? (<RunChart
									dados={runchartBoasPraticasMae}
									exportavel={true}
									mostrarLegendas={true}
									titulo='Percentual de cumprimento das boas práticas maternas'
									nome='mediamaterna'
									yLabel="Percentual de Boas Práticas maternas"
									corLinha="#07a7e3"
									percentual={true}
								/>)
								: <h4>Nenhuma coleta de boas práticas realizada</h4>
						}
						<TabelaBoasPraticasMae dados={this.state.dadosColetas} />
					</div>
				</div>

				<h4
					style={{
						borderBottom: '1px solid gray'
					}}
				>
					2.2) Gráfico de tendência do nível de cumprimento das boas
					práticas neonatais.
				</h4>

				<div className="row">
					<div className="col-12">
						{
							runchartBoasPraticasRn.length > 0
								? (<RunChart
									dados={runchartBoasPraticasRn}
									exportavel={true}
									mostrarLegendas={true}
									titulo='Percentual de cumprimento das boas práticas neonatais'
									nome='medianeonatais'
									yLabel="Percentual de Boas Práticas neonatais"
									corLinha="#07a7e3"
									percentual={true}
								/>)
								: <h4>Nenhuma coleta de boas práticas realizada</h4>
						}
						<TabelaBoasPraticasRn dados={this.state.dadosColetas} />
					</div>
				</div>
				<div className="form-group">
					<h4
						style={{
							borderBottom: '1px solid gray'
						}}
					>
						2.3) Gráfico de tendência do nível de cumprimento das boas
						práticas:
					</h4>

					{Object.entries(LabelMomentosBp).map(([value, label]) => {
						return <div key={value}>
							<input
								type="checkbox"
								id={value}
								name={value}
								onChange={(bp) => this.atualizaBpSelecionados(bp.target.value, bp.target.checked)}
								value={value}
							/><label className='lrc' htmlFor={value}>{label}</label>
						</div>
					})}
				</div>
				{this.state.bpSelecionados
					.map(bp => this.getRunchartBpEspecifico(bp))
				}
			</Card>
		);
	}

	atualizaBpSelecionados = (bp, check) => {
		if (check) {
			this.setState(prevState => ({
				bpSelecionados: [...prevState.bpSelecionados, bp]
			}))
		} else { this.removeBp(bp); }
	}

	removeBp(e) {
		let arrayFiltrado = this.state.bpSelecionados.filter(item => item !== e)
		this.setState({ bpSelecionados: arrayFiltrado });
	}

	getRunchartBpEspecifico = (qual) => {
		let lista = null;
		switch (qual) {
			case 'iniciouPartograma':
				lista = boasPraticasMaeKeys.map(p => p = 'iniciouPartograma');
				break;
			case 'presencaAcompanhante':
				lista = boasPraticasMaeKeys.map(p => p = 'presencaAcompanhante');
				break;
			case 'administradaOcitocina':
				lista = boasPraticasMaeKeys.map(p => p = 'administradaOcitocina');
				break;
			case 'administradaVitaminaK':
				lista = boasPraticasRnKeys.map(p => p = 'administradaVitaminaK');
				break;
			case 'identificacaoRnPulseira':
				lista = boasPraticasRnKeys.map(p => p = 'identificacaoRnPulseira');
				break;
			case 'rnIniciouAmamentacaoNa1Hora':
				lista = boasPraticasRnKeys.map(p => p = 'rnIniciouAmamentacaoNa1Hora');
				break;
			case 'clampeamentoOportuno':
				lista = boasPraticasRnKeys.map(p => p = 'clampeamentoOportuno');
				break;
			case 'iniciouContatoPeleaPela':
				lista = boasPraticasRnKeys.map(p => p = 'iniciouContatoPeleaPela');
				break;
			default:
				return null;
		}

		return (
			<>
				<RunChart
					key={"runchart" + qual}
					dados={processarSerie(categorizarEmMeses(this.state.dadosColetas, 'dataParto'), boaspraticas => calcularRunchartEspecificoBoasPraticas(boaspraticas, lista))}
					exportavel={true}
					mostrarLegendas={true}
					titulo={LabelMomentosBp[qual]}
					nome={qual}
					yLabel="Percentual"
					corLinha="#07a7e3"
					percentual={true}
				/>
			</>
		);
	};
}

function formatarData(data: Date): String {
	let yyyy = data.getFullYear();
	const mes = numero2MesAbreviado(data.getMonth() + 1);
	return mes + '/' + yyyy;
}

import * as React from 'react';
import { DadosColetaChecklist }  from '../modelos';
import '../utils/estilos.css';
import {
		checklistMomentoAntesExpulsaoKeys,
		calcularPreenchimentoFase,
		processarAntesExpulsaoMae,
		processarAntesExpulsaoRN,
		calcularPreenchimentoFaseAntesExpulsao,
	} from '../utils/calculosChecklist';

interface Props {
  dados: DadosColetaChecklist[];
}

export default function TabelaChecklistAntesExpulsao(props: Props) {
const { dados } = props;

return (
	<table style={{fontSize: '11pt', color: 'black'}} className="table table-sm table-striped-reverse">
		<thead>
			<tr className="linha-destaque">
				<th scope="col">Itens da fase Antes da expulsão</th>
				<th scope="col">n</th>
				<th scope="col" className="text-center">%</th>
			</tr>
		</thead>
		<thead>
			<tr>
				<th scope="col" colSpan={2}>Percentual de preenchimento na fase</th>
				<th scope="col" colSpan={1} className="text-center">
					{calcularPreenchimentoFaseAntesExpulsao(dados).toFixed(1)+'%'}
				</th>
			</tr>
		</thead>
		<thead>
			<tr>
				<th scope="col" colSpan={3} className="text-center">Percentual de preenchimento dos itens relacionados a mãe</th>
			</tr>
		</thead>
		<tbody>
			{
				processarAntesExpulsaoMae(dados).map(
					d =>
					<tr key={d.label}>
						<td>{d.label}</td>
						<td>{d.n}</td>
						<td className="text-center">{d.p}</td>
					</tr>
				)
			}
		</tbody>
		<thead>
			<tr>
				<th scope="col" colSpan={3} className="text-center">Percentual de preenchimento dos itens relacionados ao recém-nascido</th>
			</tr>
		</thead>
		<tbody>
			{
				processarAntesExpulsaoRN(dados).map(
					d =>
					<tr key={d.label}>
						<td>{d.label}</td>
						<td>{d.n}</td>
						<td className="text-center">{d.p}</td>
					</tr>
				)
			}
		</tbody>
   </table>
  );

}

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { getUsuario } from './api/api';
import moment from 'moment';
import 'moment/locale/pt-br';

import * as Highcharts from 'highcharts';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);

import AuthRouter from './login/AuthRouter';

moment.locale('pt-br');

Highcharts.setOptions({
  lang: {
    loading: 'Aguarde...',
    months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    //exportButtonTitle: 'Exportar',
    //printButtonTitle: 'Imprimir',
    rangeSelectorFrom: 'De',
    rangeSelectorTo: 'Até',
    rangeSelectorZoom: 'Período',
    downloadPNG: 'Download imagem PNG',
    downloadJPEG: 'Download imagem JPEG',
    downloadPDF: 'Download documento PDF',
    downloadSVG: 'Download imagem SVG',
    printChart: 'Imprimir gráfico'
  }
});

getUsuario()
			.then(user => {
				ReactDOM.render(<App usuario={user} />, document.getElementById('root'));
			})
			.catch(() => {
				ReactDOM.render(<AuthRouter />, document.getElementById('root'));
			});

/*
  Os campos 'chave' de cada uma destas perguntas precisam
  ter um mapeamento "um para um" com 'DadosColetaEventosAdversos'
  em /src/modelos/index.ts.
*/
export default [
  { type: 'header', text: 'Identificação do prontuário' },
  {
    identificador: 'P1',
    chave: 'numeroProntuario',
    type: 'text',
    label: 'Número do prontuário',
    value: '',
    obrigatorio: true
  },
  {
    identificador: 'P2',
    chave: 'dataParto',
    type: 'date',
    label: 'Data do parto',
    obrigatorio: true
  },
  { type: 'header', text: 'Eventos adversos na mãe' },
  {
    identificador: 'P3',
    chave: 'ocorreuMorteMaterna',
    type: 'radio',
    label: 'Ocorreu morte materna?',
    options: ['Sim', 'Não'],
    obrigatorio: true,
    value: null
  },
  {
    identificador: 'P4',
    chave: 'houveLaceracao34grau',
    type: 'radio',
    label: 'Ocorreu laceração de 3º ou 4º grau?',
    options: ['Sim', 'Não'],
    obrigatorio: true,
    value: null
  },
  {
    identificador: 'P5',
    chave: 'maeRecebeuTransfusaoSangue',
    type: 'radio',
    label: 'A mãe recebeu transfusão sanguínea?',
    options: ['Sim', 'Não'],
    obrigatorio: true,
    value: null
  },
  {
    identificador: 'P6',
    chave: 'houveRupturaUterina',
    type: 'radio',
    label: 'Ocorreu ruptura uterina?',
    options: ['Sim', 'Não'],
    obrigatorio: true,
    value: null
  },
  {
    identificador: 'P7',
    chave: 'reinternacaoMae',
    type: 'radio',
    label: 'Ocorreu retorno à sala de parto ou de cirurgia?',
    options: ['Sim', 'Não'],
    obrigatorio: true,
    value: null
  },
  {
    identificador: 'P8',
    chave: 'maeInternadaUTI',
    type: 'radio',
    label: 'A mãe foi internada em Unidade de Terapia Intensiva?',
    options: ['Sim', 'Não'],
    obrigatorio: true,
    value: null
  },

  { type: 'header', text: 'Complicações e eventos adversos no recém-nascido' },
  {
    identificador: 'P90',
    chave: 'rnMorteNeonatal',
    type: 'radio',
    label: 'Ocorreu morte neonatal?',
    options: ['Sim', 'Não'],
    obrigatorio: true,
    value: null
  },
  {
    identificador: 'P1011',
    chave: 'rnTraumaLesao',
    type: 'radio',
    label: 'O Recém-nascido sofreu algum trauma ou lesão em decorrência do parto?',
    options: ['Sim', 'Não'],
    obrigatorio: true,
    value: null
  },
  {
    identificador: 'P1112',
    chave: 'rnApgarMenor7',
    type: 'radio',
    label: 'Ocorreu Apgar menor que 7 no 5º minuto?',
    options: ['Sim', 'Não'],
    obrigatorio: true,
    value: null
  },
  {
    identificador: 'P1213',
    chave: 'rnInternadoUTIMaisde24h',
    type: 'radio',
    label: 'O Recém-nascido foi internado com peso normal(>2500g) em UTI por mais de 24 horas?',
    options: ['Sim', 'Não'],
    obrigatorio: true,
    value: null
  },

  { type: 'header', text: 'Observações a respeito da coleta' },
  {
    identificador: 'P1314',
    chave: 'observacoes',
    type: 'textarea',
    label: 'Observações(opcional)',
    value: ''
  }
];
import * as React from 'react';
import '../utils/estilos.css';

export interface InfoProps { }

export interface InfoState { }

class Info extends React.Component<InfoProps, InfoState> {
	render() {
		return (
			<div className="page login-page">
				<div className="container d-flex align-items-center">
					<div className="form-holder has-shadow">
						<div className="row">
							<div className="col-12 text-center">
								<div
									className="info"
									style={{
										backgroundColor: 'white',
										color: 'black'
									}}
								>
									<h1 className="text-center" style={{ marginBottom: 10 }}>
										SOBRE A PLATAFORMA QUALIPARTO
									</h1>
									<div className="row">
										<div className="col-12 text-justify">
											<br />
											<b>1. O que é a Plataforma QualiParto?</b>
											<br />
											<p className="textoDescricao">
												Você é gerente ou profissional de saúde de hospitais que prestam assistência ao parto e deseja conhecer a qualidade da assistência oferecida? Esta plataforma lhe ajudará na coleta, monitoramento e análise de indicadores da qualidade do parto, a partir da mensuração do nível de adesão às práticas baseadas em evidências e a ocorrência de eventos adversos. Estes últimos, entendidos como incidentes nos cuidados de saúde que resultam em dano desnecessário ao paciente. A coleta de dados deverá ser feita com consulta aos prontuários médicos e o seu registro em dispositivos móveis ou em um computador da instituição com conexão à internet. Após a coleta, a plataforma irá gerar automaticamente um relatório de avaliação com os indicadores da qualidade do parto de sua instituição.
											</p>
											<b>2. Quem pode utilizar a Plataforma QualiParto?</b>
											<p className="textoDescricao">
												Gestores, profissionais de saúde, profissionais do Núcleo de Segurança do Paciente (NSP) e lideranças com interesse em avaliar a qualidade e segurança da assistência obstétrica em hospitais brasileiros. É preciso que o coordenador da instituição ou o responsável pelo monitoramento insira dados básicos do serviço (nome, CNES, estado e cidade), além dos e-mails dos profissionais que irão realizar a coleta.
											</p>
											<b>3. Qual é o instrumento de avaliação que integra a plataforma?</b>
											<p className="textoDescricao">
												Os indicadores que compõe o Plataforma QualiParto foram construídos e validados em um estudo piloto anterior (<a className="muted-link" href="https://bmcpregnancychildbirth.biomedcentral.com/articles/10.1186/s12884-018-1797-y" target="_blank">Saturno et al, 2018; Sousa, 2020</a>) com base nos itens contidos no Checklist para o Parto Seguro da OMS (<a className="muted-link" href="https://www.who.int/patientsafety/implementation/checklists/childbirth-checklist_implementation-guide/en/" target="_blank">Safe Childbirth Checklist – SCC</a>), nos indicadores de eventos adversos padronizados por <a className="muted-link" href="https://doi.org/10.1016/S1553-7250(06)32065-X" target="_blank">Mann et al</a> (2006) e nas atuais diretrizes de boas práticas no parto.
												<br />
												Além da validação de indicadores usados no projeto e do processo de adaptação do SCC para o Brasil (<a className="muted-link" href="https://www.who.int/publications/i/item/9789241550215" target="_blank">Carvalho et al., 2018</a>), os resultados da linha de base da pesquisa foram descritos previamente e podem ser encontrados no estudo de <a className="muted-link" href="https://bmjopen.bmj.com/content/9/12/e030944.citation-tools" target="_blank">Sousa et al.</a> (2019).
											</p>
											<b>4. Quais as características do instrumento?</b>
											<p className="textoDescricao">
												Trata-se de um questionário autoaplicável (não precisa de entrevistador) que contém itens agrupados em três módulos independentes de indicadores: 1) Módulo de Boas Práticas (BP), formado por oito boas práticas recomendadas pela OMS (<a className="muted-link" href="https://www.who.int/patientsafety/implementation/checklists/childbirth-checklist_implementation-guide/en/" target="_blank">World Health Organization, 2018</a>); 2) Módulo de Adesão ao Checklist para Parto Seguro, formado pelos 49 itens do checklist adaptado para o Brasil (<a className="muted-link" href="https://www.who.int/publications/i/item/9789241550215" target="_blank">Carvalho et al., 2018</a>) e; 3) Módulo de Eventos Adversos (EA), com 10 itens padronizados por Mann et al (2006); O instrumento permite calcular indicadores simples e compostos da qualidade do parto a partir das dimensões segurança do paciente (indicadores de EA) e efetividade clínica (indicadores de BP). Adicionalmente, o questionário contém perguntas sobre dados demográficos e clínicos da mãe e do recém-nascido.
											</p>
											<b>5. Como avaliar o seu hospital?</b>
											<p className="textoDescricao">
												a) O responsável pela avaliação do hospital deverá, incialmente, criar uma conta na página inicial da plataforma. Cada profissional interessado na coleta também deverá criar o seu login no sistema. O login consiste apenas em preencher os dados de nome completo, e-mail e senha do usuário. O acesso à plataforma será automático, não sendo necessário confirmação no e-mail pessoal.
												<br />
												b) Após criar o login, o responsável pela avaliação deverá cadastrar o seu estabelecimento no sistema, preenchendo as informações de nome, CNES, cidade e estado do serviço. Quando o hospital estiver cadastrado na plataforma, já pode ser iniciada a coleta de dados.
												<br />
												c) Ao criar a conta pessoal e cadastrar o hospital na plataforma, o responsável pela avaliação deverá autorizar os profissionais de saúde que irão realizar a coleta. Pra isso, cadastre os e-mails dos profissionais do seu hospital na opção “Autorizações” no menu lateral da plataforma. Após conceder a autorização, cada profissional, com seu login previamente criado, deverá acessar o sistema e iniciar a coleta.
												<br />
												d) Independente da tecnologia (computador, smartphone ou tablet) que viabilizará a coleta de dados, é necessária a criação prévia da conta na plataforma. Quando disponível, baixe o aplicativo da plataforma QualiParto nos dispositivos móveis que pretende utilizar na coleta.
												<br />
												e) Para realizar a coleta de dados, clique na opção “Meus Hospitais” no menu esquerdo e, então, clique em “Coletar” {">>"} Eventos Adversos (ou outro módulo que deseja utilizar). A página irá abrir o formulário de perguntas e quando este for preenchido, clique em “Enviar” para concluir a coleta. Após preencher cada formulário, uma página de nova coleta será aberta automaticamente. É possível, também, alternar as páginas de coleta de acordo com cada módulo de indicadores.
												<br />
												f) Para fins de monitoramento da qualidade, os criadores da Plataforma QualiParto aconselham que as coletas sejam realizadas de forma retrospectiva a partir da aleatorização prévia de pelo menos 30 prontuários por quinzena ou mês, a depender da periodicidade das medições estabelecida pelo serviço. Um monitoramento é considerado válido a partir de 12 medições ou amostras. Esclarece-se, ainda, que o monitoramento da qualidade é a medição sistemática, repetida e planificada de indicadores de qualidade, cujo objetivo é identificar situações problemáticas.
											</p>
											<b>6. Como obter os resultados da avaliação?</b>
											<p className="textoDescricao">
												Após os passos descritos no tópico anterior, conforme os profissionais cadastrados preencham o questionário, os dados são automaticamente importados para um único banco de dados. O programa realiza uma análise descritiva automática calculando os indicadores de qualidade e apresenta-os em tabelas e gráficos. Os resultados são visíveis no seguinte caminho: “Meus Hospitais” {">>"} “Relatório”. Na mesma página é gerada de forma automática a apresentação dos principais resultados da coleta. O relatório completo da análise pode ser gerado e importado da plataforma no formato PDF. É possível também fazer o download do banco de dados e dos principais gráficos produzidos na análise. Os relatórios são gerados considerando todas as coletas realizadas pelo hospital, mas é possível personalizar a produção do relatório de acordo com o período de coleta desejada, além de personalizar gráficos de indicadores específicos, conforme interesse do serviço.
											</p>
											<b>7. O que fazer após a avaliação?</b>
											<p className="textoDescricao">
												Não se esqueça de apresentar o relatório da avaliação contendo os resultados da análise a todos os profissionais respondentes e lideranças de sua instituição. Além disso, é importante que o NSP planeje uma intervenção e um plano de ação para melhorar os indicadores encontrados após essa avaliação. Recomenda-se que o monitoramento da qualidade e segurança do parto seja feito pelo menos uma vez por ano na instituição.
											</p>
											<b>8. O aplicativo tem algum custo financeiro?</b>
											<p className="textoDescricao">
												Toda inovação tecnológica tem custos, assim como sua manutenção de funcionamento. No entanto, este sistema foi desenvolvido e disponibilizado de forma livre e sem custos para os usuários. A Universidade Federal do Rio Grande do Norte, com recursos do Grupo de Pesquisa QualiSaúde e do Instituto de Metrópole Digital (IMD), possibilitou este produto.
											</p>
											<b>9. O cadastro de hospitais é gratuito?</b>
											<p className="textoDescricao">Sim</p>
											<b>10. O que preciso fazer para me cadastrar?</b>
											<p className="textoDescricao">
												Para o cadastro do hospital, é preciso que o responsável pela avaliação preencha um pequeno formulário com informações do serviço que será avaliado, o mesmo também irá conceder autorizações no sistema dos outros profissionais que realizarão a coleta de dados. Além disso, é necessário concordar com a visualização dos dados pelo Grupo de Pesquisa QualiSaúde da UFRN, que é o administrador do sistema. Em nenhum caso, o grupo divulgará nomes de profissionais ou hospitais participantes, nem mesmo realizará pesquisas sem a devida autorização ética.
											</p>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
				<button
					type="button"
					className="btn btn-primary"
					style={{
						position: 'absolute',
						top: 5,
						left: 5,
						zIndex: 999
					}}
					onClick={() => document.location.href = "/"}
				><i className="fas fa-angle-double-left" /> Voltar</button>
			</div>

		);
	}
}
export default Info;

export default function arrayObjectsToCSV(source: any[]): string {
	const keys = Object.keys(source[0]);
	const header = 	keys
					.reduce((acumulado, key) => acumulado += key+';', '')
					.slice(0, -1);

	let body = '';
	source.forEach(data => {
		body += keys
				.reduce((acumulado, key) => {
					const value = data[key];
					if(value === null || value === undefined) {
						return acumulado += ';';
					}
					if(typeof value == 'boolean') {
						return acumulado += (value ? "SIM" : "NÃO")+';';
					}
					return acumulado += value+';'
				}, '')
				.slice(0, -1)
				+"\n";
	});

	return header+"\n"+body;
}
import * as React from 'react';
import '../utils/estilos.css';

export interface InfoProps { }

export interface InfoState { }

class Info extends React.Component<InfoProps, InfoState> {
	render() {
		return (
			<div className="page login-page">
				<div className="container d-flex align-items-center">
					<div className="form-holder has-shadow">
						<div className="row">
							<div className="col-12 text-center">
								<div
									className="info"
									style={{
										backgroundColor: 'white',
										color: 'black'
									}}
								>
									<h1 className="text-center" style={{ marginBottom: 10 }}>
										POLÍTICA DE PRIVACIDADE DO QUALIPARTO
									</h1>
									<div className="row">
										<div className="col-12 text-justify">
											<br />
											<b>1. Qual o obetivo dessa Política?</b>
											<br />
											<p className="textoDescricao">
												Para a execução dos serviços de pesquisa clínica, o sistema Qualiparto precisa utilizar dados pessoais que são fornecidos pelos usuários cadastrados.

												A fim de garantir a transparência esta Política de Privacidade estabelece como é feita a coleta e uso dos dados de usuários que utilizam nosso sistema.

												Ela esclarece quais são os dados coletados, para quais finalidades os coletamos, além de detalhar como os armazenamos. Sempre com o intuito de proteger e resguardar a sua privacidade e proteção.

												Esta política cumpre à legislação vigente referente à privacidade e proteção de dados pessoais no Brasil, especialmente a Lei Geral de Proteção de Dados Pessoais – LGPD (Lei nº 13.709/18).
											</p>
											<b>2. Quais dados coletamos sobre você e para qual finalidade?</b>
											<p className="textoDescricao">
												O sistema coleta e utiliza alguns dados pessoais seus, de forma a viabilizar a utilização do mesmo e aprimorar a experiência de uso.

												Os dados para acesso de usuário coletados pelo sistema são somente aqueles definidos pela LGPD como dados pessoais não sensíveis, são eles:
												<ul>
													<li>Nome completo do usuário;</li>
													<li>Endereço de e-mail;</li>
													<li>Nome do Hospital;</li>
													<li>Estado e cidade do Hospital;</li>
													<li>CNES do Hospital que realizará coleta de dados no sistema;</li>
													<li>Vínculo do usuário com o sistema (coletador, administrador geral ou administrador de rede de hospitais);</li>
												</ul>
											</p>
											<b>3. Como coletamos os seus dados?</b>
											<p className="textoDescricao">
												Ao acessar a página de cadastro do sistema e clicar no botão cadastrar, suas informações fornecidas são armazenadas no mesmo instante no banco de dados do sistema.

												No mesmo momento do cadastro, o dado de tipo de usuário é criado automaticamente pelo sistema e armazenados em nossos bancos de dados apenas para fins de possibilitar a utilização do sistema pelo usuário.
											</p>
											<b>4. Com quem o Qualiparto compartilha os seus dados pessoais e com qual finalidade?</b>
											<p className="textoDescricao">
												O Qualiparto compartilha seu nome e e-mail somente com outros usuários do sistema e de forma alguma disponibiliza esses dados para entidades externas ao sistema para qualquer finalidade.

												O compartilhamento entre os usuários se dá para que seja possível o correto funcionamento do sistema, de acordo com as responsabilidades que cada tipo de usuário possível dentro do sistema.

												Esse compartilhamento interno ocorre de maneira controlada, de modo que somente usuários, específicos e com autorização, de um mesmo hospital ou rede de saúde, podem visualizá-los.
											</p>
											<b>5. Por quanto tempo os dados pessoais serão armazenados?</b>
											<p className="textoDescricao">
												Seus dados pessoais permanecem no sistema por tempo indefinido até que o mesmo seja desligado ou até que você decida excluir seu cadastro do sistema.
											</p>
											<b>6. Como é feito o consentimento com relação ao armazenamento e uso dos seus dados?</b>
											<p className="textoDescricao">
												Visto que os dados que solicitamos e usamos para o correto funcionamento da plataforma são somente aqueles minimamente necessários, você consente em fornecer e permitir a utilização desses dados no ato do cadastro deles no sistema. O consentimento é a manifestação livre, informada e inequívoca pela qual você autoriza o Qualiparto a armazenar e tratar seus dados.

												Ao utilizar o Qualiparto e fornecer seus dados pessoais, você está ciente e consentindo com as disposições desta Política de Privacidade, além de conhecer seus direitos e como exercê-los.

												Como usuário do sistema, você tem o direito de acessar, modificar e remover seus dados do sistema. Solicitando ou realizando a ação de remover seus dados do sistema, você deixa claro que não consente mais com essa essa Política de Privacidade. Esse procedimento pode ser feito a qualquer momento a pedido ou por ação 	própria.
											</p>
											<b>7. Quais medidas de segurança são tomadas para a proteção dos dados pessoais?</b>
											<p className="textoDescricao">
												Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais orientadas para a proteção da sua privacidade.

												Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos dados coletados e tratados.

												Entre as medidas que adotamos, destacamos as seguintes:
												<ol>
													<li>Apenas pessoas autorizadas têm acesso a seus dados pessoais.</li>
													<li>O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade.</li>
													<li>Seus dados pessoais são armazenados em ambiente físico e virtual seguro e com permissão de acesso somente a técnicos autorizados.</li>
												</ol>
											</p>
											<b>8. Como falar com o responsável de proteção de dados do Qualiparto?</b>
											<p className="textoDescricao">
												Se você crê que seus dados pessoais foram usados de maneira contraditório ao descrito nessa Política ou com a lei vigente de proteção de dados(LGPD), ou, ainda, se você tiver outras dúvidas, pedidos, comentários ou sugestões relacionadas à gestão de seus dados, poderá entrar em contato conosco, através do seguinte e-mail: projetopartoseguro@gmail.com.
											</p>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
				<button
					type="button"
					className="btn btn-primary"
					style={{
						position: 'absolute',
						top: 5,
						left: 5,
						zIndex: 999
					}}
					onClick={() => document.location.href = "/"}
				><i className="fas fa-angle-double-left" /> Voltar</button>
			</div>

		);
	}
}
export default Info;

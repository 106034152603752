import React, { Component } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { salvarHospital } from '../api/api';
import copiaProfunda from '../utils/copiaProfunda';
import validarCNES from "../utils/validarCNES";

class CadastroHospitais extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      hospital: {
        nome: '',
        cnes: '',
        estado: '',
        cidade: ''
      },
      cidades: [],
      estados: []
    };
  }

  componentDidMount() {
    axios.get('https://www.geonames.org/childrenJSON?geonameId=3469034').then(res => {
      this.setState({
        estados: res.data.geonames.map(geo => ({
          id: geo.geonameId,
          nome: geo.toponymName,
          abreviacao: geo.adminCodes1.ISO3166_2
        }))
      });
    });
  }

  handleChange = (valor, input) => {
    this.setState(state => {
      const copia = copiaProfunda(state.hospital);
      copia[input] = valor;
      return { hospital: copia };
    });
  };

  selecionouItem = (valor, item) => {
    if (item === 'estado') {
      axios.get(`https://www.geonames.org/childrenJSON?geonameId=${valor}`).then(res => {
        this.setState({
          cidades: res.data.geonames.map(geo => ({ id: geo.geonameId, nome: geo.toponymName }))
        });
      });
    }

    this.setState(state => {
      const copia = copiaProfunda(state.hospital);
      copia[item] = valor;
      return { hospital: copia };
    }
    );
  };

  handleSubmit = event => {
    event.preventDefault();
    if (!validarCNES(this.state.hospital.cnes)) {
      return;
    }

    const estado = this.state.estados.find(estado => estado.id === +this.state.hospital.estado);
    const cidade = this.state.cidades.find(cidade => cidade.id === +this.state.hospital.cidade);

    if (!estado || !cidade || this.state.hospital.nome === "") { Swal.hideLoading(); return; }

    Swal({
      title: 'Salvando'
    });
    Swal.showLoading();

    salvarHospital({ ...this.state.hospital, estado: estado.abreviacao, cidade: cidade.nome })
      .then(res => {
        if (!res) {
          Swal('Falha', 'Falha ao salvar o hospital', 'error');
        } else {
          Swal('Concluído', 'Hospital salvo com sucesso', 'success')
            .then(() => this.props.history.push('/relatorios/hospitais'));
        }
      })
      .catch(err => {
        Swal('Falha', err.response.data, 'error');
      });
  };

  render() {
    return (
      <div className="card">
        <div className="card-header d-flex align-items-center">
          <h3 className="h4 lead">Cadastro de hospitais</h3>
        </div>
        <div className="card-body">
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label className="form-control-label">Nome</label>
                  <input
                    type="text"
                    name="nome"
                    placeholder="Nome"
                    className="form-control"
                    value={this.state.hospital.nome}
                    onChange={e => this.handleChange(e.target.value, 'nome')}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label className="form-control-label">CNES</label>
                  <input
                    type="text"
                    name="cnes"
                    placeholder="CNES"
                    className={"form-control" +
                      ((validarCNES(this.state.hospital.cnes) || this.state.hospital.cnes === "")
                        ? "" : " is-invalid")
                    }
                    value={this.state.hospital.cnes}
                    onChange={e => this.handleChange(e.target.value, 'cnes')}
                  />
                  <div className="invalid-feedback">
                    CNES Inválido
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label className="form-control-label">Estado</label>
                  <select
                    disabled={!this.state.estados.length}
                    name="estado"
                    placeholder="Estado"
                    className="form-control"
                    value={this.state.hospital.estado}
                    onChange={e => this.selecionouItem(e.target.value, 'estado')}>
                    <option value="" />
                    {this.state.estados.map(estado => (
                      <option key={estado.id} value={estado.id}>
                        {estado.nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label className="form-control-label">Cidade</label>
                  <select
                    disabled={!this.state.cidades.length}
                    name="cidade"
                    placeholder="Cidade"
                    className="form-control"
                    value={this.state.hospital.cidade}
                    onChange={e => this.selecionouItem(e.target.value, 'cidade')}>
                    <option value="" />
                    {this.state.cidades.map(cidade => (
                      <option key={cidade.id} value={cidade.id}>
                        {cidade.nome}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group">
              <input type="submit" value="Cadastrar" className="btn btn-primary" />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default CadastroHospitais;

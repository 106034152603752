import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PageHeader from '../layout/PageHeader';
import CadastroHospitais from './CadastroHospitais';
import EditarHospital from './EditarHospital';
import PageNotFound from '../pages/PageNotFound';

class Hospitais extends Component<any, any> {
  render() {
    return (
      <div className="content-inner">
        <PageHeader titulo="Gerenciamento de Hospitais" />
        <div className="container-fluid mt-4">
          <Switch>
            <Route path="/hospitais/novo" component={CadastroHospitais} />
            <Route path="/hospitais/editar/*" component={EditarHospital} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Hospitais;

import '../utils/estilos.css';
import * as React from 'react';
import "../utils/estilos.css"
import { resetNovaSenha, getValidacaoEmailLink } from '../api/api';
import copiaProfunda from '../utils/copiaProfunda';
import LoadingReset from '../api/LoadingReset';
import Swal from 'sweetalert2';


export interface InfoProps {
    search: ''
}

export interface ResetState {
    resetNewPass: boolean;
    erroSenhas?: string;
    logando: boolean;
    tentativa: boolean
    validou: boolean;
    messagem: string;
    newPass: {
        senha: string,
        token: string
    },
    erroNewPass?: string;
    erro?: string;
}

class LinkEmail extends React.Component<InfoProps, ResetState> {
    senhaConfirm = '';
    constructor(props) {
        super(props);
        /*Com o componente URLSearchParams é possível capturar a url atual*/
        const params = new URLSearchParams(props.location.search);
        let tokenUrl = params.get('token');


        this.state = {
            tentativa: false,
            resetNewPass: false,
            logando: false,
            validou: false,
            messagem: '',
            newPass: {
                senha: '',
                token: tokenUrl
            }
        };
        this.handleChange = this.handleChange.bind(this);



    }
    handleChange(event) {
        const target = event.target;
        const { value, name } = target;
        this.setState({
            [name]: value
        } as ResetState);
    }



    handleChangeInsertNewPass = event => {
        const target = event.target;
        const { value, name } = target;

        const copia = copiaProfunda(this.state.newPass);
        copia[name] = value;

        this.setState({ newPass: copia });
    }

    /*O método realiza a chamada da api para a inserção da nova senha */

    novaSenha = async () => {
       

        /*Validação das senhas inseridas nos inputs*/
        if (this.state.newPass.senha !== this.senhaConfirm) {
            this.setState({ erroSenhas: 'As senhas não conferem' });
            return;
        } else {
            this.setState({ erroSenhas: '' });
        }

        this.setState({ logando: true });

        try {
            let r = await resetNovaSenha(
                this.state.newPass.senha,
                this.state.newPass.token
            );
            /*Exibe mensagem da api correspondente ao sucesso da solicitação */
            if ((r !== 400) && (r !== 500)) {
                Swal('Concluído', r, 'success');
                window.location.href = '/';
            }
            /*Exibe mensagem da api correspondente ao código http */
            if (r == 400) {
                Swal('Falha', r, 'error');
                window.location.href = '/';
            }
        } catch (err) {
            this.setState({ erroNewPass: err.response ? err.response.data : 'Falha na conexão', logando: false });
        }
    }


    /*Requisita a API para verificar se o token existe para o usuário */
    validaToken = async () => {
        try {

            let r = await getValidacaoEmailLink(
                this.state.newPass.token
            );

            if ((r !== 400) && (r !== 500) && (r!== 100)) {
                this.setState({ validou: true });
            } else if (r == 400) {
			    Swal('Atenção', 'O seu token de alteração expirou ou não é válido.', 'warning');
                this.setState({ validou: false });
                window.location.href = '/';
            }else if( r == 500 || r == 100) {
                Swal('Erro', 'Servidor não responde.', 'error');
                this.setState({validou: false});
                window.location.href = '/';
            }
        } catch (err) {
            this.setState({validou: false});
            this.setState({ erroNewPass: err.response ? err.response.data : 'Falha na conexão', logando: false });
        }
    }

    render() {
        /*Quando o usuário clica sobre o link que contém o endereço da página
        com o token a primeira coisa que essa página tem que fazer é chamar a função
        de validação do token. Isso deverá ocorrer uma única vez */
        if (this.state.tentativa == false) {
            this.validaToken()
            if (this.state.validou == true) {
                this.setState({ tentativa: true });
            } else {
                this.setState({ tentativa: true })
            }
        }

        return (

            /*Exibe o layout padrão do sistema */
            <div className="page login-page bgContain">
                <div className="container d-flex align-items-center">
                    <div className="form-holder has-shadow">
                        <div className="row">
                            {/* Logo & Information Panel*/}
                            <div className="col-lg-6">
                                <div className="info d-flex align-items-center">
                                    <div className="content">
                                        <div
                                            style={{
                                                //backgroundColor: 'white',
                                                textAlign: 'justify',
                                                paddingBottom: '5px'
                                            }}
                                        >
                                            <img
                                                src="/theme/img/logos/full_logo_branca.png"
                                                width="100%"
                                            />
                                            <p
                                                style={{
                                                    color: 'white',
                                                    marginLeft: '20px',
                                                    marginRight: '20px',
                                                    fontSize: '11pt',
                                                    textShadow: '2px 2px 5px black'
                                                }}
                                            >
                                                A plataforma QualiParto é uma tecnologia desenvolvida para auxiliar
                                                o monitoramento da qualidade do cuidado e segurança do paciente na
                                                assistência ao parto. Inclui coleta e análise automática de indicadores
                                                de boas práticas obstétricas, de adesão ao Checklist para Parto Seguro da Organização
                                                Mundial de Saúde (OMS) e de eventos adversos.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Form Panel*/}

                            <div className="col-lg-6 bg-white">

                                <div className="form d-flex align-items-center">
                                    {/* Verifico o estado da variável e tenho o controle sobre quem deve ser
                                exibido */}

                                    {this.state.validou ?
                                        this.getTelaInsertNewPass() :
                                        this.getTelaValidacaoLinkEmail()

                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );


    }

    /* Método que apresenta o conteúdo do formulário de cadastro da nova senha*/
    getTelaInsertNewPass = () => (<div className="content">
        <div><p style={{
            color: 'grey',
            fontSize: '19pt',
        }}>Cadastre Nova Senha
        </p>
        </div>
        <form method="post" className="form-validate">
            <div className="form-group">
                <input
                    type="password"
                    name="senha"
                    required
                    value={this.state.newPass.senha}
                    onChange={this.handleChangeInsertNewPass}
                    className={
                        "input-material form-control "
                        + (this.state.erroSenhas
                            ? "is-invalid"
                            : ""
                        )
                    }
                    placeholder="Senha"
                />
                <div className="invalid-feedback">
                    {this.state.erroSenhas}
                </div>
            </div>
            <div className="form-group">
                <input
                    type="password"
                    name="senha_confirm"
                    required
                    onChange={e => this.senhaConfirm = e.target.value}
                    className="input-material form-control"
                    placeholder="Repita a senha"
                />
            </div>
            <button
                disabled={this.state.logando}
                type="button"
                id="login"
                className="btn btn-primary"
                onClick={this.novaSenha}
            >
                {!this.state.logando ? "Alterar" : "Aguarde..."}
            </button>
            {/* This should be submit button but I replaced it with <a> for demo purposes*/}
        </form>
    </div>);

    getTelaValidacaoLinkEmail = () => (

        <LoadingReset/>


    );


}



export default LinkEmail;

import * as React from 'react';
import DatePicker from 'react-datepicker';
import Card from '../layout/Card';
import GradientCard from '../layout/GradientCard';
import PieChart from '../graficos/PieChart';
import ColumnChart from '../graficos/ColumnChart';
import RunchartEAMensal from '../graficos/RunchartEAMensal';
import Loading from '../api/Loading.jsx';
import moment from 'moment';
import { obterDadosEventosAdversosFiltrado, obterDadosEventosAdversos } from '../api/api';
import { processarEventosAdversosMaeBarChart, processarEventosAdversosRNBarChart, processarDadosTipoParto } from '../utils/ProcessarDadosCharts';
import Histograma from '../graficos/Histograma';
import RunChart from '../graficos/RunChart';
import arrayObjectsToCSV from '../utils/arrayObjectsToCSV';
import * as XLSX from 'xlsx'
import { preprocessarParaXLSX } from '../utils/eventos-adversos';
import { saveAs } from 'file-saver';
import {
	Hospital,
	DadosColetaEventosAdversos,
	LabelEventosAdversos
} from '../modelos';
import {
	categorizarEmMeses,
	processarSerie,
	calcularIRA,
	calcularEPRA,
	calcularIG
} from '../utils/calculos_IRA_EPRA_IG';
import RunChartEAMae from '../graficos/RunChartEAMae';
import RunChartEARN from '../graficos/RunChartEARN';
import { calcularIdadeMae, numero2MesAbreviado } from '../utils';
import { DateTime } from 'luxon';
import TabelaSociodemografica from '../graficos/TabelaSociodemografica';

interface Props {
	hospital: Hospital;
	onClose: Function;
}

interface State {
	pieChartSelecionado: string;
	runchartSubsecao3: string;
	dadosColetas: DadosColetaEventosAdversos[] | null;
	dataInicio: moment.Moment;
	dataFim: moment.Moment;
	coletasSelecionadas: string[];
}

export default class RelatorioResumidoColetas extends React.Component<Props, State> {
	dataInicioConsultada = null;
	dataFimConsultada = null;

	constructor(props: Props) {
		super(props);
		this.state = {
			pieChartSelecionado: 'tipoParto',
			runchartSubsecao3: 'nenhum',
			dataInicio: moment(props.hospital.dataCadastro),
			dataFim: moment(props.hospital.dataUltimaColetaEventosAdversos),
			dadosColetas: null,
			coletasSelecionadas: []
		};

		this.dataInicioConsultada = moment(props.hospital.dataCadastro).toDate();
		this.dataFimConsultada = moment(props.hospital.dataUltimaColetaEventosAdversos).toDate();
	}

	componentDidMount() {
		this.consultarRelatorioCompleto();
	}

	consultarRelatorio = (dataInicio: string, dataFim: string) => {
		const { id } = this.props.hospital;
		obterDadosEventosAdversosFiltrado(id, dataInicio, dataFim).then((dadosColetas) => {
			this.setState({ dadosColetas: dadosColetas }, () => {
				this.dataInicioConsultada = moment(dataInicio).toDate();
				this.dataFimConsultada = moment(dataFim).toDate();
				this.forceUpdate();
			});
		});
	};

	consultarRelatorioCompleto = () => {
		const { id } = this.props.hospital;
		obterDadosEventosAdversos(id).then((dadosColetas) => {
			const faixaDeTempo = this.getColetaFaixaAltas(dadosColetas);
			this.dataInicioConsultada = faixaDeTempo.primeira.toDate();
			this.dataFimConsultada = faixaDeTempo.ultima.toDate();
			this.setState({
				dadosColetas: dadosColetas,
				dataInicio: faixaDeTempo.primeira,
				dataFim: faixaDeTempo.ultima
			});
		});
	};

	consultarDadosCSV = (hospitalId) => {
		obterDadosEventosAdversos(hospitalId).then((dadosColetas) => {
			const dadosPreProcessados: any[] = dadosColetas.map(dado => ({
				...dado,
				dataColeta: dado.dataColeta.split('-').reverse().join('-'),
				dataAltaObito: dado.dataAltaObito.split('-').reverse().join('-'),
				dataInternacao: dado.dataInternacao.split('-').reverse().join('-'),
				dataNascimento: dado.dataNascimento.split('-').reverse().join('-'),
				hospital: dado.hospital.nome
			}));
			const data = arrayObjectsToCSV(dadosPreProcessados);
			const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
			saveAs(blob, dadosPreProcessados[0].hospital + '-eventos-adversos.csv');
		});
	};

	consultarDadosXLSX = (hospitalId) => {
		obterDadosEventosAdversos(hospitalId).then((dadosColetas) => {
			const processado = preprocessarParaXLSX(dadosColetas);
			const wb = XLSX.utils.book_new();
			const ws = XLSX.utils.json_to_sheet(processado);
			XLSX.utils.book_append_sheet(wb, ws, 'eventos-adversos');
			XLSX.writeFile(wb, processado[0].hospital + '-eventos-adversos.xlsx');
		});
	};

	getColetaFaixaAltas(dados: DadosColetaEventosAdversos[]) {
		if (dados.length === 0) {
			return {
				primeira: moment(),
				ultima: moment()
			};
		}
		const sorted = dados.slice(0).sort((data1, data2) => {
			return DateTime.fromISO(data1.dataAltaObito).toMillis() - DateTime.fromISO(data2.dataAltaObito).toMillis();
		});

		return {
			primeira: moment(sorted[0].dataAltaObito),
			ultima: moment(sorted[sorted.length - 1].dataAltaObito)
		};
	}
	handleChange(valor, identificador) {
		/*const i = this.identificador2Indice(this.state.perguntas, identificador);
		this.setState((state) => {
			const copia = copiaProfunda(state.perguntas);
			copia[i].value = valor;
			return {perguntas: copia};
		});*/
	}

	render() {
		const { dadosColetas } = this.state;

		if (!dadosColetas)
			return <Loading />;
		if (dadosColetas.length === 0)
			return (<h5>Nenhuma informação inserida no banco de dados</h5>);

		const categorizadoMeses = categorizarEmMeses(dadosColetas);
		const serieIRA = processarSerie(categorizadoMeses, calcularIRA);
		const serieEPRA = processarSerie(categorizadoMeses, calcularEPRA);
		const serieIG = processarSerie(categorizadoMeses, calcularIG);

		const eventosAdversosMaeBarChart = processarEventosAdversosMaeBarChart(dadosColetas);
		const eventosAdversosRNBarChart = processarEventosAdversosRNBarChart(dadosColetas);

		return (
			<Card titulo={"Relatório de eventos adversos da instituição " + this.props.hospital.nome} onClose={this.props.onClose}>
				<h5 className="text-center">Período de consulta</h5>
				<div className="row justify-content-center">
					<div className="col-4">
						<div className="form-group d-flex justify-content-end align-items-end">
							<label>Início</label>
							<DatePicker
								className="form-control datepicker"
								selected={this.state.dataInicio}
								selectsStart
								startDate={this.state.dataInicio}
								endDate={this.state.dataFim}
								onChange={(data) => this.setState({ dataInicio: data })}
							/>
						</div>
					</div>
					<div className="col-4">
						<div className="form-group d-flex justify-content-space-between align-items-end">
							<label>Fim</label>
							<DatePicker
								className="form-control datepicker"
								selected={this.state.dataFim}
								selectsEnd
								startDate={this.state.dataInicio}
								endDate={this.state.dataFim}
								onChange={(data) => this.setState({ dataFim: data })}
							/>

							<button
								type="button"
								className="btn btn-success"
								onClick={() =>
									this.consultarRelatorio(this.state.dataInicio.toISOString(), this.state.dataFim.toISOString())
								}>
								Consultar
							</button>
						</div>
					</div>
					<div className="col-4">
						<div className="btn-group">
							<button className="btn btn-info btn-sm dropdown" type="button" style={{ display: 'inline-block' }}>
								<span className="dropdown-toggle" data-toggle="dropdown">
									Exportar
								</span>
								<div className="dropdown-menu">
									<a className="dropdown-item" href="#" onClick={() => this.consultarDadosCSV(this.props.hospital.id)}>
										<i className="fas fa-table"> </i> CSV
									</a>
									<a className="dropdown-item" href="#" onClick={() => this.consultarDadosXLSX(this.props.hospital.id)}>
										<i className="fas fa-file-excel"> </i> XLSX
									</a>
								</div>
							</button>
							<a
								href={
									"/relatorio/"
									+ this.props.hospital.id
									+ '?dataInicio=' + this.state.dataInicio.toISOString()
									+ '&dataFim=' + this.state.dataFim.toISOString()
									+ '&eaSelecionados=' + this.state.coletasSelecionadas
								}
								target="_blank"
							>
								<button className="btn btn-success">
									Gerar PDF
								</button>
							</a>
						</div>
					</div>
				</div>

				<div className="row p-3 mb-4 justify-content-center">
					<div className="col-md-6 mb-4">
						<GradientCard icon="list" estatistica={dadosColetas.length} descricao="Questionários coletados" />
					</div>
				</div>

				<h4
					style={{
						borderBottom: '1px solid gray'
					}}
				>
					1) Caracterização dos partos na instituição {this.props.hospital.nome}, no período de {formatarData(this.dataInicioConsultada)} a {formatarData(this.dataFimConsultada)}, {this.props.hospital.cidade}, {(new Date()).getFullYear()}
				</h4>
				<div className="row">
					<div className="col-md-6 offset-md-3">
						<PieChart
							id="pieChartTipoParto"
							titulo="Tipos de Parto"
							series={processarDadosTipoParto(dadosColetas)}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<Histograma
							arredondarFaixas={true}
							titulo="Histograma idade da mãe"
							corBarras="indianred"
							nClasses={3}
							dados={dadosColetas.map(c => calcularIdadeMae(c))}
						/>
					</div>
					<div className="col-md-6">
						{<Histograma
							arredondarFaixas={true}
							titulo="Histograma idade gestacional(em semanas)"
							corBarras="indianred"
							nClasses={3}
							dados={dadosColetas.map(c => c.maeIdadeGestacional)}
						/>}
					</div>
				</div>
				<TabelaSociodemografica dados={dadosColetas} />

				<br /><br />

				<h4
					style={{
						borderBottom: '1px solid gray'
					}}
				>
					2) Eventos Adversos na instituição {this.props.hospital.nome}, no período de {formatarData(this.dataInicioConsultada)} a {formatarData(this.dataFimConsultada)}, {this.props.hospital.cidade}, {(new Date()).getFullYear()}
				</h4>
				<ColumnChart
					titulo="Eventos adversos maternos"
					categorias={eventosAdversosMaeBarChart.categorias}
					series={eventosAdversosMaeBarChart.series}
				/>
				<RunChartEAMae dados={dadosColetas} />

				<br /><br />
				<ColumnChart
					titulo="Eventos adversos neonatais"
					categorias={eventosAdversosRNBarChart.categorias}
					series={eventosAdversosRNBarChart.series}
				/>
				<RunChartEARN dados={dadosColetas} />

				<br /><br />
				<h4
					style={{
						borderBottom: '1px solid gray'
					}}
				>
					3) Controle de qualidade do cuidado
				</h4>
				<h5
					style={{
						borderBottom: '1px solid gray',
						marginTop: '20px'
					}}
				>
					3.1) Indicadores compostos de eventos adversos na assistência ao parto
				</h5>
				<RunChart
					dados={serieIRA}
					exportavel={true}
					mostrarLegendas={true}
					titulo='Indice de Resultados Adversos - IRA (Estimativa mensal de partos nos quais ocorreram ao menos um evento adverso)'
					nome={"IRA"}
					yLabel="Percentual de IRA"
					corLinha="#07a7e3"
					percentual={true}
				/>
				<hr />
				<RunChart
					dados={serieEPRA}
					exportavel={true}
					mostrarLegendas={true}
					titulo='Escore Ponderado de Resultados Adversos - EPRA'
					nome={"EPRA"}
					yLabel="Percentual de EPRA"
					corLinha="#07a7e3"
					percentual={false}
				/>
				<hr />
				<RunChart
					dados={serieIG}
					exportavel={true}
					mostrarLegendas={true}
					titulo='Índice de Gravidade - IG'
					nome={"IG"}
					yLabel="Percentual de IG"
					corLinha="#07a7e3"
					percentual={false}
				/>
				<hr />
				<h5
					style={{
						borderBottom: '1px solid gray',
						marginTop: '20px'
					}}
				>
					3.2) Gráfico de tendência de complicações e eventos adversos:
				</h5>
				<div className="form-group">
					{Object.entries(LabelEventosAdversos).map(([value, label]) => {
						return <div key={value}>
							<input
								type="checkbox"
								id={value}
								name={value}
								onChange={(cl) => this.atualizaCSelecionados(cl.target.value, cl.target.checked)}
								value={value}
							/><label className='lrc' htmlFor={value}>{label}</label>
						</div>
					})}
					{this.state.coletasSelecionadas
						.map(cl =>
							<RunchartEAMensal
								dados={dadosColetas}
								eventoAdversoSelecionado={cl}
							/>)
					}
				</div>
			</Card>
		);
	}

	atualizaCSelecionados = (cl, check) => {
		if (check) {
			this.setState(prevState => ({
				coletasSelecionadas: [...prevState.coletasSelecionadas, cl]
			}))
		} else { this.removeBp(cl); }
	}

	removeBp(e) {
		let arrayFiltrado = this.state.coletasSelecionadas.filter(item => item !== e)
		this.setState({ coletasSelecionadas: arrayFiltrado });
	}
}

function formatarData(data: Date): String {
	let yyyy = data.getFullYear();
	const mes = numero2MesAbreviado(data.getMonth() + 1);
	return mes + '/' + yyyy;
}
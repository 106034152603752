import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
          calcularMediana,
          marcarOutliers,
          marcarPontosConsecutivosAbaixoDaMediana,
          marcarPontosConsecutivosAcimaDaMediana,
          marcarQuedasConsecutivas,
          marcarSubidasConsecutivas,
		  ChecklistRegrasAplicadasRunChart,
		  calcularMedianas
        } from "../utils/processarRunChart";
import RunChartLegendaRegras from './RunChartLegendaRegras';

export interface ponto {
	x: number;
	y: number;
}

interface Props {
  dados: ponto[];
  mostrarLegendas: boolean;
  exportavel: boolean;
  titulo: string;
  nome: string;
  yLabel: string;
  corLinha: string;
  percentual: boolean;
  maxVal?: number;
}

const CONSECUTIVAS = 5;
const CONSECUTIVOS_MEDIANA = 6;

export default function RunChart(props: Props) {
	const checklist: ChecklistRegrasAplicadasRunChart = {
		outlierNegativo: false,
		outlierPositivo: false,
		pontosConsecutivosAbaixoDaMediana: false,
		pontosConsecutivosAcimaDaMediana: false,
		quedasConsecutivas: false,
		subidasConsecutivas: false
	};

	let maxVal = 100;
	if(!props.percentual && props.maxVal > 0)
		maxVal = props.maxVal;
	else if (!props.percentual)
		maxVal = Infinity;

    marcarQuedasConsecutivas(props.dados, CONSECUTIVAS, () => checklist.quedasConsecutivas = true);
	marcarSubidasConsecutivas(props.dados, CONSECUTIVAS, () => checklist.subidasConsecutivas = true);
	
	const medianas = calcularMedianas(props.dados, maxVal);

    marcarPontosConsecutivosAbaixoDaMediana(props.dados, medianas, CONSECUTIVOS_MEDIANA, () => checklist.pontosConsecutivosAbaixoDaMediana = true);
    marcarPontosConsecutivosAcimaDaMediana(props.dados, medianas, CONSECUTIVOS_MEDIANA, () => checklist.pontosConsecutivosAcimaDaMediana = true);
	/*
		Outliers não estão mais sendo marcados por uma solicitação de Kelienny em 08/11/2019
	marcarOutliers(
		props.dados, out => (out < 0)
		? (checklist.outlierNegativo=true)
		: (checklist.outlierPositivo=true)
	);
	*/

	const temMaximoPercentual = {} as any;
	if(props.percentual)
		temMaximoPercentual.max = 100;

  return (
    <div style={{ width: '100%' }} className="mt-4" key={props.nome}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: { type: 'line', height: 400 },
          credits: { enabled: false },
          title: { text: props.titulo },
          xAxis: { type: 'datetime', gridLineWidth: 0 },
          yAxis: { 
			  		title: {
								text: props.yLabel
							},
					allowDecimals: false,
					gridLineWidth: 0,
					...temMaximoPercentual
				},
		  plotOptions: {
			line: {
				dataLabels: {
					enabled: true,
					formatter: function() {
						if(this.series.name.startsWith('Mediana') && this.point.index == 1) {
							return;
						}
						let sulFixo = '';
						if(props.percentual)
							sulFixo = '%';
						return '<span style="color:'+this.series.color+'" >'+this.y.toFixed(1)+sulFixo+'</span>';
					}
				}
			}
		  },
          tooltip: { enabled: false },
          legend: { enabled: true },
          series: [
			...medianas,
            {
              name: "Indicador",
              data: props.dados,
			  color: props.corLinha,
			  marker:{ symbol: 'circle' }
			}
          ],
          exporting: {
			enabled: props.exportavel,
            filename: props.titulo
          }
        }}
      />
	  {
		  props.mostrarLegendas
		  ? <RunChartLegendaRegras {...checklist} />
		  : null
	  }
    </div>
  );
}

import React from 'react';

const Footer = () => {
  return (
	<footer
		className="main-footer bg-white"
		style={{
			position: 'initial',
			padding: '10px',
			borderTop: '1px solid gray'
		}}
	>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-3">
            <p style={{color: 'black'}}>QualiParto &copy; 2018-{(new Date()).getFullYear()}</p>
          </div>
		  <div className="col-sm-6 text-center">
              <a href="http://avaqualisaude.sedis.ufrn.br/" className="external">
			  	<img
					src="/theme/img/logos/qualisaude_wide.png"
					style={{ height: '55px', marginRight: '100px' }}
				/>
              </a>
			  <a href="https://www.imd.ufrn.br/portal/" className="external">
			  	<img
					src="/theme/img/logos/imd.svg"
					style={{ height: '55px', marginRight: '100px' }}
				/>
              </a>
			  <a href="http://ufrn.br/" className="external">
			  	<img
					src="/theme/img/logos/ufrn.png"
					style={{ height: '55px', marginRight: '100px' }}
				/>
              </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

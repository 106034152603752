import * as React from 'react';
import PageHeader from '../layout/PageHeader';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { obterDadosBoasPraticas, deletarBoasPraticasById } from '../api/api';
import Loading from '../api/Loading.jsx';
import { DadosColetaBoasPraticas } from '../modelos';
import { DateTime } from 'luxon';
import getFetchErroComponent from '../utils/erroCompotent';

interface state {
  carregando: boolean;
  fetchErro: boolean;
  nomeHospital: String;
  coletasBoasPraticas: DadosColetaBoasPraticas[];
}

export default class ListagemBoasPraticas extends React.Component<any, state> {
  id = Number(this.props.location.pathname.split('/').slice(-1)[0]);
  constructor(props) {
    super(props);
    this.state = {
      carregando: true,
      fetchErro: false,
      nomeHospital: "",
      coletasBoasPraticas: []
    }
  }

  componentDidMount() {
    this.getBoasPraticasByHospitalId();
  }

  getBoasPraticasByHospitalId = async () => {
    this.setState({ carregando: true });
    try {
      const boaspraticas = await obterDadosBoasPraticas(this.id);
      this.setState({
        coletasBoasPraticas: boaspraticas,
        nomeHospital: boaspraticas[0].hospital.nome
      });
    } catch (e) {
      //Swal('Falha', e.response.data.message, 'error');
      this.setState({ fetchErro: true });
    } finally {
      this.setState({ carregando: false });
    }
  }
  deletarBoasPraticas = async id => {
    const r = await Swal({
      title: 'Tem certeza?',
      text: "Esta operação vai remover completamente a coleta!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, delete!'
    });

    if (!r.value) { return; }

    Swal({
      title: 'Deletando...'
    });
    Swal.showLoading();

    try {
      await deletarBoasPraticasById(id);
      await Swal('Concluído', 'Coleta deletada com sucesso', 'success');
      const arrayCopy = this.state.coletasBoasPraticas.filter((boapratica) => boapratica.id !== id);
      this.setState({ coletasBoasPraticas: arrayCopy });

    } catch (err) {
      Swal('Falha', err.response.data.message, 'error');
    }
  }

  render() {
    const linhas = this.state.coletasBoasPraticas.map((boapratica) => (
      <tr key={boapratica.id}>
        <td>{boapratica.numeroProntuario}</td>
        <td>{boapratica.hospital.usuario.nome}</td>
        <td>{DateTime.fromISO(boapratica.dataColeta.toString()).toFormat('dd/MM/yyyy')}</td>
        <td>{boapratica.tipoParto}</td>
        <td>{DateTime.fromISO(boapratica.dataParto).toFormat('dd/MM/yyyy')}</td>
        <td>{boapratica.rnPeso}</td>
        <td>{boapratica.maeIdadeGestacional}</td>
        <td style={{ whiteSpace: 'nowrap' }}>
          <div className="btn-group">
            <Link to={"/boaspraticas/editar/" + boapratica.id}>
              <button title="Editar coleta" className="btn btn-success btn-sm">
                <i className="far fa-edit"></i>
              </button>
            </Link>
            <button
              className="btn btn-danger btn-sm ml-auto"
              onClick={() => this.deletarBoasPraticas(boapratica.id)}
            ><i className="fas fa-trash" /></button>
          </div>
        </td>
      </tr>
    ));

    const tabela = (<div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Prontuário</th>
            <th>Usuário</th>
            <th>Data Coleta</th>
            <th>Tipo Parto</th>
            <th>Data Parto</th>
            <th>Peso RN</th>
            <th>Idade Gestacional</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>{linhas}</tbody>
      </table>
    </div>);

    const conteudo = (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h3 className="h4 lead">Coletas de boas práticas: {this.state.nomeHospital}</h3>
              <Link to={`/questionarios/boas-praticas/${this.id}`}>
                <button className="btn btn-sm btn-success">
                  Novo <i className="fas fa-plus" />
                </button>
              </Link>
            </div>
            <div className="card-body">
              {
                this.state.carregando
                  ? <Loading />
                  : (
                    this.state.fetchErro
                      ? getFetchErroComponent(this.getBoasPraticasByHospitalId)
                      : tabela
                  )
              }
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="content-inner">
        <PageHeader titulo="Coletas" />
        <div className="container-fluid mt-4">
          {conteudo}
        </div>
      </div>
    );
  }
}

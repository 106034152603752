import * as React from 'react';
import './GradientCard.css';

export default function GradientCard({ icon, estatistica, descricao, danger = false }) {
  return (
    <div className={`gradient-card ${danger ? 'danger-gradient' : ''}`}>
      <div className="d-flex justify-content-between">
        <i
			className={`fas fa-${icon} fa-3x align-self-center`}
			style={{textShadow: 'none'}}
		/>
        <div>
          <div className="text-right" style={{ fontSize: '1.6em' }}>
            {estatistica}
          </div>
          <div className="text-right" style={{ marginTop: -10 }}>
            {descricao}
          </div>
        </div>
      </div>
    </div>
  );
}

import moment from 'moment';
/*
  Os campos 'chave' de cada uma destas perguntas precisam
  ter um mapeamento "um para um" com 'DadosColetaBoasPraticas'
  em /src/modelos/index.ts.
*/
export default function getPerguntasComDados(coleta) {
  return [
    { type: 'header', text: 'Informações do parto' },
    {
      identificador: 'P6',
      chave: 'iniciouPartograma',
      type: 'radio',
      label: 'Iniciou o partograma?',
      options: ['Sim', 'Não', 'Não se aplica'],
      descricao: 'Marcar “sim” quando foi preenchido pelo menos um dos quatro critérios do Partograma: temperatura, frequência cardíaca da mãe e do feto, pressão arterial e dilatação cervical. Marque “Não se aplica” para mulheres com cesárea programada ou que não entraram em trabalho de parto (dilatação de colo do útero maior que 4 cm).',
      value: coleta.iniciouPartograma === 'NA' ? 'Não se aplica' : coleta.iniciouPartograma,
      obrigatorio: true
    },
    {
      identificador: 'P7',
      chave: 'presencaAcompanhante',
      type: 'radio',
      label: 'Há o registro de acompanhante durante o parto?',
      options: ['Sim', 'Não'],
      descricao: 'Considere “Sim” quando existir o registro explícito de presença de acompanhante durante o parto.',
      value: coleta.presencaAcompanhante ? 'Sim':'Não',
      obrigatorio: true
    },
    {
      identificador: 'P8',
      chave: 'administradaOcitocina',
      type: 'radio',
      label: 'Foi administrada ocitocina após o parto?',
      options: ['Sim', 'Não'],
      descricao: 'A ocitocina (10 UI, IM / IV) é o medicamento uterotônico recomendado para prevenir a hemorragia pós-parto (HPP). Esta informação pode ser encontrada Folha de Prescrição de Medicamentos ou de Evolução Interdisciplinar.',
      value: coleta.administradaOcitocina ? 'Sim':'Não',
      obrigatorio: true
    },
    {
      identificador: 'P9',
      chave: 'clampeamentoOportuno',
      type: 'radio',
      label: 'Foi realizado o clampeamento oportuno do cordão umbilical?',
      descricao: 'Esta informação pode ser encontrada na Ficha de Identificação do Recém-Nascido, na folha da Evolução da sala de cirurgia ou ainda no checklist para parto seguro. Marque “Não se aplica” para o recém-nascido que precisou de reanimação. Considera-se oportuno o clampeamento do cordção quando este ocorre em não menos de 1 minuto depois do nascimento.',
      options: ['Sim', 'Não', 'Não se aplica'],
      value: coleta.clampeamentoOportuno === 'NA' ? 'Não se aplica' : coleta.clampeamentoOportuno,
      obrigatorio: true
    },
    {
      identificador: 'P10',
      chave: 'iniciouContatoPeleaPela',
      type: 'radio',
      label: 'Foi realizado contato pele a pele entre a mãe e o recém-nascido?',
      descricao: 'Esta informação pode ser encontrada na Ficha de Identificação do Recém-Nascido, na folha da Evolução da sala de cirurgia ou ainda no checklist para parto seguro. Marque “Não se aplica” para o recém-nascido que precisou de reanimação',
      options: ['Sim', 'Não', 'Não se aplica'],
      value: coleta.iniciouContatoPeleaPela === 'NA' ? 'Não se aplica' : coleta.iniciouContatoPeleaPela,
      obrigatorio: true
    },
    {
      identificador: 'P11',
      chave: 'rnIniciouAmamentacaoNa1Hora',
      type: 'radio',
      label: 'Foi iniciada a amamentação na primeira hora após o parto?',
      descricao: 'Esta informação pode ser encontrada na Ficha de Identificação do Recém-Nascido ou na folha da Evolução da sala de cirurgia. Procurar também nas Folhas de Evolução Interdisciplinar. Marque “Não se aplica” para o recém-nascido que precisou de reanimação.',
      options: ['Sim', 'Não', 'Não se aplica'],
      value: coleta.rnIniciouAmamentacaoNa1Hora === 'NA' ? 'Não se aplica' : coleta.rnIniciouAmamentacaoNa1Hora,
      obrigatorio: true
    },
    {
      identificador: 'P12',
      chave: 'administradaVitaminaK',
      type: 'radio',
      label: 'Foi administrada vitamina K no recém-nascido?',
      options: ['Sim', 'Não'],
      descricao: 'Deve ser administrado a todo recém-nascido 1 mg de vitamina K por via intramuscular após o nascimento para profilaxia para doença hemorrágica. Esta informação pode ser encontrada na Ficha de Identificação do Recém-Nascido, na folha da Evolução da sala de cirurgia ou no checklist para parto seguro.',
      value: coleta.administradaVitaminaK ? 'Sim':'Não',
      obrigatorio: true
    },
    {
      identificador: 'P13',
      chave: 'identificacaoRnPulseira',
      type: 'radio',
      label: 'Foi realizada a identificação do recém-nascido com pulseira?',
      descricao: 'Esta informação pode ser encontrada na Ficha de Identificação do Recém-Nascido, na folha da Evolução da sala de cirurgia ou no checklist para parto seguro.',
      options: ['Sim', 'Não'],
      value: coleta.identificacaoRnPulseira ? 'Sim':'Não',
      obrigatorio: true
    },
    { type: 'header', text: 'Observações a respeito da coleta' },
    {
      identificador: 'P14',
      chave: 'observacoes',
      type: 'textarea',
      label: 'Observações(opcional)',
      value: coleta.observacoes
    }
  ];
}
